import React, {Component} from 'react';
import {Form, Message} from "semantic-ui-react";
import firebase from 'firebase/app';
import 'firebase/firestore';

export default class ContactForm extends Component {

    state = {name: '', email: '', phone: '', message: '', sending: false, success: false};

    handleChange = (e, {name, value}) => this.setState({[name]: value});

    handleSubmit = () => {
        const form = this.state;
        form.createdAt = new Date();
        this.setState({sending: true});
        firebase.firestore().collection("contact").add(form).then((ref) => {

            this.setState({name: '', email: '', phone: '', message: '', sending: false, success: true});
        });
    };


    render() {
        const {name, email, phone, message, sending, success} = this.state;

        return <Form onSubmit={this.handleSubmit} loading={sending} success={success}>
            <Message
                success
                header='Form successfully sent'
                content='Thank you for your message!'
            />
            <Form.Input required label='Name' name="name" value={name} onChange={this.handleChange}/>
            <Form.Group widths="equal">
                <Form.Input fluid label='E-mail' name="email" value={email} onChange={this.handleChange}/>
                <Form.Input fluid label='Phone' name="phone" value={phone} onChange={this.handleChange}/>
            </Form.Group>
            <Form.TextArea required label='Message' name="message" value={message} onChange={this.handleChange}/>
            <Form.Button type='submit'>Submit</Form.Button>
        </Form>;
    }
}