// @flow

import React, {Component} from 'react';
import {
    Card, Container, Dropdown, Grid, Icon, Label, Loader, Popup, Select,
    Transition
} from "semantic-ui-react";

import update from 'immutability-helper';
import {connect} from "react-redux";
import SearchUpdate, {
    SearchUpdateAirports,
    SearchUpdateDriving, SearchUpdateFlights, SearchUpdateFlightTransit, SearchUpdateInterpretation,
    SearchUpdateTransit, SearchUpdateTransitTickets,
    SearchUpdateTypes
} from "../models/SearchUpdate";
import ModeIcon from "./ModeIcon";
import {dateConstraint, MODES, supportedCountries} from "../constants";
import * as RouteActions from "../actions/RouteActions";
import type {Dispatch} from "../models/Dispatch";
import {formatHoursAsString} from "../utils";

type
ComponentProps = {
    active: boolean,
    by: string,
};

type
StateProps = {
    searchUpdates: Array < SearchUpdate >,
    activeRequestId: string,
    itemsInfo: Object,
    modes: Object,
}
type
DispatchProps = {
    showPlannerForm: () => Dispatch,
}

type
State = {
    requestId: string,
    visible: {
        start: boolean,
        search: boolean,
        driving: boolean,
        transit: boolean,
        transittickets: boolean,
        airports: boolean,
        flights: boolean,
        flightTransit: boolean,
    },
    interpretation: ? SearchUpdateInterpretation,
    driving: ? SearchUpdateDriving,
    transit: ? SearchUpdateTransit,
    transittickets: ? SearchUpdateTransitTickets,
    airports: ? SearchUpdateAirports,
    flights: ? {[string]: SearchUpdateFlights},
    flightTransit: ? SearchUpdateFlightTransit,
};
const defaultState: State = {
    requestId: "",
    visible: {
        start: true,
        search: true,
        driving: false,
        transit: false,
        transittickets: false,
        airports: false,
        flights: false,
        flightTransit: false,
    },
    interpretation: null,
    driving: null,
    transit: null,
    transittickets: null,
    airports: null,
    flights: null,
    flightTransit: null,
};

class LoadingAnimation extends Component<ComponentProps & StateProps & DispatchProps, State> {
    state = defaultState;

    componentWillReceiveProps(nextProps: ComponentProps & StateProps & DispatchProps) {
        let newState = this.state;

        // if(nextProps.activeRequestId){
        if (nextProps.activeRequestId !== this.state.requestId) {
            newState = update(defaultState, {
                requestId: {$set: this.state.requestId},
            })
        }
        // }

        if (nextProps.searchUpdates) {
            const interpretation = nextProps.searchUpdates.filter(searchUpdate => searchUpdate.type === "SEARCH_INTERPRETATION");
            if (interpretation.length > 0) {
                if (interpretation[0].type === SearchUpdateTypes.SEARCH_INTERPRETATION) {
                    const searchUpdate: SearchUpdateInterpretation = ((e_: any): SearchUpdateInterpretation => e_)(interpretation[0]);
                    newState = update(newState, {
                        visible: {
                            search: {$set: true},
                        },
                        interpretation: {$set: searchUpdate}
                    });
                }
            }

            const driving = nextProps.searchUpdates.filter(searchUpdate => searchUpdate.type === "DRIVING");
            if (driving.length > 0) {
                if (driving[0].type === SearchUpdateTypes.DRIVING) {
                    const searchUpdateDriving: SearchUpdateDriving = ((e_: any): SearchUpdateDriving => e_)(driving[0]);
                    newState = update(newState, {
                        visible: {
                            driving: {$set: true},
                        },
                        driving: {$set: searchUpdateDriving}
                    });
                }
            }

            const transit = nextProps.searchUpdates.filter(searchUpdate => searchUpdate.type === "TRANSIT");
            if (transit.length > 0) {
                newState = update(newState, {
                    visible: {
                        transit: {$set: true},
                    },
                    transit: {$set: transit[0]}
                });
            }

            const transittickets = nextProps.searchUpdates.filter(searchUpdate => searchUpdate.type === "TRANSITTICKETS");
            if (transittickets.length > 0) {
                newState = update(newState, {
                    visible: {
                        transittickets: {$set: true},
                    },
                    transittickets: {$set: transittickets[0]}
                });
            }

            const airports = nextProps.searchUpdates.filter(searchUpdate => searchUpdate.type === "AIRPORTS");
            if (airports.length > 0) {
                newState = update(newState, {
                    visible: {
                        airports: {$set: true},
                    },
                    airports: {$set: airports[0]}
                });
            }

            const flights = nextProps.searchUpdates.filter(searchUpdate => searchUpdate.type === "FLIGHTS");
            if (flights.length > 0) {
                newState = update(newState, {
                    visible: {
                        flights: {$set: true},
                    },
                    flights: {
                        $set: flights.numFlights,
                        // $set: flights.reduce((acc, cur) => {
                        //     // $FlowFixMe
                        //     acc[cur.airports && cur.airports[0].iata + cur.airports[1].iata] = cur;
                        //     return acc
                        // }, {})
                    }
                });
            }

            const flightTransit = nextProps.searchUpdates.filter(searchUpdate => searchUpdate.type === "FLIGHTTRANSIT");
            if (flightTransit.length > 0) {
                newState = update(newState, {
                    visible: {
                        flightTransit: {$set: true},
                    },
                    flightTransit: {$set: flightTransit[0]}
                });
            }
        }
        if (nextProps.active) {
            newState = update(newState, {
                visible: {
                    start: {$set: true},
                }
            })
        }

        this.setState(newState);
    }

    render() {
        if (!this.props.active) return null;

        // const airports = [["AMS", "BER"], ["DUS", "BER"], ["DUS", "SXN"]]

        const {visible} = this.state;
        // const loading = !visible.search || !visible.car || !visible.transit || !visible.airports || !visible.flights || !visible.flightTransit;


        return (
            <div className="loading-animation">
                <Container>
                    {/*{visible.start &&*/}
                    <Card fluid>
                        <Card.Content>
                            {!visible.search &&
                            <Loader active inline="centered" className="waiting-search-interpretation"/>}
                            {visible.search && <Grid doubling divided columns={4}>
                                <Grid.Row className="result-row">
                                    <SearchInterpretation visible={visible.search}
                                                          search={this.state.interpretation}
                                                          showPlannerForm={this.props.showPlannerForm}/>

                                    {(this.props.modes.driving.fetching || this.props.modes.driving.fetched) &&
                                    <CarResult visible={visible.driving}
                                               search={this.state.driving}/>}

                                    {(this.props.modes.transit.fetching || this.props.modes.transit.fetched) &&
                                    <TransitResult visible={visible.transit}
                                                   visibleTickets={visible.transittickets}
                                                   searchUpdateTransit={this.state.transit}
                                                   searchUpdateTransitTickets={this.state.transittickets}
                                                   itemsInfo={this.props.itemsInfo}/>}

                                    {(this.props.modes.flights.fetching || this.props.modes.flights.fetched) &&
                                    <FlightsResult airportsVisible={visible.airports}
                                                   flightsVisible={visible.flights}
                                                   transitVisible={visible.flightTransit}
                                                   searchUpdateAirports={this.state.airports}
                                                   searchUpdatesFlights={this.state.flights}
                                                   searchUpdateFlightTransit={this.state.flightTransit}
                                                   itemsInfo={this.props.itemsInfo}/>}
                                </Grid.Row>
                            </Grid>}
                        </Card.Content>
                    </Card>
                    {/*}*/}
                </Container>
            </div>
        );
    }
}

const UnsupportedLocationWarning = () => <Popup trigger={<Icon name="warning sign" color="orange"/>}
                                                content="This location is not fully supported yet, the results may be incomplete."/>;

const SearchInterpretation = (props: { visible: boolean, search: ?SearchUpdateInterpretation, showPlannerForm: () => Dispatch }) => {

    // let fromWarning = (props.search && props.search.fromCountry && supportedCountries.indexOf(props.search.fromCountry) === -1) ? <UnsupportedLocationWarning /> : null;
    // let toWarning = (props.search && props.search.toCountry && supportedCountries.indexOf(props.search.toCountry) === -1) ? <UnsupportedLocationWarning /> : null;

    let fromWarning = false;
    let toWarning = false;

    return <Grid.Column className={"search-interpretation" + (!props.search ? " loading" : "")} width={6}
                        onClick={() => props.showPlannerForm()}>
        <Icon name="arrow left" className="back-icon"/>
        {props.search ? <Transition visible={props.visible} animation="scale" duration={200}>
                <div>
                    <div className="title">Searching routes</div>
                    <div className="date-constraint-info">
                        {/*{props.search.dateConstraint === dateConstraint.DEPARTURE ? "departing" : "arriving"} between {props.search.dateFrom.format("HH:mm")} and*/}
                        {/*{" " + props.search.dateTo.format("HH:mm")}<br/>*/}
                        on {props.search.dateFrom.format("D MMMM YYYY")}
                    </div>
                    {fromWarning} {props.search && props.search.from}
                    <Icon name="long arrow down"/>
                    {toWarning} {props.search && props.search.to}<br/>
                    {/*{props.search.dateConstraint}:*/}

                </div>
            </Transition>
            :
            <Loader active={true} inline size="small"/>
        }
    </Grid.Column>;
}

let CarResult = (props: { visible: boolean, search: ?SearchUpdateDriving }) => {
    const {visible, search, drivingPax} = props;

    let text = <div/>;
    if (search) {
        const hours = formatHoursAsString(search.duration / 60);
        // const distance = Math.round(search.distance / 1000);
        text = <div><i className="fal fa-clock duration"/> {" "} {hours}</div>;
    }

    let options = [
        {
            value: "default",
            text: "Average car"
        },
        {
            value: "electric",
            text: "Electric car",
            description: "Avg. energy mix",
        },
        {
            value: "small-petrol",
            text: "Small car",
            description: "Petrol",
        },
        {
            value: "medium-petrol",
            text: "Medium car",
            description: "Petrol",
        },
        {
            value: "large-petrol",
            text: "Large car",
            description: "Petrol",
        },
        {
            value: "small-diesel",
            text: "Small car",
            description: "Diesel",
        },
        {
            value: "medium-diesel",
            text: "Medium car",
            description: "Diesel",
        },
        {
            value: "large-diesel",
            text: "Large car",
            description: "Diesel",
        },
    ];

    return <Grid.Column className="result-column" width={3}>
        <ModeIcon mode={MODES.DRIVING} size="large"/><br/>
        <Loader active={!visible} inline size="small"/>
        <Transition visible={visible} animation="scale" duration={200}>
            <div>
                {text}<br/>
                <Icon name={drivingPax > 1 ? "users" : "user"} size="small" color="grey"/>
                {drivingPax > 1 &&
                <Icon link name="minus" size="small" onClick={() => props.adjustDrivingPax("subtract")}/>}
                <span style={{color: "#555", paddingRight: '4px'}}>{drivingPax}</span>
                <Icon name="plus" size="small" link onClick={() => props.adjustDrivingPax()}/>{" "}
                <Dropdown options={options} size="mini" inline value={props.drivingType}
                          onChange={(_, data) => props.setDrivingType(data.value)}/>
            </div>
        </Transition>
    </Grid.Column>
};
CarResult = connect((state) => ({
    drivingType: state.routes.drivingType,
    drivingPax: state.routes.drivingPax,
}), (dispatch) => ({
    setDrivingType: (type) => dispatch(RouteActions.setDrivingType(type)),
    adjustDrivingPax: (type) => dispatch(RouteActions.adjustDrivingPax(type)),
}))(CarResult);

const TransitResult = (props: {
    visible: boolean,
    visibleTickets: boolean,
    searchUpdateTransit: ?SearchUpdateTransit,
    searchUpdateTransitTickets: ?SearchUpdateTransitTickets,
    itemsInfo: Object,
}) => {
    const {visible, visibleTickets, searchUpdateTransit, searchUpdateTransitTickets, itemsInfo, fetching} = props;

    let loading = !visible || !visibleTickets;
    let visibleSearchingTickets = searchUpdateTransit && !searchUpdateTransitTickets;

    let text = <div/>;
    if (searchUpdateTransit) {
        if (searchUpdateTransit.isError) {
            text = <Label color="red" size="mini">Error</Label>;
            loading = false;
            visibleSearchingTickets = false;
        } else if (searchUpdateTransit.foundRoutes) {
            if (itemsInfo && itemsInfo["shortestDurations"] && itemsInfo["shortestDurations"]["transit"] &&
                itemsInfo && itemsInfo["longestDurations"] && itemsInfo["longestDurations"]["transit"]) {
                let shortestDuration = formatHoursAsString(itemsInfo["shortestDurations"]["transit"] / 60);
                let longestDuration = formatHoursAsString(itemsInfo["longestDurations"]["transit"] / 60);
                text = <div>
                    <i className="fal fa-clock duration"/>{" "}
                    {shortestDuration}
                    {shortestDuration !== longestDuration &&
                    <span>
                        {" "}-{" "}{longestDuration}
                    </span>
                    }
                </div>
            } else {
                text = <div>Found transit routes</div>;
            }
        } else {
            text = <div>No transit routes found</div>;
        }
    }

    return <Grid.Column className="result-column" width={3}>
        <ModeIcon mode={MODES.TRANSIT} size="large"/><br/>
        <Loader active={loading} inline size="small"/>
        <Transition visible={visible} animation="scale" duration={200}>
            {text}
        </Transition>
        <Transition visible={visibleSearchingTickets} animation="scale" duration={200}>
            <div className="note">Searching for tickets.</div>
        </Transition>
    </Grid.Column>
};

const FlightsResult = (props: {
    airportsVisible: boolean,
    flightsVisible: boolean,
    transitVisible: boolean,
    searchUpdateAirports: ?SearchUpdateAirports,
    searchUpdatesFlights: ?{ [string]: SearchUpdateFlights },
    searchUpdateFlightTransit: ?SearchUpdateFlightTransit,
    itemsInfo: Object,
}) => {
    const {airportsVisible, flightsVisible, transitVisible, searchUpdateAirports, searchUpdatesFlights, itemsInfo} = props;
    const isLoading = !flightsVisible; // !airportsVisible || !flightsVisible || !transitVisible;

    let airportsText = "";
    if (searchUpdateAirports) {
        airportsText = <Grid.Column className={"airports" + (!isLoading ? " no-margin" : "")} width={8}>
            {airportsVisible && <span>{searchUpdateAirports.airports.map(couple => {
                let foundFlights = false;
                let numFlights = 0;
                if (searchUpdatesFlights && searchUpdatesFlights[couple[0].iata + couple[1].iata]) {
                    foundFlights = true;
                    numFlights = searchUpdatesFlights[couple[0].iata + couple[1].iata].numFlights;
                }

                return <div className="airport-couple" key={couple[0].iata + couple[1].iata}>
                    <Popup
                        trigger={<span>{couple[0].iata}</span>}
                        content={couple[0].name}
                        position="left center"
                        size="mini"
                        inverted/>
                    <Icon fitted name="arrow right"/>
                    <Popup
                        trigger={<span>{couple[1].iata}</span>}
                        content={couple[1].name}
                        position="right center"
                        size="mini"
                        inverted/>:
                    <Transition visible={foundFlights} animation="scale" duration={200}>
                        <span className="flights-count">
                            {/*<Icon fitted name="arrow right"/>*/}
                            {numFlights}
                        </span>
                    </Transition>
                    <Loader size="mini" inline active={!foundFlights}/>
                </div>;
            })}</span>}
        </Grid.Column>;
    }

    let text = <div/>;
    if (itemsInfo && itemsInfo["shortestDurations"] && itemsInfo["shortestDurations"]["flights"] &&
        itemsInfo && itemsInfo["longestDurations"] && itemsInfo["longestDurations"]["flights"]) {
        let shortestDuration = formatHoursAsString(itemsInfo["shortestDurations"]["flights"] / 60);
        let longestDuration = formatHoursAsString(itemsInfo["longestDurations"]["flights"] / 60);
        text = <div>
            <i className="fal fa-clock duration"/>{" "}
            {shortestDuration}
            {shortestDuration !== longestDuration &&
            <span>
                        {" "}-{" "}{longestDuration}
                    </span>
            }
        </div>
    } else {
        text = <div>Found plane routes</div>;
    }


    return <Grid.Column className="result-column" width={3}>
        <ModeIcon mode={MODES.FLYING} size="large"/><br/>
        <Loader active={isLoading} inline size="small"/>
        <Transition visible={!isLoading} animation="scale" duration={200}>
            {text}
        </Transition>
    </Grid.Column>;

    return <Grid.Column className="result-column " width={4}> {/*flight-results*/}
        <Grid>
            <Grid.Row>
                <Grid.Column fluid textAlign="center"> {/*width={8}*/}
                    <ModeIcon mode={MODES.FLYING} size="large"/>
                </Grid.Column>
                {/*<Transition visible={airportsVisible} animation="scale" duration={200}>*/}
                {/*{airportsVisible ? airportsText : <span/>}*/}
                {/*</Transition>*/}
            </Grid.Row>
            <Grid.Row>
                <Grid.Column style={{textAlign: "center"}}>
                    <Transition visible={airportsVisible && flightsVisible && !transitVisible} animation="scale"
                                duration={200}>
                        <div className="note">Searching for transit connections.<br/></div>
                    </Transition>
                    <Loader active={isLoading} inline size="small"/>
                    <Transition visible={!isLoading} animation="scale" duration={200}>
                        {text}
                    </Transition>
                </Grid.Column>
            </Grid.Row>
        </Grid>
    </Grid.Column>
};


export default connect((state) => ({
    searchUpdates: state.searchUpdates.items[state.routes.activeRequestId || state.routes.requestId],
    activeRequestId: state.routes.activeRequestId,
    itemsInfo: state.routes.itemsInfo,
    modes: state.routes.modes,
}), (dispatch) => ({
    showPlannerForm: () => dispatch(RouteActions.showPlannerForm())
}))(LoadingAnimation)