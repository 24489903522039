import React, {Component} from 'react';
import {Button, Card, Container, Grid, Header, Icon, Input, Label, Table} from "semantic-ui-react";
import {NavLink} from "react-router-dom";
import {emissionFactors, modeDescriptions, MODES} from "../constants";
import ModeIcon from "../components/ModeIcon";
import logo from "../resources/images/logo-header.png"
import trainImage from "../resources/images/fred-ohlander-679357-unsplash-small.jpg"
import jeppeShirtImage from "../resources/images/Jeppe-shirt.jpg"
import teamPhoto1 from "../resources/images/20180526_183814-small.jpg"
import teamPhoto2 from "../resources/images/ORG_DSC04818-small.JPG"
import teamPhoto3 from "../resources/images/36088178_1771297829619705_2643779583047892992_o-small.jpg"
import teamPhoto4 from "../resources/images/36036777_1771296252953196_909579480933072896_o-small.jpg"
import jb from "../resources/images/jb.jpg"
import mb from "../resources/images/mb.jpg"
import mz from "../resources/images/mz.jpg"
import el from "../resources/images/el.jpg"
import nk from "../resources/images/nk.jpg"
import IWantToHelpPage from "../pages/iWantToHelp";
import {connect} from "react-redux";
import * as TravelTipActions from "../actions/TravelTipActions";
import moment from "moment";
import TravelTip from "../components/TravelTip";
import AdsGridRow from "../components/ads/AdsGridRow";

class AboutPage extends Component {

    componentDidMount() {
        this.props.fetchTravelTipItems();
    }

    render() {

        return (
            <div className="page-container">

                <div className="about-header">
                    <div className="line-background">
                        <div className="line"/>
                        <Container>
                            <Grid>
                                <Grid.Row>
                                    <Grid.Column width={7}>
                                        <img src={logo}
                                             alt="Green Tickets | Building the European travel planner of the future"/>
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>
                        </Container>
                    </div>
                </div>

                <Container>


                    <Grid>
                        <Grid.Row>
                            <a id="planner" name="planner"> </a>
                            <h1 className="large-main-title"><span>Green Tickets in the media</span></h1>
                        </Grid.Row>

                    </Grid>
                </Container>

                <Container className="about-travel-tips-container">
                    <Grid stackable>
                        <Grid.Row className="travel-tips-container simple" centered>

                            {this.props.tips && this.props.tips.map((item, i) =>
                                <TravelTip item={item} key={"tip" + i} simple/>
                            )}

                        </Grid.Row>
                    </Grid>
                </Container>

                <Container>

                    <Grid>
                        <Grid.Row>
                            <a id="planner" name="planner"> </a>
                            <h1 className="large-main-title"><span>about Green Tickets</span></h1>
                        </Grid.Row>
                    </Grid>

                    <Card fluid>
                        <Card.Content>

                            <Grid stackable>

                                <Grid.Row>
                                    <Grid.Column textAlign="left" width={10}>
                                        <Header as='h2'>
                                            What is Green Tickets?
                                        </Header>
                                        <p>
                                            We believe travelling is amazing. But we also believe that the way we travel
                                            has
                                            direct
                                            climate impact and that we should take responsibility. If we don't make
                                            significant
                                            changes to the way we travel, it is impossible to live up to the Paris
                                            agreement. We
                                            don’t have to travel less, we need to travel differently.
                                        </p>
                                        <p>
                                            In our own daily lives we experienced how difficult it can be to find
                                            sustainable
                                            travel
                                            options in Europe. We decided to use our software engineering and data
                                            analysis
                                            skills
                                            and make Green Tickets!
                                        </p>
                                        <p>Easy, fast and cheap travel has brought us a lot: not just individual
                                            well-being and
                                            happiness, but also the spreading of wealth and a broader awareness and
                                            acceptance
                                            of cultural differences. However, <strong>the climate impact of our current
                                                travel
                                                behaviour can no longer be ignored.</strong></p>
                                    </Grid.Column>
                                    <Grid.Column width={6} textAlign="center">
                                        <img src={trainImage}/>
                                    </Grid.Column>
                                </Grid.Row>

                                <Grid.Row>
                                    <Grid.Column width={6} textAlign="center">
                                        <img src={jeppeShirtImage}/>
                                    </Grid.Column>
                                    <Grid.Column width={10}>
                                        <p>
                                            Green Tickets makes it as easy as possible for you to find all the
                                            information on
                                            sustainable European travelling you need. We collect data on possible
                                            routes,
                                            combine
                                            timetables and crack the code on the best way to get from A to B. Our
                                            comprehensive
                                            travel planner shows all options for your European travels: comparing
                                            flights,
                                            trains,
                                            buses, ferries, etc has never been easier! For every option, we show the
                                            price,
                                            duration
                                            and climate impact to help you find the best way to travel.
                                        </p>
                                        <p>
                                            We hope that in the near future all European travellers consider the climate
                                            impact
                                            of their travel before booking, and Green Tickets can make this easier for
                                            everyone.
                                            We invite you to travel with us to a better future!
                                        </p>
                                    </Grid.Column>
                                </Grid.Row>

                                <Grid.Row centered>
                                    <Grid.Column>

                                        <p>Read more on our Medium blog: <a href="https://medium.com/green-tickets"
                                                                            target="_blank"
                                                                            rel="noopener noreferrer">medium.com/green-tickets</a>
                                        </p>
                                        <p>Do you want to help us? <NavLink to="i-want-to-help"> Click here!</NavLink>
                                        </p>
                                    </Grid.Column>
                                </Grid.Row>
                                <Grid.Row centered>
                                    <Grid.Column width={4}>
                                        <Header as='h3'>
                                            Follow us
                                        </Header>

                                        <Button color='facebook' href="https://www.facebook.com/greenticketsapp/"
                                                target="_blank"
                                                rel="noopener noreferrer">
                                            <Icon name='facebook'/> Follow us on Facebook
                                        </Button><br/><br/>
                                        <Button as="a" href="https://www.facebook.com/groups/easygreentravels"
                                                target="_blank" rel="noopener noreferrer" basic color="blue">
                                            <Icon name='facebook'/> Join our Facebook
                                            group</Button><br/>
                                    </Grid.Column>
                                    <Grid.Column width={4}>
                                        <Header as='h3'>
                                            Roadmap
                                        </Header>
                                        <p>Interested to see what we're currently working on? Have a look at
                                            our <NavLink
                                                to="/roadmap">roadmap</NavLink> and vote for your favourite features!
                                        </p>
                                    </Grid.Column>
                                    <Grid.Column width={4}>
                                        <Header as='h3'>
                                            Contact us
                                        </Header>
                                        <p>Fill in our <NavLink to="/contact">Contact Form</NavLink> to get in touch, or
                                            contact
                                            us directly:</p>
                                        <Icon disabled name='mail outline'/> <a
                                        href="mailto:info@greentickets.app">info@greentickets.app</a><br/>
                                        <Icon disabled name='phone'/> <a href="tel:+31633149435">+31 6 3314
                                        9435</a><br/>
                                    </Grid.Column>
                                    <Grid.Column width={4}>
                                        <Header as='h3'>
                                            Mailing list
                                        </Header>
                                        <form
                                            action="https://getgreentickets.us17.list-manage.com/subscribe/post?u=caa6df6f2f574c93cf5b3607b&amp;id=4c3362a8f8"
                                            method="post" id="mc-embedded-subscribe-form"
                                            name="mc-embedded-subscribe-form"
                                            className="validate" target="_blank" noValidate>
                                            <div id="mc_embed_signup_scroll">
                                                <label htmlFor="mce-EMAIL">Receive updates on the development of Green
                                                    Tickets
                                                    by subscribing to our mailing list.</label><br/><br/>
                                                <Input type="email" name="EMAIL" className="email" id="mce-EMAIL"
                                                       placeholder="email address" required size="small"/>
                                                <div style={{position: "absolute", left: "-5000px"}} aria-hidden="true">
                                                    <input type="text" name="b_caa6df6f2f574c93cf5b3607b_4c3362a8f8"
                                                           tabIndex="-1"
                                                           value=""/>
                                                </div>
                                                <br/>
                                                <Input type="submit" value="Subscribe" name="subscribe"
                                                       id="mc-embedded-subscribe" size="small"/>
                                            </div>
                                        </form>
                                    </Grid.Column>
                                </Grid.Row>

                            </Grid>
                        </Card.Content>
                    </Card>

                    <Grid>
                        <Grid.Row>
                            <div style={{height: "20px"}}/>
                        </Grid.Row>


                        {/*<Grid.Row>*/}
                        {/*    <h1 className="large-main-title"><span>the team</span></h1>*/}
                        {/*</Grid.Row>*/}

                    </Grid>
                </Container>

                {/*<div className="main-background-line-container">*/}
                {/*    <div className="main-background-line"/>*/}
                {/*</div>*/}
                {/*<Container>*/}
                {/*    <Grid>*/}
                {/*        <Grid.Row className="gt-card">*/}
                {/*            <Grid.Column>*/}
                {/*                <Container>*/}
                {/*                    <Grid stackable>*/}
                {/*                        <Grid.Row centered className="team">*/}
                {/*                            <Grid.Column width={8} className="main-explanation">*/}
                {/*                                <p>*/}
                {/*                                    We are a team of young engineers with a passion for*/}
                {/*                                    travelling and a*/}
                {/*                                    drive to*/}
                {/*                                    contribute to a better world. Our mission is to create the*/}
                {/*                                    best*/}
                {/*                                    tools so*/}
                {/*                                    we can*/}
                {/*                                    continue to travel whilst decreasing our climate impact.*/}
                {/*                                </p>*/}
                {/*                                <p>*/}
                {/*                                    <span className="quote">&bdquo;We'll make sustainable traveling as easy as possible!&rdquo;</span>*/}
                {/*                                </p>*/}
                {/*                            </Grid.Column>*/}
                {/*                        </Grid.Row>*/}

                {/*                        <Grid.Row centered className="team">*/}
                {/*                            <Grid.Column width={4} style={{textAlign: "center"}}>*/}
                {/*                                <img src={jb} style={{maxWidth: "200px"}}/>*/}
                {/*                                <h3>Jeppe Bijker</h3>*/}
                {/*                                <div className="study">MSc Industrial Design Engineering</div>*/}
                {/*                                <em>Managing co-founder</em><br/>*/}
                {/*                            </Grid.Column>*/}
                {/*                            <Grid.Column width={4} style={{textAlign: "center"}}>*/}
                {/*                                <img src={el} style={{maxWidth: "200px"}}/>*/}
                {/*                                <h3>Erika van der Linden</h3>*/}
                {/*                                <div className="study">MSc student Engineering & Policy*/}
                {/*                                    Analysis*/}
                {/*                                </div>*/}
                {/*                                <em>Business Development</em><br/>*/}
                {/*                                /!*<span*/}
                {/*                                    // className="quote">&bdquo;Let's get travel back on its rails!&rdquo;</span>*!/*/}
                {/*                            </Grid.Column>*/}
                {/*                            <Grid.Column width={4} style={{textAlign: "center"}}>*/}
                {/*                                <img src={mb} style={{maxWidth: "200px"}}/>*/}
                {/*                                <h3>Marthijn Bontekoning</h3>*/}
                {/*                                <div className="study">MSc European Wind Energy Master</div>*/}
                {/*                                <em>Advising co-founder</em><br/>*/}
                {/*                                /!*<span className="quote">&bdquo;Let's get sustrainable!&rdquo;</span>*!/*/}
                {/*                            </Grid.Column>*/}
                {/*                        </Grid.Row>*/}

                {/*                        <Grid.Row centered className="team">*/}
                {/*                            <Grid.Column width={4} style={{textAlign: "center"}}>*/}
                {/*                                <img src={mz} style={{maxWidth: "200px"}}/>*/}
                {/*                                <h3>Martine de Zoeten</h3>*/}
                {/*                                <div className="study">BSc student Governance, Economics &*/}
                {/*                                    Development*/}
                {/*                                </div>*/}
                {/*                                /!*<div className="extra">Co-founder <a href="" target="_blank" rel="noopener noreferrer">Miles that Matter</a></div>*!/*/}
                {/*                                <em>Social Media & Marketing</em><br/>*/}
                {/*                                /!*<span className="quote">&bdquo;Let's get travel back on its rails!&rdquo;</span>*!/*/}
                {/*                            </Grid.Column>*/}

                {/*                            <Grid.Column width={4} style={{textAlign: "center"}}>*/}
                {/*                                <img src={nk} style={{maxWidth: "200px"}}/>*/}
                {/*                                <h3>Naomi van Kuijk</h3>*/}
                {/*                                <div className="study">BSc Logistics Engineering</div>*/}
                {/*                                <em>Content</em><br/>*/}
                {/*                                /!*<span className="quote">&bdquo;We'll make sustainable traveling as easy as possible!&rdquo;</span>*!/*/}
                {/*                            </Grid.Column>*/}
                {/*                        </Grid.Row>*/}
                {/*                    </Grid>*/}
                {/*                </Container>*/}
                {/*            </Grid.Column>*/}
                {/*        </Grid.Row>*/}

                {/*        <Grid.Row centered style={{marginTop: "30px"}}>*/}
                {/*            <Grid.Column width={5} style={{paddingTop: "25px"}}>*/}
                {/*                <img src={teamPhoto2}/>*/}
                {/*            </Grid.Column>*/}
                {/*            <Grid.Column width={3}>*/}
                {/*                <img src={teamPhoto3}/>*/}
                {/*            </Grid.Column>*/}
                {/*            <Grid.Column width={5} style={{paddingTop: "30px"}}>*/}
                {/*                <img src={teamPhoto1}/>*/}
                {/*            </Grid.Column>*/}
                {/*            <Grid.Column width={3}>*/}
                {/*                <img src={teamPhoto4}/>*/}
                {/*            </Grid.Column>*/}
                {/*        </Grid.Row>*/}

                {/*        <Grid.Row style={{marginBottom: '40px'}}>*/}
                {/*            <h1 className="large-main-title"><span>join our mission</span></h1>*/}
                {/*        </Grid.Row>*/}

                {/*        <IWantToHelpPage/>*/}

                {/*    </Grid>*/}

                {/*</Container>*/}

                <Grid stackable style={{marginTop: '100px'}}>

                    <AdsGridRow/>

                    <Grid.Row>
                        <div style={{height: "100px"}}/>
                    </Grid.Row>
                </Grid>

            </div>
        );
    }
}


export default connect(state => ({
    // offersFetching: state.offers.fetching,
    tips: state.travelTips.items.filter(tip => tip.tags && tip.tags.indexOf("publicity") > -1).slice(0, 6),
    // offers: state.offers.items,
}), dispatch => ({
    // fetchOfferItems: () => dispatch(fetchOfferItems(1))
    fetchTravelTipItems: () => dispatch(TravelTipActions.fetchTravelTipItems()),
}))(AboutPage)