import React from 'react'
import {Button, Label, Grid, Icon, Divider} from "semantic-ui-react";
import moment from "moment";
import TipTag from "./TipTag";

const TravelTip = ({item, simple, edit}) =>
    <Grid.Column width={simple ? 3 : 5} key={item.id} className="column">
        <div className={"gt-card" + (!item.active ? " inactive" : "")}>
            <a href={item.url} target="_blank" rel="noopener noreferrer">
                <h2>{item.name}</h2>
            </a>
            <h5 className="subtitle">{item.subtitle}</h5>
            {!simple && <div>
                {item.description}
            </div>}
            {item.date && <div className="date">
                {moment(item.date).format("D MMMM Y")}
            </div>}
            {item.url &&
            <a href={item.url} target="_blank" rel="noopener noreferrer">
                {item.price ?
                    <Button basic size={simple ? "tiny" : "small"} as="div"
                            labelPosition="left" style={{marginTop: '8px'}}>
                        <Label basic color="blue" pointing="right">
                            {item.urlText ? item.urlText : item.url.replace("http://www.", "").replace("https://www.", "")}
                        </Label>
                        <Button>
                            {item.price}
                        </Button>
                    </Button>
                    :
                    <Button basic color="blue" size={simple ? "tiny" : "small"}
                            style={{fontWeight: "800", marginTop: '8px'}}>
                        {item.urlText ? item.urlText : item.url.replace("http://www.", "").replace("https://www.", "")}
                    </Button>
                }
            </a>
            }
            {item.topHtml && <div className="html-container"
                                  dangerouslySetInnerHTML={{__html: item.topHtml}}/>}
            {item.image && <div className="image-container">
                <a href={item.url} target="_blank"
                   rel="noopener noreferrer">
                    <img src={item.image} alt={item.name}/>
                </a>
            </div>}
            {!simple &&
            <div className="tags">
                {item.tags && item.tags.sort((a, b) => a > b).map(tag =>
                    <TipTag tag={tag} key={item.id + tag}/>
                )}
            </div>
            }
            {edit && <div>
                <Divider />
                {edit}
                {!item.active && <Label color="red">Hidden</Label>}
            </div>}
        </div>
    </Grid.Column>;


export default TravelTip;