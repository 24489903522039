const RouteDetailsCloseControl = (controlDiv, callback) => {

    // Set CSS for the control border.
    let controlUI = document.createElement('div');
    // controlUI.style.backgroundColor = '#fff';
    // controlUI.style.border = '2px solid #fff';
    // controlUI.style.borderRadius = '3px';
    // controlUI.style.boxShadow = '0 2px 6px rgba(0,0,0,.3)';
    controlUI.style.cursor = 'pointer';
    // controlUI.style.margin = '22px';
    controlUI.style.marginRight = "10px";
    controlUI.style.textAlign = 'center';
    controlUI.title = 'Close';
    controlDiv.appendChild(controlUI);

    // Set CSS for the control interior.
    let controlText = document.createElement('i');
    controlText.className = 'far fa-times';
    controlText.style.fontSize = '16px';
    controlText.style.color = '#555';
    controlText.style.lineHeight = '38px';
    controlText.style.paddingLeft = '5px';
    controlText.style.paddingRight = '5px';
    controlText.style.textShadow = '0 2px 6px rgba(0,0,0,.3)';
    controlUI.appendChild(controlText);

    // Setup the click event listeners: simply set the map to Chicago.
    controlUI.addEventListener('click', function () {
        callback();
    });

};

export default RouteDetailsCloseControl;