import React, {Component} from 'react';
import PlannerPage from './pages/planner-new'; //
// import PlannerPage from './pages/planner-matrix'; //
import CheckoutPage from './pages/checkout';
import {
    BrowserRouter as Router,
    Route,
    // Redirect,
    NavLink, Switch
} from 'react-router-dom'
import {
    Dropdown,
    Icon,
    Input,
    Label,
    Loader,
    Menu,
    Sidebar,
    Responsive,
    Grid,
    Segment,
    Button
} from "semantic-ui-react";
import AboutPage from "./pages/about";
// import TravelTipsPage from "./pages/travel-tips";
import * as ReactGA from "react-ga";
import GlobalFeedbackButton from "./components/GlobalFeedbackButton";
import RouteForward from "./components/RouteForward";
import Footer from "./components/Footer";
import logo from "./resources/images/green-tickets-logo.png"
import ContactPage from "./pages/contact";
import RoadMapPage from "./pages/roadMap";
import PageErrorBoundary from "./components/PageErrorBoundary";
import Homepage from "./pages/homepage";
import InterrailPage from "./pages/interrail";
import * as RouteActions from "./actions/RouteActions";
import * as UserActions from "./actions/UserActions";
import {connect} from "react-redux";
import NewsletterSubscribeButton from "./components/NewsletterSubscribeButton";
import IWantToHelpPage from "./pages/iWantToHelp";
import CompensationInfoPage from "./pages/compensationInfoPage";
import InteractiveMapPage from "./pages/interactive-map";
import ClimateImpactPage from "./pages/climate-impact";
import Loadable from 'react-loadable';
import MapMaker from "./homepage/MapMaker";
import 'semantic-ui-css/semantic.min.css';
import SignInModal from "./components/SignInModal";
import EditStoryStatusBar from "./components/stories/EditStoryStatusBar";

if (window.Raven) {
    window.Raven.config('https://252a1de915f145dca8285a4df0d43c53@sentry.io/290963').install();
}

const LoadingPage = props => {
    if (props.error) {
        console.error(props.error);
        return <div style={{paddingTop: '120px', color: '#ccc'}}>Something went wrong...<br/><br/><Button
            onClick={props.retry}>Retry</Button></div>;
    } else if (props.pastDelay) {
        return <div style={{paddingTop: '120px'}}><Loader active={true}/></div>;
    } else {
        return null;
    }
};
const InfographicPage = Loadable({
    loader: () => import('./pages/infographic'),
    loading: LoadingPage,
});
const RouteInfoPage = Loadable({
    loader: () => import('./pages/route'),
    loading: LoadingPage,
});
const TravelTipsPage = Loadable({
    loader: () => import('./pages/travel-tips'),
    loading: LoadingPage,
});
const AdminPage = Loadable({
    loader: () => import('./pages/admin'),
    loading: LoadingPage,
});
const BlogPage = Loadable({
    loader: () => import('./pages/blog'),
    loading: LoadingPage,
});
const ProfilePage = Loadable({
    loader: () => import('./pages/profile'),
    loading: LoadingPage,
});
const AddStoryPage = Loadable({
    loader: () => import('./components/stories/add'),
    loading: LoadingPage,
});
const StoryPage = Loadable({
    loader: () => import('./components/stories/story'),
    loading: LoadingPage,
});
const StoriesPage = Loadable({
    loader: () => import('./pages/stories'),
    loading: LoadingPage,
});

const PlannerWithParams = ({match, location, history}) => (
    <PlannerPage params={match.params} location={location} history={history}/>
);
const HomepageWithParams = ({match, location, history}) => (
    <Homepage history={history}/>
);

const RouteWithErrorBoundary = (props) => <PageErrorBoundary>
    <Route {...props} />
</PageErrorBoundary>;

class App extends Component {

    state = {
        sidebarVisible: false,
        showSignInModal: false,
    };

    toggleSidebar() {
        this.setState({sidebarVisible: !this.state.sidebarVisible});
    };

    handleSidebarHide = () => this.setState({sidebarVisible: false});


    static logPageView() {
        window.scrollTo(0, 0);
        ReactGA.set({page: window.location.pathname});
        ReactGA.pageview(window.location.pathname);
        return null;
    };

    render() {
        const {activeItem} = this.state;
        const {signIn, authenticated} = this.props;

        return <Router>

            <div className="fullHeight">

                <Route component={App.logPageView}/>

                <Grid>
                    <Grid.Row columns={1}>
                        <Grid.Column only="computer tablet">

                            <Menu fixed="top">
                                <Menu.Item as={NavLink} to="/" exact header className="home"
                                           onClick={() => this.props.showPlannerForm()}>
                                    <img src={logo} alt="Green Tickets" className="logo"/>
                                    <strong>Green Tickets</strong>
                                </Menu.Item>

                                <Menu.Menu position='right'>
                                    <Menu.Item as={NavLink} to="/" exact name='home'/>

                                    <Dropdown item text='Travel Information'>
                                        <Dropdown.Menu>
                                            <Dropdown.Item as={NavLink} to="/map" exact text='European Travel Map'/>
                                            <Dropdown.Item as={NavLink} to="/travel-tips" exact text='Travel Tips'/>
                                            <Dropdown.Item as={NavLink} to="/interrail" exact text='Interrail'/>
                                        </Dropdown.Menu>
                                    </Dropdown>

                                    <Dropdown item text='Sustainability'>
                                        <Dropdown.Menu>
                                            <Dropdown.Item as={NavLink} to="/infographic" exact text='Infographic'/>
                                            <Dropdown.Item as={NavLink} to="/climate-impact" text='Climate Impact'/>
                                        </Dropdown.Menu>
                                    </Dropdown>

                                    <Dropdown item text='About us'>
                                        <Dropdown.Menu>
                                            <Dropdown.Item as={NavLink} to="/about" exact text='About Green Tickets'/>
                                            {/*<Dropdown.Item as={NavLink} to="/roadmap" exact text='Roadmap'/>*/}
                                            <Dropdown.Item as={NavLink} to="/blog" text='Blog'/>
                                            <Dropdown.Item as={NavLink} to="/contact" text='Contact'/>
                                        </Dropdown.Menu>
                                    </Dropdown>


                                    {/*{authenticated &&*/}
                                    {/*<Menu.Item as={NavLink} to="/profile" exact name='My Green Tickets'/>*/}
                                    {/*    // :*/}
                                        {/*// <Menu.Item name='Sign In' onClick={() => signIn()} />*/}


                                </Menu.Menu>
                            </Menu>
                        </Grid.Column>
                    </Grid.Row>

                    <Grid.Row columns={1}>
                        <Grid.Column only="mobile">
                            <Menu fixed="top">
                                <Menu.Item as={NavLink} to="/" exact header className="home"
                                           onClick={() => this.props.showPlannerForm()}>
                                    <img src={logo} alt="Green Tickets" className="logo"/>
                                    <strong>Green Tickets</strong>
                                </Menu.Item>

                                <Menu.Menu position='right'>
                                    <Menu.Item onClick={() => this.toggleSidebar()}>
                                        <Icon name="sidebar"/>
                                    </Menu.Item>
                                </Menu.Menu>

                            </Menu>
                        </Grid.Column>
                    </Grid.Row>

                </Grid>

                <RouteWithErrorBoundary exact path="/stories/edit/:id" component={EditStoryStatusBar}/>

                <Sidebar.Pushable style={{transform: "none"}}>
                    <Sidebar
                        as={Menu}
                        animation='overlay'
                        inverted
                        onHide={this.handleSidebarHide}
                        vertical
                        visible={this.state.sidebarVisible}
                        direction="right"
                        width='wide'
                        onClick={this.handleSidebarHide}
                    >
                        <Menu.Item as={NavLink} to="/" exact name='home'/>

                        <Menu.Item header>TRAVEL INFORMATION</Menu.Item>
                        <Menu.Item as={NavLink} to="/map" exact name='European Travel Map'/>
                        <Menu.Item as={NavLink} to="/travel-tips" exact name='Travel Tips'/>
                        <Menu.Item as={NavLink} to="/interrail" exact name='Interrail'/>

                        <Menu.Item header>SUSTAINABILITY</Menu.Item>
                        <Menu.Item as={NavLink} to="/infographic" exact name='Infographic'/>
                        <Menu.Item as={NavLink} to="/climate-impact" name='Climate Impact'/>

                        <Menu.Item header>ABOUT US</Menu.Item>
                        <Menu.Item as={NavLink} to="/about" exact name='About Green Tickets'/>
                        {/*<Menu.Item as={NavLink} to="/roadmap" exact name='Roadmap'/>*/}
                        <Menu.Item as={NavLink} to="/blog" name='Blog'/>

                        <Menu.Item as={NavLink} to="/contact" name='contact'/>

                        {authenticated &&
                        <Menu.Item as={NavLink} to="/profile" exact name='My Green Tickets'/>
                            // :
                            // <Menu.Item name='Sign In' onClick={() => signIn()} />
                        }

                    </Sidebar>

                    <Sidebar.Pusher>
                        <RouteWithErrorBoundary exact path="/" component={HomepageWithParams}/>
                        <RouteWithErrorBoundary exact path="/infographic" component={InfographicPage}/>
                        <RouteWithErrorBoundary path="/admin" component={AdminPage}/>
                        <RouteWithErrorBoundary exact path="/blog" component={BlogPage}/>
                        <RouteWithErrorBoundary path="/blog/:id" component={BlogPage}/>
                        <RouteWithErrorBoundary exact path="/about" component={AboutPage}/>
                        <RouteWithErrorBoundary exact path="/profile" component={ProfilePage}/>
                        <RouteWithErrorBoundary exact path="/travel-tips" component={TravelTipsPage}/>
                        <RouteWithErrorBoundary exact path="/checkout" component={CheckoutPage}/>
                        <RouteWithErrorBoundary exact path="/climate-impact" component={ClimateImpactPage}/>
                        <RouteWithErrorBoundary path="/climate-impact/:part/:subpart?" component={ClimateImpactPage}/>
                        <RouteWithErrorBoundary exact path="/checkout/result/:result/" component={CheckoutPage}/>
                        <RouteWithErrorBoundary exact path="/checkout/cart/:co2/:donation" component={CheckoutPage}/>
                        <RouteWithErrorBoundary exact path="/info/:part" component={CompensationInfoPage}/>
                        <RouteWithErrorBoundary exact path="/i-want-to-help" component={IWantToHelpPage}/>
                        <RouteWithErrorBoundary exact path="/roadmap" component={RoadMapPage}/>
                        <RouteWithErrorBoundary exact path="/map" component={InteractiveMapPage}/>
                        <RouteWithErrorBoundary exact path="/contact" component={ContactPage}/>
                        <RouteWithErrorBoundary exact path="/interrail" component={InterrailPage}/>
                        <RouteWithErrorBoundary exact path="/from/:from/to/:to/on/:on/by/:by"
                                                component={PlannerWithParams}/>
                        <RouteWithErrorBoundary exact path="/from/:from/to/:to/on/:on" component={PlannerWithParams}/>
                        <RouteWithErrorBoundary exact path="/old" component={PlannerWithParams}/>
                        <RouteWithErrorBoundary exact path="/from/:from/to/:to" component={PlannerWithParams}/>
                        <RouteWithErrorBoundary exact path="/to/:to" component={PlannerWithParams}/>
                        <RouteWithErrorBoundary exact path="/from/:to" component={PlannerWithParams}/>

                        <RouteWithErrorBoundary exact path="/route/:routeId" component={RouteForward}/>
                        <RouteWithErrorBoundary exact path="/train/:routeId" component={RouteInfoPage}/>

                        <RouteWithErrorBoundary exact path="/mapmaker" component={MapMaker}/>
                        <RouteWithErrorBoundary exact path="/create-map" component={MapMaker}/>
                        <RouteWithErrorBoundary exact path="/mapmaker/:userId/:id" component={MapMaker}/>
                        <RouteWithErrorBoundary exact path="/maps/:userId/:id" component={MapMaker}/>

                        <Switch>
                            <RouteWithErrorBoundary exact path="/stories/add" component={AddStoryPage}/>
                            <RouteWithErrorBoundary exact path="/stories/edit-route/:editId" component={AddStoryPage}/>
                            <RouteWithErrorBoundary exact path="/stories/edit/:editId" component={StoryPage}/>
                            <RouteWithErrorBoundary exact path="/stories/:id" component={StoryPage}/>
                            <RouteWithErrorBoundary exact path="/stories" component={StoriesPage}/>
                        </Switch>

                    </Sidebar.Pusher>
                </Sidebar.Pushable>

                <GlobalFeedbackButton source="global">
                    <div key="button" className="global-feedback-button">
                        <i className="fas fa-comment fa-flip-horizontal"/> Feedback
                    </div>
                </GlobalFeedbackButton>

                <Footer/>
                <SignInModal/>

            </div>
        </Router>
            ;
    }
}


export default connect((store) => ({
    authenticated: store.user.authenticated,
}), (dispatch) => ({
    showPlannerForm: () => dispatch(RouteActions.showPlannerForm()),
    signIn: () => dispatch(UserActions.signIn()),
}))(App);
