import React from "react";
import Autocomplete from '../components/AutocompletePlaceInput.js';
import greatcircle from 'great-circle'
import {Button, Card, Checkbox, Dropdown, Grid, Icon, Input, Loader} from "semantic-ui-react";

// import co2about from "../resources/images/co2-about.png"
import {Link} from "react-router-dom";
import {holidayDescriptions, holidayOptions} from "../constants";


const CO2factor = {
    flying: 0.297
};
const priceFactor = 20 / 1000;

const donationOptions = [
    {
        text: 'Compensate your flight',
        value: 'Flight',
    },
    {
        text: 'Compensate your holiday',
        value: 'Complete Holiday',
    },
    {
        text: 'Compensate custom CO2',
        value: 'Custom',
    },
    {
        text: 'Only donation',
        value: 'Donation',
    },
];
const paxOptions = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map(nr => {
    return {text: nr, value: nr}
});


export default class CO2Compensation extends React.Component {

    fieldTo = null;
    fieldVia = null;
    fieldFrom = null;

    constructor(props) {
        super(props);

        this.state = {
            page: props.page || "about",

            airportsfrom: [],
            airportsto: [],
            airportsvia: [],

            fieldfrom: "",
            fieldto: "",
            fieldvia: "",
            fieldretour: true,
            showViaAirport: false,

            airportfrom: null,
            airportto: null,
            airportvia: null,

            holiday: null,
            holidayMode: "Plane",

            description: null,
            pax: 1,
            distance: 0,
            co2: 0,

            donation: 0,

            visiblePage: 'Flight'
        };

    }


    handleChangeForNameField(field, value) {

        this.setState({
            ["airports" + field]: this.airportsArray.filter(airport => filterAirport(airport, value)).sort((a, b) => sortAirports(a, b, value)).slice(0, 5),
            ["field" + field]: value,
        })
    }

    handleChange(name, data) {
        this.setState({
            [name]: data.value,
        }, () => {
            if (name === "pax") this.calculateFlightCO2();
        })
    }

    handleRetour(value) {
        this.setState({fieldretour: value}, () => this.calculateFlightCO2())
    }

    handleCustom(co2, description) {
        const price = co2 * priceFactor;

        this.setState({
            description,
            co2,
            price,
            donation: 0,
        })
    }

    handleDonation(donation) {
        donation = parseFloat(donation.replace(",", "."));
        this.setState({
            co2: 0,
            donation,
            price: donation
        })
    }

    handleHoliday(holiday) {
        let holidayMode = this.state.holidayMode;
        if (holiday === "intercontinental") {
            this.setState({
                holidayMode: "Plane"
            });
            holidayMode = "Plane";
        }
        let description = holidayDescriptions[holiday] + " by " + holidayMode.toLowerCase();
        this.handleCustom(holidayOptions[holiday][holidayMode], description);
        this.setState({holiday});
    }

    handleHolidayMode(holidayMode) {
        this.setState({
            holidayMode
        });

        if (this.state.holiday != null) {
            let description = holidayDescriptions[this.state.holiday] + " by " + holidayMode.toLowerCase();
            this.handleCustom(holidayOptions[this.state.holiday][holidayMode], description)
        }
    }

    selectPlaceID(field, value) {
        let airport = this.airports[value];
        airport.place_id = value;
        this.setState({
            ["field" + field]: this.airports[value].name,
            ["airport" + field]: airport,
        }, () => {
            this.calculateFlightCO2()
        });

        if (field === "from") {
            this.fieldTo.focus()
        }
    }

    calculateFlightCO2() {
        const {airportfrom, airportto, airportvia, fieldretour, pax} = this.state;

        if (airportfrom !== null && airportto !== null) {

            let distance = Math.round(greatcircle.distance(airportfrom.lat, airportfrom.lng, airportto.lat, airportto.lng, "KM"));
            let placeIds = [airportfrom.place_id];

            if (airportvia !== null) {
                distance = Math.round(greatcircle.distance(airportfrom.lat, airportfrom.lng, airportvia.lat, airportvia.lng, "KM")) +
                    Math.round(greatcircle.distance(airportvia.lat, airportvia.lng, airportto.lat, airportto.lng, "KM"));
                placeIds.push(airportvia.place_id);
            }

            distance *= (fieldretour ? 2 : 1);
            placeIds.push(airportto.place_id);

            const co2 = Math.round(distance * CO2factor.flying * pax);
            const price = co2 * priceFactor;

            let description = pax + "x " + (fieldretour ? " roundtrip " : "") + placeIds.join(" - ");

            this.setState({
                distance,
                co2,
                price,
                description,
                donation: 0,
            })
        }
    }

    handleItemClick(event, data) {
        this.setState({visiblePage: data.value})
    }

    loadAirports() {

        if (!this.airportsArray || this.airportsArray.length === 0) {

            import('../iata.json').then(airports => {

                this.airports = airports;
                this.airportsArray = Object.keys(airports).map(id => ({
                    place_id: id,
                    name: airports[id].name,
                    city: airports[id].city,
                    size: airports[id].size,
                    country: airports[id].country,
                    structured_formatting: {
                        main_text: id + " " + airports[id].name
                    }
                })).filter(airport => airport && airport.name);

                this.setState({
                    airportsfrom: this.airportsArray.filter(a =>
                        a.place_id === "AMS" ||
                        a.place_id === "EIN" ||
                        a.place_id === "RTM" ||
                        a.place_id === "MST" ||
                        a.place_id === "GRQ"
                    )
                })

            });
        }

    }

    setPage(page) {
        this.setState({page});

        if (page === "flight") {
            this.loadAirports();
        }

    }

    componentWillReceiveProps(nextProps, nextContext) {

        if(nextProps.page){
            this.setPage(nextProps.page);
        }


    }

    render() {
        const {page} = this.state;

        return <Card className="co2-compensation" fluid>
            <Card.Header className="title">
                <Link to="/info/faq" className="faq"><Icon name="question circle"/></Link>
                <h1>Compensate your climate impact & help build Green Tickets</h1>
            </Card.Header>
            <Card.Content className="content">
                <Grid stackable>
                    <Grid.Row>
                        <Grid.Column className="menu" width={4}>
                            <ul>
                                <li onClick={() => this.setPage("about")} className={page === "about" ? "active" : ""}>
                                    <Icon
                                        name="cloud"/> About Carbon Offset
                                </li>
                            </ul>
                            <div className="in-menu-text">Calculate your emissions</div>
                            <ul>
                                <li onClick={() => this.setPage("flight")}
                                    className={page === "flight" ? "active" : ""}><Icon
                                    name="plane"/> Calculate Flight
                                </li>
                                {/*<li onClick={() => this.setPage("roadtrip")} className={page === "roadtrip" ? "active" : ""}>
                            <Icon name="car"/> Roadtrip
                        </li>*/}
                                <li onClick={() => this.setPage("holiday")}
                                    className={page === "holiday" ? "active" : ""}><Icon
                                    name="travel"/> Complete Holiday
                                </li>
                                <li onClick={() => this.setPage("amount")}
                                    className={page === "amount" ? "active" : ""}><Icon
                                    name="cloud"/> CO<sub>2</sub> Amount
                                </li>
                                <li onClick={() => this.setPage("donation")}
                                    className={page === "donation" ? "active" : ""}>
                                    <Icon name="gift"/> Donation Only
                                </li>
                            </ul>
                        </Grid.Column>
                        {page === "about" && (<Grid.Column width={12} className="page about">
                            {/*<img src={co2about} className="top-image" alt="About Carbon Offsetting"/>*/}
                            <h1>Carbon Crowdfunding</h1>
                            <p>By offsetting the carbon emissions of your travel through Green Tickets your money will
                                have
                                triple impact:</p> {/* Via flight numbers? */}
                            <p/>
                            <dl className="list">
                                <dt>
                                    <strong>Compensate your carbon emissions by decreasing carbon emissions
                                        elsewhere.</strong><br/>
                                    Compensating through Green Tickets finances
                                    water filters in Laos, where consequently less wood has to be burned to cook water.
                                </dt>
                                <dt>
                                    <strong>Improve the lives of people.</strong><br/>
                                    By
                                    providing water filters in Laos, health and safety hazards will be reduced and the
                                    quality
                                    of life of those affected will increase.
                                </dt>
                                <dt>
                                    <strong>Help fund Green Tickets and stimulate sustainable traveling within
                                        Europe!</strong><br/>
                                    Green Tickets will make it
                                    easier for everyone to travel with smaller climate impact, thereby making your
                                    compensation go even further.
                                </dt>
                            </dl>
                            <p>
                                <Link to="/info/faq"><Button basic size="tiny">Read more <Icon
                                    name="arrow right"/></Button></Link>
                            </p>

                        </Grid.Column>)}
                        {page === "flight" && (<Grid.Column width={12} className="page">
                            <h1>Compensate a flight</h1>
                            <p>
                                Flying causes a significant amount of CO<sub>2</sub> emissions. However, the required
                                carbon
                                offset is
                                often not expensive. Use the form below to calculate how much CO<sub>2</sub> your flight
                                trip
                                emitted.
                            </p>

                            {(this.airportsArray || this.state.airportsfrom.length > 0) ?
                                <div className="content-flight">
                                    <div className="ui input field-holders-container">
                                        <div className="field-holder autocomplete-holder">
                                            <span className="label">From</span>
                                            <Autocomplete
                                                items={this.state.airportsfrom}
                                                name="donationFrom"
                                                value={this.state.fieldfrom}
                                                inputProps={{
                                                    placeholder: "Enter the name of an airport",
                                                    className: "donation-airport"
                                                }}
                                                onChange={(e) => this.handleChangeForNameField("from", e.target.value)}
                                                onSelect={(value) => this.selectPlaceID("from", value)}
                                                selectOnBlur={true}
                                                hideGoogle
                                            />
                                        </div>
                                        {this.state.showViaAirport &&
                                        <Icon name="long arrow right" className="flights-arrow"/>}
                                        {this.state.showViaAirport &&
                                        <div className="field-holder autocomplete-holder">
                                            <span className="label">Via</span>
                                            <Icon className="hide" name="close" onClick={() => this.setState({
                                                showViaAirport: false,
                                                fieldvia: "",
                                                airportvia: null
                                            }, () => this.calculateFlightCO2())}/>
                                            <Autocomplete
                                                items={this.state.airportsvia}
                                                inputRef={el => this.fieldvia = el}
                                                name="donationVia"
                                                value={this.state.fieldvia}
                                                inputProps={{
                                                    placeholder: "Enter the name of an airport",
                                                    className: "donation-airport donation-airport-to"
                                                }}
                                                onChange={(e) => this.handleChangeForNameField("via", e.target.value)}
                                                onSelect={(value) => this.selectPlaceID("via", value)}
                                                selectOnBlur={true}
                                                hideGoogle
                                            />
                                        </div>}
                                        <Icon name="long arrow right" className="flights-arrow"/>
                                        <div className="field-holder autocomplete-holder">
                                            <span className="label">To</span>
                                            <Autocomplete
                                                items={this.state.airportsto}
                                                inputRef={el => this.fieldTo = el}
                                                name="donationTo"
                                                value={this.state.fieldto}
                                                inputProps={{
                                                    placeholder: "Enter the name of an airport",
                                                    className: "donation-airport donation-airport-to"
                                                }}
                                                onChange={(e) => this.handleChangeForNameField("to", e.target.value)}
                                                onSelect={(value) => this.selectPlaceID("to", value)}
                                                selectOnBlur={true}
                                                hideGoogle
                                            />
                                        </div>

                                        {!this.state.showViaAirport &&
                                        <Button icon="plus" content="Via" size="tiny" onClick={() => {
                                            this.setState({showViaAirport: true})
                                        }}/>
                                        }
                                    </div>

                                    <br/><br/>

                                    <div className="donation-retour">
                                        <div className="ui input field-holders-container">

                                            <div className="field-holder">
                                                <span className="label">Roundtrip</span>
                                                <Checkbox toggle checked={this.state.fieldretour}
                                                          onChange={(e, data) => this.handleRetour(data.checked)}/>
                                            </div>

                                            <div className="field-holder pax-holder">
                                                <span className="label">Number of passengers</span>
                                                <Dropdown selection compact options={paxOptions} value={this.state.pax}
                                                          onChange={(e, data) => this.handleChange("pax", data)}/>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="distance">
                                        <br/>
                                        {this.state.distance ? ("Your flights distance is estimated at " + Math.round(this.state.distance).toLocaleString() + "km") : "Please enter two airports."}
                                    </div>
                                </div> : <Loader active/>
                            }
                        </Grid.Column>)}
                        {page === "roadtrip" && (<Grid.Column width={12} className="page">
                            <h1>Compensate a roadtrip</h1>
                            <p>
                                Travelling by car can be a sustainable option when the car is completely filled. Use the
                                form
                                below to calculate the CO<sub>2</sub> emissions of your trip. You can change the millage
                                if you
                                know it.
                            </p>
                        </Grid.Column>)}
                        {page === "holiday" && (<Grid.Column width={12} className="page">
                            <h1>Compensate your holiday</h1>
                            <p>
                                You can also compensate a whole holiday. We’ve compiled several
                                typical holidays, where we include both transport, stay and activities.
                            </p>
                            <div className="content-holiday">
                                <br/>
                                <Button.Group>
                                    <Button primary={this.state.holidayMode === "Plane"}
                                            onClick={() => this.handleHolidayMode("Plane")}>Plane</Button>
                                    <Button primary={this.state.holidayMode === "Car"}
                                            onClick={() => this.handleHolidayMode("Car")}>Car</Button>
                                    <Button primary={this.state.holidayMode === "Car Sharing"}
                                            onClick={() => this.handleHolidayMode("Car Sharing")}>Car Sharing</Button>
                                    <Button primary={this.state.holidayMode === "Train / Bus"}
                                            onClick={() => this.handleHolidayMode("Train / Bus")}>Train / Bus</Button>
                                </Button.Group>
                                <br/><br/>
                                <Grid>
                                    <Grid.Row>
                                        <Grid.Column width={8}>
                                            <Card onClick={() => {
                                                this.handleHoliday("weekend")
                                            }} className={this.state.holiday === "weekend" ? "active" : ""}>
                                                <Card.Content>
                                                    <Card.Header>
                                                        Weekend Europe
                                                    </Card.Header>
                                                    <Card.Meta>
                                            <span className='date'>
                                                {holidayOptions["weekend"][this.state.holidayMode]} kg CO<sub>2</sub>
                                            </span>
                                                    </Card.Meta>
                                                </Card.Content>
                                            </Card>
                                        </Grid.Column>
                                        <Grid.Column width={8}>
                                            <Card onClick={() => {
                                                this.handleHoliday("week")
                                            }} className={this.state.holiday === "week" ? "active" : ""}>
                                                <Card.Content>
                                                    <Card.Header>
                                                        Week Europe
                                                    </Card.Header>
                                                    <Card.Meta>
                                            <span className='date'>
                                              {holidayOptions["week"][this.state.holidayMode]} kg CO<sub>2</sub>
                                            </span>
                                                    </Card.Meta>
                                                </Card.Content>
                                            </Card>
                                        </Grid.Column>
                                        <Grid.Column width={8}>
                                            <Card onClick={() => {
                                                this.handleHoliday("weeks")
                                            }} className={this.state.holiday === "weeks" ? "active" : ""}>
                                                <Card.Content>
                                                    <Card.Header>
                                                        3 weeks Europe
                                                    </Card.Header>
                                                    <Card.Meta>
                                            <span className='date'>
                                              {holidayOptions["weeks"][this.state.holidayMode]} kg CO<sub>2</sub>
                                            </span>
                                                    </Card.Meta>
                                                </Card.Content>
                                            </Card>
                                        </Grid.Column>
                                        <Grid.Column width={8}>
                                            {this.state.holidayMode === "Plane" && <Card onClick={() => {
                                                this.handleHoliday("intercontinental")
                                            }} className={this.state.holiday === "intercontinental" ? "active" : ""}>
                                                <Card.Content>
                                                    <Card.Header>
                                                        Intercontinental
                                                    </Card.Header>
                                                    <Card.Meta>
                                            <span className='date'>
                                              {holidayOptions["intercontinental"]["Plane"]} kg CO<sub>2</sub>
                                            </span>
                                                    </Card.Meta>
                                                </Card.Content>
                                            </Card>}
                                        </Grid.Column>
                                    </Grid.Row>
                                </Grid>
                            </div>
                        </Grid.Column>)}
                        {page === "amount" && (<Grid.Column width={12} className="page">
                            <h1>Custom amount</h1>
                            <p>
                                There are many different ways to calculate the CO<sub>2</sub> emission of holiday
                                (trip). We try
                                to make it
                                easy with this form, but if you’ve made your calculation, you can enter the amount here.
                            </p>

                            <Input
                                label={{basic: true, content: 'kg'}}
                                labelPosition='right'
                                onChange={(e, data) => this.handleCustom(data.value)}/>

                        </Grid.Column>)}
                        {page === "donation" && (<Grid.Column width={12} className="page">
                            <h1>Set a donation</h1>
                            <p>
                                If you don’t need to compensate your CO<sub>2</sub> emissions, but still want to
                                contribute to
                                the development of Green Tickets, you can give us a donation!
                            </p>
                            <p>
                                <Input
                                    label={{basic: true, content: '€'}}
                                    labelPosition='left'
                                    onChange={(e, data) => this.handleDonation(data.value)}/>
                            </p>
                        </Grid.Column>)}
                    </Grid.Row>
                </Grid>
            </Card.Content>
            <Card.Content extra>
                <div className="pay-button-holder">
                    <Link to={"/checkout/cart/" + this.state.co2 + "/" + this.state.donation}>
                        <Button primary disabled={!this.state.co2 && !this.state.donation}>Compensate</Button>
                    </Link>
                </div>
                <div className="co2-amount">
                    {!!this.state.price &&
                    <div>
                        {this.state.description ? this.state.description + " : " : ""}
                        {Math.round(this.state.co2).toLocaleString() || 0} kg -{" "}
                        {formatPrice(this.state.price)}
                    </div>}
                </div>
            </Card.Content>
        </Card>
    }
}

export function filterAirport(airport, search) {
    if (!airport || !airport.name) return [];
    return search
        .split(" ")
        .reduce((acc, cur) => {
            if (!acc) return false;

            const simpleValue = replaceSpecialCharacters(cur.toLowerCase());

            const name = replaceSpecialCharacters(airport.name.toLowerCase())
                .indexOf(simpleValue) >= 0;

            const city = replaceSpecialCharacters(airport.city.toLowerCase())
                .indexOf(simpleValue) >= 0;

            const country = replaceSpecialCharacters(airport.country.toLowerCase())
                .indexOf(simpleValue) >= 0;

            const code = cur.length === 3 && simpleValue === airport.place_id.toLowerCase();

            return name || city || country || code;
        }, true);
}

function replaceSpecialCharacters(string) {
    return string.normalize('NFD').replace(/[\u0300-\u036f]/g, "")
};

function formatPrice(price) {
    return "€" + price.toFixed(2)
}

export function sortAirports(a, b, value) {
    if (value.length === 3) {
        if (a.place_id === value.toUpperCase()) return -1;
        if (b.place_id === value.toUpperCase()) return 1;
    }
    if (a.size !== b.size) return a.size - b.size;
    return a.name.localeCompare(b.name)
}