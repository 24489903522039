import {Grid} from "semantic-ui-react";
import React from "react";

export default () => <Grid.Row textAlign="center" columns={4}>

    <Grid.Column>
        <iframe src="https://rotator.tradetracker.net/?r=85013&amp;t=iframe" width="300"
                height="250"
                frameBorder="0" border="0" marginWidth="0" marginHeight="0" scrolling="no"/>
    </Grid.Column>

    <Grid.Column>
        <a href="https://www.awin1.com/cread.php?s=2368477&v=10901&q=361296&r=510525">
            <img src="https://www.awin1.com/cshow.php?s=2368477&v=10901&q=361296&r=510525"
                 border="0"/>
        </a>
    </Grid.Column>

    <Grid.Column>
        <a href="https://www.awin1.com/cread.php?s=2037161&v=8674&q=322173&r=510525">
            <img src="https://www.awin1.com/cshow.php?s=2037161&v=8674&q=322173&r=510525"
                 border="0"/>
        </a>
    </Grid.Column>

    <Grid.Column>
        <iframe src="https://rotator.tradetracker.net/?r=85014&amp;t=iframe" width="300"
                height="250"
                frameBorder="0" border="0" marginWidth="0" marginHeight="0" scrolling="no"/>
    </Grid.Column>

</Grid.Row>;
