import React from "react";
import PlannerInput from '../components/plannerInput';

export default class Planner extends React.Component {
    state = {};


    render() {
        // const {page} = this.state;

        let from = "";
        let to = "";
        // on={on} by={by}

        return <div className="gt-card planner">
            <div
                className={"splash header" + ((false && (!from || !to)) ? " large" : "")}>
                {(from && to) !== false &&
                <PlannerInput from={from} to={to} splash={false} history={this.props.history} onHomepage={this.props.onHomepage}/>
                }
            </div>
        </div>
    }
}