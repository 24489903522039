import firebase from 'firebase/app';
import 'firebase/firestore';

export const fetchBlogItems = (limit, all) => {
    return (dispatch, getState) => {

        const state = getState();
        if(state.travelTips.fetched)return;

        dispatch({type: "FETCH_BLOG_ITEMS"});

        let ref = firebase.firestore().collection("blogs");
        if (!all) ref = ref.where("active", "==", true);
        if (limit && limit !== 0)ref = ref.limit(limit);
            ref.orderBy("date", "desc")
            .onSnapshot({
                includeMetadataChanges: false
            }, snapshot => {
                let items = [];
                snapshot.forEach(doc => {

                    const item = doc.data();
                    item.id = doc.id;
                    items.push(item);

                });



                dispatch({type: "FETCHED_BLOG_ITEMS", payload: items})
            })
    };
};
export const saveBlog = (id, item) => {
    return (dispatch) => {
        dispatch({type: "SAVE_BLOG"});

        if(!item.order){
            item.order = 0;
        }

        let ref = firebase.firestore().collection("blogs");

        ref = id === "new" ? ref.add(item) : ref.doc(id).update(item);

        ref
            .then(() => {
            dispatch({type: "SAVED_BLOG"});
        }).catch(e => {
            console.error(e);
            dispatch({type: "SAVED_BLOG"});
        })
    };
};