// @flow

import RoadMapItem from "../models/RoadMapItem";
import type {Action} from "../models/Action";
import update from 'immutability-helper';

type State= {
    +items: Array<RoadMapItem>;
    +fetched: boolean,
    +fetching: boolean,
}

const initialState : State = {
    items: [],
    fetched: false,
    fetching: false,
};

export default (state : State = initialState, action : Action) : State  => {

    switch (action.type){
        case "FETCH_ROADMAP_ITEMS":
            return update(state, {
                fetched: {$set: false},
                fetching: {$set: true}
            });
        case "FETCHED_ROADMAP_ITEMS":
            return update(state, {
                fetched: {$set: true},
                fetching: {$set: false},
                items: {$set: action.payload}
            });
        default:
            return state;
    }
}