import React, {Component} from 'react';
import {Button, Icon, Label, Modal, Segment, Tab} from "semantic-ui-react";
import * as formatters from "../../formatters";
import InteractiveMapModal from "./InteractiveMapModal";
import {Link} from "react-router-dom";
import {ucfirst} from "../../utils";

class InteractiveMapLineSegmentPhotos extends Component {
    render() {
        let {photos} = this.props;
        photos = photos.map(photo => {
            return photo.indexOf("http") > -1 ? photo : "https://greentickets.app/external-resources/interactive-map/photos/" + photo
        });

        return photos.map((photo, i) => <InteractiveMapModal urls={photos} i={i} key={photo + i}/>);
    }
}

export default class InteractiveMapLineSegment extends Component {

    state = {
        showReturn: false,
    };

    render() {

        const {route, active} = this.props;
        const {showReturn} = this.state;

        let panes = [];

        if (route.stops && route.stops.length > 0) {
            panes.push({
                menuItem: {key: 'first', icon: 'arrow right', content: ""},
                render: () => <table>
                    <tbody>
                    {route.stops.map((stop, i) => <tr key={route.id + "stop" + i}>
                        <td>{stop.time}</td>
                        <td>{ucfirst(stop.place)}</td>
                    </tr>)}
                    </tbody>
                </table>
            });
        }
        if (route.stopsReturn && route.stopsReturn.length > 0) {
            panes.push({
                menuItem: {key: 'second', icon: 'arrow left', content: ""},
                render: () => <table>
                    <tbody>
                    {route.stopsReturn.map((stop, i) => <tr key={route.id + "rstop" + i}>
                        <td>{stop.time}</td>
                        <td>{ucfirst(stop.place)}</td>
                    </tr>)}
                    </tbody>
                </table>
            });
        }

        let icon = {
            "Night": "moon",
            "Scenic": "image outline",
            "High Speed": "lightning",
            "Ferry": "ship",
            "Other": "train",
        }[route.type];

        if(active)console.log(route);

        let providerLabel = <Label basic
                                   size={active ? "small" : "mini"}>{route.provider} {route.provider !== route.name ? route.name : ""}</Label>;

        return <Segment className="line" onClick={this.props.onClick} secondary={active}
                        id={"InteractiveMapLineSegment" + route.id}>
            <h2>
                <Icon name={icon}/>
                {route.cars && <Icon name="car"/>}
                {route.from} &#8596; {route.to}
            </h2>
            {active && route.photos && <div className="photos-container">
                <InteractiveMapLineSegmentPhotos photos={route.photos}/>
            </div>}

            {active && <Link to={"/train/" + route.id}><Button icon primary style={{float: "right"}} labelPosition="right" size="mini">Details <Icon name="right arrow" /></Button></Link>}

            {route.duration !== null && !isNaN(route.duration) && <Label basic size={active ? "small" : "mini"}><Icon
                name="clock"/>{formatters.minutesToTimeDurationString(route.duration)}
            </Label>}
            {/*<Label basic size={active ? "small" : "mini"}><Icon*/}
                {/*name="clock"/>{formatters.minutesToTimeDurationString(showReturn ? route.durationReturn : route.duration)}*/}
            {/*</Label>*/}

            {route.providerUrl ? <a href={route.providerUrl} target="blank"
                                    rel="noopener noreferrer">{providerLabel}</a> : providerLabel}
            {active && <div>
                {route.daily && <Label basic size={active ? "small" : "mini"}>Daily</Label>}
                {route.tripsPerDay &&
                <Label basic size={active ? "small" : "mini"}>{route.tripsPerDay} times a day</Label>}
                {route.interrail && <Label basic size={active ? "small" : "mini"}>Interrail valid</Label>}
                {route.priceRange &&
                <Label basic size={active ? "small" : "mini"}>{route.priceRange}</Label>}
                {route.reservationRequired &&
                <Label basic size={active ? "small" : "mini"}> Reservation required {route.reservationCost &&
                <span>({route.reservationCost})</span>}</Label>}
            </div>
            }

            {active && <Tab menu={{secondary: true, pointing: true}} panes={panes}
                            onTabChange={(e, data) => this.setState({showReturn: data.activeIndex === 1})}/>}

        </Segment>;
    }
}