// @flow

import React, {Component} from 'react';
import {Button, Container, Grid, Header, Icon, Menu} from "semantic-ui-react";
import {Link, NavLink} from "react-router-dom";

export default class IWantToHelpPage extends Component<Props> {

    constructor(props) {
        super(props);

        this.state = {
            faq: props.match.params.part === "faq",
            conditions: props.match.params.part === "conditions",
            privacy: props.match.params.part === "privacy",
        }
    }

    componentWillReceiveProps(props) {
        this.setState({
            faq: props.match.params.part === "faq",
            conditions: props.match.params.part === "conditions",
            privacy: props.match.params.part === "privacy",
        });
    }

    static menu(part) {
        return (
            <Menu pointing secondary>
                <Menu.Item
                    name='FAQ Carbon Offset'
                    as={NavLink}
                    to="/info/faq"
                />
                <Menu.Item
                    name='Terms and conditions'
                    as={NavLink}
                    to="/info/conditions"
                />
                <Menu.Item
                    name='Privacy Statement'
                    as={NavLink}
                    to="/info/privacy"
                />
            </Menu>
        );
    }

    render() {

        return (
            <div className="page-container compensation">
                <Container>
                    <Grid stackable>
                        <Grid.Row centered>
                            <Grid.Column width={12}><br/><br/>
                                {IWantToHelpPage.menu(this.props.match.params.part)}
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row centered>
                            {this.state.faq && <Grid.Column width={12}>
                                <Header as='h2'>
                                    FAQ Carbon Offset
                                </Header>
                                <h4>What is CO<sub>2</sub> offset and why should I do it?</h4>
                                <p>By travelling, you cause CO<sub>2</sub> emissions. CO<sub>2</sub> is a gas that -
                                    once it is in the atmosphere - causes climate change. Your travelling therefore has
                                    consequences: in the long run, all our travelling will cause a less habitable planet
                                    for future generations. To compensate for this, you can buy
                                    CO<sub>2</sub> compensation, which is used to invest in projects that take as much
                                    CO<sub>2</sub> from the air as you emit while travelling. An example of a
                                    compensation project is a tree-planting project: trees take CO<sub>2</sub> from the
                                    air and use it to grow and produce oxygen. But there are many other ways to take
                                    CO<sub>2</sub> from the air and compensate for your CO<sub>2</sub> emissions! To
                                    answer the question, by buying CO<sub>2</sub> compensation, you make sure that in
                                    the long run, we can still all live on this planet.</p>
                                <h4>Which project will my CO<sub>2</sub> compensation support and why this project?</h4>
                                <p>
                                    We chose a project of <a
                                    href="https://www.goldstandard.org/projects/terraclear-clean-water-access-families-laos"
                                    target="_blank">TerraClear</a> to compensate the CO<sub>2</sub> emissions from your
                                    travels, that promote clean water access for rural communities in Laos. What do
                                    water filters have to do with CO<sub>2</sub> emissions? Well, in Laos, over 80% of
                                    the households must still boil their water to make it safe to drink. To boil the
                                    water, they burn wood that they have collected by cutting down forests in their
                                    environment. When these communities have water filters, they don’t need to boil the
                                    water anymore but can instead produce their own safe water every day. We like this
                                    project because not only does it prevent CO<sub>2</sub> emission, it also improves
                                    the lives of many. We hope you like this project as well! If you think other
                                    projects are also interesting or even better to invest in, you can always contact us
                                    via our Contact page!
                                </p>
                                <h4>How do you check if the money is used effectively?</h4>
                                <p>
                                    The project we chose is safeguarded by the Gold Standard certification, one of the
                                    most trusted certifications for CO<sub>2</sub> compensation projects. The Gold
                                    Standard was established in 2003 by WWF and other international NGOs as a best
                                    practice standard to ensure that projects actually reduce CO<sub>2</sub> emissions
                                    as well as foster sustainable development. Gold Standard safeguards the effective
                                    use of your money through an elaborate process of involving stakeholders,
                                    monitoring, reporting and verifying the outcomes of the project. For more
                                    information, you can check the website of <a
                                    href="https://www.goldstandard.org/our-work/what-sets-us-apart" target="_blank">Gold
                                    Standard</a>.
                                </p>
                                <h4>Is this the cheapest way for me to compensate my emissions?</h4>
                                <p>
                                    We base the CO<sub>2</sub> emissions of your flight on the list of
                                    CO<sub>2</sub> emissions factors as established by the specialized website <a
                                    href="http://www.co2emissiefactoren.nl"
                                    target="_blank">www.co2emissiefactoren.nl</a>. This NGO has calculated that the
                                    average CO<sub>2</sub> emissions for flights within Europe is 0.200 kg, <a
                                    href="https://ec.europa.eu/jrc/en/jec/activities/wtw" target="_blank">Well to
                                    Wheel</a>.
                                </p>
                                <h4>How do you calculate the CO<sub>2</sub> emissions of my holiday?</h4>
                                <p>
                                    The calculations of your CO<sub>2</sub> emissions are based on the calculations by
                                    the Dutch NGO MilieuCentraal. They developed the tool <a
                                    href="https://advies-op-maat.milieucentraal.nl/aom/?module=klimaatwijs_op_vakantie">ClimateClever
                                    on Holiday</a>, specifically to explicate how much carbon you emit during a holiday.
                                    We identified categories of possible holidays, and use the average of a number of
                                    typical holidays for these categories as your CO<sub>2</sub> emission. For example:
                                    if you indicated that your holiday was a weekend trip within Europe, you may have
                                    been on a trip to Prague, or to Milan: flying, these trips cause emissions of
                                    respectively 340 and 370 kg of CO<sub>2</sub> emission, by train they cause
                                    respectively 80 and 110 kgs. Thus, we use the average of 350 kg for flying, and 100
                                    kg for a trip by train.
                                </p>
                                <h4>Alright, so you calculate the CO<sub>2</sub> emissions my trip causes based on my
                                    means of transport. Do you also include other factors?</h4>
                                <p>Yes, in the calculation of your CO<sub>2</sub> emissions, apart from your means of
                                    transportation, also your accomodation and activities are included in the
                                    calculation of your CO<sub>2</sub> emissions. However, in the calculations of many
                                    trips of <a
                                        href="https://advies-op-maat.milieucentraal.nl/aom/?module=klimaatwijs_op_vakantie">ClimateClever
                                        on Holiday</a>, your means of transportation account for at least 80% of the
                                    CO<sub>2</sub> emissions, therefore we did not include choices for accomodation or
                                    activities in the tool at this webpage.</p>
                                <h4>I’ve been on two completely different holidays this year: first, I went hiking just
                                    over the border of my own country and on the second, I went on a trip through
                                    several countries in Europe, and yet in this tool both these holidays would fall
                                    within the category of spending a week of holiday within Europe. How can I add more
                                    detail?
                                </h4>
                                <p>We use averages of certain categories of holidays, in order to make it possible to
                                    compensate your CO<sub>2</sub> emissions with the click of a button. Unfortunately,
                                    this means that holidays such as your hiking holiday nearby and the multi-country
                                    trip end up in one category. To be able to calculate the CO<sub>2</sub> emissions of
                                    your trip more precisely, we are happy to refer you to the tool <a
                                        href="https://advies-op-maat.milieucentraal.nl/aom/?module=klimaatwijs_op_vakantie">ClimateClever
                                        on Holiday</a>. You can fill in the resulting CO<sub>2</sub> emissions in our
                                    tool in order to buy a custom amount of CO<sub>2</sub> compensation and to support
                                    us in our travel to a sustainable future!</p>
                            </Grid.Column>}
                            {this.state.conditions && <Grid.Column width={12}>
                                <Header as='h2'>
                                    Terms and Conditions <Header.Subheader>V1.0.0</Header.Subheader>
                                </Header>

                                <Header as='h3'>Green Tickets</Header>
                                <p>Green Tickets is a registered tradename of <a href="https://www.webzites.nl/"
                                                                                 target="_blank">Webzites.nl</a>,
                                    registered at the Chamber of Commerce in The Netherlands (Kamer van Koophandel) with
                                    registration number <a
                                        href="https://server.db.kvk.nl/TST-BIN/FU/TSWS001@?BUTT=72725036"
                                        target="_blank">72725036</a>. Our address is Admiralengracht 317-3 in
                                    Amsterdam, The Netherlands. You can e-mail us at <a
                                        href="mailto:info@greentickets.app">info@greentickets.app</a> or call us at +31
                                    (0)6 33 14 94 35.</p>
                                <p>By using our website and/or by buying carbon offset you agree to these terms and
                                    conditions and to our <Link to="/info/privacy">privacy policy</Link>, which are
                                    governed by Dutch law.</p>

                                <Header as='h3'>Carbon offset</Header>
                                <p>We only invest in carbon offset projects with the Gold Standard certification. You
                                    can only buy carbon offset on our website if you are 18 years or older.</p>

                                <Header as='h3'>CO<sub>2</sub> Calculation Carbon offset</Header>
                                <p>The calculator that we provide to calculate the CO<sub>2</sub> emissions of your
                                    holiday, flight or otherwise should merely be seen as a rough estimate. We do not
                                    take responsibility for the result or the accuracy of the result as it should be
                                    seen as a rough estimation. It is also not necessarily the same value as the travel
                                    planner provides. An accurate calculation of the amount of CO<sub>2</sub> emitted
                                    would simply be too complex for this purpose. Please read more about the calculation
                                    method in our FAQ. You can bypass our calculations and enter a specific amount of CO<sub>2</sub> if
                                    you have better calculations available to you.</p>

                                <Header as='h3'>Our website</Header>
                                <p>Green Tickets accepts no liability for any loss as a result of the usage of our
                                    website. Green Tickets reserves the right to change the material of the website,
                                    including the terms and conditions and the privacy policy. If the terms and
                                    conditions or the privacy policy changes, it’s version number will be changed
                                    accordingly.</p>
                                <p>We try to keep our website as available as possible, but we can’t make any promises
                                    on that.</p>

                                <Header as='h3'></Header>
                                <p>The logo of Green Tickets is property of Green Tickets (Webzites.nl). Feel free to
                                    use it as you wish, preferably with a link to https://www.greentickets.app.</p>
                            </Grid.Column>}
                            {this.state.privacy && <Grid.Column width={12}>
                                <Header as='h2'>
                                    Privacy Statement <Header.Subheader>V1.1.0</Header.Subheader>
                                </Header>

                                <Header as='h3'>Why this page?</Header>
                                <p>The operator of the greentickets.app website is Webzites.nl, registered in
                                    Amsterdam, The Netherlands. The website greentickets.app provides an easy travel
                                    planner that includes travel duration, price, comfort and climate impact, and an
                                    easy way to compensate for the climate impact in terms of carbon emissions of your
                                    travel.</p>
                                <p>We want to inform you via this page about our processing of your personal data in
                                    accordance with the General Data Protection Regulation (GDPR) and the Algemene
                                    Verordening Gegevensbescherming (AVG). Website visitors can read here our policies
                                    concerning the collection, use and disclosure of personal information if anyone
                                    decided to use the greentickets.app website, hereafter to be called our Service. If
                                    you choose to use our Service , then you agree to the collection, use, and storage
                                    of information in relation with this policy. The Personal Information that we
                                    collect are used for providing and improving the Service. We will not use or share
                                    your information with anyone except as described in this Privacy Policy. The terms
                                    used in this Privacy Policy have the same meanings as in our <Link
                                        to="/info/conditions">Terms and conditions</Link>, unless otherwise defined in
                                    this Privacy Policy.</p>

                                <Header as='h3'>Concerning data collection and use</Header>

                                <Header as='h4'>Log Data: automatically generated information on website
                                    visitors</Header>
                                <p>We want to inform you that whenever you visit our Service, we collect so-called Log
                                    Data. Log Data is data that is automatically generated by your browser, each time
                                    you visit a website. When you visit our Service, your browser transmits to us
                                    information including:</p>
                                <ol>
                                    <li>Your IP address (Internet Protocol address)</li>
                                    <li>Your browser</li>
                                    <li>Your operating system and user interface</li>
                                    <li>Your device used to access our Service</li>
                                    <li>The time of your access</li>
                                    <li>The pages viewed of our Service</li>
                                    <li>The time spent on the pages our Service</li>
                                </ol>
                                <p>The Log Data is stored for a period of 38 months and deleted thereafter.</p>
                                <p>We use Google Analytics and Google AdWords. Please refer to <a
                                    href="https://stripe.com/us/privacy/" target="_blank">the privacy statement of
                                    Google</a> to learn more about this.</p>

                                <Header as='h4'>Cookies: files with small amounts of anonymous data</Header>
                                <p>
                                    Green Tickets uses cookies to collect information on how you use our website and to
                                    offer relevant advertisements.
                                </p>
                                <p>
                                    <strong>Wat are cookies?</strong><br/>
                                    Cookies are small files that we place on the device used to access our Service, and
                                    which we read when you use our Service. Some cookies are necessary, for example to
                                    make it possible to book travels with our Service. Our Service uses these cookies to
                                    collect information and to improve our Service. </p>

                                <Header as='h4'>Collection and Use of data</Header>
                                <p>When you use our Service, you provide us information about yourself and about the
                                    travel-planning of your trip(s), which we collect, use and store. The data include
                                    but are not limited to:</p>
                                <ul>
                                    <li>Your name</li>
                                    <li>Your email address</li>
                                    <li>Your payment method</li>
                                    <li>Your booked travel option or carbon offset, if any</li>
                                </ul>
                                <p>We only require these data from you in order to complete the booking of the preferred
                                    travel option. These data are stored for a period of 38 months and deleted thereafter.</p>
                                <strong>Payment information</strong>
                                <p>When buying carbon offset, you will provide payment information to <a
                                    href="https://stripe.com/us/privacy/" target="_blank">Stripe, Inc.</a>. This data is
                                    not stored on our servers and you are therefore referred to <a
                                        href="https://stripe.com/us/privacy/" target="_blank">the privacy statement of
                                        Stripe</a> to learn more about the privacy of your payment information.</p>
                                <strong>Server and database</strong>
                                <p>Our servers are hosted at <a href="https://www.digitalocean.com/"
                                                                target="_blank">DigitalOcean</a> and you are therefore
                                    referred to <a href="https://www.digitalocean.com/legal/privacy/" target="_blank">the
                                        privacy statement of DigitalOcean</a> (including the <a
                                        href="https://www.digitalocean.com/security/gdpr/data-processing-agreement/"
                                        target="_blank">data processing agreement</a>) for more information. Our
                                    database is stored at <a href="https://firebase.google.com/" target="_blank">Firebase
                                        by Google</a>, which you can read more about <a
                                        href="https://firebase.google.com/support/privacy/" target="_blank">at
                                        Google</a> (including <a
                                        href="https://firebase.google.com/terms/data-processing-terms" target="_blank">the
                                        data processing agreement</a>).</p>

                                <Header as='h3'>Concerning third parties</Header>

                                <Header as='h4'>Service Providers</Header>
                                <p>We employ third-party companies and individuals for the following reasons:</p>
                                <ol>
                                    <li>To facilitate our Service;</li>
                                    <li>To perform Service-related services; or</li>
                                    <li>To assist us in analyzing how our Service is used</li>
                                </ol>
                                <p>We want to inform our Service users that these third parties may have access to your
                                    Personal Information. The reason is to perform the tasks assigned to them on our
                                    behalf. However, they are obligated not to disclose or use the information for any
                                    other purpose.</p>

                                <Header as='h4'>Service Providers</Header>
                                <p>We value your trust in providing us your Personal Information, thus we strive to
                                    protect your information and use those means that will protect your data the best
                                    within commercially acceptable limits. But remember that no method of transmission
                                    over the internet, or method of electronic storage is 100% secure and reliable, and
                                    we cannot guarantee the absolute security of your personal information.</p>

                                <Header as='h4'>Links to Other Sites</Header>
                                <p>Our Service contains links to other sites, for example to mobility providers. If you
                                    click
                                    on a third-party link, you will be directed to that site. Note that these external
                                    sites are not operated by us. Therefore, we strongly advise you to review the
                                    Privacy Policy of these websites. We have no control over, and assume no
                                    responsibility for the content, privacy policies, or practices of any third-party
                                    sites or services.</p>

                                <Header as='h3'>Other concerns</Header>

                                <Header as='h4'>Children's Privacy</Header>
                                <p>Our Services do not address anyone of the age of 17 or younger. We do not knowingly
                                    collect personal identifiable information from children of the age of 17 or younger.
                                    In the case we discover that a child of the age of 17 or younger has provided us
                                    with personal information, we immediately delete this from our servers. If you are a
                                    parent or guardian and you are aware that your child has provided us with personal
                                    information, we kindly ask you to contact us so that we will be able to do necessary
                                    actions.</p>

                                <Header as='h4'>Changes to This Privacy Policy</Header>
                                <p>We may update our Privacy Policy from time to time. You can find these updates on
                                    this page and therefore we advise you to review this page periodically for any
                                    changes. Updates can be recognized by an increase of the version number on the top
                                    of this page. The changes are effective immediately when they are posted on this
                                    page.</p>

                                <Header as='h4'>Contact Us</Header>
                                <p>If you have any questions, complaints or suggestions about our Privacy Policy, do not
                                    hesitate to contact us at info@greentickets.app.</p>
                            </Grid.Column>}
                        </Grid.Row>
                    </Grid>
                </Container>
            </div>
        );
    }
}