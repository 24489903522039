import React, {Component} from 'react';
import {connect} from "react-redux";
import PlannerInput from '../components/plannerInput';
import moment from "moment";
import {Button, Grid, Icon, Transition} from "semantic-ui-react";
import HomePageContent from "../components/HomePageContent";
import {PLANNER_VIEWS, SORTING} from "../constants";
import {Ref} from "semantic-ui-react";
import * as RouteActions from "../actions/RouteActions";
import RouteInfoModal from "../components/RouteInfoModal";
import LoadingAnimation from "../components/LoadingAnimation";
import RouteRow from "../components/planner/RouteRow";
import RouteInfo from "../components/planner/RouteInfo";
import logo from "../resources/images/green-tickets-logo.png"
import {NavLink} from "react-router-dom";


class PlannerPage extends Component {

    DEFAULT_FACTOR = 0.05;
    static activeView() {
        return PLANNER_VIEWS.LIST;
    }

    constructor(props) {
        super(props);

        this.state = {
            from: (props.params && props.params.from),
            to: (props.params && props.params.to),
            on: (props.params && props.params.on),
            by: (props.params && props.params.by),
            factor: 0.05,
            backgroundWidth: "100%",
            baseTime: moment(),
        };


    }

    calculateFactor(routes, baseTime) {
        if (this.routesContainer && this.routesContainer.offsetWidth) {
            let containerWidth = this.routesContainer && this.routesContainer.offsetWidth;
            let durations = routes.map(result => result && result.duration);
            let longestDuration = Math.max(...durations);
            let lastArrival = moment(Math.max(...routes.map(result => result && result.arrivalDate)));
            // let averageDuration = durations.reduce((p, c) => p + c, 0) / durations.length;
            // let padding = 200;
            //@TODO : Factor aanpassen
            let factor = (containerWidth - 200) / (longestDuration); //Math.max((containerWidth - padding) / (longestDuration * 1.1), 0.02);
            let backgroundWidth = (lastArrival.diff(baseTime, "s") + 3600) * factor;
            this.setState({
                factor,
                backgroundWidth,
            });

        } else {
            this.setState({factor: this.DEFAULT_FACTOR});
        }
    }

    componentWillReceiveProps(newProps) {


        let {routes} = newProps;
        let results = routes && routes.length > 0;
        let baseTime = null;
        if (results) {
            let firstDeparture = Math.min(...routes.map(result => result && result.departureDate));
            baseTime = moment(firstDeparture).minutes(0).seconds(0).subtract(1, "h");
            this.calculateFactor(routes, baseTime); //0.03;
            this.setState({
                baseTime,
                from: (newProps.params && newProps.params.from),
                to: (newProps.params && newProps.params.to),
                on: (newProps.params && newProps.params.on),
                by: (newProps.params && newProps.params.by),
            });
        }

        routes.forEach(route => {
            if (!route.pendingAddTransit) {
                if (route.legs.reduce((acc, cur) => acc || cur.type === "NOT_FETCHED_ESTIMATION", false)) {
                    this.props.addTransit(route);
                }
            }
        })
    }

    componentDidUpdate() {
        if (this.state.factor === this.DEFAULT_FACTOR) {
            // this.componentWillReceiveProps(this.props);
        }
    }

    render() {

        let {from, to, on, by, activeRow} = this.state;

        let {routes, itemCount, filteredItemCount, fetching, filtering, sort, sorting, fetchingTakesLong, showPlannerForm, query} = this.props;
        let results = routes && routes.length > 0;
        // let showBetaBanner = (!from || !to);
        let showSplash = (!from || !to);

        return (
            <div className={"main-container container" + (showSplash ? " splash" : "")}>

                <div className={"header-container"}>

                    {showSplash && <div className={"splash"}>
                        <div className={"heading"}>
                            <Grid>
                                <Grid.Row >
                                    <Grid.Column width={6}>
                                        <img src={logo} alt="Green Tickets" className="logo"/>
                                        <div className={"text"}>
                                            <h1>Green Tickets</h1>
                                            <h2>Building the European<br /> travel planner of the future.</h2>
                                        </div>
                                    </Grid.Column>
                                    <Grid.Column width={10}>
                                        <div className="welcome-container">
                                            <h1>Join us on our mission towards sustainable traveling!</h1>

                                            <strong className="step">Step 1.</strong>
                                            Compensate your trips and support our platform.<br />

                                            <strong className="step">Step 2.</strong>
                                            Try our travel planner and send us your feedback.<br />

                                            <strong className="step">Step 3.</strong>
                                            <NavLink to="/i-want-to-help">Click here to see how you can help us further</NavLink>.<br />

                                        </div>
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>
                        </div>
                        <div className="bottom-container">
                            <Button basic className={"more"} onClick={(e) => {e.preventDefault(); window.document.querySelector("#more").scrollIntoView({behavior: 'smooth', block: "start", inline: "nearest"})}}>
                                <Transition animation="bounce" duration={1000}>
                                    <Icon name="angle down" className="looping" />
                                </Transition>
                                Read more
                            </Button>
                        </div>
                    </div>}

                    {/*{showBetaBanner &&*
                    {/*<BetaBanner/>* // + (showBetaBanner ? " push-down" : "")
                    // }*/}
                    <div
                        className={"header" + ((false && (!from || !to)) ? " large" : "") + (showSplash ? " splash" : "")}>
                        {(from && to && showPlannerForm) !== false ?
                            <PlannerInput from={from} to={to} on={on} by={by} history={this.props.history} splash={showSplash}/>
                            :
                            <LoadingAnimation active={true} by={by || "car,plane,transit"}/>
                        }
                    </div>

                </div>

                <div className="content-container">
                    <Grid centered columns={2} style={{height: "100%"}}>
                        {(from && to && results) &&
                        <Grid.Row className="trip-results-info">
                            {/*Showing {filteredItemCount} of {itemCount} routes.<br/>*/}
                            {/*{JSON.stringify(plannerInfo)}*/}
                            <Grid.Column className={"left"} width={12} textAlign="center">
                                {itemCount === 1 ?
                                    <span>Showing one route.<br/></span> :
                                    <span>Showing the best {routes.length} of {itemCount} routes.<br/></span>
                                }
                                {fetching ?
                                    <span>
                                        <i className="spinner fas fa-circle-notch fa-spin"/>
                                        {fetchingTakesLong && <strong> Hold on, we're still searching...</strong>}
                                    </span>
                                    :
                                    itemCount !== filteredItemCount && (filtering ?
                                        <a onClick={(e) => this.props.toggleFiltering(false)} className="pointer">Show
                                            unfiltered results.</a>
                                        :
                                        <a onClick={(e) => this.props.toggleFiltering(true)} className="pointer">Show
                                            less.</a>
                                    )}
                            </Grid.Column>
                            <Grid.Column width={4} textAlign="center">
                                {/*Sort on:<br />*/}
                                <Button basic className="inactive no-border" size="mini">Sort:</Button>
                                <Button.Group className="sort-buttons" size="mini">
                                    <Button active={sorting === SORTING.PRICE} onClick={(e) => {
                                        sort(SORTING.PRICE);
                                        e.target.blur()
                                    }}>&euro;</Button>
                                    <Button active={sorting === SORTING.DURATION} onClick={(e) => {
                                        sort(SORTING.DURATION);
                                        e.target.blur()
                                    }}><i className="fa fa-clock"/></Button>
                                    <Button active={sorting === SORTING.TRANSFERS} onClick={(e) => {
                                        sort(SORTING.TRANSFERS);
                                        e.target.blur()
                                    }}><i className="fa fa-random"/></Button>
                                    <Button active={sorting === SORTING.CLIMATE_IMPACT} onClick={(e) => {
                                        sort(SORTING.CLIMATE_IMPACT);
                                        e.target.blur()
                                    }}>CO<sub>2</sub></Button>
                                </Button.Group>
                            </Grid.Column>
                        </Grid.Row>
                        }
                        {from && to ? (
                            results ?
                                <Grid.Row>
                                    <Ref innerRef={el => this.routesContainer = el}>
                                        <Grid.Column width={12} className="routes-container">
                                            <svg style={{width: this.state.backgroundWidth}}>
                                                <defs>
                                                    <pattern id="basicPattern" x="0" y="0"
                                                             width={3600 * this.state.factor}
                                                             height="40"
                                                             patternUnits="userSpaceOnUse">
                                                        <line x1="0" y1="0" x2="0" y2="40" stroke="#eee"
                                                              strokeWidth="2"/>
                                                    </pattern>
                                                </defs>
                                                <rect fill="url(#basicPattern)"/>
                                            </svg>
                                            {query && query.date &&
                                            <div className="target-time"
                                                 style={{marginLeft: this.state.factor * query.date.diff(this.state.baseTime, "s")}}/>}
                                            {routes.map((option, i) => (
                                                <RouteRow key={option.id + "row"} {...option} factor={this.state.factor}
                                                          baseTime={this.state.baseTime}
                                                          className={i === activeRow ? "active" : ""}
                                                          onMouseEnter={(e) => this.setState({activeRow: i})}
                                                          onMouseLeave={(e) => this.setState({activeRow: null})}
                                                          onClick={(e) => this.props.setActiveRoute(option.id)}
                                                />
                                            ))}
                                        </Grid.Column>
                                    </Ref>
                                    <Grid.Column width={4}
                                                 className={"routes-info-container" + (fetching ? " loading" : "")}>
                                        {routes.map((option, i) => (
                                            <RouteInfo key={option.id + "info"} {...option}
                                                       className={i === activeRow ? "active" : ""}
                                                       onMouseEnter={(e) => this.setState({activeRow: i})}
                                                       onMouseLeave={(e) => this.setState({activeRow: null})}
                                                       onClick={(e) => this.props.setActiveRoute(option.id)}
                                            />
                                        ))}
                                    </Grid.Column>
                                </Grid.Row>
                                :
                                <Grid.Row style={{color: "#888"}}>
                                    {fetching ?
                                        <div>
                                            <i className="spinner fas fa-circle-notch fa-spin"/><br/>Loading routes...
                                        </div> :
                                        <div>
                                            No routes found...
                                        </div>
                                    }
                                    <br/><br/>
                                    <br/><br/>
                                </Grid.Row>
                        ) : (
                            <HomePageContent/>
                        )}
                    </Grid>
                </div>
                <RouteInfoModal/>
            </div>
        );
    }
}

export default connect(state => ({
    filtering: state.routes.filtering,
    fetching: state.routes.fetching,
    fetchingTakesLong: state.routes.fetchingTakesLong,
    sorting: state.routes.sorting,
    routes: state.routes.items.filter(r => !state.routes.filtering || !r.hidden),
    itemCount: state.routes.itemCount,
    query: state.routes.query,
    // plannerInfo: state.routes.plannerInfo,
    filteredItemCount: state.routes.filteredItemCount,
    showPlannerForm: state.routes.showPlannerForm,
}), dispatch => ({
    setActiveRoute: (i) => dispatch(RouteActions.setActive(i)),
    toggleFiltering: (i) => dispatch(RouteActions.toggleFiltering(i)),
    addTransit: (route, query) => dispatch(RouteActions.addTransit(route, query)),
    sort: (on) => dispatch(RouteActions.sort(on)),
}))(PlannerPage);