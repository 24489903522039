import chroma from "chroma-js";

export function formatHoursAsString(totalMinutes){
    if(!totalMinutes) return "";

    const hours = totalMinutes / 60;
    const fullHours = Math.floor(hours);
    const minutes = parseInt(Math.max(0, totalMinutes - fullHours * 60), 10);
    return fullHours + " hr " + minutes + " min";
}
export function formatHoursAsSmallString(totalMinutes){
    if(!totalMinutes) return "";

    const hours = totalMinutes / 60;
    const fullHours = Math.floor(hours);
    const minutes = parseInt(Math.max(0, totalMinutes - fullHours * 60), 10);
    return fullHours + ":" + (minutes < 10 ? "0" : "") + minutes;
}

const priceFormatter = new Intl.NumberFormat('nl-NL', {
    style: 'currency',
    currency: 'EUR',
});

const numberFormatter = new Intl.NumberFormat('nl-NL', {
    maximumFractionDigits: 2
});

// const colorScale = chroma.scale(['#66cc33', '#8C000A']).mode('lch');
const colorScale = chroma.scale(['#038e1a', '#997975']);
export const climateImpactScale = colorScale;
export const transfersScale = colorScale;
export const durationScale = colorScale;

export function formatPriceAsString(price, withoutEuroSign){
    if(!price)return null;
    let formattedPrice = priceFormatter.format(price);
    if(withoutEuroSign)formattedPrice = formattedPrice.substring(1);
    return formattedPrice;
}

export function formatCo2(co2){
    if (co2 < 1){
        return "<1";
        // return parseInt(co2 * 10, 10) / 10;
    } else {
        return parseInt(co2, 10);
    }
}
export function formatDistance(distance){
    if (distance < 1000) {
        return Math.round(distance) + " m"
    } else {
        return numberFormatter.format(distance / 1000) + " km"
    }
}



export function ucfirst(string) {
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
}