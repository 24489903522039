import React, {Component} from 'react';
import HomePage from "../homepage/Homepage";

export default class DevPage extends Component {

    render() {

        return (
            <div className="page-container">
                <HomePage history={this.props.history} />
            </div>
        );
    }
}