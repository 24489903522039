import React, {Component} from 'react';
import {Button, Card, Container, Grid, Header, Icon} from "semantic-ui-react";
import {NavLink} from "react-router-dom";


export default class IWantToHelpPage extends Component {

    render() {

        return (
            <Container>
                <Card fluid>
                    <Card.Content>
                        <Grid stackable celled>
                            <Grid.Row centered className="main-explanation">
                                <Grid.Column width={8}>
                                    <p>Because we are an early stage startup we would really appreciate your help in
                                        getting us of the ground (or getting us on track, if you like). You can help us
                                        in a few ways.</p>
                                </Grid.Column>
                            </Grid.Row>

                            <Grid.Row centered divided>
                                <Grid.Column width={4}>
                                    <Header as='h3'>
                                        <Icon name="users"/> Join the conversation
                                    </Header>
                                    <p>In our <a href="https://www.facebook.com/groups/easygreentravels" target="_blank"
                                                 rel="noopener noreferrer">Facebook group</a> or <a
                                        href="https://www.linkedin.com/groups/8673338" rel="noopener noreferrer"
                                        target="_blank">LinkedIn group</a> <strong>Easy Green Travels</strong> you can
                                        join the conversation. Tell us about your challenges when traveling through
                                        Europe and share ideas on how Green Tickets can help.</p>
                                    <p>Of course you can also follow us on our social media accounts!</p>
                                    <p>
                                        <Button as="a" href="https://www.facebook.com/groups/easygreentravels"
                                                target="_blank" rel="noopener noreferrer" basic color="blue">Facebook
                                            group</Button><br/><br/>
                                        <Button as="a" href="https://www.linkedin.com/groups/8673338" target="_blank"
                                                rel="noopener noreferrer" basic color="teal">LinkedIn
                                            group</Button><br/>
                                    </p>
                                </Grid.Column>
                                <Grid.Column width={4}>
                                    <Header as='h3'>
                                        <Icon name="cloud"/> Buy carbon offset
                                    </Header>
                                    <p>On our homepage you can find a form where you can buy carbon offset. If you buy
                                        this offset through us, you are helping us to build Green Tickets as well. You
                                        can (additionally) also add a donation to your payment, completely optional of
                                        course!</p>

                                    <Header as='h3'>
                                        <Icon name="comments"/> Spread the word
                                    </Header>
                                    <p>One of the challenges of a new startup is getting the word out. It would help us
                                        a lot of you discuss our project with your friends and family.</p>
                                </Grid.Column>
                                <Grid.Column width={4}>
                                    <Header as='h3'>
                                        <Icon name="power cord"/> Help us to connect
                                    </Header>
                                    <p>Do you work at a company that's working on reducing their mobility footprint? Or
                                        at a company where everyone just travels a lot? Let us know! We're always
                                        looking for opportunities to work together with companies on improving their
                                        workflow of booking international travel tickets.</p>
                                    <p>Or do you work at a mobility provider and want your company to be integrated with
                                        our system? Send us an e-mail at <a
                                            href="mailto:info@greentickets.app">info@greentickets.app</a>.</p>
                                </Grid.Column>
                                <Grid.Column width={4}>
                                    <Header as='h3'>
                                        <Icon name="heart"/> Support us
                                    </Header>
                                    <p>We're an independent early-stage startup and can use all the help we can get! Do
                                        you believe in our mission and can you spare something to bring sustainable
                                        traveling closer for everybody?</p>
                                    <p>
                                        <Button as={NavLink} to="/climate-impact/compensation/donation" color="green">Donate</Button>
                                    </p>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Card.Content>
                </Card>
            </Container>
        );
    }
}