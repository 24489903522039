import React from 'react';

import InteractiveMap from '../../homepage/InteractiveMap'

import infographic from './EmissionsInfographic.png'
import logo from './EmissionsInfographiclogo.png'
import fig1 from './EmissionsInfographic1.png'
import fig2 from './EmissionsInfographic2.png'
import fig31 from './EmissionsInfographic31.png'
import fig32 from './EmissionsInfographic32.png'
import fig33 from './EmissionsInfographic33.png'
import {Grid, Icon, Popup} from "semantic-ui-react";

export default class EmissionsInfographic extends React.Component {

    copyURL() {
        const el = window.document.createElement('textarea');
        el.value = window.location;
        window.document.body.appendChild(el);
        el.select();
        window.document.execCommand('copy');
        window.document.body.removeChild(el);
    }

    download() {
        const link = window.document.createElement('a');
        link.href = infographic;
        link.download = 'Infographic Green Tickets.png';
        document.body.appendChild(link);
        link.click();
        window.document.body.removeChild(link);
    }

    render() {
        return <div className="infographic infographic-emissions">
            <div className="line-background">
                <div className="line"/>
                <img src={logo} alt="Green Tickets"/>
            </div>
            <div className="header">
                <div className="sharing">
                    <Popup inverted basic position="left" size="tiny"
                           trigger={<Icon fitted name="share" onClick={() => this.copyURL()}/>}
                           content="Share (copy URL)"/>
                    <Popup inverted basic position="left" size="tiny"
                           trigger={<Icon fitted name="download" onClick={() => this.download()}/>}
                           content="Download as image"/>
                </div>
                <h1 className="header-h1">Travelling through Europe</h1>
                <h2>What's the climate impact?</h2>
                <h3>A Green Tickets infographic &mdash; www.greentickets.app</h3>
            </div>
            <h2>Global Travel Emissions without Significant Climate Action <a href="#ref-1" className="ref">(1)</a></h2>
            <div className="padding">
                <img src={fig1} alt="lobal Travel Emissions without Significant Climate Action"/>
                <div className="legend">
                    <div className="legend-item blue"></div>
                    Emissions Travel Industry 'Business as usual' <br/>
                    <div className="legend-item green"></div>
                    Global (all industry) Emissions Limits Paris Agreement
                </div>
            </div>
            <h1>The emissions of the travel industry alone will exceed the<br/>
                complete global CO<sub>2</sub> budget around 2050 (1.5&deg;) / 2070 (2&deg;)<br/>
                if we continue business as usual.<br/>
                <br/>
                We need to change our travel behaviour.</h1>
            <Grid stackable>
                <Grid.Row columns={2}>
                    <Grid.Column>
                        <img src={fig2} alt="Share of CO2 and trips"/>
                    </Grid.Column>
                    <Grid.Column>
                        <div className="inset">
                            Although flying is a small fraction of the amount of trips, it causes most of the
                            CO<sub>2</sub> emissions. <a href="#ref-1" className="ref">(1)</a>
                        </div>
                        <div className="inset">&nbsp;</div>
                        <div className="inset">
                            For the annual CO<sub>2</sub> emissions of tourism
                            (transport & stay), considering business as usual, the share of air transport will
                            increase from 50% in 2005 to 76% in 2100. <a href="#ref-1" className="ref">(1)</a>
                        </div>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
            <h1>The mode of transport you choose has a significant effect:</h1>
            <Grid stackable>
                <Grid.Row only="mobile">
                    <Grid.Column>
                        <div className="country-description">
                            <h2>CO<sub>2</sub> emissions as a result of a one-way trip <small>[kg CO<sub>2</sub>]
                            </small> <a
                                href="#ref-2" className="ref">(2)</a></h2>
                            <p className="padding">
                                Note that this excludes nitrogen oxides, <br/>
                                particulate matter and other emissions. <br/><br/>
                                <small>Car: Middle class diesel EURO4, 1 passenger / Flight: avg. load factor,
                                    CO<sub>2</sub> with climate factor / Train: National energy production mix
                                </small>
                            </p>
                        </div>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={2}>
                    <Grid.Column>
                        <img src={fig31} alt="Amsterdam - Berlin"/>
                    </Grid.Column>
                    <Grid.Column>
                        <div className="bar-graph">
                            <div className="value">170</div>
                            <div className="bar blue"><Popup inverted basic position="right" size="tiny"
                                                             trigger={<Icon name="plane"/>} content="Air travel"/></div>
                            <div className="value">113</div>
                            <div className="bar" style={{width: (113 / 1.70) + "%"}}><Popup inverted basic
                                                                                            position="right"
                                                                                            size="tiny"
                                                                                            trigger={<Icon name="car"/>}
                                                                                            content="Car travel"/></div>
                            <div className="value">27</div>
                            <div className="bar green" style={{width: (27 / 1.70) + "%"}}><Popup inverted basic
                                                                                                 position="right"
                                                                                                 size="tiny"
                                                                                                 trigger={<Icon
                                                                                                     name="train"/>}
                                                                                                 content="Train travel"/>
                            </div>
                            <div className="value"/>
                            <div className="bar savings" style={{marginLeft: (27 / 1.70) + "%"}}>
                                <div><span>savings of 84%</span></div>
                            </div>
                            <div className="value unit">kg CO<sub>2</sub></div>
                        </div>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={2}>
                    <Grid.Column only="large screen">
                        <div className="bar-graph">
                            <div className="value">120</div>
                            <div className="bar blue"><Popup inverted basic position="right" size="tiny"
                                                             trigger={<Icon name="plane"/>} content="Air travel"/></div>
                            <div className="value">90</div>
                            <div className="bar" style={{width: (90 / 1.20) + "%"}}><Popup inverted basic
                                                                                           position="right" size="tiny"
                                                                                           trigger={<Icon name="car"/>}
                                                                                           content="Car travel"/></div>
                            <div className="value">9</div>
                            <div className="bar green" style={{width: (9 / 1.20) + "%"}}><Popup inverted basic
                                                                                                position="right"
                                                                                                size="tiny"
                                                                                                trigger={<Icon
                                                                                                    name="train"/>}
                                                                                                content="Train travel"/>
                            </div>
                            <div className="value"/>
                            <div className="bar savings" style={{marginLeft: (9 / 1.20) + "%"}}>
                                <div><span>savings of 93%</span></div>
                            </div>
                        </div>
                        <div className="country-description">
                            <h2>CO<sub>2</sub> emissions as a result of a one-way trip <small>[kg CO<sub>2</sub>]
                            </small> <a
                                href="#ref-2" className="ref">(2)</a></h2>
                            <p className="padding">
                                Note that this excludes nitrogen oxides, <br/>
                                particulate matter and other emissions. <br/><br/>
                                <small>Car: Middle class diesel EURO4, 1 passenger / Flight: avg. load factor,
                                    CO<sub>2</sub> with climate factor / Train: National energy production mix
                                </small>
                            </p>
                        </div>
                    </Grid.Column>
                    <Grid.Column>
                        <img src={fig32} alt="Amsterdam - Paris"/>
                    </Grid.Column>
                    <Grid.Column only="mobile">
                        <div className="bar-graph">
                            <div className="value">120</div>
                            <div className="bar blue"><Popup inverted basic position="right" size="tiny"
                                                             trigger={<Icon name="plane"/>} content="Air travel"/></div>
                            <div className="value">90</div>
                            <div className="bar" style={{width: (90 / 1.20) + "%"}}><Popup inverted basic
                                                                                           position="right" size="tiny"
                                                                                           trigger={<Icon name="car"/>}
                                                                                           content="Car travel"/></div>
                            <div className="value">9</div>
                            <div className="bar green" style={{width: (9 / 1.20) + "%"}}><Popup inverted basic
                                                                                                position="right"
                                                                                                size="tiny"
                                                                                                trigger={<Icon
                                                                                                    name="train"/>}
                                                                                                content="Train travel"/>
                            </div>
                            <div className="value"/>
                            <div className="bar savings" style={{marginLeft: (9 / 1.20) + "%"}}>
                                <div><span>savings of 93%</span></div>
                            </div>
                        </div>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={2}>
                    <Grid.Column>
                        <img src={fig33} alt="Amsterdam - London"/>
                    </Grid.Column>
                    <Grid.Column>
                        <div className="bar-graph">
                            <div className="value">125</div>
                            <div className="bar blue"><Popup inverted basic position="right" size="tiny"
                                                             trigger={<Icon name="plane"/>} content="Air travel"/></div>
                            <div className="value">76</div>
                            <div className="bar" style={{width: (76 / 1.25) + "%"}}><Popup inverted basic
                                                                                           position="right"
                                                                                           size="tiny"
                                                                                           trigger={<Icon name="car"/>}
                                                                                           content="Car travel"/></div>
                            <div className="value">17</div>
                            <div className="bar green" style={{width: (17 / 1.25) + "%"}}><Popup inverted basic
                                                                                                 position="right"
                                                                                                 size="tiny"
                                                                                                 trigger={<Icon
                                                                                                     name="train"/>}
                                                                                                 content="Train travel"/>
                            </div>
                            <div className="value"/>
                            <div className="bar savings" style={{marginLeft: (17 / 1.25) + "%"}}>
                                <div><span>savings of 86%</span></div>
                            </div>
                            <div className="value unit">kg CO<sub>2</sub></div>
                        </div>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
            {/*<h1 className="flush">On short distances the train can even be faster (or almost as fast) as flying: <a href="#ref-3" className="ref">(3)</a></h1>*/}
            {/*<div className="flush">*/}
            {/*<InteractiveMap floatingLegend darkWater cities={["Brussel", "Parijs", "Düsseldorf", "Londen", "Frankfurt", "Hannover", "Rennes", "Liverpool", "Bremen", "Stuttgart", "Lyon"]} />*/}
            {/*</div>*/}
            <h1 className="flush">The next time you’re going to travel through Europe, take a minute
                to check which travel method suits you best.<br/>
                Have a look at <a href="https://www.greentickets.app" target="_blank">www.greentickets.app</a> and
                you
                might be surprised!</h1>
            <div className="footer flush">
                <a href="https://repository.tudelft.nl/islandora/object/uuid%3A615ac06e-d389-4c6c-810e-7a4ab5818e8d"
                   id="ref-1" className="ref-details"><span className="number">(1)</span> Peeters, P. (2017).
                    Tourism’s
                    impact on climate change and its mitigation challenges:
                    How can tourism become ‘climatically sustainable’?
                    DOI: 10.4233/uuid:615ac06e-d389-4c6c-810e-7a4ab5818e8d</a>
                <a href="http://www.ecopassenger.org/" id="ref-2" className="ref-details"><span
                    className="number">(2)</span> www.ecopassenger.org (HaCon Ingenieurgesellschaft mbH) visited on
                    July
                    1st, 2018</a>
                <a href="https://www.royalhaskoningdhv.com/nl-nl/nederland/nieuws/nieuwsberichten/trein-is-goed-alternatief-voor-korte-vluchten-vanaf-schiphol/8122"
                   id="ref-3" className="ref-details"><span className="number">(3)</span> Royal HaskoningDHV (May
                    2018)
                    Vergelijk vliegen met treinreizen voor korte afstanden:
                    en hoe we vaker voor de trein kunnen kiezen.
                    Ref: T&PBG1539R001F01 (BG1539) 1.0/Definitief</a>
                <br/>
                <div className="center">For more information go to <a href="https://www.greentickets.app"
                                                                      target="_blank">www.greentickets.app</a>.
                </div>
            </div>
        </div>
    }
}