import React, {Component} from 'react';
import CO2Compensation from "../homepage/CO2Compensation";
import {Card, Container, Grid, Input, Table} from "semantic-ui-react";
import world from "../resources/images/np_world_1831712_000000.png"
import citizen from "../resources/images/np_citizen_169547_000000.png"
import berlin from "../resources/images/np_brandenburg-gate_1174783_000000.png"
import vega from "../resources/images/np_vegetarian_383979_000000.png"
import vegan from "../resources/images/np_no-dairy_383972_000000.png"
import tree from "../resources/images/np_tree_1937768_000000.png"
import iphone from "../resources/images/np_smartphone_1261530_000000.png"

const co2Values = {
    vegetarian: {
        amount: 460,
        type: "years",
        title: "Vegetarian diet",
        description: "You need to eat vegetarian for % years.",
        image: vega,
        source: "Volkskrant",
        color: "#e1eee3",
    },
    vegan: {
        amount: 850,
        type: "years",
        title: "Vegan diet",
        description: "You need to eat vegan for % years.",
        image: vegan,
        source: "Volkskrant",
        color: "#b7eeaf",
    },
    trees: {
        amount: 20,
        type: "trees",
        title: "Plant a forest",
        description: "% trees (growing for one year) can absorb your emissions.",
        image: tree,
        source: "Climate Neutral Group",
        sourceURL: "https://www.climateneutralgroup.com/nieuws-inzicht/wat-is-1-ton-co2/",
        color: "#7ed628",
    },

    budget: {
        amount: 1000,
        type: "years",
        title: "Climate budget",
        description: "Your emission equals % times your annual carbon budget.",
        image: world,
    },
    capitaAverage: {
        amount: 4800,
        type: "person",
        title: "World average per capita emissions",
        description: "Your emission equals % times the world average emissions per person.",
        image: citizen,
        source: "Global Carbon Project",
        sourceURL: "http://www.globalcarbonproject.org/carbonbudget/17/files/GCP_CarbonBudget_2017.pdf",
        color: "#e3e8e5",
    },
    // otherPerson: {
    //     amount: 1000,
    //     type: "years",
    //     title: "Climate budget",
    // },

    trainBerlin: {
        amount: 30,
        type: "times",
        title: "To Berlin by Train",
        description: "You could've gone % times to Berlin by train.",
        image: berlin,
        color: "#eeee93",
    },
    iphones: {
        amount: 79,
        type: "iPhones",
        title: "iPhones",
        description: "% iPhones have the same footprint as your emissions.",
        image: iphone,
        source: "CNET",
        sourceURL: "https://www.cnet.com/news/apple-iphone-x-environmental-report/",
        color: "#cbd8ee",
    }

};

const CO2ComparisonUnit = (comparison, i, co2) => {
    // style={{transform: "scale(" + scale + ")"}}
    let factor = Math.round(co2 / comparison.amount * 100) / 100;
    let icons = [];
    for (let i = 0; i<Math.ceil(factor); i++)icons.push(i+1 < factor ? 1 : factor - i);
    return <div className="co2-comparison-unit" key={"comparison" + i}>
        <div className="header"  style={comparison.color ? {backgroundColor: comparison.color} : {}}>
            <h4 className="title">{comparison.title}</h4>
            <div>{comparison.description && comparison.description.replace("%", factor)}</div>
        </div>
        {icons.map((scale, j) => <div className="icon" key={"icon" + i + "-" + j}>
            <img src={comparison.image} />
            <div className="bar" style={{height: (1-scale) * 100 + "%"}} />
        </div>)}
        <div className="factor">
            {comparison.source && <div style={{float: "right"}} className="source">Source: {comparison.sourceURL ? <a href={comparison.sourceURL} target="_blank" rel="noopener noreferrer">{comparison.source}</a> : comparison.source}</div>}
            {factor} {comparison.type}
        </div>
    </div>;
};

export default class CO2Comparison extends Component {

    state = {
        co2: 1000,
    };

    componentWillReceiveProps(newProps) {
        const {co2} = newProps;

        if (co2) {
            this.setState({co2});
        }
    }

    render() {

        //{{transform: "scale(" + factor + ")"}}
        const {co2} = this.state;

        return <div className="co2-comparison-container">
            <Input value={co2} onChange={(e) => this.setState({co2: e.target.value})} style={{width: "100px", textAlign: "right"}} /> kg CO<sub>2</sub> equivalent

            <br style={{clear: "both"}} />
            {/*<h3>You can compensate by doing any of the following:</h3>*/}
            {["vegetarian", "vegan", "trees"].map((comparison) => CO2ComparisonUnit(co2Values[comparison], comparison, co2))}

            {/*<h3>Your CO<sub>2</sub> emissions are equal to:</h3>*/}
            {["iphones", "trainBerlin", "capitaAverage"].map((comparison) => CO2ComparisonUnit(co2Values[comparison], comparison, co2))}

            <br style={{clear: "both"}} />

            <br style={{clear: "both"}} />

        </div>
            ;
    }
}