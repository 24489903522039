import React from 'react';
import {Button, Container, Header, Icon, Modal} from "semantic-ui-react";
import {Link} from "react-router-dom";
import Script from "react-load-script";
import * as ReactGA from "react-ga";

export default class CookieInfo extends React.Component {

    constructor(props) {
        super(props);

        let consent = window.localStorage.getItem("cookieConsent");

        this.state = {
            modalOpen: !consent,
            consent: consent === "1",
        };

        if (this.state.consent) {
            this.addCookieScripts();
        }
        var botPattern = "(googlebot\/|Googlebot-Mobile|Googlebot-Image|Google favicon|Mediapartners-Google|bingbot)";
        var re = new RegExp(botPattern, 'i');
        var userAgent = navigator.userAgent;
        if (re.test(userAgent)) {
            this.state.modalOpen = false;
            this.decline();
        }

    }

    addCookieScripts() {
        ReactGA.initialize('UA-113590858-2'); //Unique Google Analytics tracking number
    }

    accept(){
        window.localStorage.setItem("cookieConsent", "1");
        this.setState({modalOpen: false, consent: true});
        this.addCookieScripts();
    }

    decline(){
        window.localStorage.setItem("cookieConsent", "0");
        this.setState({modalOpen: false, consent: false});
    }

    loadGoogleAds() {

        (window.adsbygoogle || []).push({
            google_ad_client: "ca-pub-5852781193487729",
            enable_page_level_ads: true
        });
    }

    render() {
        return <div className="cookie-consent">

            {this.state.consent ? <div>
                <em> Cookies are currently accepted.</em>
            </div> : <div>
                <em>Cookies are currently not accepted.</em>
            </div>}
            <Button size="mini" color="grey" style={{marginTop: '4px'}} onClick={() => this.setState({modalOpen: true})}>Change cookie preferences</Button>

            <Modal
                open={this.state.modalOpen}
                onClose={() => this.accept()}
                size='small'
            >
                <Header icon='browser' content='Cookies policy'/>
                <Modal.Content>
                    <p>
                        We would like to collect some information on how you use Green Tickets by placing cookies for:<br />
                        <ul>
                            <li>Analytical purposes <small>(Google Analytics)</small></li>
                            <li>Showing relevant advertisments <small>(Google AdSense)</small></li>
                        </ul>
                    </p>
                    <p>
                        You can read more about this in our <Link to="/info/privacy">Privacy statement</Link>.
                    </p>

                </Modal.Content>
                <Modal.Actions>
                    <div style={{float: 'left', textAlign: 'left'}}>
                        <Link to="/info/privacy">Privacy statement</Link><br/>
                        <Link to="/info/conditions">Terms and conditions</Link>
                    </div>

                    <Button color='black' onClick={() => this.decline()} inverted style={{color: '#ccc'}}>
                        <Icon name='close'/> No cookies
                    </Button>
                    <Button color='green' onClick={() => this.accept()} inverted>
                        <Icon name='checkmark'/> Accept Cookies
                    </Button>
                </Modal.Actions>
            </Modal>

            {this.state.consent && <Script
                url="//pagead2.googlesyndication.com/pagead/js/adsbygoogle.js"
                onLoad={() => this.loadGoogleAds()}
            />}



        </div>;
    }
}