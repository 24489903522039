import React from 'react';
import {Container, Divider, Grid, Header, Label} from "semantic-ui-react";
// import logo from "../resources/images/logo.png"
import logo from "../resources/images/green-tickets-logo.png"
import medium from "../resources/images/medium.png"
import twitter from "../resources/images/twitter.png"
import instagram from "../resources/images/instagram.png"
import facebook from "../resources/images/facebook.png"
import screenshot1 from "../resources/images/screenshot-1.jpg"
import screenshot2 from "../resources/images/screenshot-2.jpg"

import {NavLink} from "react-router-dom";

export default () => {
    return <Container key="content">
        <a id="more" />
        <Grid>
            <Grid.Row centered className="home-container" verticalAlign="middle">
                <Grid.Column width={8}>
                    <Header as='h1' className="green-tickets-title">
                        Green Tickets
                        <Label color='red'>
                            BETA
                        </Label>
                    </Header>
                    <h2 className="green-tickets-subtitle">Unravel your travel</h2>
                    <p className="with-highlighted"><span className="highlighted">Showing the best door-to-door routes - including plane, train, bus, boat or car.</span> Compare
                        by duration, price, comfort, and climate impact!</p>
                    <div className="social">
                        <div className="calltoaction">Follow us on social media!</div>
                        <a href="https://medium.com/greenticketsapp" target="_blank" rel="noopener noreferrer"
                           title="Read our blog on medium">
                            <img src={medium} alt="Read our blog on medium"/>
                        </a>
                        <a href="https://twitter.com/greenticketsapp" target="_blank" rel="noopener noreferrer"
                           title="Follow us on twitter">
                            <img src={twitter} alt="Follow us on twitter"/>
                        </a>
                        <a href="https://www.instagram.com/greenticketsapp/" target="_blank" rel="noopener noreferrer"
                           title="Follow us on instagram">
                            <img src={instagram} alt="Follow us on instagram"/>
                        </a>
                        <a href="https://www.facebook.com/greenticketsapp/" target="_blank" rel="noopener noreferrer"
                           title="Follow us on facebook">
                            <img src={facebook} alt="Follow us on facebook"/>
                        </a>
                        <div className="facebook-container">
                            <div className="fb-like" data-href="https://www.facebook.com/greenticketsapp/"
                                 data-layout="button" data-action="like" data-size="large" data-show-faces="false"
                                 data-share="true"/>
                        </div>
                    </div>
                </Grid.Column>
                <Grid.Column width={1} />
                <Grid.Column width={7} textAlign="right">
                    <div className="circle map">
                        <div className="explanation">
                            <div className="padding">
                                <h3>Currently supported countries</h3>
                                <table>
                                    <thead>
                                    <tr>
                                        <th>National & local</th>
                                        <th>National</th>
                                        <th className="soon">Coming soon</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr>
                                        <td>The Netherlands</td>
                                        <td>Germany</td>
                                        <td className="soon">
                                            France<br/>
                                            Belgium<br/>
                                            United Kingdom
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </Grid.Column>
            </Grid.Row>

            <Divider/>

            <Grid.Row>
                <Grid.Column textAlign="left" width={12}>
                    <Header as='h2'>
                        What is Green Tickets?
                    </Header>
                    <p>
                        We believe it should be easy to compare all available modes on the same level. With Green Tickets, we are determined to help people find the best travel options — taking into account the climate impact of the different transport alternatives. <strong>Green Tickets is an online planner to better compare different travel modes within Europe</strong>, showing price, door-to-door duration and climate impact, but also other aspects like comfort, effective working time and risk of delays.
                    </p>
                    <p>
                        <NavLink to="/about">Read more about Green Tickets »</NavLink>
                    </p>
                </Grid.Column>
                <Grid.Column width={3} textAlign="center">
                    <br />
                    <img src={logo} alt="Green Tickets" className="logo"/>
                </Grid.Column>

            </Grid.Row>

            <Divider/>

            <Grid.Row>

                <Grid.Column width={10}>
                    <img src={screenshot1} alt="Visualization of travel options with Green Tickets make it easy to find the best option." />
                </Grid.Column>
                <Grid.Column width={6}>
                    <img src={screenshot2} alt="Route details, stops and ticket prices are shown in a clear overview on Green Tickets." /><br /><br />
                    <em style={{color: "#555"}}>Finding the best way to get to your destination has never been this easy!</em>
                </Grid.Column>

            </Grid.Row>

            <Divider/>

            <Grid.Row>
                <Grid.Column width={3} textAlign="center">
                    <br />
                    <i className="fal fa-tasks" style={{fontSize: "40pt", color: "#5cd792"}} />
                </Grid.Column>
                <Grid.Column textAlign="left" width={12}>
                    <Header as='h2'>
                        Using Green Tickets
                    </Header>
                    <p>
                        Currently, Green Tickets is still under development. This means that not all European countries are included yet, and some issues could still exist when using our planner. Furthermore, planning a return trip is not yet possible.
                    </p>
                    <p>
                        <NavLink to="/roadmap">See what we're working on in our roadmap »</NavLink>
                    </p>
                </Grid.Column>
            </Grid.Row>

            <Grid.Row />
            <Grid.Row />
            {/*<HomePageTripSuggestions/>*/}
            {/*<Divider />*/}


        </Grid>
    </Container>
        ;
};