import React, {Component} from 'react';
import InteractiveMap from "../homepage/InteractiveMap";
import {Container, Grid} from "semantic-ui-react";
import AdsGridRow from "../components/ads/AdsGridRow";

export default class DevPage extends Component {

    render() {

        return (
            <div className="page-container interactive-map-full-page">
                <Container>
                    <Grid>
                        <Grid.Row>
                            <h1 className="large-main-title"><span>explore Europe</span></h1>
                        </Grid.Row>
                    </Grid>
                </Container>

                <InteractiveMap list homepage/>


                <Grid stackable style={{marginTop: '100px'}}>

                    <AdsGridRow/>

                    <Grid.Row>
                        <div style={{height: "100px"}}/>
                    </Grid.Row>
                </Grid>

            </div>
        );
    }
}