import React from "react";
import InteractiveMapLineSegment from "../components/interactive-map/InteractiveMapLineSegment";
import {interactiveMapStyle} from "./interactiveMapStyle";
import {connect} from "react-redux";
import {climateImpactScale} from "../utils";
import * as InteractiveMapActions from "../actions/InteractiveMapActions";
import {initMapLabel} from "../modules/maps-label"
import {minutesToTimeDurationString} from "../formatters";
import * as greatcircle from "great-circle";
import {Button, Checkbox, Container, Grid, Icon, Input, Loader, Segment} from "semantic-ui-react";

const CO2factor = {
    air: 0.297,
    transit: 0.026,
};

const lineSymbol = {
    path: 'M 0,-1 0,1',
    strokeOpacity: 1,
    scale: 2
};

// floatingLegend [bool: true] Show legend on top of map
// legend         [bool: true] Show legend
// source         [bool: true] Show source (Royal HaskoningDHV)
// cities         [array[string]: null] Filter to show cities by name, show all when not set
// darkWater      [bool: false] Make water blue
class InteractiveMap extends React.Component {

    state = {
        hasError: false,

        activeRouteId: null,
        searchQuery: "",
        filterTypes: [],
        visibleRoutes: [],

        colors: {
            air: {
                fill: '#004260',
                fillOpacity: 0.5,
                stroke: '#004260',
                strokeWeight: 1,
            },
            transit: {
                fill: '#5eac38',
                fillOpacity: 0.5,
                stroke: '#5eac38',
                strokeWeight: 1,
            },
            routes: {
                "Night": {
                    stroke: '#004260',
                    strokeWeight: 2,
                },
                "High Speed": {
                    stroke: '#c40d25',
                    strokeWeight: 2,
                },
                "Scenic": {
                    stroke: '#5eac38',
                    strokeWeight: 4,
                },
                "Other": {
                    stroke: '#c48e33', //363636
                    strokeWeight: 2,
                },
                "Ferry": {
                    stroke: '#007fd6',
                    strokeWeight: 3,
                    strokeOpacity: 0.001,
                    icons: [{
                        icon: lineSymbol,
                        offset: '0',
                        repeat: '10px'
                    }],
                },
            }
        }
    };

    componentDidMount() {
        // this.props.fetchItems();
        if (this.props.list) {
            this.props.fetchRoutes();
        } else if (this.props.destinations) {
            this.props.fetchDestinations("haskoning");
        }

        if (this.props.showActiveOnly && this.props.activeRoute){
            this.componentWillReceiveProps(this.props);
        }

    }

    setMapElement(el) {
        if (this.mapElement) return;
        this.mapElement = el;

        this.initMap();

    }

    initMap() {

        try {
            if (!window.google) {
                setTimeout(this.initMap.bind(this), 500);
                return;
            }
            initMapLabel();

            let mapStyle = interactiveMapStyle;
            if (this.props.darkWater) {
                mapStyle = [...interactiveMapStyle,
                    {
                        "featureType": "water",
                        "elementType": "geometry.fill",
                        "stylers": [
                            {
                                "visibility": "on"
                            },
                            {
                                "color": "#004b6b"
                            }
                        ]
                    }
                ]
            }

            let center = this.props.list ? {lat: 55, lng: 8} : {lat: 52.0, lng: 5.2};

            this.map = new window.google.maps.Map(this.mapElement, {
                zoom: 4,
                center,
                streetViewControl: false,
                fullscreenControl: false,
                mapTypeControl: false,
                mapTypeControlOptions: {
                    position: window.google.maps.ControlPosition.LEFT_BOTTOM
                },
                styles: mapStyle
            });

        } catch (e) {
            console.error(e);
        }
    }

    componentWillReceiveProps(newProps) {

        try {

            if (!window.google) {
                console.error("Received items, but map is not ready");
                setTimeout(() => {
                    this.componentWillReceiveProps(newProps)
                }, 500);
                return;
            }

            if (newProps.destinations && newProps.destinations.length > 0 &&
                (!this.markers || this.markers.length === 0)) {

                this.markers = [];

                let items = newProps.destinations;

                let maxMinutes = 12 * 60;
                let maxCircleRadius = 25;
                let maxCO2 = 200;

                let origin = {
                    lat: 52.378889,
                    lng: 4.900556,
                };
                let marker = new window.google.maps.Marker({
                    position: origin,
                    icon: {
                        path: window.google.maps.SymbolPath.CIRCLE,
                        scale: 2,
                        fillColor: "#000",
                        fillOpacity: 0.8,
                        strokeWeight: 0,
                    },
                    map: this.map,
                    zIndex: 100,
                });
                this.markers.push(marker);

                const {colors} = this.state;

                for (let item of items) {

                    if (!item.location) continue;

                    if (newProps.cities && !newProps.cities.includes(item.name)) continue;

                    let infoWindow = new window.google.maps.InfoWindow({
                        content: '<table>' +
                        '<h3 style="margin-bottom:0;">' + item.name + '</h3>' +
                        'Estimated travel durations:' +
                        '<tr><th style="width: 60px;text-align: left;">By plane:</th><td>' + minutesToTimeDurationString(item.air.duration) + '</td></tr>' +
                        '<tr><th style="text-align: left;">By train:</th><td>' + minutesToTimeDurationString(item.transit.duration) + '</td></tr>' +
                        '</table>'
                    });
                    let openInfoWindow = (marker) => {
                        let _this = this;
                        if (_this.openInfoWindow) {
                            _this.openInfoWindow.close();
                        }
                        infoWindow.open(_this.map, marker);
                        _this.openInfoWindow = infoWindow;
                    };

                    let marker = new window.google.maps.Marker({
                        position: {lat: item.location.latitude, lng: item.location.longitude},
                        icon: {
                            path: window.google.maps.SymbolPath.CIRCLE,
                            scale: 1.5,
                            fillColor: "#000",
                            fillOpacity: 0.8,
                            strokeWeight: 0,
                        },
                        map: this.map,
                        zIndex: 100,
                    });
                    marker.addListener('click', () => {
                        openInfoWindow(marker)
                    });
                    this.markers.push(marker);

                    if (item.transit && item.transit.duration) {

                        // let distance = Math.round(greatcircle.distance(item.location.latitude, item.location.longitude, origin.lat, origin.lng, "KM"));
                        // let co2 = Math.round(distance * CO2factor.transit);
                        // let co2Factor = co2 / maxCO2;
                        // console.log(item.name, distance, co2);

                        let marker2 = new window.google.maps.Marker({
                            position: {lat: item.location.latitude, lng: item.location.longitude},
                            icon: {
                                path: window.google.maps.SymbolPath.CIRCLE,
                                // path: "M-2,0c0,1.1,0.9,2,2,2l0-4C-1.1-2-2-1.1-2,0z",
                                scale: 0.8 * (item.transit.duration / maxMinutes * maxCircleRadius),
                                strokeColor: colors.transit.stroke,
                                // fillColor: climateImpactScale(co2Factor).hex(),
                                fillColor: colors.transit.fill,
                                fillOpacity: colors.transit.fillOpacity,
                                strokeWeight: colors.transit.strokeWeight,
                            },
                            map: this.map,
                            zIndex: (item.transit && item.transit.duration && item.air && item.air.duration && item.transit.duration < item.air.duration) ? 100 : 90,
                            optimized: false,
                        });
                        marker2.addListener('click', () => {
                            openInfoWindow(marker2)
                        });
                        this.markers.push(marker2);
                    }
                    if (item.air && item.air.duration) {

                        // let distance = Math.round(greatcircle.distance(item.location.latitude, item.location.longitude, origin.lat, origin.lng, "KM"));
                        // let co2 = Math.round(distance * CO2factor.air);
                        // let co2Factor = co2 / maxCO2;
                        // console.log(item.name, distance, co2);

                        let marker1 = new window.google.maps.Marker({
                            position: {lat: item.location.latitude, lng: item.location.longitude},
                            icon: {
                                path: window.google.maps.SymbolPath.CIRCLE,
                                // path: "M2,0c0,1.1-0.9,2-2,2l0-4C1.1-2,2-1.1,2,0z",
                                scale: 0.8 * (item.air.duration / maxMinutes * maxCircleRadius),
                                strokeColor: colors.air.stroke,
                                // fillColor: climateImpactScale(co2Factor).hex(),
                                fillColor: colors.air.fill,
                                fillOpacity: colors.air.fillOpacity,
                                strokeWeight: colors.air.strokeWeight,
                            },
                            map: this.map,
                            zIndex: 95,
                            optimized: false,
                        });
                        marker1.addListener('click', () => {
                            openInfoWindow(marker1)
                        });
                        this.markers.push(marker1);
                    }

                    let mapLabel = new window.MapLabel({
                        text: item.name,
                        position: new window.google.maps.LatLng(item.location.latitude, item.location.longitude),
                        map: this.map,
                        fontSize: 10,
                        align: 'center',
                        zIndex: 2000,
                    });
                    this.markers.push(mapLabel);

                    // if (item.name === "Berlijn") {
                    //
                    //     marker.addListener("mouseover", () => {
                    //         let path = "}hu~Hm|{\\\\fx\\\\iav@lgM_nd@u{IaasB_kHkud@aoRmp_BlsQecZ}jHsdfAvpEc|t@pV_nyBzvL{bcBugPyleAa|Oau|CgfIahkEig_@aloEn{Jq|eGjv@a}_@";
                    //         this.line = new window.google.maps.Polyline({
                    //             path: window.google.maps.geometry.encoding.decodePath(path),
                    //             map: this.map,
                    //             geodesic: true,
                    //             strokeColor: colors.transit.stroke,
                    //             strokeOpacity: 0.9,
                    //             strokeWeight: 2
                    //         });
                    //         this.line2 = new window.google.maps.Polyline({
                    //             path: [origin, {lat: item.location.latitude, lng: item.location.longitude}],
                    //             map: this.map,
                    //             geodesic: true,
                    //             strokeColor: colors.air.stroke,
                    //             strokeOpacity: 0.9,
                    //             strokeWeight: 2
                    //         });
                    //     });
                    //     let _this = this;
                    //     marker.addListener("mouseout", () => {
                    //        _this.line.map = null;
                    //        _this.line2.map = null;
                    //     });
                    // }


                }

            }

            if (this.props.showActiveOnly && newProps.activeRoute) {

                this.renderedRoutes = [];
                this.lines = {};

                const {colors} = this.state;

                let route = newProps.activeRoute;
                let bounds = bounds = new window.google.maps.LatLngBounds();

                let infoWindow = new window.google.maps.InfoWindow({
                    content: '<table>' +
                    '<h3 style="margin-bottom:0;">' + route.provider + ' ' + route.name + '</h3>' +
                    '<h4 style="margin-bottom:0;margin-top:0;">' + route.from + ' - ' + route.to + '</h4>' +
                    '</table>'
                });
                let openInfoWindow = (marker, event) => {
                    let _this = this;
                    if (_this.openInfoWindow) {
                        _this.openInfoWindow.close();
                    }
                    infoWindow.setPosition(event.latLng);
                    infoWindow.open(_this.map, marker);
                    _this.openInfoWindow = infoWindow;
                };

                let path = route.polyline || route.path;
                let points = window.google.maps.geometry.encoding.decodePath(path);

                let line = new window.google.maps.Polyline({
                    path: points,
                    map: this.map,
                    geodesic: true,
                    strokeColor: colors.routes[route.type].stroke,
                    strokeOpacity: colors.routes[route.type].strokeOpacity || 0.7,
                    strokeWeight: colors.routes[route.type].strokeWeight,
                    icons: colors.routes[route.type].icons,
                    zIndex: 10,
                });

                for (let point of points) {
                    bounds.extend(point);
                }
                this.map.fitBounds(bounds, 20);

                this.renderedRoutes.push({
                    id: route.id,
                    line: line,
                    type: route.type,
                });
                this.updateLineStyles(route.id);

            }

            /* Trains */

            if (newProps.routes &&
                newProps.routes.trains &&
                (!this.renderedRoutes || this.renderedRoutes.length !== newProps.routes.trains.length)) {

                this.filterRoutes(newProps);

                this.renderedRoutes = [];
                this.lines = {};

                const {colors} = this.state;

                for (let route of newProps.routes.trains.filter(route => !route.hidden)) {

                    let infoWindow = new window.google.maps.InfoWindow({
                        content: '<table>' +
                        '<h3 style="margin-bottom:0;">' + route.provider + ' ' + route.name + '</h3>' +
                        '<h4 style="margin-bottom:0;margin-top:0;">' + route.from + ' - ' + route.to + '</h4>' +
                        '</table>'
                    });
                    let openInfoWindow = (marker, event) => {
                        let _this = this;
                        if (_this.openInfoWindow) {
                            _this.openInfoWindow.close();
                        }
                        infoWindow.setPosition(event.latLng);
                        infoWindow.open(_this.map, marker);
                        _this.openInfoWindow = infoWindow;
                    };

                    let path = route.polyline || route.path;

                    let line = new window.google.maps.Polyline({
                        path: window.google.maps.geometry.encoding.decodePath(path),
                        map: this.map,
                        geodesic: true,
                        strokeColor: colors.routes[route.type].stroke,
                        strokeOpacity: colors.routes[route.type].strokeOpacity || 0.7,
                        strokeWeight: colors.routes[route.type].strokeWeight,
                        icons: colors.routes[route.type].icons,
                        zIndex: 10,
                    });
                    line.addListener('click', (event) => {
                        this.showLineDetails(route.id);
                        // openInfoWindow(line, event)
                    });

                    this.renderedRoutes.push({
                        id: route.id,
                        line: line,
                        type: route.type,
                    });

                }


            }

            /* lines */

            if (newProps.routes &&
                newProps.routes.lines &&
                (!this.renderedRoutes || this.renderedRoutes.length !== newProps.routes.lines.length)) {


                console.log("Adding lines...");
                // this.filterRoutes(newProps);

                this.renderedRoutes = [];
                this.lines = {};

                const {colors} = this.state;

                for (let route of newProps.routes.lines) {

                    let path = route.polyline || route.path;
                    route.type = "Other";

                    let line = new window.google.maps.Polyline({
                        path: window.google.maps.geometry.encoding.decodePath(path),
                        map: this.map,
                        geodesic: true,
                        strokeColor: colors.routes[route.type].stroke,
                        strokeOpacity: colors.routes[route.type].strokeOpacity || 0.7,
                        strokeWeight: colors.routes[route.type].strokeWeight,
                        icons: colors.routes[route.type].icons,
                        zIndex: 10,
                    });
                    this.renderedRoutes.push({
                        id: route.id,
                        line: line,
                        type: route.type,
                    });

                }


            }
        } catch (e) {
            console.error(e);
        }

    }

    showLineDetails(routeId) {

        if (routeId === null && this.lastShowLineDetailsDate && (new Date().valueOf() - this.lastShowLineDetailsDate.valueOf() < 400)) return;

        this.lastShowLineDetailsDate = new Date();
        this.setState({activeRouteId: routeId});
        this.updateLineStyles(routeId);

        setTimeout(() => {
            if (document.getElementById("InteractiveMapLineSegment" + routeId)) {
                let offsetTop = document.getElementById("InteractiveMapLineSegment" + routeId).offsetTop;
                document.getElementById("interactive-map-list-container").scrollTop = offsetTop;
            }
        }, 100);

        // this.lines[routeId].setOptions({strokeWeight: 6});
    }

    updateLineStyles(activeRouteId) {

        // return;

        try {
            const {colors, visibleRoutes} = this.state;
            let visibleRouteIds = visibleRoutes.map(route => route.id);

            if (!this.renderedRoutes) return;
            for (let route of this.renderedRoutes) {
                if (route.id === activeRouteId ||
                    (!activeRouteId && visibleRouteIds.indexOf(route.id) !== -1)) {
                    route.line.setOptions({
                        strokeWeight: (route.id === activeRouteId) ? 5 : colors.routes[route.type].strokeWeight,
                        strokeColor: colors.routes[route.type].stroke,
                        strokeOpacity: colors.routes[route.type].strokeOpacity || ((route.id === activeRouteId) ? 0.9 : 0.7),
                        icons: colors.routes[route.type].icons,
                        zIndex: 1000,
                        map: this.map,
                    });
                } else {
                    route.line.setOptions({
                        strokeWeight: colors.routes[route.type].strokeWeight,
                        icons: colors.routes[route.type].icons,
                        strokeColor: "#ccc",
                        strokeOpacity: colors.routes[route.type].strokeOpacity ||  0.7,
                        zIndex: 100,
                        map: (activeRouteId && visibleRouteIds.indexOf(route.id) !== -1) ? this.map : null,
                    });
                }
            }
        } catch (e) {
            console.error(e);
        }
    }

    search(searchQuery) {
        this.setState({searchQuery}, this.filterRoutes);
    }

    toggleTypeFilter(type) {
        // console.log("tTF", type);
        const {filterTypes} = this.state;
        const currentFilterPos = filterTypes.indexOf(type);
        let newFilterTypes = filterTypes;
        if (currentFilterPos === -1) {
            newFilterTypes = [...filterTypes, type];
        } else {
            newFilterTypes.splice(currentFilterPos, 1);
        }
        this.setState({filterTypes: newFilterTypes}, this.filterRoutes);
    }

    filterRoutes(newProps) {

        try {
            let {routes} = this.props;
            if (newProps) {
                routes = newProps.routes;
            }


            let {searchQuery, filterTypes} = this.state;
            // console.log(filterTypes);

            searchQuery = searchQuery.toLowerCase();

            let visibleRoutes = [];
            if (routes && routes.trains && routes.trains) {
                visibleRoutes = routes.trains;
                if (searchQuery !== "" || filterTypes.length !== 0) {
                    visibleRoutes = visibleRoutes.filter(route => {
                        // console.log(route, (route.stops && route.stops.length > 0 && route.stops.filter(stop => (
                        //     stop.address && stop.address.toLowerCase().indexOf(searchQuery) !== -1) || (stop.place && stop.place.toLowerCase().indexOf(searchQuery) !== -1)
                        // ).length > 0));
                        return (searchQuery === "" ||
                            route.from.toLowerCase().indexOf(searchQuery) !== -1 ||
                            route.to.toLowerCase().indexOf(searchQuery) !== -1 ||
                            route.provider.toLowerCase().indexOf(searchQuery) !== -1 ||
                            (route.name && route.name.toLowerCase().indexOf(searchQuery) !== -1) ||
                            (route.stops && route.stops.length > 0 && route.stops.filter(stop => (
                                stop.address && stop.address.toLowerCase().indexOf(searchQuery) !== -1) || (stop.place && stop.place.toLowerCase().indexOf(searchQuery) !== -1)
                            ).length > 0)) && (
                            filterTypes.length === 0 ||
                            filterTypes.indexOf(route.type) !== -1 ||
                            (filterTypes.indexOf("car") !== -1 && route.cars)
                        )
                    })
                }
            }

            this.setState({visibleRoutes}, this.updateLineStyles);

        } catch (e) {
            console.error(e);
        }

    }

    componentDidCatch(error, info) {
        // Display fallback UI
        this.setState({hasError: true});
    }

    render() {

        if (this.state.hasError) {
            return null;
        }

        const {colors, filterTypes, visibleRoutes} = this.state;
        const {fetching} = this.props;

        return <div className="interactive-map-container">

            {this.props.legend && (<div className="legend">
                <em>Estimated travel times from Amsterdam, The Netherlands.</em><br/>
                <div className="legend-item">
                    <div className="legend-color" style={{backgroundColor: colors.air.fill}}/>
                    By plane
                </div>
                <div className="legend-item">
                    <div className="legend-color" style={{backgroundColor: colors.transit.fill}}/>
                    By train
                </div>
            </div>)}

            {this.props.list ?
                this.props.homepage ?
                    <Grid>
                        <Container>
                            <Grid.Row>
                                <Grid.Column className="type-filter-header" style={{textAlign: "center"}}>
                                    <Button
                                        basic={filterTypes.length === 0 || filterTypes.indexOf("Night") === -1}
                                        onClick={() => this.toggleTypeFilter("Night")}>
                                        <Icon name="moon"/> Night trains
                                        <div className="color"
                                             style={{backgroundColor: colors.routes["Night"].stroke}}/>
                                    </Button>
                                    <Button basic={filterTypes.length === 0 || filterTypes.indexOf("Scenic") === -1}
                                            onClick={() => this.toggleTypeFilter("Scenic")}>
                                        <Icon name="image outline"/> Scenic routes
                                        <div className="color"
                                             style={{backgroundColor: colors.routes["Scenic"].stroke}}/>
                                    </Button>
                                    <Button basic={filterTypes.length === 0 || filterTypes.indexOf("Other") === -1}
                                            onClick={() => this.toggleTypeFilter("Other")}>
                                        <Icon name="train"/> Other routes
                                        <div className="color"
                                             style={{backgroundColor: colors.routes["Other"].stroke}}/>
                                    </Button>
                                    <Button basic={filterTypes.length === 0 || filterTypes.indexOf("Ferry") === -1}
                                            onClick={() => this.toggleTypeFilter("Ferry")}>
                                        <Icon name="ship"/> Ferries
                                        <div className="color"
                                             style={{backgroundColor: colors.routes["Ferry"].stroke}}/>
                                    </Button>
                                    <Button basic={filterTypes.length === 0 || filterTypes.indexOf("High Speed") === -1}
                                            onClick={() => this.toggleTypeFilter("High Speed")}>
                                        <Icon name="lightning"/> High speed trains
                                        <div className="color"
                                             style={{backgroundColor: colors.routes["High Speed"].stroke}}/>
                                    </Button>
                                    {/*<Button basic={filterTypes.length === 0 || filterTypes.indexOf("car") === -1}*/}
                                            {/*onClick={() => this.toggleTypeFilter("car")}>*/}
                                        {/*<Icon name="car"/> Car trains*/}
                                    {/*</Button>*/}
                                    <br/>

                                    <small>Note: This map is still under construction and doesn't show all available
                                        routes yet. All information shown is subject to change, please refer to the
                                        website of the transport provider for the latest information.
                                    </small>
                                </Grid.Column>
                            </Grid.Row>
                        </Container>
                        <Grid.Row>
                            <Grid.Column className="interactive-map-div-container">
                                <div id="interactive-map" ref={el => this.setMapElement(el)}
                                     onClick={() => this.showLineDetails(null)}/>
                                <div className="list-container" id="interactive-map-list-container">
                                    <Input icon='search' fluid placeholder="Search..." value={this.state.searchQuery}
                                           onChange={(e) => this.search(e.target.value)}/>
                                    {fetching ? <Loader active/> :
                                        <Segment.Group>
                                            {visibleRoutes.map(route =>
                                                <InteractiveMapLineSegment
                                                    route={route}
                                                    key={route.id}
                                                    active={this.state.activeRouteId === route.id}
                                                    onClick={() => this.showLineDetails(route.id)}/>
                                            )}
                                        </Segment.Group>
                                    }
                                </div>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                    :
                    <Grid>

                        <Grid.Row>
                            <Grid.Column width={5} className="list-container-header">
                                <Input icon='search' fluid placeholder="Search..." value={this.state.searchQuery}
                                       onChange={(e) => this.search(e.target.value)}/>
                            </Grid.Column>
                            <Grid.Column width={11} className="type-filter-header">
                                <Button
                                    basic={filterTypes.length === 0 || filterTypes.indexOf("Night") === -1}
                                    onClick={() => this.toggleTypeFilter("Night")}>
                                    <Icon name="moon"/> Night trains
                                    <div className="color" style={{backgroundColor: colors.routes["Night"].stroke}}/>
                                </Button>
                                <Button basic={filterTypes.length === 0 || filterTypes.indexOf("Scenic") === -1}
                                        onClick={() => this.toggleTypeFilter("Scenic")}>
                                    <Icon name="image outline"/> Scenic routes
                                    <div className="color" style={{backgroundColor: colors.routes["Scenic"].stroke}}/>
                                </Button>
                                <Button basic={filterTypes.length === 0 || filterTypes.indexOf("Other") === -1}
                                        onClick={() => this.toggleTypeFilter("Other")}>
                                    <Icon name="train"/> Other routes
                                </Button>
                                <Button basic={filterTypes.length === 0 || filterTypes.indexOf("Ferry") === -1}
                                        onClick={() => this.toggleTypeFilter("Ferry")}>
                                    <Icon name="ship"/> Ferries
                                </Button>
                                <Button basic={filterTypes.length === 0 || filterTypes.indexOf("High Speed") === -1}
                                        onClick={() => this.toggleTypeFilter("High Speed")}>
                                    <Icon name="lightning"/> High speed trains
                                    <div className="color"
                                         style={{backgroundColor: colors.routes["High Speed"].stroke}}/>
                                </Button>
                                <Button basic={filterTypes.length === 0 || filterTypes.indexOf("car") === -1}
                                        onClick={() => this.toggleTypeFilter("car")}>
                                    <Icon name="car"/> Car trains
                                </Button><br/>

                                <small>Note: This map is still under construction and doesn't show all available routes
                                    yet. All information shown is subject to change, please refer to the website of the
                                    transport provider for the latest information.
                                </small>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column width={5} className="list-container" id="interactive-map-list-container">
                                {fetching ? <Loader active/> :
                                    <Segment.Group>
                                        {visibleRoutes.map(route =>
                                            <InteractiveMapLineSegment
                                                route={route}
                                                key={route.id}
                                                active={this.state.activeRouteId === route.id}
                                                onClick={() => this.showLineDetails(route.id)}/>
                                        )}
                                    </Segment.Group>
                                }
                            </Grid.Column>
                            <Grid.Column width={11}>
                                <div id="interactive-map" ref={el => this.setMapElement(el)}
                                     onClick={() => this.showLineDetails(null)}/>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid> :
                <div id="interactive-map" ref={el => this.setMapElement(el)}/>
            }

            {this.props.source && (<div className="explanation">
                <p>
                    Travel times source: Report <a
                    href="https://www.royalhaskoningdhv.com/nl-nl/nederland/nieuws/nieuwsberichten/trein-is-goed-alternatief-voor-korte-vluchten-vanaf-schiphol/8122">‘Vergelijk
                    vliegen met treinreizen voor korte afstanden’</a> by Royal HaskoningDHV. Flight times include an
                    estimation of airport waiting time and transfer times.
                </p>

            </div>)}

            {this.props.floatingLegend && (<div className="floating-legend">
                <strong>Travel time from Amsterdam</strong><br/>
                <div className="icon green"/>
                Travel time by train<br/>
                <div className="icon blue"/>
                Travel time by plane<br/>
            </div>)}

        </div>
    }
}


export default connect(state => ({
    items: state.interactiveMap.items,
    // destinations: state.interactiveMap.destinations,
    routes: state.interactiveMap.routes,
    fetching: state.interactiveMap.fetchingRoutes,
    activeRoute: state.interactiveMap.activeRoute,
}), dispatch => ({
    fetchItems: () => dispatch(InteractiveMapActions.fetchInteractiveMapItems()),
    // fetchDestinations: (originId) => dispatch(InteractiveMapActions.fetchInteractiveMapDestinations(originId)),
    fetchRoutes: () => dispatch(InteractiveMapActions.fetchInteractiveMapRoutes()),
}))(InteractiveMap)