import React from 'react';
import {climateImpactScale, durationScale, transfersScale} from "../../utils";
import * as utils from "../../utils";
import {Grid, Icon, Label, Popup} from "semantic-ui-react";
import {NavLink} from "react-router-dom";
import unique from 'array-unique';
import ModeIcon from "../ModeIcon";
import {MODES} from "../../constants";

const showShadowPrice = false;

const Price = (props) => {
    return <div>
        {props.priceIsEstimate ? <Popup
            trigger={<div
                className={"new price estimate" + (!props.priceIsComplete ? " incomplete" : "")}>
                            <span className={"euro-sign" + (props.lowestPrice ? " cheapest" : "")}>
                              &euro;
                            </span>
                {
                    props.priceIsAcceptable ?
                        utils.formatPriceAsString(props.price, true)
                        :
                        <i className={"fal fa-question"}/>
                }
            </div>}
            content={<div>
                This price is an estimation.
            </div>}
            position="bottom left" basic
        /> :
        <div
            className={"new price" + (!props.priceIsComplete ? " incomplete" : "")}>
                            <span className={"euro-sign" + (props.lowestPrice ? " cheapest" : "")}>
                              &euro;
                            </span>
            {
                props.priceIsAcceptable ?
                    utils.formatPriceAsString(props.price, true)
                    :
                    <i className={"fal fa-question"}/>
            }
        </div>}
        {showShadowPrice && <div className={"shadow price"}>
            <Label color="brown" size="tiny"><strong>+</strong> € {utils.formatPriceAsString(props.shadowPrice, true)}</Label>
        </div>}
    </div>;
};

const RouteInfo = (props) => {

    let durationIconClassname = props.shortest ? "fas shortest" : "fal";
    // let carriers = unique(props.parts.map(part => part.carrier && part.carrier.name)).join(", ");
    let carriers = {};
    for (let part of props.parts) {
        if (part.carrier && part.carrier.name) {
            carriers[part.carrier.name] = part.carrier;
        }
    }

    //style={(props.hidden ? {opacity: 0.6} : {})}

    let small = !props.highlighted && !props.large;

    return <div
        className={props.className + " route-row-container " + (props.mainMode || MODES.UNKNOWN) + (props.highlighted ? " highlighted" : (props.large ? "" : " small"))}
        onMouseEnter={props.onMouseEnter}
        onMouseLeave={props.onMouseLeave}
        onClick={props.onClick}
    >
        <div className="route-info new">
            <Grid centered>
                <Grid.Row>
                    <Grid.Column className="first-column" tablet={5} computer={6} mobile={6}>
                        <div className="price phone-only" style={{float: 'right'}}>
                            <Price {...props} />
                        </div>
                        <div className="icons">
                            <ModeIcon mode={props.mainMode || MODES.UNKNOWN} size="large"/>
                        </div>
                        <div className="duration"><i
                            className={durationIconClassname + " fa-clock duration"}/>
                            <span className="hide-small">
                                {utils.formatHoursAsString(props.duration / 60)}
                            </span>
                            <span className="hide-large">
                                {utils.formatHoursAsSmallString(props.duration / 60)}
                            </span>
                            {props.overnight && <Icon name="moon" className="overnight-icon" />}
                        </div>
                        {!small &&
                        <div className="carriers large-screen-only">
                            {Object.values(carriers).map((carrier, i) => carrier &&
                                <Label basic style={{borderColor: carrier.color}} size="mini"
                                       key={"carrier" + i}>{carrier.name}</Label>)}
                        </div>
                        }
                    </Grid.Column>
                    <Grid.Column textAlign="center" className="new scores" tablet={8} computer={7} mobile={10}>
                        <div className="items">
                            <div className="item">
                                <div className="number small"
                                     style={{backgroundColor: durationScale(props.durationFactor).hex()}}>
                                    <span>{parseInt(props.duration / 60, 10)}</span>
                                    <i className="fa fa-clock"/>
                                </div>
                                <div className="title" title="Duration in minutes"><i className="fa fa-clock"/></div>
                            </div>
                            <div className="item">
                                <div className="number"
                                     style={{backgroundColor: transfersScale(props.transfersFactor).hex()}}>
                                    <span>{props.transfers}</span>
                                    <i className="fa fa-random"/>
                                </div>
                                <div className="title" title="Number of transfers"><i className="fa fa-random"/></div>
                            </div>
                            <div className="item">
                                <Popup
                                    trigger={
                                        <NavLink to="/climate-impact/factors" style={{marginTop: 0}}
                                                 onClick={(e) => e.stopPropagation()} target="_blank">
                                            <div className="number"
                                                 style={{backgroundColor: climateImpactScale(props.co2Factor).hex()}}>
                                                <span>{utils.formatCo2(props.co2)}</span>
                                                <div className="title" title="kg CO2">CO<sub>2</sub></div>
                                            </div>
                                        </NavLink>}
                                    content={<div>
                                        The estimated climate impact of this route
                                        equals {utils.formatCo2(props.co2)} kg
                                        CO<sub>2</sub>.<br/>Click to read more.
                                    </div>}
                                    position="bottom left" basic
                                />
                                <div className="title" title="kg CO2">CO<sub>2</sub></div>
                            </div>
                        </div>
                    </Grid.Column>
                    <Grid.Column textAlign="center" width={3} only="tablet computer"
                                 style={{padding: 0, paddingRight: '15px'}}>
                        <Price {...props} />
                        {/*<a className="round-button booking">View</a>*/}
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </div>
    </div>;
};
export default RouteInfo;