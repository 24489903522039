import React from 'react';

import walkingIcon from "./resources/mode-icons/walking.png";
import drivingIcon from "./resources/mode-icons/driving.png";
import busIcon from "./resources/mode-icons/bus.png";
import flyingIcon from "./resources/mode-icons/flying.png";
import trainIcon from "./resources/mode-icons/train.png";
import tramIcon from "./resources/mode-icons/tram.png";
import subwayIcon from "./resources/mode-icons/subway.png";
import taxiIcon from "./resources/mode-icons/taxi.png";
import shipIcon from "./resources/mode-icons/ship.png";
import waitingIcon from "./resources/mode-icons/waiting.png";
import unknownIcon from "./resources/mode-icons/unknown.png";

require('es7-object-polyfill');

export const PLANNER_VIEWS = {
    TIMELINE: 0,
    LIST: 1,
};

export const MODES = {
    DRIVING: "DRIVING",
    TRAM: "TRAM",
    TRAIN: "TRAIN",
    BUS: "BUS",
    UNKNOWN: "UNKNOWN",
    WALKING: "WALKING",
    METRO: "METRO",
    TAXI: "TAXI",
    BOAT: "BOAT",
    OTHER: "OTHER",
    FLYING: "FLYING",
    WAITING: "WAITING",
    ESTIMATION: "ESTIMATION",

    TRANSIT: "TRANSIT",
    FETCHING: "FETCHING",
};

export const modeIcons = {
    [MODES.WALKING]: walkingIcon,
    [MODES.FLYING]: flyingIcon,
    [MODES.TRAIN]: trainIcon,
    [MODES.TRAM]: tramIcon,
    [MODES.METRO]: subwayIcon,
    [MODES.BUS]: busIcon,
    [MODES.DRIVING]: drivingIcon,
    [MODES.TAXI]: taxiIcon,
    [MODES.BOAT]: shipIcon,
    [MODES.WAITING]: waitingIcon,
    [MODES.ESTIMATION]: unknownIcon,
    [MODES.UNKNOWN]: unknownIcon,
    [MODES.OTHER]: unknownIcon,
    [MODES.FETCHING]: shipIcon,

    [MODES.TRANSIT]: trainIcon,
};

const transitRailColor = "#aaa";
const transitBusColor = "#bbb";
const transitLocalColor = "#ccc";
const drivingColor = "#333";
const flightsColor = "#555";
const otherColor = "#fff";

export const MODECOLORS = {
    [MODES.DRIVING]: drivingColor,
    [MODES.TRAM]: transitLocalColor,
    [MODES.TRAIN]: transitRailColor,
    [MODES.BUS]: transitBusColor,
    [MODES.UNKNOWN]: otherColor,
    [MODES.WALKING]: otherColor,
    [MODES.METRO]: transitLocalColor,
    [MODES.TAXI]: drivingColor,
    [MODES.BOAT]: transitLocalColor,
    [MODES.OTHER]: otherColor,
    [MODES.FLYING]: flightsColor,
    [MODES.WAITING]: flightsColor,
    [MODES.ESTIMATION]: otherColor,
    [MODES.FETCHING]: otherColor,
};


export const modeDescriptions = {
    [MODES.WALKING]: "Walking",
    [MODES.FLYING]: "Airplane",
    [MODES.TRAIN]: "Train",
    [MODES.TRAM]: "Tram",
    [MODES.METRO]: "Metro",
    [MODES.BUS]: "Bus",
    [MODES.DRIVING]: "Car",
    [MODES.TAXI]: "Taxi",
    [MODES.BOAT]: "Ship",
    [MODES.WAITING]: "Waiting",
    [MODES.ESTIMATION]: "Estimation",
    [MODES.OTHER]: "Other",
    [MODES.UNKNOWN]: "Unknown",
    [MODES.FETCHING]: "Fetching",

    [MODES.TRANSIT]: "Transit",
};

export const emissionFactors: { [Mode] : number } = {

    //Numbers from https://co2emissiefactoren.nl/lijst-emissiefactoren/
    [MODES.FLYING]: 0.297, // also used in front-end/reducers/RouteReducer

    [MODES.TRAM]: 0.084,
    [MODES.TRAIN]: 0.026,
    [MODES.BUS]: 0.033,
    [MODES.METRO]: 0.095,


    [MODES.DRIVING]: 0.220,
    [MODES.TAXI]: 0.220,


    [MODES.WALKING]: 0,
    [MODES.WAITING]: 0,
    [MODES.ESTIMATION]: 0.03,
    [MODES.UNKNOWN]: 0,
    // BOAT: 0,
};

export const dateConstraint = {
    ARRIVAL: "ARRIVAL",
    DEPARTURE: "DEPARTURE"
};

export const SORTING = {
    PRICE: "PRICE",
    DURATION: "DURATION",
    TRANSFERS: "TRANSFERS",
    CLIMATE_IMPACT: "CLIMATE_IMPACT",
};

export const supportedCountries = ["NL", "DE"];

export const holidayOptions = {
    weekend: {
        "Plane": 350,
        "Car": 400,
        "Car Sharing": 200,
        "Train / Bus": 100,
    },
    week: {
        "Plane": 800,
        "Car": 500,
        "Car Sharing": 250,
        "Train / Bus": 250,
    },
    weeks: {
        "Plane": 1200,
        "Car": 1200,
        "Car Sharing": 600,
        "Train / Bus": 400,
    },
    intercontinental: {
        "Plane": 3000
    }
};
export const holidayDescriptions = {
    weekend: "Weekend",
    week: "1 week",
    weeks: "3 weeks",
    intercontinental: "Intercontinental trip",
};

export const extendedEmissionFactorSources = require("./resources/co2/sources");

export const extendedEmissionFactors = require("./resources/co2/factors") || {};

const extendedFlyingEmissionFactors = Object.values(extendedEmissionFactors).filter(factor => factor.mode === "Flying");

const extendedEmissionFactorSearchStrings = require("./resources/co2/search-strings");

export const extendedEmissionFactor = (mode, country, provider, type, distance) => {
    let factor = {
        amount: 0,
    };
    let precisionScale = [
        "modality", "modality & country", "provider", "provider & vehicle type"
    ];

    if (mode === MODES.FLYING) {
        let flyingEmissionFactors = [...extendedFlyingEmissionFactors.filter(f => (f.provider.length === 1 && f.country.length === 1) || f.provider.indexOf(provider) === -1).sort((a, b) => (a.maxDistance || 9999999) > (b.maxDistance || 9999999))];
        if (flyingEmissionFactors.length > 0) {
            let flyingEmissionFactor = flyingEmissionFactors.shift();
            // console.log(flyingEmissionFactor);
            while (distance > (flyingEmissionFactor.maxDistance || 99999999)) flyingEmissionFactor = flyingEmissionFactors.shift();

            factor = {
                ...flyingEmissionFactor,

            };
            // console.log(factor);
        }
        return factor;

    } else {
        let searchString = "";
        let searchParts = [];
        if (mode) searchParts.push(mode.toLowerCase());
        if (country) searchParts.push(country.toLowerCase());
        if (provider) searchParts.push(provider.toLowerCase());
        if (type) searchParts.push(type.toLowerCase());

        if (searchParts.length > 0) {
            // searchString = searchParts.shift();

            for (let searchPart of searchParts) {
                searchString += searchPart;
                // console.log("extendedEmissionFactor searchString", searchString);
                let factorId = extendedEmissionFactorSearchStrings[searchString];
                if (factorId) {
                    let precision = (factor.country && factor.country.length > 0 ? 1 : 0) +
                        (factor.provider && factor.provider.length > 0 ? 1 : 0) +
                        (factor.type && factor.type.length > 0 ? 1 : 0);
                    factor = {
                        ...extendedEmissionFactors[factorId],
                        precision,
                        precisionDescription: precisionScale[precision],
                    }
                }
            }
        }
    }

    return factor;
};

//https://developers.google.com/maps/documentation/javascript/reference/directions#VehicleType.INTERCITY_BUS
export const googleMapsModes = {
    HIGH_SPEED_TRAIN: MODES.TRAIN,
    HEAVY_RAIL: MODES.TRAIN,
    LONG_DISTANCE_TRAIN: MODES.TRAIN,
    COMMUTER_TRAIN: MODES.TRAIN,
    RAIL: MODES.TRAIN,
    METRO_RAIL: MODES.METRO,
    SUBWAY: MODES.METRO,

    MONORAIL: MODES.TRAM,
    TRAM: MODES.TRAM,

    BUS: MODES.BUS,
    INTERCITY_BUS: MODES.BUS,
    TROLLEYBUS: MODES.BUS,
};

export const extendedEmissionFactorModes = {
    Train: MODES.TRAIN,
    Bus: MODES.BUS,
    Metro: MODES.METRO,
    Tram: MODES.TRAM,
    Flying: MODES.FLYING,
    Driving: MODES.DRIVING,
    Boat: MODES.BOAT,
};

export const googleMapsProviders = {
    "Deutsche Bahn AG": "DB",
    "NS International": "NS",

};

export const flightawareProviders = {
    "AirFrance": "AFR",
    "Finnair": "",
    "Aer Lingus": "",
    "LOT Polish Airlines": "",
    "Norwegian Air Shuttle": "",
    "Corendon": "",
    "Austrian Airlines": "",
    "Vueling": "",
    "Easyjet": "",
    "Transavia": "",
    "AirEuropa": "",
};