import React, {Component} from 'react';
import {Button, Form, Input, Modal, TextArea} from "semantic-ui-react";
import firebase from 'firebase/app';
import 'firebase/firestore';
import * as ReactGA from "react-ga";

const ScaleQuestion = props => <div className="scale-question">
    <h3 style={{textAlign: "left"}}>{props.title}</h3>
    {props.left}
    <Button.Group size="mini">
        {[1, 2, 3, 4, 5, 6, 7].map(nr =>
            <Button primary={props.value === nr} key={props.name+nr} onClick={(e) => {
                e.preventDefault();
                props.onChange({target: {name: props.name, value: nr}});
            }}>{nr}</Button>
        )}
    </Button.Group>
    {props.right}
</div>;

export default class GlobalFeedbackButton extends Component {

    initialState = {
        open: false,
        feedbackText: "",
        autoOpened: false,
        email: "",
        type: "survey",
        sending: false,
        surveyProgress: 0,
        solutionText: "",
        solution: null,
        difficulty: null,
    };

    constructor(props) {
        super(props);
        this.state = this.initialState;
        this.submit = this.submit.bind(this);
        this.handleChange = this.handleChange.bind(this);

        let _this = this;

        if (props.autoOpen && !window.localStorage.getItem("feedback-auto-opened")) {
            window.setTimeout(() => {
                if (!_this.state.used) {
                    ReactGA.event({
                        category: 'feedback',
                        action: "auto-open",
                    });
                    window.localStorage.setItem("feedback-auto-opened", true);
                    _this.setState({
                        open: true,
                        used: true,
                        autoOpened: true,
                    });
                }
            }, 5*60*1000);
        }
    }

    renderChildren() {
        //https://mxstbr.blog/2017/02/react-children-deepdive/#changing-children-props
        return React.Children.map(this.props.children, child => {
            return React.cloneElement(child, {
                onClick: () => {
                    this.setState({
                        open: true,
                        used: true,
                    });
                    ReactGA.modalview("feedback");
                }
            })
        })
    }

    submit(e) {
        this.setState({"sending": true});
        firebase.firestore().collection("feedback").add({
            createdAt: new Date(),
            type: this.props.source,
            ...this.state
        }).then((ref) => {
            this.setState(this.initialState);
        });
    }

    handleChange(e) {

        let stateUpdates = {
            [e.target.name]: e.target.value
        };

        let surveySteps = ["solution", "difficulty"];
        if (surveySteps.indexOf(e.target.name) > -1) {
            stateUpdates.surveyProgress = Math.max(surveySteps.indexOf(e.target.name) + 1, this.state.surveyProgress);

            ReactGA.event({
                category: 'feedback',
                action: e.target.name,
                value: e.target.value,
            })
        }

        this.setState(stateUpdates);

    }

    render() {
        return [
            this.renderChildren(),
            <Modal size="tiny" key="modal" open={this.state.open} closeIcon
                   onClose={(e) => this.setState({open: false})}>
                <Modal.Content>
                    {this.state.autoOpened ?
                        <div>
                            <h2>How do you experience Green Tickets?</h2>
                            You would help us a lot if you could take a few minutes to share your experience with us.
                        </div>
                        :
                        <div>
                            <Button.Group>
                                <Button primary={this.state.type === "survey"}
                                        onClick={(e) => this.setState({type: "survey"})}>Your experience</Button>
                                <Button primary={this.state.type === "question"}
                                        onClick={(e) => this.setState({type: "question"})}>Question</Button>
                                <Button primary={this.state.type === "suggestion"}
                                        onClick={(e) => this.setState({type: "suggestion"})}>Suggestion</Button>
                                <Button primary={this.state.type === "comment"}
                                        onClick={(e) => this.setState({type: "comment"})}>Comment</Button>
                            </Button.Group>
                        </div>
                    }<br/>
                    <Form onSubmit={this.submit}>

                        {this.state.type === "survey" ?
                            <div>
                                <ScaleQuestion title="Does Green Tickets solve a problem for you?"
                                               left="Not at all" right="Totally" name="solution"
                                               value={this.state.solution}
                                               onChange={this.handleChange}/><br/>
                                {this.state.surveyProgress > 0 &&
                                <div>
                                    <ScaleQuestion title="Do you think this website is easy to use?"
                                                   left="Very hard" right="Very easy" name="difficulty"
                                                   value={this.state.difficulty}
                                                   onChange={this.handleChange}/><br/>
                                </div>
                                }
                                {this.state.surveyProgress > 1 &&
                                <div>

                                    <h3>{
                                        this.state.solution > 3 ? "What problem does Green Tickets exactly solve for you?" : "Why doesn't Green Tickets solve a problem for you?"
                                    }</h3>
                                    <TextArea name="solutionText" value={this.state.solutionText}
                                              onChange={this.handleChange}
                                              autoHeight placeholder='And how could we do better?'/>

                                    <h3>Anything else you'd like to share?</h3>
                                    <TextArea name="feedbackText" value={this.state.feedbackText}
                                              onChange={this.handleChange}
                                              autoHeight placeholder='Other comments or questions'/>
                                    <br/><br/>
                                    <Input fluid name="email" value={this.state.email} onChange={this.handleChange}
                                           placeholder='Your e-mail address (optional)'/>
                                </div>
                                }
                            </div>
                            :
                            <div>
                                <TextArea name="feedbackText" value={this.state.feedbackText}
                                          onChange={this.handleChange}
                                          autoHeight placeholder='Let us know what you think...'/><br/><br/>
                                <Input fluid name="email" value={this.state.email} onChange={this.handleChange}
                                       placeholder='Your e-mail address (optional)'/>
                            </div>
                        }

                    </Form>

                </Modal.Content>
                <Modal.Actions>
                    <Button color='black' onClick={(e) => this.setState({open: false})}>
                        Cancel
                    </Button>
                    <Button type='submit' positive icon='checkmark' labelPosition='right' content="Send"
                            loading={this.state.sending}
                            onClick={this.submit}/>
                </Modal.Actions>
            </Modal>
        ];
    }
}
