import React, {Component} from 'react';
import {Button, Input, Modal} from "semantic-ui-react";
import * as ReactGA from "react-ga";

export default class NewsletterSubscribeButton extends Component {

    initialState = {
        open: false,
        sending: false,
    };

    constructor(props) {
        super(props);
        this.state = this.initialState;
        this.submit = this.submit.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    renderChildren() {
        //https://mxstbr.blog/2017/02/react-children-deepdive/#changing-children-props
        return React.Children.map(this.props.children, child => {
            return React.cloneElement(child, {
                onClick: () => {
                    this.setState({open: true});
                    ReactGA.modalview("newsletter");
                }
            })
        })
    }

    submit(e) {
    //     this.setState({"sending": true});
    //     firebase.firestore().collection("feedback").add({
    //         type: this.props.source,
    //         text: this.state.feedbackText,
    //         email: this.state.email,
    //         reason: this.state.type,
    //     }).then((ref) => {
            this.setState(this.initialState);
    //     });
    }

    handleChange(e) {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    render() {
        return [
            this.renderChildren(),
            <Modal size="tiny" key="modal" open={this.state.open} closeIcon
                   onClose={(e) => this.setState({open: false})}>
                <Modal.Content>
                    <form
                        key="form"
                        ref={(el) => this.formEl = el}
                        action="https://getgreentickets.us17.list-manage.com/subscribe/post?u=caa6df6f2f574c93cf5b3607b&amp;id=4c3362a8f8"
                        method="post" id="mc-embedded-subscribe-form2" name="mc-embedded-subscribe-form"
                        className="validate" target="_blank" noValidate>
                        <div id="mc_embed_signup_scroll">
                            <label htmlFor="mce-EMAIL">Enter your e-mail address to subscribe to our mailing list:</label><br /><br />
                            <Input type="email" name="EMAIL" className="email" id="mce-EMAIL"
                                   placeholder="email address" required fluid />
                            <div style={{position: "absolute", left: "-5000px"}} aria-hidden="true">
                                <input type="text" name="b_caa6df6f2f574c93cf5b3607b_4c3362a8f8" tabIndex="-1"
                                       value=""/>
                            </div>
                        </div>
                </form>
                </Modal.Content>
                <Modal.Actions>
                    <Button color='black' onClick={(e) => this.setState({open: false})}>
                        Cancel
                    </Button>
                    <Button type='submit' form="mc-embedded-subscribe-form2" positive icon='checkmark' labelPosition='right' content="Submit" onClick={() => this.formEl.submit()} />
                </Modal.Actions>
            </Modal>
        ];
    }
}
