import React from 'react'
import {MODES} from "../../constants";
import moment from "moment/moment";
import * as utils from "../../utils";
import {RouteInfoModal} from "../RouteInfoModal";
import ModeIcon from "../ModeIcon";
import {Label, Loader, Popup} from "semantic-ui-react";
import * as formatters from "../../formatters";

const durationToString = duration => {
    let minutes = duration / 60;
    if (minutes < 30 || (minutes > 30 && minutes < 60)) {
        return minutes + " minutes"
    } else if (minutes === 60) {
        return "one hour";
    } else if (minutes === 90) {
        return "an hour and a half";
    } else {
        return minutes + " minutes"
    }
};

const RouteDetailsRouteInfoPart = (part) => {
    let mode = part.mode;
    if (mode === MODES.ESTIMATION && part.pendingAddTransit === true) mode = MODES.FETCHING;

    // let agencyName = part.agencies && part.agencies[0].name !== "UNKNOWN" && part.agencies[0].name;
    let carrierName = part.carrier && part.carrier.name;
    let carrierColor = (part.carrier && part.carrier.color) || "#ccc";
    if (mode === "WALKING") carrierColor = "#333";
    // let agencyColor = (part.agencies && part.agencies[0].color) || "#eee";
    let departureDate = moment(part.departureDate || "");
    let arrivalDate = moment(part.arrivalDate || "");
    let transportPart = mode !== MODES.WALKING && mode !== MODES.WAITING && mode !== MODES.DRIVING;
    let stops = part.stops;
    if (stops) {
        stops = stops.slice(1, -1);
    }

    const CO2Label = () => part.co2 ?
        <Popup trigger={<Label size={"mini"}> {utils.formatCo2(part.co2.amount)} kg CO<sub>2</sub></Label>}
               position="bottom right" content={
            <div>
                {part.co2.factor} g CO<sub>2</sub> per passenger km, based on {part.co2.precisionDescription}.
                Source: {part.co2.sources && part.co2.sources.map(s => s.name).join(", ")}
            </div>}/>
        : null;

    return <div className={"part" + (part.active ? " active" : "")
    + (part.first ? " first" : "")
    + (part.last ? " last" : "")
    + (part.flightAfterWait ? " first-flight" : "")
    + (part.flightBeforeWait ? " last-flight" : "")
    + " " + part.extraClassName
    + " " + mode.toLowerCase()}
                onMouseEnter={(e) => RouteInfoModal.highlight(part)}
                onMouseLeave={(e) => RouteInfoModal.deHighlight(part)}
                onClick={(e) => part.focus(e, part)}>
        {(!(mode === MODES.WAITING) &&
            <div className="part-row first">
                <div className="left">
                    {!part.notBoundToTime && departureDate.format("H:mm")}
                </div>
                {mode !== MODES.WAITING &&
                <div className="line">
                    <div className="inner-line" style={{backgroundColor: carrierColor}}/>
                    <div className="circle" style={{backgroundColor: carrierColor}}/>
                </div>
                }
                <div className="middle">
                    <strong>{part.from && part.from.name}</strong> {mode === "FLYING" && part.from.code && ("(" + part.from.code + ")")}
                </div>
            </div>
        )}
        {/*{(mode !== MODES.WAITING &&*/}
            <div className="part-row">
            <div className="left">
            {mode === MODES.FETCHING ? <Loader active={true} inline size="small"/> : <ModeIcon mode={mode}/>}
            {/*<i className={"fas fa-" + modeIcons[mode]}/>*/}
            </div>
            <div className="line">
            {mode !== MODES.WAITING &&
            <div className="inner-line" style={{backgroundColor: carrierColor}}/>
            }
            </div>
            <div className="middle">
            {mode === MODES.WAITING &&
            <span>
                    {part.waitBeforeFlight && "Arriving at least " + durationToString(part.duration) + " prior to departure"}
                {part.waitAfterFlight && "Departing at least " + durationToString(part.duration) + "  after touchdown"}
                </span>
            }
            {(mode === MODES.WALKING) &&
            part.distance && (
                <em>Walking {utils.formatDistance(part.distance)}</em>
            )
            }
            {(mode === MODES.DRIVING) &&
            part.distance && (
                <div>
                    <em>Driving {parseInt(part.distance, 10)} km</em>{" "}
                    <CO2Label/>
                </div>
            )
            }
            {(mode === MODES.ESTIMATION) &&
            <div>
                <em>Unfortunately, we were unable to process this part of the route.</em>{" "}
            </div>
            }
            {part.notBoundToTime && <Label basic size={"mini"}> {formatters.minutesToTimeDurationString(part.duration / 60)} </Label>}
            {(mode === MODES.FETCHING) &&
            "Information about this part of the route is still being loaded..."
            }
            {(part.warning) &&
            <div style={{color: "orange"}}>{part.warning}</div>
            }
            {transportPart &&
            <div>
                {carrierName && <div style={{paddingBottom: '4px'}}>
                    {carrierName}
                </div>}
                {/*{part.distance} km,*/}
                {(part.longName || part.headsign) &&
                <Label basic
                       size={"mini"}>{part.longName} {part.headsign && "towards"} {part.headsign}</Label>}
                {" "}
                <CO2Label/>
                <br style={{clear: "both"}}/><br/>
                {/*{stops &&*/}
                {/*<div className="stops">*/}
                {/*{part.active ? <table>*/}
                {/*<tbody>*/}
                {/*{stops.map(stop => <tr key={stop.id}>*/}
                {/*<td>{moment(stop.departure).format("HH:mm")}</td>*/}
                {/*<td>{stop.name}</td>*/}
                {/*</tr>)}*/}
                {/*</tbody>*/}
                {/*</table> : stops.length + " stops"}*/}
                {/*</div>*/}
                {/*}*/}
            </div>
            }
            </div>
            {/*{part.carrier && part.carrier.logo &&*/}
            {/*<img src={part.carrier.logo} alt={carrierName} style={{float: "right"}}*/}
            {/*className="carrier-image"/>*/}
            {/*}*/}
            </div>
        {/*})*/}
        {(!(mode === MODES.WAITING) &&
            <div className="part-row last">
                <div className="left">
                    {!part.notBoundToTime && arrivalDate.format("H:mm")}
                </div>
                <div className="line">
                    <div className="inner-line" style={{backgroundColor: carrierColor}}/>
                    {(part.last || mode === MODES.FLYING) &&
                    <div className="circle" style={{backgroundColor: carrierColor}}/>
                    }
                </div>
                <div className="middle">
                    {(part.last || mode === MODES.FLYING) &&
                    <span>
                        <strong>{part.to && part.to.name}</strong> {mode === "FLYING" && part.to.code && ("(" + part.to.code + ")")}
                    </span>
                    }
                </div>
            </div>
        )}
    </div>;
    //
    //
    // return <div className="leg"
    //     onMouseEnter={part.onMouseEnter}
    //     onMouseLeave={part.onMouseLeave}
    //     onClick={part.onClick}>
    //     {(mode === "WALKING" || mode === "WAITING" || mode === "DRIVING") ?
    //         <div className="info">
    //             <div className="type">
    //                 {departureDate.format("H:mm")}
    //                 <i className={"fas fa-" + modeIcons[mode]}/>
    //                 {arrivalDate.format("H:mm")}
    //             </div>
    //                 {(part.distance && (
    //                     <em>{part.distance < 1000 ? (
    //                         part.distance + " m"
    //                     ) : (
    //                         part.distance / 1000 + " km"
    //                     )}</em>
    //                 ))}
    //             <div className="line" style={{backgroundColor: carrierColor}}/>
    //         </div>
    //         :
    //         <div className="info">
    //             <div className="type">
    //                 {departureDate.format("H:mm")}
    //                 <i className={"fas fa-" + modeIcons[mode]}/>
    //                 {arrivalDate.format("H:mm")}
    //             </div>
    //             <table>
    //                 <tbody>
    //                 <tr>
    //                     <td>{part.from && part.from.name} {mode === "FLYING" && part.from.code && ("(" + part.from.code + ")")}</td>
    //                 </tr>
    //                 <tr>
    //                     <td>{part.to && part.to.name} {mode === "FLYING" && part.to.code && ("(" + part.to.code + ")")}</td>
    //                 </tr>
    //                 </tbody>
    //             </table>
    //             <br/>
    //             {part.carrier && part.carrier.logo && (
    //                 <img src={part.carrier.logo} alt={carrierName} style={{float: "right"}} />
    //             )}
    //             {(part.price && (
    //                 utils.formatPriceAsString(part.price) + " "
    //             ))}
    //             {part.co2 && (
    //                 " - " + utils.formatCo2(part.co2) + " kg CO2"
    //             )}
    //             <br/><br/>
    //             <div className="line" style={{backgroundColor: carrierColor}}/>
    //         </div>
    //     }
    // </div>;
};

export default RouteDetailsRouteInfoPart;