// @flow

import React, {Component} from 'react';
import RoadMap from "../components/RoadMap";
import {Container, Grid} from "semantic-ui-react";
import logo from "../resources/images/logo-header.png"

type Props = {};

export default class RoadMapPage extends Component<Props> {

    render() {

        return (
            <div className="page-container">

                <div className="roadmap-header">
                    <div className="line-background">
                        <div className="line"/>
                        <Container>
                            <Grid>
                                <Grid.Row>
                                    <Grid.Column width={7}>
                                        <img src={logo}
                                             alt="Green Tickets | Building the European travel planner of the future"/>
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>
                        </Container>
                    </div>
                </div>

                <Container>

                    <Grid>
                        <Grid.Row>
                            <a id="planner" name="planner"> </a>
                            <h1 className="large-main-title"><span>roadmap</span></h1>
                        </Grid.Row>
                    </Grid>

                    <RoadMap/>

                    <Grid>
                        <Grid.Row>
                            <div style={{height: "100px"}}/>
                        </Grid.Row>
                    </Grid>
                </Container>
            </div>
        );
    }
}