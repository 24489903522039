// @flow

import type {Action} from "../models/Action";
import update from 'immutability-helper';
import {SearchUpdateFactory} from "../models/SearchUpdate";
import SearchUpdate from "../models/SearchUpdate";
import * as TaskActions from "../actions/RouteActions";

export const SEARCH_UPDATE = "SEARCH_UPDATE";

type State = {
    +items: {[string]: Array<SearchUpdate>},
}

const initialState : State = {
    items: {},
};

export default (state : State = initialState, action : Action) : State  => {

    switch (action.type){

        case TaskActions.FETCH_ROUTES:
            return update(state, {
                items: {$set: {}}
            });

        case SEARCH_UPDATE:
            const searchUpdate = SearchUpdateFactory(action);
            if(!searchUpdate) return state;

            if(state.items[action.meta.requestId]){
                return update(state, {
                    items: {
                        [action.meta.requestId]: {$push: [searchUpdate]}
                    }
                });
            }else{
                return update(state, {
                    items: {
                        [action.meta.requestId] : {$set: [searchUpdate]}
                    }
                });
            }
        default:
            return state;
    }
}