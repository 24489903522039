import firebase from 'firebase/app';
import 'firebase/auth';

export function saveStory() {
    return function (dispatch, getState) {
        dispatch({type: "SAVE_STORY"});

        const state = getState();
        const story = state.inspiration.editingStory;
        if (!story || !story.id) {
            console.warn("Story is not saveable...");
            return;
        }

        story.savedMoment = null;
        story.updatedAt = new Date();
        if(story.userInfo)delete story.userInfo;

        firebase.firestore().collection("stories").doc(story.id).update(story).then(() => {
            dispatch({type: "SAVED_STORY", payload: story.id});
        }).catch(console.error)

    }
}

export function addStory(routeData) {
    return function (dispatch, getState) {
        dispatch({type: "SAVE_STORY", payload: routeData});

        const state = getState();

        routeData.userId = state.user.user.uid;
        routeData.createdAt = new Date();
        if(routeData.userInfo)delete routeData.userInfo;

        firebase.firestore().collection("stories/").add(routeData).then((ref) => {
            dispatch({type: "SAVED_STORY", payload: ref.id});
        }).catch(console.error)

    }
}

export function fetchSavedStories(all, _userId) {
    return (dispatch, getState) => {

        const state = getState();

        let userId = null;
        if(_userId) {
            userId = _userId;
        } else if(state.user.user && state.user.user.uid) {
            userId = state.user.user.uid;
        }

        if (state.inspiration.fetchedSavedStories) return;

        dispatch({type: "FETCH_SAVED_STORIES"});

        let ref = firebase.firestore().collection("stories");
        if (!all) ref.where("userId", "==", userId);
        ref.orderBy("createdAt", "desc")
            .onSnapshot({
                includeMetadataChanges: false
            }, snapshot => {
                let items = {};
                snapshot.forEach(doc => {
                    const item = doc.data();
                    item.id = doc.id;
                    items[doc.id] = item;
                    item.userStory = item.userId === userId;
                });

                dispatch({type: "FETCHED_SAVED_STORIES", payload: items})
            })
    };
}

export function fetchStory(id) {
    return (dispatch, getState) => {

        const state = getState();
        if (!state.user.user || !state.user.user.uid) return;
        if (state.inspiration.editingStory && state.inspiration.editingStory.id === id) return;

        dispatch({type: "FETCH_SAVED_STORIES"});

        let ref = firebase.firestore().collection("stories").doc(id)
            .get().then(doc => {
                const item = doc.data();
                if (item) {
                    item.id = doc.id;
                    item.fetched = true;
                    item.saved = true;
                    dispatch({type: "FETCHED_SAVED_STORY", payload: item});

                    //Fetch user info
                    firebase.firestore().collection("users").doc(item.userId)
                        .get().then(doc => {
                        const userInfo = doc.data();
                        dispatch({
                            type: "FETCHED_SAVED_STORY_USER_INFO", payload: {
                                id: item.id,
                                userInfo
                            }
                        });
                    });

                }
            })
    };
}

export function editField(label, value) {
    return {type: "EDIT_STORY_FIELD", payload: {label, value}};
}

export function editSubField(field, nr, label, value) {
    console.log({field, nr, label, value});
    return {type: "EDIT_STORY_SUBFIELD", payload: {field, nr, label, value}};
}