// @flow

import React from 'react'
import {modeIcons, modeDescriptions} from "../constants";
import type {Mode} from "../constants";

type Props = {
    mode: Mode,
    light?: boolean,
    size?: string,
    onClick?: () => {}
}

const ModeIcon = (props : Props) =>
    <img src={modeIcons[props.mode]} className={"mode-icon" + (props.light ? " white" : "") + (props.active ? " active" : "") +  (props.size ? " " + props.size : "")} alt={modeDescriptions[props.mode]} onClick={props.onClick} />;


export default ModeIcon;