import React, {Component} from 'react';
import {Button, Container, Grid, Header} from "semantic-ui-react";
import {NavLink} from "react-router-dom";
import util from 'util';

export default class PageErrorBoundary extends Component {
    constructor(props) {
        super(props);
        this.state = {hasError: false};
    }

    componentDidCatch(error, info) {
        // Display fallback UI
        this.setState({hasError: true, error, info});
    }

    render() {
        if (this.state.hasError) {
            const {error, info} = this.state;

            // You can render any custom fallback UI
            return <div className="page-container">
                <Container>
                    <Grid stackable>
                        <Grid.Row centered>
                            <Grid.Column width={10} textAlign="center">
                                <br /><br />
                                <Header as="h1">Something went wrong...</Header><br/>
                                <div>
                                    Unfortunately, an error occurred. We have been notified and will try to solve the
                                    issue as soon as possible.
                                </div>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row centered>
                            <Button as={NavLink} to="/" onClick={(e) => this.setState({hasError: false})}>Go
                                Back</Button>
                        </Grid.Row>
                        <Grid.Row centered>
                            <Grid.Column>
                                <pre>
                                    {util.inspect(error, false, null)}
                                </pre>
                                <code>
                                    {util.inspect(info, false, null)}
                                </code>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Container>
            </div>
        }
        return this.props.children;
    }
}