import React from 'react'
import {MODES} from "../../constants";
import * as utils from "../../utils";
import RouteDetailsRouteInfoPart from "./RouteDetailsRouteInfoPart";
import {Button, Dropdown, Label, Popup} from "semantic-ui-react";
import * as ReactGA from "react-ga";

function logBooking(id, price) {
    ReactGA.event({
        category: 'booking',
        action: 'click',
        label: id,
        value: price,
    });
    ReactGA.plugin.execute('ecommerce', 'addTransaction', {
        id,
        revenue: price,
    });
}

const RouteDetailsRouteInfoLeg = (props) => {

    let {leg, partsById} = props;
    let partIds = leg.parts || [];
    // let agencies = leg.agencies && leg.agencies.filter(a => a.best);
    let ticket = leg.tickets && leg.tickets.filter(ticket => (ticket.isBestTicket === true || leg.tickets.length === 1))[0];
    // let ticketPrices = (leg.tickets && leg.tickets.map(ticket => ticket.price)) || [];
    // let lowestTicketPrice = Math.min(...ticketPrices);
    // let highestTicketPrice = Math.max(...ticketPrices);

    let carriers = [];

    // console.log(partsById);
    // console.log(partIds.join(","));

    let firstPart = partIds[0] && partsById[partIds[0]];
    if (firstPart.mode === MODES.ESTIMATION) {
        carriers = firstPart.suggestedCarriers;
    } else {
        let carriersById = partIds.map(partId => partsById[partId])
            .filter(part => part.carrier)
            .map(part => part.carrier)
            .reduce((acc, curr) => {
                acc[curr.id] = curr;
                return acc
            }, {});
        carriers = Object.values(carriersById);
    }


    // let agency = agencies && agencies.length > 0 && agencies[0];
    // return leg.bookable ?
    //     <div className="leg bookable-combination">
    //         {ticket && ticket.url &&
    //         <div className="booking-button-container">
    //             <a href={ticket.url} target="_blank" className="round-button" onClick={(e) =>
    //                 ReactGA.event({
    //                     category: 'booking',
    //                     action: 'click',
    //                     value: leg.price,
    //                 })}>Book</a>
    //         </div>
    //         }
    //         {partIds.map((partId, i) => {
    //                 let routeInfo = partsById[partId];
    //                 // routeInfo.agency = agency;
    //                 routeInfo.legBookable = !!leg.bookable;
    //                 routeInfo.active = partId === props.activePart;
    //                 routeInfo.first = i === 0;
    //                 routeInfo.last = i === partIds.length - 1;
    //                 routeInfo.legPrice = i === 0 && leg.price;
    //                 return <RouteInfoPart key={partId} focus={props.focus} {...routeInfo} />;
    //             }
    //         )}
    //         {/*{(leg.bookingUrl && (*/}
    //         {/*<a href={leg.bookingUrl} target="_blank"> Book!</a>*/}
    //         {/*))}*/}
    //         {/*{agency &&*/}
    //         {/*<div className="line" style={{backgroundColor: agency.color}}/>*/}
    //         {/*}*/}
    //     </div>
    //     :
    // let showHeaderAndFooter = leg.bookingPossible && partsById[partIds[0]].mode !== MODES.WALKING; //@TODO FIX IN BACK_END

    return <div className="leg">
        {/*{showHeaderAndFooter &&*/}
            <div className="part first leg-header">
            <div className="agency">
            {carriers.map(carrier =>
                carrier && carrier.logo && (
                    carrier.url ? <a href={carrier.url} target="_blank" title={carrier.name} key={carrier.name}><img
                            src={carrier.logo}
                            alt={carrier.name}/></a>
                        : <img src={carrier.logo} alt={carrier.name} key={carrier.name}/>
                )
            )}
            </div>

            {leg.bookingPossible && !leg.bookingRequired && (
                <div className="info">
                    Booking not required
                </div>
            )}
            <br style={{clear: "both"}} />
            </div>
        {/*}*/}
        {partIds.map((partId, i) => {
            let routeInfo = partsById[partId] || {};
            // routeInfo.agency = agency;
            routeInfo.legBookable = !!leg.bookable;
            routeInfo.legPrice = i === 0 && leg.price > 0 && leg.price;
            routeInfo.active = partId === props.activePart;
            routeInfo.first = i === 0;
            routeInfo.last = i === partIds.length - 1;
            routeInfo.pendingAddTransit = props.pendingAddTransit;
            // routeInfo.extraClassName = showHeaderAndFooter ? "no-round-corners" : "";
            // routeInfo.firstFlight = i > 0 && partsById[partIds[i-1]].waitingBeforeFlight;
            // routeInfo.lastFlight = i < partIds.length - 1 && partsById[partIds[i+1]].waitingAfterFlight;
            return <RouteDetailsRouteInfoPart key={partId} focus={props.focus} {...routeInfo} />
        })}
        {/*{showHeaderAndFooter &&*/}
            <div className="part last leg-footer">

            <div className="ticket-options">
            {leg.tickets && leg.tickets.length > 1 &&
            <Dropdown text={leg.tickets.length + (leg.priceIsEstimate ? " booking options" : " ticket options")}
                      icon="caret down" labeled className='icon'
                      pointing="top">
                <Dropdown.Menu>
                    {leg.tickets.map((ticket, i) =>
                        <Dropdown.Item key={leg.id + ticket + i} as="a" href={ticket.url} target="_blank"
                                       onClick={() => logBooking(leg.id, ticket.price)}>
                            <span style={{float: "right", fontWeight: "500"}}>
                                {utils.formatPriceAsString(ticket.price)}
                            </span>
                            {ticket.agency.logo ?
                                <img src={ticket.agency.logo} alt={ticket.agency.name}
                                     style={{overflow: "hidden"}}/>
                                : ticket.agency.name}
                            <span style={{width: "100px", display: "inline-block"}}>
                                {ticket.label ? ticket.label : ticket.agency.name}
                            </span>
                            {ticket.comfortClass && <span className="class">{ticket.comfortClass}</span>}
                        </Dropdown.Item>
                    )}
                </Dropdown.Menu>
            </Dropdown>
            }
            </div>
            <div>
            {ticket && ticket.url ?
                leg.priceIsEstimate ?
                    <Popup
                        trigger={<Button as="a" labelPosition="left" style={{cursor: "default"}} href={ticket.url}
                                         target="_blank" onClick={() => logBooking(leg.id, ticket.price)}>
                            <Label basic icon={"calculator"} pointing='right' style={{paddingLeft: "10px"}}/>
                            <Button basic className={"inactive"}>
                                {utils.formatPriceAsString(ticket.price) + " "}
                            </Button>
                        </Button>}
                        content={ticket.description}
                        position="bottom center"
                        inverted
                    />
                    :
                    <Button as='a' labelPosition='left' href={ticket.url} target="_blank"
                            onClick={() => logBooking(leg.id, ticket.price)}>
                        <Label basic pointing='right'>
                            {ticket && ticket.agency && (ticket.agency.logo ?
                            <img src={ticket.agency.logo} alt={ticket.agency.name}/>
                            : ticket.agency.name)}
                        </Label>
                        <Button>
                            {ticket.price ? utils.formatPriceAsString(ticket.price) : "Book"}
                        </Button>
                    </Button>
                : leg.price && leg.priceIsEstimate ?
                    <Popup
                        trigger={<Button as="div" labelPosition="left" style={{cursor: "default"}}>
                            <Label basic icon={"calculator"} pointing='right' style={{paddingLeft: "10px"}}/>
                            <Button basic className={"inactive"}>
                                {utils.formatPriceAsString(leg.price) + " "}
                            </Button>
                        </Button>}
                        content={"This is an estimation, please find the accurate price on the website of the mobility provider."}
                        position="bottom center"
                        inverted
                    />
                    : leg.price &&
                    <Button basic className={"inactive"}>
                        {utils.formatPriceAsString(leg.price) + " "}
                    </Button>
            }
            </div>
            <br style={{clear: "both"}} />
            </div>
        {/*}*/}
    </div>
    // ; `
};

export default RouteDetailsRouteInfoLeg;