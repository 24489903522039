import * as TaskActions from '../actions/RouteActions'
import update from 'immutability-helper';
import moment from "moment";
import {MODES, SORTING} from "../constants";
import {SEARCH_UPDATE} from "./SearchUpdateReducers";
import {SearchUpdateTypes} from "../models/SearchUpdate";
import {formatCo2} from "../utils";


export default (state = {

    items: [],
    groupedItems: {},

    allItems: [],
    fetching: false,
    fetchingTakesLong: false,
    filtering: false,
    fetched: false,
    sorting: SORTING.CLIMATE_IMPACT,
    info: {},
    active: null,
    itemCount: 0,
    filteredItemCount: 0,
    showPlannerForm: true,

    shouldSearchAgain: false,
    plannerInfo: "",
    query: null,
    modes: {
        transit: {fetching: false,},
        flights: {fetching: false,},
        driving: {fetching: false,},
    },
    activeRequestId: null,
    requestId: null,

    fetchingFlights: false,
    flights: [],

    drivingType: "default",
    drivingPax: 2,

    highlightMode: null,

}, action) => {
    switch (action.type) {

        case SEARCH_UPDATE:
            if (action.meta && action.meta.type === SearchUpdateTypes.SEARCH_INTERPRETATION) {
                return update(state, {
                    activeRequestId: {$set: action.meta.requestId}
                })
            }
            return state;
        case TaskActions.FETCH_FLIGHTS:
            return update(state, {
                fetchingFlights: {$set: true}
            });
        case TaskActions.SET_DRIVING_TYPE:
            return update(state, {
                drivingType: {$set: action.payload}
            });
        case TaskActions.ADJUST_DRIVING_PAX: {
            let pax = (action.payload === "subtract" ? state.drivingPax - 1 : state.drivingPax + 1);
            let setSorting = state.sorting;
            let on = "departureDate";
            if (setSorting) {
                on = {
                    [SORTING.DURATION]: "duration",
                    [SORTING.PRICE]: "price",
                    [SORTING.TRANSFERS]: "transfers",
                    [SORTING.CLIMATE_IMPACT]: "co2",
                }[setSorting];
            }
            let filteredResults = filterItems(state.items, pax);
            let items = filteredResults["items"];
            return update(state, {
                drivingPax: {$set: pax},
                items: {
                    $set: sortItems(items, on),
                },
            });
        }
        case TaskActions.FLIGHTS_ERROR:
            return update(state, {
                fetchingFlights: {$set: false},
                flights: {$set: []},
            });
        case TaskActions.RECEIVED_FLIGHTS:
            return update(state, {
                flights: {$set: action.payload.results},
                fetchingFlights: {$set: false},
            });
        case TaskActions.RECEIVED_REMOVED_ROUTES:
            if(!action.payload || action.payload.length < 1)return state;

            let items = [];
            for (let item of state.items) {
                if(action.payload.indexOf(item.id) === -1)items.push(item);
            }
            let allItems = [];
            for (let item of state.allItems) {
                if(action.payload.indexOf(item.id) === -1)allItems.push(item);
            }

            return update(state, {
                items: {$set: items},
                allItems: {$set: allItems},
            });
        case TaskActions.RECEIVED_ROUTES: {

            if (!action.payload || action.payload.length < 1) {
                return update(state, {
                    modes: {
                        [action.meta.type]: {
                            fetching: {$set: !action.meta.finished},
                            fetched: {$set: !!action.meta.finished}
                        }
                    }
                });
            }
            if (state.activeRequestId !== null && action.meta.requestId !== state.activeRequestId) {
                console.warn("Received routes for inactive request id");
                return state;
            }
            let itemsById = {};
            for (let item of state.items) {
                itemsById[item.id] = item;
            }

            //Update current items
            let newItems = parseItems(action.payload, action.meta.type);
            for (let newItem of newItems) {
                itemsById[newItem.id] = newItem;
            }

            let items = Object.values(itemsById);

            //sort items
            items = sortItems(items, state.sorting || "departureDate");
            let filteredResults = filterItems(items, state.drivingPax);
            items = filteredResults["items"];

            //group items
            // let groupedItems = groupItems(items);
            // console.log(groupedItems);

            let setSorting = state.sorting;
            let on = "departureDate";
            if (setSorting) {
                on = {
                    [SORTING.DURATION]: "duration",
                    [SORTING.PRICE]: "price",
                    [SORTING.TRANSFERS]: "transfers",
                    [SORTING.CLIMATE_IMPACT]: "co2",
                }[setSorting];
            }

            return update(state, {
                items: {$set: sortItems(items, on)}, //routes
                // groupedItems: {$set: groupedItems}, //routes
                allItems: {$set: items}, //routes
                itemCount: {$set: items.length},
                filteredItemCount: {$set: items.filter(item => !item.hidden).length},
                activeRequestId: {$set: action.meta.requestId},
                plannerInfo: {$set: action.meta},
                itemsInfo: {
                    $set:
                        {
                            shortestDurations: filteredResults["shortestDurations"],
                            longestDurations: filteredResults["longestDurations"],
                        }
                },
                // active: {$set: items[0]},
                modes: {
                    [action.meta.type]: {
                        fetching: {$set: !action.meta.finished},
                        fetched: {$set: !!action.meta.finished}
                    }
                }
            });
        }
        case TaskActions.ADDED_TRANSIT_PART:
            let newCompletedRoute = null;
            let newItems = state.items.map(route => {
                if (route.id === action.payload.id) {
                    newCompletedRoute = parseItem(Object.assign(
                        action.payload, {
                            pendingAddTransit: false
                        }), route.source);
                    return newCompletedRoute;
                }
                return route;
            });
            let updates = {
                items: {
                    $set: filterItems(newItems)["items"]
                }
            };
            //Need to update activeRoute?
            if (newCompletedRoute && state.active && state.active.id === action.payload.id) {
                updates.active = {$set: newCompletedRoute}
            }

            return update(state, updates);

        // const index = state.items.findIndex(route => route.id === action.payload.id);
        // return update(state, {
        //     items: {
        //         [index]: {
        //             $set: parseItem(Object.assign(
        //                 action.payload, {
        //                     pendingAddTransit: state.items[index].pendingAddTransit
        //                 }), state.items[index].source)
        //         }
        //     }
        // });
        case TaskActions.SET_FILTERING:
            return update(state, {
                filtering: {$set: action.payload},
            });
        case TaskActions.SHOW_PLANNER_FORM:
            return update(state, {
                showPlannerForm: {$set: true},
            });
        case TaskActions.CHECK_FETCHING_STATUS:
            //TODO: fix for different tasks
            return update(state, {
                fetchingTakesLong: {$set: state.fetching},
            });
        case TaskActions.FINISHED_RECEIVED_ROUTES:
            return update(state, {
                fetching: {$set: false},
                activeRequestId: {$set: null},
                requestId: {$set: action.meta.requestId},
                // modes: {
                //     transit: {fetching: {$set: false}},
                //     flights: {fetching: {$set: false}},
                //     driving: {fetching: {$set: false}},
                // }
            });
        case TaskActions.SET_ACTIVE_ROUTE:
            if (typeof action.payload === "object") {
                return update(state, {
                    active: {$set: action.payload},
                });
            } else if (action.payload !== null) {
                let routes = state.items.filter(item => item.id === action.payload);
                if (routes && routes.length > 0) {
                    return update(state, {
                        active: {$set: routes[0]},
                    });
                }
            }
            return update(state, {
                active: {$set: null},
            });
        case TaskActions.FETCH_ROUTES:
            return update(state, {
                items: {$set: []}, //routes
                fetching: {$set: true},
                showPlannerForm: {$set: false},
                query: {$set: action.data},
                activeRequestId: {$set: null},
                modes: {
                    $set: {
                        transit: {fetching: action.data.modes.transit,},
                        flights: {fetching: action.data.modes.flights,},
                        driving: {fetching: action.data.modes.driving,},
                    }
                }
            });
        case TaskActions.FETCH_ADD_TRANSIT:

            return update(state, {
                items: {
                    $set: state.items.map(route => {
                        if (route.id === action.payload) {
                            route.pendingAddTransit = true;
                        }
                        return route;
                    })
                }
            });
        case TaskActions.CANCEL_ROUTES:
            return update(state, {
                activeRequestId: {$set: null},
                fetching: {$set: false},
            });
        case TaskActions.FILTER_MODE:
            return update(state, {
                items: {$set: state.allItems.filter(item => action.payload.modes[item.source])}
            });
        case TaskActions.HIGHLIGHT_MODE:
            if (state.highlightMode === action.payload.mode) {
                return update(state, {
                    highlightMode: {
                        $set: null,
                    },
                    items: {
                        $set: state.items.map(item => {
                            item.highlighted = false;
                            return item;
                        })
                    }
                });
            } else {
                return update(state, {
                    highlightMode: {
                        $set: action.payload.mode,
                    },
                    items: {
                        $set: state.items.map(item => {
                            item.highlighted = item.mainMode === action.payload.mode;
                            return item;
                        })
                    }
                });
            }
        case TaskActions.EXPAND_ALL:
            if (state.highlightMode === "ALL") {
                return update(state, {
                    highlightMode: {
                        $set: null,
                    },
                    items: {
                        $set: state.items.map(item => {
                            item.highlighted = false;
                            return item;
                        })
                    }
                });
            } else {
                return update(state, {
                    highlightMode: {
                        $set: "ALL",
                    },
                    items: {
                        $set: state.items.map(item => {
                            item.highlighted = true;
                            return item;
                        })
                    }
                });
            }

        case TaskActions.SORT:
            let setSorting = action.payload.on;
            let on;
            if (setSorting === state.sorting) {
                on = "departureDate";
                setSorting = null;
            } else {
                on = {
                    [SORTING.DURATION]: "duration",
                    [SORTING.PRICE]: "price",
                    [SORTING.TRANSFERS]: "transfers",
                    [SORTING.CLIMATE_IMPACT]: "co2",
                }[action.payload.on];
            }
            return update(state, {
                sorting: {
                    $set: setSorting,
                },
                items: {
                    $set: sortItems(state.items, on),
                }
            });

        default:
            return state;
    }
}
;


// function standardDeviation(values){
//     let avg = average(values);
//
//     let squareDiffs = values.map(function(value){
//         let diff = value - avg;
//         return diff * diff;
//     });
//
//     let avgSquareDiff = average(squareDiffs);
//
//     return Math.sqrt(avgSquareDiff);
// }

// function average(data){
//     let sum = data.reduce(function(sum, value){
//         return sum + value;
//     }, 0);
//
//     return sum / data.length;
// }

function sortItems(_items, on) {
    let items = [..._items];
    if (on === "departureDate" || on === "arrivalDate") {
        items.sort((a, b) => a[on].diff(b[on]))
    } else {
        items.sort((a, b) => a[on] - b[on])
    }
    return items;
}

function filterItems(items, drivingPax) {
    //TODO: Optimize and enable SORTING on other keys

    // At least 1 per mode
    let durations = {}, prices = {}, climateImpacts = [], transfers = [];

    for (let item of items) {
        if (!durations[item.source]) durations[item.source] = [];
        durations[item.source].push(parseInt(item.duration, 10));
        if (!prices[item.source]) prices[item.source] = [];
        prices[item.source].push(parseInt(item.price, 10));
    }

    let shortestDurations = {}, lowestPrices = {}, longestDurations = {};
    for (let source of Object.keys(durations)) {
        shortestDurations[source] = Math.min(...durations[source]);
        longestDurations[source] = Math.max(...durations[source]);
    }
    for (let source of Object.keys(prices)) {
        lowestPrices[source] = Math.min(...prices[source]);
    }

    let shortestOverallDuration = Math.min(...Object.values(shortestDurations));
    let lowestOverallPrice = Math.min(...Object.values(lowestPrices));

    for (let item of items) {
        let price = item.price;
        let duration = item.duration;
        let lowestPriceForCurrentSource = lowestPrices[item.source];
        let shortestDurationForCurrentSource = shortestDurations[item.source];
        if (
            !(price <= lowestPriceForCurrentSource || duration <= shortestDurationForCurrentSource) && (
                (price > lowestPriceForCurrentSource * 1.2 && duration > shortestDurationForCurrentSource * 1.2) ||
                (price > lowestPriceForCurrentSource * 2.5 && duration > shortestDurationForCurrentSource * 0.8) ||
                (price > lowestPriceForCurrentSource * 0.8 && duration > shortestDurationForCurrentSource * 2)
            )
        ) {
            // console.log("hiding route, price " + price + "/" + lowestPriceForCurrentSource + " and " + duration + "/" + shortestDurationForCurrentSource)
            item.hidden = true;
        } else {
            // Not hidden, include for scaling
            climateImpacts.push(item.co2);
            transfers.push(item.transfers);
        }
        item.large = (price <= lowestPriceForCurrentSource * 1.1 || duration <= shortestDurationForCurrentSource * 1.1);
        item.shortest = (duration < shortestOverallDuration * 1.1);
        item.lowestPrice = (price < lowestOverallPrice * 1.1);

        if (item.mainMode === MODES.DRIVING && drivingPax) {
            if (!item.originalCo2) {
                item.originalCo2 = item.co2;
            }
            item.co2 = item.originalCo2 / drivingPax;
            if (!item.originalPrice) {
                item.originalPrice = item.price;
            }
            item.price = item.originalPrice / drivingPax;
        }

        item.large = true;
    }

    const maxDistance = items.reduce((acc, item) => Math.max(acc, item.distance), 0);
    const maxDuration = items.reduce((acc, item) => Math.max(acc, item.duration), 0);
    const minDuration = items.reduce((acc, item) => Math.min(acc, item.duration), Infinity);
    const estimateClimateImpactPlane = maxDistance * 0.32 / 1000;

    let smallestClimateImpact = Math.min(Math.min(...climateImpacts), estimateClimateImpactPlane);
    let largestClimateImpact = Math.max(Math.max(...climateImpacts), estimateClimateImpactPlane);
    let largestAmountOfTransfers = Math.max(...transfers);

    items = items.map(item => {

        item.co2Factor = item.co2 / largestClimateImpact;
        item.transfersFactor = item.transfers / largestAmountOfTransfers;
        item.durationFactor = (item.duration - minDuration) / (maxDuration - minDuration);

        item.description = itemDescription(item.source, item.shortest, item.lowestPrice, item.co2 / smallestClimateImpact <= 1.1);
        return item
    });


    // let durations = items.map(item => parseInt(item.duration, 10));
    // let durationStDev = parseInt(standardDeviation(durations), 10);
    //
    // let prices = items.filter(item => item.price).map(item => parseInt(item.price, 10));
    // let priceStDev = parseInt(standardDeviation(prices), 10);
    //
    // for(let item of items){
    //     item.nrOfDurationStDevs = Math.round((durationStDev - item.duration) / durationStDev * 100) / 100;
    //     let hideForDuration = item.nrOfDurationStDevs < -0.5;
    //     let largeForDuration = item.nrOfDurationStDevs >= 0.2;
    //
    //     let hideForPrice = false;
    //     let largeForPrice = false;
    //     if(item.price) {
    //         item.nrOfPriceStDevs = Math.round((priceStDev - item.price) / priceStDev * 100) / 100;
    //         hideForPrice = item.nrOfPriceStDevs < -0.5;
    //         largeForPrice = item.nrOfPriceStDevs >= 0.2;
    //     }
    //
    //     item.hidden = hideForDuration || hideForPrice;
    //     item.large = largeForDuration || largeForPrice;
    // }


    // let csv = "";
    // let info = items.map(item => parseInt(item.duration, 10)); //item.co2 + ";" +
    //     ({
    //     co2: item.co2,
    //     duration: item.duration,
    //     distance: item.distance
    // }));


    return {
        items,
        shortestDurations,
        longestDurations
    };
}

function itemDescription(item, shortest, cheapest, cleanest) {
    let description = "";

    let properties = [];
    if (shortest) {
        properties.push("fastest");
    }
    if (cheapest) {
        properties.push("cheapest");
    }
    if (cleanest) {
        properties.push("most environmentally friendly");
    }

    let propertiesText = properties.join(" and ");
    if (properties.length > 2) propertiesText = propertiesText.replace(" and ", ", ");

    if (properties.length > 0) {
        description = "This is one of the " + propertiesText + " options.";
    }

    return description;
}

function parseItems(items, type) {

    let itemHashes = [];

    let returnItems = [];
    for (let item of items) {
        if (!item) continue;
        let parsedItem = parseItem(item, type);
        if (itemHashes.indexOf(parsedItem.hash) === -1 &&
            item.hasNegativeWaitingTimes !== true) {
            returnItems.push(parsedItem);
            itemHashes.push(parsedItem.hash);
        }
    }

    return returnItems;
}

function groupItems(items) {

    // console.log(items);

    let groupedItems = {};
    for (let item of items) {
        let modes = item.parts.map(item => item.mode).unique().sort((a, b) => a.localeCompare(b)).join("-");

        if (!groupedItems[modes]) {
            groupedItems[modes] = {items: []}
        }
        groupedItems[modes].items.push(item);
    }

    for (let mode of Object.keys(groupedItems)) {
        let groupItems = groupedItems[mode].items;
        groupedItems[mode].durations = groupItems.map(item => item.duration).unique().sort((a, b) => a - b);
        groupedItems[mode].co2 = groupItems.map(item => formatCo2(item.co2) + "").unique().sort((a, b) => a.localeCompare(b));
        groupedItems[mode].prices = groupItems.filter(item => item.price).unique().map(item => item.prices).sort((a, b) => a - b);
    }

    return groupedItems;
}

function parseItem(item, type) {
    item.departureDate = moment(item.departureDate);
    item.arrivalDate = moment(item.arrivalDate);
    item.source = type;

    item.waitingTimes = [];
    item.hasNegativeWaitingTimes = false;
    item.hash = item.parts.map((part, i) => {
        let partArrival = moment(part.arrivalDate);
        if (item.parts.length > i + 1) {
            let waitingTime = moment(item.parts[i + 1].departureDate).diff(partArrival);
            item.waitingTimes.push(waitingTime);
            if (waitingTime < 0) {
                item.hasNegativeWaitingTimes = true;
                console.warn("Route found with negative waiting times: ", item);
            }
        }
        return part.mode + "-" +
            (part.carrier && part.carrier.id) + ":" +
            moment(part.departureDate).unix() + "@" +
            part.from.id + "-" +
            partArrival.unix() + "@" +
            part.to.id;
    }).join("+");

    return item;
}