import React from 'react';
import {climateImpactScale, transfersScale} from "../../utils";
import * as utils from "../../utils";
import {Popup} from "semantic-ui-react";
import {NavLink} from "react-router-dom";

const RouteInfo = (props) => {

    let durationIconClassname = props.shortest ? "fas shortest" : "fal";

    return <div className={props.className + " route-row-container" + (props.large ? "" : " small")}
                onMouseEnter={props.onMouseEnter}
                onMouseLeave={props.onMouseLeave}
                onClick={props.onClick}
                style={(props.hidden ? {opacity: 0.6} : {})}>
        <div className="route-info">
            <div className="padding">
                <div className="info">
                    <div className={"price" + (!props.priceIsComplete ? " incomplete" : "")}>
                        <span className={"euro-sign" + (props.lowestPrice ? " cheapest" : "")}>
                          &euro;
                        </span>
                        {
                            props.priceIsAcceptable ?
                                utils.formatPriceAsString(props.price, true)
                                :
                                <i className={"fal fa-question"}/>
                        }
                    </div>
                    {/*<a className="round-button booking">View</a>*/}
                    <i className={durationIconClassname + " fa-clock duration"}/> {utils.formatHoursAsString(props.duration / 60)}
                </div>
                <div className="scores hide-small">
                    <div className="items">
                        <div className="item">
                            <div className="number"
                                 style={{backgroundColor: transfersScale(props.transfersFactor).hex()}}>{props.transfers}</div>
                            <div className="title"><i className="fa fa-random"/></div>
                        </div>
                        <div className="item">
                            <Popup
                                trigger={
                                    <NavLink to="/about#climate-impact" style={{marginTop: 0}}
                                             onClick={(e) => e.stopPropagation()} target="_blank">
                                        <div className="number"
                                             style={{backgroundColor: climateImpactScale(props.co2Factor).hex()}}>
                                            {utils.formatCo2(props.co2)}</div>
                                    </NavLink>}
                                content={<div>
                                    The estimated climate impact of this route equals {utils.formatCo2(props.co2)} kg
                                    CO<sub>2</sub>.<br/>Click to read more.
                                </div>}
                                position="bottom left" basic
                            />
                            <div className="title">kg CO<sub>2</sub></div>
                        </div>
                    </div>
                    <div className="description hide-until-finished">
                        {props.description}
                    </div>
                </div>
            </div>
        </div>
    </div>;
};
export default RouteInfo;