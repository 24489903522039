import React from 'react';
import {Container, Grid, Input, Label} from "semantic-ui-react";
import medium from "../resources/images/medium.png"
import twitter from "../resources/images/twitter.png"
import instagram from "../resources/images/instagram.png"
import facebook from "../resources/images/facebook.png"
import linkedin from "../resources/images/linkedin.png"
import logo from "../resources/images/logo-header.png"
import {Link} from "react-router-dom";
import CookieInfo from "./CookieInfo";

export default () => <div className="footer">
    <div className="line"/>
    <Container>
        <Grid stackable>
            <Grid.Row>
                <Grid.Column width={4}>
                    <img src={logo} className="logo"
                         alt="Green Tickets | Building the European travel planner of the future"/>
                </Grid.Column>
            </Grid.Row>
            <Grid.Row divided centered>
                <Grid.Column width={4}>
                    <strong>Green Tickets</strong><br/>
                    Amsterdam<br/><br/>
                    <a href="mailto:info@greentickets.app">info@greentickets.app</a><br/>
                    <a href="tel:+31633149435">+31 6 3314 9435</a><br/>

                    <br/>
                    <Link to="/info/privacy">Privacy statement</Link><br/>
                    <Link to="/info/conditions">Terms and conditions</Link><br /><br />
                    <CookieInfo />

                </Grid.Column>
                <Grid.Column width={11}>
                    <strong>We are building the European travel planner
                        of the future.</strong>
                    <div>Showing the best door-to-door routes - including plane, train, bus, boat or car. Compare trips
                        by
                        duration, price, comfort, and climate impact! Read the <a
                            href="https://medium.com/green-tickets" target="_blank" rel="noopener noreferrer"
                            title="Read our blog on medium">Green Tickets blog on Medium</a> for more information about
                        our vision and updates on our progress.
                    </div>
                    <div style={{marginTop: "5px"}}>Green Tickets is currently in <Label color='red'
                                                                                         size="mini">BETA</Label> Found
                        an issue, got a
                        question or a suggestion? Don't hesitate to contact us by e-mail or via the Feedback button.
                    </div>
                    <br/>
                    <div className="social">
                        {/*<a href="https://medium.com/greenticketsapp" target="_blank" rel="noopener noreferrer"*/}
                           {/*title="Read our blog on medium">*/}
                            {/*<img src={medium} alt="Read our blog on medium"/>*/}
                        {/*</a>*/}
                        <a href="https://www.facebook.com/greenticketsapp/" target="_blank" rel="noopener noreferrer"
                           title="Follow us on facebook">
                            <img src={facebook} alt="Follow us on facebook"/>
                        </a>
                        <a href="https://twitter.com/greenticketsapp" target="_blank" rel="noopener noreferrer"
                           title="Follow us on twitter">
                            <img src={twitter} alt="Follow us on twitter"/>
                        </a>
                        <a href="https://www.instagram.com/greenticketsapp/" target="_blank" rel="noopener noreferrer"
                           title="Follow us on instagram">
                            <img src={instagram} alt="Follow us on instagram"/>
                        </a>
                        <a href="https://www.linkedin.com/company/greenticketsapp/" target="_blank"
                           rel="noopener noreferrer"
                           title="Follow us on LinkedIn">
                            <img src={linkedin} alt="Follow us on LinkedIn"/>
                        </a>
                    </div>
                    <div className="mailing-subscribe">
                        <form
                            action="https://getgreentickets.us17.list-manage.com/subscribe/post?u=caa6df6f2f574c93cf5b3607b&amp;id=4c3362a8f8"
                            method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form"
                            className="validate" target="_blank" noValidate>
                            <div id="mc_embed_signup_scroll">
                                <label htmlFor="mce-EMAIL">Subscribe to our mailing list:</label><br/><br/>
                                <Input type="email" name="EMAIL" className="email" id="mce-EMAIL"
                                       placeholder="email address" required size="small"/>
                                <div style={{position: "absolute", left: "-5000px"}} aria-hidden="true">
                                    <input type="text" name="b_caa6df6f2f574c93cf5b3607b_4c3362a8f8" tabIndex="-1"
                                           value=""/>
                                </div>
                                {" "}<Input type="submit" value="Subscribe" name="subscribe" id="mc-embedded-subscribe"
                                            size="small"/>
                            </div>
                        </form>
                    </div>
                </Grid.Column>
            </Grid.Row>
        </Grid>
    </Container>
</div>
;