import React, {Component} from 'react';
import * as InteractiveMapActions from "../../actions/InteractiveMapActions";
import {connect} from "react-redux";
import {Button, Container, Grid, Icon, Label, Loader, Tab, Table} from "semantic-ui-react";
import * as formatters from "../../formatters";
import InteractiveMapModal from "../../components/interactive-map/InteractiveMapModal";
import InteractiveMap from "../../homepage/InteractiveMap";
import {Link} from "react-router-dom";
import {ucfirst} from "../../utils";


class RoutePhotos extends Component {
    render() {
        let {photos} = this.props;
        photos = photos.map(photo => {
            return photo.indexOf("http") > -1 ? photo : "https://greentickets.app/external-resources/interactive-map/photos/" + photo
        });

        return photos.map((photo, i) => <InteractiveMapModal urls={photos} i={i} key={photo + i}/>);
    }
}

class RouteInfoPageContent extends Component {

    state = {
        route: {},
    };

    componentDidMount() {
        const {routeId} = this.props;
        if (routeId) {
            this.props.fetchRoute(routeId);
        }
    }

    static priceTable(string) {

        if (!string) return null;
        let lines = string.replace("\r", "").replace("\n", " ").trim().split("€");
        // console.log(lines);

        return <Table size="small" compact>
            <Table.Body>
                {lines.map((lineString, i) => {
                    let line = lineString.trim();
                    if (line === "")return null;
                    let parts = line.split(":");
                    return <Table.Row key={"price-row-" + i}>
                        <Table.Cell style={{width: "60px"}}>
                            <strong>€ {parts[0]}</strong>
                        </Table.Cell>
                        <Table.Cell>
                            {parts[1]}
                        </Table.Cell>
                    </Table.Row>
                })}
            </Table.Body>
        </Table>;

    }

    render() {

        const {fetching} = this.props;
        const {route} = this.props;
        // const {route} = this.props;

        // console.log(JSON.stringify(route));

        let showReturn = true;
        let panes = [];

        let icon, providerLabel, displayUrl = null;

        if (route) {
            if (route.stops && route.stops.length > 0) {
                panes.push({
                    menuItem: {key: 'first', icon: 'arrow right', content: ""},
                    render: () => <table>
                        <tbody>
                        {route.stops.map((stop, i) => <tr key={route.id + "stop" + i}>
                            <td>{stop.time}</td>
                            <td>{ucfirst(stop.place)}</td>
                        </tr>)}
                        </tbody>
                    </table>
                });
            }
            if (route.stopsReturn && route.stopsReturn.length > 0) {
                panes.push({
                    menuItem: {key: 'second', icon: 'arrow left', content: ""},
                    render: () => <table>
                        <tbody>
                        {route.stopsReturn.map((stop, i) => <tr key={route.id + "rstop" + i}>
                            <td>{stop.time}</td>
                            <td>{ucfirst(stop.place)}</td>
                        </tr>)}
                        </tbody>
                    </table>
                });
            }

            icon = {
                "Night": "moon",
                "Scenic": "image outline",
                "High Speed": "lightning",
            }[route.type];

            providerLabel = <Label basic>{route.provider} {route.provider !== route.name ? route.name : ""}</Label>;

            displayUrl = route.url && route.url.replace("http://", "").replace("https://", "").split("/")[0];
        }
        // console.log(route);
        // console.log(this.state.route);

        return (
            fetching || !route ? <Loader active={true}/> :
                <Grid>
                    <Grid.Row>
                        <Grid.Column>
                            <h1>

                                {icon && <Icon name={icon}/>}
                                {route.cars && <Icon name="car"/>}
                                {route.from} &#8596; {route.to}
                            </h1>
                        </Grid.Column>
                    </Grid.Row>
                    {route.photos && route.photos.length > 0 && <Grid.Row>
                        <Grid.Column>
                            <div className="photos-container large">
                                <RoutePhotos photos={route.photos}/>
                            </div>
                        </Grid.Column>
                    </Grid.Row>
                    }
                    {route.longDescription && <Grid.Row>
                        <Grid.Column>
                            <p>
                                {route.longDescription}
                            </p>
                        </Grid.Column>
                    </Grid.Row>}
                    <Grid.Row>
                        <Grid.Column width={4}>
                            {route.duration !== null && !isNaN(route.duration) && <Label basic><Icon
                                name="clock"/>{formatters.minutesToTimeDurationString(showReturn ? route.durationReturn : route.duration)}
                            </Label>}

                            {route.providerUrl ? <a href={route.providerUrl} target="blank"
                                                    rel="noopener noreferrer">{providerLabel}</a> : providerLabel}
                            {route.daily && <Label basic>Daily</Label>}
                            {route.tripsPerDay &&
                            <Label basic>{route.tripsPerDay} times a day</Label>}
                            {route.reservationRequired &&
                            <Label basic> Reservation required {route.reservationCost &&
                            <span>({route.reservationCost})</span>}</Label>}
                        </Grid.Column>
                        <Grid.Column width={12}>
                            {route.priceRange &&
                            <Label basic>{route.priceRange}</Label>}
                            {route.interrail && <Link to="/interrail">
                                <Label>Interrail valid <Icon name="arrow right"/></Label>
                            </Link>}
                            {RouteInfoPageContent.priceTable(route.priceRangeComment)}
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column>
                            {route.timetableUrl &&
                            <Button icon labelPosition="left" as="a" target="blank" rel="noopener noreferrer"
                                    href={route.timetableUrl}>
                                <Icon name="time"/>
                                Timetable
                            </Button>}
                            {route.url &&
                            <Button icon labelPosition="left" as="a" target="blank" rel="noopener noreferrer"
                                    href={route.url}>
                                <Icon name="external"/>
                                {displayUrl}
                            </Button>
                            }
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column width={4}>
                            <Tab menu={{secondary: true, pointing: true}} panes={panes}
                                 onTabChange={(e, data) => this.setState({showReturn: data.activeIndex === 1})}/>
                        </Grid.Column>
                        <Grid.Column width={12}>
                            <InteractiveMap showActiveOnly/>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>

        );
    }
}

export default connect(state => ({
    items: state.interactiveMap.items,
    destinations: state.interactiveMap.destinations,
    routes: state.interactiveMap.routes,
    route: state.interactiveMap.activeRoute,
    fetching: state.interactiveMap.fetchingRoutes,
}), dispatch => ({
    fetchRoute: (routeId) => dispatch(InteractiveMapActions.fetchInteractiveMapRoute(routeId)),
}))(RouteInfoPageContent)