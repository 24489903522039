import {combineReducers} from "redux"
import routes from "./RoutesReducer"
import autocomplete from "./AutocompleteReducer"
import roadmap from './RoadMapReducer'
import interactiveMap from './InteractiveMapReducer'
import searchUpdates from './SearchUpdateReducers'
import travelTips from './TravelTipReducer'
import offers from './OfferReducer'
import admin from './AdminReducer'
import user from './UserReducer'
import blogs from './BlogReducer'
import inspiration from './InspirationReducer'

export default combineReducers({
    routes,
    autocomplete,
    roadmap,
    searchUpdates,
    interactiveMap,
    travelTips,
    offers,
    admin,
    blogs,
    user,
    inspiration
});