// @flow

import React from "react";
import {Button, Form, Message, Modal} from "semantic-ui-react";
import firebase from 'firebase/app';
import 'firebase/firestore';

type Props = {
}
type State = {
    name: string,
    email: string,
    message: string,
    sending: boolean,
    success: boolean,
}

export default class RoadMapForm extends React.Component<Props, State>{

    state = {
        name: "",
        email: "",
        message: "",
        sending: false,
        success: false
    }

    submitIdea(event : Event){
        event.preventDefault();

        const {name, email, message} = this.state;
        if(!name || !email || !message) return;

        this.setState({sending: true});

        firebase.firestore().collection("ideas")
            .add({
                date: new Date(),
                name,
                email,
                message
            })
            .then(ref => this.setState({
                name: "",
                email: "",
                message: "",
                sending: false,
                success: true
            }))
            .then(() => setTimeout(() => this.setState({success: false}), 3000))
    }

    handleChange(event : SyntheticInputEvent<>){
        // if(!(event.target instanceof window.HTMLInputElement)) return;
        this.setState({[event.target.name] : event.target.value});
    }

    render(){
        return (
            <Modal trigger={<Button primary floated='right' size="mini">Submit idea</Button>}>
                <Modal.Header>Submit an idea</Modal.Header>
                <Modal.Content>
                    <p>Thank you for your enthusiasm! Please provide us with some details about your idea and about yourself, so we can contact you if we still have some questions.</p>
                    <Form onSubmit={this.submitIdea.bind(this)} loading={this.state.sending} success={this.state.success}>
                        <Message
                            success
                            header='Form successfully sent'
                            content='Thank you for sharing your idea!'
                        />
                        <Form.Group widths='equal'>
                            <Form.Field required>
                                <label>Name</label>
                                <input placeholder='Your name' value={this.state.name} required name="name" onChange={this.handleChange.bind(this)} />
                            </Form.Field>
                            <Form.Field required>
                                <label>E-mail address</label>
                                <input placeholder='Your e-mail address' value={this.state.email} type='email' required name="email" onChange={this.handleChange.bind(this)} />
                            </Form.Field>
                        </Form.Group>
                        <Form.TextArea required placeholder="Your amazing idea" name="message" value={this.state.message} onChange={this.handleChange.bind(this)} />
                        <Form.Button primary>Submit</Form.Button>
                    </Form>
                </Modal.Content>
            </Modal>
        );
    }
}