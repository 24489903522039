import React, {Component} from 'react';
import {Button, Container, Grid, Sticky} from "semantic-ui-react";
import {connect} from "react-redux";
import * as InspirationActions from "../../actions/InspirationActions";
import * as AutocompleteActions from "../../actions/AutocompleteActions";

class EditStoryStatusBar extends Component {

    render() {

        const {savingStory, saveStory, story} = this.props;

        return <div className="editStoryStatusBar">
            <Container>
                <Grid>
                    <Grid.Row columns={2}>
                        <Grid.Column>
                            Editing story
                        </Grid.Column>
                        <Grid.Column textAlign="right">
                            {story.savedMoment && <span>Last saved on {story.savedMoment.format("HH:mm")}&nbsp;&nbsp;</span>}
                            <Button primary disabled={story.saved} loading={savingStory} onClick={() => saveStory()}>Save</Button>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Container>
        </div>;
    }
}


export default connect((state) => ({

    story: state.inspiration.editingStory || {},
    savingStory: state.inspiration.savingStory,

}), dispatch => ({

    saveStory: () => dispatch(InspirationActions.saveStory()),

}))(EditStoryStatusBar);
