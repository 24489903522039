// @flow

import firebase from 'firebase/app';
import 'firebase/firestore';
import RoadMapItem from "../models/RoadMapItem";
import type {Dispatch} from "../models/Dispatch";

export const fetchRoadmapItems = () => {
    return (dispatch : Dispatch) => {
        dispatch({type: "FETCH_ROADMAP_ITEMS"});


        firebase.firestore().collection("roadmap")
            .orderBy("order")
            .onSnapshot({
                includeMetadataChanges: false
            }, snapshot => {
                let items : Array<RoadMapItem> = [];
                snapshot.forEach(doc => {
                    const docData = doc.data();

                    // window.doc = doc;

                    const item : RoadMapItem = Object.assign(new RoadMapItem(), docData);
                    item.id = doc.id;

                    if(item.isValid()) items.push(item);
                    if(!item.isValid()) console.error("bad roadmap item", item);
                });

                dispatch({type: "FETCHED_ROADMAP_ITEMS", payload: items})
            })
    };
}