import React from "react";
import {
    Card,
    Container,
    Grid,
} from "semantic-ui-react";
import logo from "../resources/images/logo-header.png"
import Roadmap from "../resources/images/Roadmap.png"
import CO2Compensation from "./CO2Compensation";
import InteractiveMap from "./InteractiveMap";
import {Link, NavLink} from "react-router-dom";
import Planner from "./Planner";
import HomePlannerInput from "../components/homePlannerInput"
import {connect} from "react-redux";
import IWantToHelpPage from "../pages/iWantToHelp";
import * as BlogActions from "../actions/BlogActions";
import AdsGridRow from "../components/ads/AdsGridRow";
import coverageMap from "../resources/images/coverageMap.png"

class HomePage extends React.Component {

    state = {
        navigationStep: 0,
    };

    componentDidMount() {
        // this.props.fetchOfferItems();
        // this.props.fetchTravelTipItems();
        this.props.fetchBlogItems();
    }

    static scrollTo(id) {
        document.getElementById(id).scrollIntoView();
    }

    next(step) {
        this.setState({
            navigationStep: step,
        })
    }

    render() {

        const {navigationStep} = this.state;

        return <div>
            <div className="homepage-header">
                <div className="line-background">
                    <div className="line"/>
                    <Container>
                        <Grid>
                            <Grid.Row>
                                <Grid.Column computer={7} tablet={7} mobile={16}>
                                    <div style={{width: '100%'}}>
                                        <img src={logo} style={{width: '100%', maxWidth: '500px'}}
                                             alt="Green Tickets | Building the European travel planner of the future"/>
                                    </div>
                                    <p className="white-text">Traveling is amazing, right? We completely agree! But you
                                        probably know
                                        that traveling can have a large climate impact. With Green Tickets you can
                                        take
                                        responsibility of your travel behaviour and reduce your footprint, while still
                                        enjoying everything that traveling has to offer.</p>

                                </Grid.Column>
                            </Grid.Row>

                            <Grid.Row className="homepage-planner-form-container planner">
                                <Grid.Column fluid>

                                    <div className="homepage-planner-form">
                                        <h1 style={{textAlign: "left"}}>Try our new European route planner:
                                            {/*<Label style={{marginLeft: '20px', float: 'right'}}*/}
                                            {/*size="small"*/}
                                            {/*color="red">BETA</Label>*/}
                                        </h1>

                                        <HomePlannerInput history={this.props.history} onHomepage={true}/>
                                        <br style={{clear: "both"}}/>

                                        <div className="coverage-map">
                                            <img src={coverageMap} title={"Coverage of Green Tickets route planner"}/>
                                            <div className="title">Coverage of Green Tickets route planner</div>
                                        </div>

                                    </div>
                                </Grid.Column>

                            </Grid.Row>

                        </Grid>
                    </Container>
                </div>
            </div>


            <Container>
                <Grid stackable>
                    <Grid.Row>
                        <h1 className="large-main-title"><span>our roadmap</span></h1>
                    </Grid.Row>
                    <Grid.Row centered style={{backgroundColor: "#fff"}}>
                        <p>
                            We're on our way! Do you want to help us get on the right track? Join us on our journey!
                            There are a number of ways:
                        </p>
                    </Grid.Row>
                    <Grid.Row centered style={{backgroundColor: "#fff"}}>
                        <Grid.Column width={12}>
                            <img src={Roadmap}/>
                        </Grid.Column>
                        <Grid.Column width={4}>
                            <p style={{height: '50%',paddingTop: '40px'}}>
                                &#8594; Are you part of, or do you know a company that wants to work on decreasing their carbon footprint? <Link to={"/contact"}>Get in touch</Link>! We would love to help!
                            </p>

                            <p style={{paddingTop: '40px'}}>
                                &#8594; Do you have a green heart and would you like to help us as a volunteer? Get in touch! We would love to <Link to={"/contact"}> hear from you</Link>!<br /><br />
                                &#8594; Try out our travel planner and give us feedback!
                                {/*Keep track of our journey via social media:*/}

                            </p>
                            <br style={{clear: "both"}} />
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Container>

            {/*<Container>*/}
            {/*    <Grid>*/}
            {/*        <Grid.Row className="homepage-header-newsletter" centered>*/}
            {/*            <Grid.Column widescreen={3} mobile={6} textAlign="right">*/}
            {/*                <Button color='facebook' href="https://www.facebook.com/greenticketsapp/" target="_blank"*/}
            {/*                        rel="noopener noreferrer">*/}
            {/*                    <Icon name='facebook'/> Follow us <br/>on Facebook*/}
            {/*                </Button>*/}
            {/*            </Grid.Column>*/}
            {/*            */}
            {/*            <Grid.Column width={10}>*/}
            {/*                <form*/}
            {/*                    action="https://getgreentickets.us17.list-manage.com/subscribe/post?u=caa6df6f2f574c93cf5b3607b&amp;id=4c3362a8f8"*/}
            {/*                    method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form"*/}
            {/*                    className="validate" target="_blank" noValidate>*/}
            {/*                    <div id="mc_embed_signup_scroll">*/}
            {/*                        <label htmlFor="mce-EMAIL">Subscribe to our mailing list<br/>*/}
            {/*                            <small>And follow our journey to more sustainable traveling!</small>*/}
            {/*                        </label>*/}
            {/*                        <Input type="email" name="EMAIL" className="email" id="mce-EMAIL"*/}
            {/*                               placeholder="Email address" required size="small"/>*/}
            {/*                        <div style={{position: "absolute", left: "-5000px"}} aria-hidden="true">*/}
            {/*                            <input type="text" name="b_caa6df6f2f574c93cf5b3607b_4c3362a8f8" tabIndex="-1"*/}
            {/*                                   value=""/>*/}
            {/*                        </div>*/}
            {/*                        {" "}<Input type="submit" value="Subscribe" name="subscribe"*/}
            {/*                                    id="mc-embedded-subscribe"*/}
            {/*                                    size="small"/>*/}
            {/*                    </div>*/}
            {/*                </form>*/}
            {/*            </Grid.Column>*/}
            {/*            */}
            {/*        </Grid.Row>*/}
            {/*    </Grid>*/}
            {/*</Container>*/}

            {/*<div id="rail">*/}

            {/*    <Container>*/}
            {/*        <Grid stackable>*/}
            {/*            <Grid.Row>*/}
            {/*                <h1 className="large-main-title dark"><span>news & blogs</span></h1>*/}
            {/*            </Grid.Row>*/}
            {/*             <Grid.Row centered>*/}
            {/*                {this.props.fetching && <Loader active/>}*/}
            {/*                {this.props.blogs.map((item, i) =>*/}
            {/*                    <Grid.Column width={5} key={"tip" + i}>*/}
            {/*                        {*/}
            {/*                            <BlogItem simple item={item}/>*/}
            {/*                        }*/}
            {/*                    </Grid.Column>*/}
            {/*                )}*/}
            {/*                {!this.props.fetching &&*/}
            {/*                <Grid.Column verticalAlign="middle">*/}
            {/*                    <Button as={Link} to="/blog" circular>More...</Button>*/}
            {/*                </Grid.Column>*/}
            {/*                }*/}

            {/*            </Grid.Row>*/}
            {/*        </Grid>*/}
            {/*    </Container>*/}
            {/*</div>*/}

            <Container>
                <Grid>
                    <Grid.Row>
                        <h1 className="large-main-title"><span>explore Europe</span></h1>
                    </Grid.Row>
                </Grid>
            </Container>
            <InteractiveMap list homepage/>

            <div className="main-background-line-container">
                <div className="main-background-line compensation"/>
            </div>
            <Container>
                <Grid>
                    <Grid.Row>
                        <a id="compensate" name="compensate"> </a>
                        <h1 className="large-main-title"><span>compensate</span></h1>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column>
                            <CO2Compensation/>
                        </Grid.Column>
                    </Grid.Row>
                    {/*<Grid.Row>*/}
                    {/*<h1 className="large-main-title"><span>explore Europe</span></h1>*/}
                    {/*</Grid.Row>*/}
                    {/*<Grid.Row>*/}
                    {/*<Grid.Column>*/}
                    {/*<InteractiveMap legend source/>*/}
                    {/*</Grid.Column>*/}
                    {/*</Grid.Row>*/}
                </Grid>
            </Container>


            <div id="rail">

                <Container>
                    <Grid stackable>
                        <Grid.Row centered>
                            <Grid.Column width={7}>
                                <div className="window-card">
                                    <div className="line"/>
                                    <div className="padding">
                                        <h2>We couldn’t ignore the climate impact of our traveling any longer…</h2>
                                        <p>We believe that we should all acknowledge that the way we travel has direct
                                            climate impact and that we should take responsibility. We don’t have to
                                            travel less, we need to travel differently. We help you take control of the
                                            way you travel: to improve your experience and decrease your impact at the
                                            same time.</p>
                                    </div>
                                </div>
                            </Grid.Column>

                            <Grid.Column width={1}/>

                            <Grid.Column width={7}>
                                <div className="window-card">
                                    <div className="line"/>
                                    <div className="padding">
                                        <h2>Finding the best way to travel, by land, sea or air.</h2>
                                        <p>
                                            Green Tickets makes it as easy as possible for you to find the information
                                            you need, to travel the way you want. We are building a new European travel
                                            planer to compare all modes of travel, and we're gathering information to
                                            help you travel more sustainably.
                                        </p>
                                    </div>
                                </div>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Container>
            </div>

            <div className="main-background-line-container">
                <div className="main-background-line planner"/>
            </div>
            <Container>
                <Grid>
                    <Grid.Row>
                        <a id="planner" name="planner"> </a>
                        <h1 className="large-main-title"><span>route planner</span></h1>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column>
                            <Planner history={this.props.history} onHomepage={true}/>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
                <Grid stackable>

                    <Grid.Row centered className="navigation-items-container">

                        <Grid.Column width={5}>
                            <Link to="/map">
                                <div className="item">
                                    <div className="image">
                                        <div className="img two"/>
                                    </div>
                                    <Card.Content>
                                        <h2>Explore Europe</h2>
                                        <p>
                                            From scenic routes to high speed lines and night trains to ferries.
                                        </p>
                                    </Card.Content>
                                </div>
                            </Link>
                        </Grid.Column>
                        <Grid.Column width={5}>
                            <Link to="/climate-impact">
                                <div className="item">
                                    <div className="image">
                                        <div className="img three"/>
                                    </div>
                                    <Card.Content>
                                        <h2>Climate impact & compensation</h2>
                                        <p>
                                            Calculate and compensate your climate impact.
                                        </p>
                                    </Card.Content>
                                </div>
                            </Link>
                        </Grid.Column>
                        <Grid.Column width={5}>
                            <Link to="/about">
                                <div className="item">
                                    <div className="image">
                                        <div className="img four"/>
                                    </div>
                                    <Card.Content>
                                        <h2>About Green Tickets</h2>
                                        <p>
                                            Read our story on why we want to change the way we travel.
                                        </p>
                                    </Card.Content>
                                </div>
                            </Link>
                        </Grid.Column>

                    </Grid.Row>

                    {/*<Grid.Row className="main-screenshots">*/}

                    {/*<Grid.Column width={10}>*/}

                    {/*<img src={screenshot1}*/}
                    {/*alt="Visualization of travel options with Green Tickets make it easy to find the best option."/>*/}
                    {/*</Grid.Column>*/}
                    {/*<Grid.Column width={6}>*/}
                    {/*<img src={screenshot2}*/}
                    {/*alt="Route details, stops and ticket prices are shown in a clear overview on Green Tickets."/><br/><br/>*/}
                    {/*<em style={{color: "#555"}}>Finding the best way to get to your destination has never been*/}
                    {/*this easy!</em>*/}
                    {/*</Grid.Column>*/}

                    {/*</Grid.Row>*/}


                    <Grid.Row style={{marginBottom: "30px"}}>
                        <h1 className="large-main-title"><span>join our mission</span></h1>
                    </Grid.Row>

                </Grid>

                <IWantToHelpPage/>
            </Container>

            <Grid stackable style={{marginTop: '100px'}}>

                <AdsGridRow/>

                {/*<Grid>*/}
                <Grid.Row>
                    <div style={{height: "100px"}}/>
                </Grid.Row>


                {/*<Divider/>*/}

                {/*<Grid.Row>*/}
                {/*<Grid.Column width={3} textAlign="center">*/}
                {/*<br/>*/}
                {/*<i className="fal fa-tasks" style={{fontSize: "40pt", color: "#5cd792"}}/>*/}
                {/*</Grid.Column>*/}
                {/*<Grid.Column textAlign="left" width={12}>*/}
                {/*<Header as='h2'>*/}
                {/*Using Green Tickets*/}
                {/*</Header>*/}
                {/*<p>*/}
                {/*Currently, Green Tickets is still under development. This means that not all European*/}
                {/*countries are included yet, and some issues could still exist when using our planner.*/}
                {/*Furthermore, planning a return trip is not yet possible.*/}
                {/*</p>*/}
                {/*<p>*/}
                {/*<NavLink to="/roadmap">See what we're working on in our roadmap »</NavLink>*/}
                {/*</p>*/}
                {/*</Grid.Column>*/}
                {/*</Grid.Row>*/}

            </Grid>
            {/*</Container>*/}
        </div>
    }
}

export default connect(state => ({
    // offersFetching: state.offers.fetching,
    // tips: state.travelTips.items.filter(tip => tip.homepage).slice(0, 3),
    // offers: state.offers.items,
    fetching: state.blogs.fetching,
    blogs: state.blogs.items,
}), dispatch => ({
    // fetchOfferItems: () => dispatch(fetchOfferItems(1))
    // fetchTravelTipItems: () => dispatch(TravelTipActions.fetchTravelTipItems()),
    fetchBlogItems: () => dispatch(BlogActions.fetchBlogItems(3)),
}))(HomePage)