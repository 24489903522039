// @flow

import React from "react";
import {connect} from "react-redux";
import * as RoadmapActions from '../actions/RoadmapActions'
import RoadMapItem from "../models/RoadMapItem";
import RoadMapItemComponent from "./RoadMapItemComponent";
import {Button, Grid, Header} from "semantic-ui-react";
import RoadMapForm from "./RoadMapForm";

type Props = {
    fetchRoadmapItems: () => void,
    items: Array<RoadMapItem>,
    fetching: boolean,

}
type State = {
    showAllProgress: boolean,
    showAllSoon: boolean,
    showAllIdea: boolean,
}

class RoadMap extends React.Component<Props, State> {

    state = {
        showAllProgress: false,
        showAllSoon: false,
        showAllIdea: false,
    };

    componentDidMount() {
        this.props.fetchRoadmapItems();
    }

    render() {

        const {items, fetching} = this.props;
        const {showAllProgress, showAllSoon, showAllIdea} = this.state;

        const progressItems = items.filter(item => item.category === 1)
            .map((item, index) => <RoadMapItemComponent item={item} visible={index < 3 || showAllProgress}
                                                        key={item.id}/>);

        const soonItems = items.filter(item => item.category === 2)
            .map((item, index) => <RoadMapItemComponent item={item} visible={index < 3 || showAllSoon} key={item.id}/>);

        const ideaItems = items.filter(item => item.category === 3)
            .map((item, index) => <RoadMapItemComponent item={item} visible={index < 3 || showAllIdea} key={item.id}/>);

        return <Grid stackable className="roadmap gt-card">
            <Grid.Row centered>
                <Grid.Column width={12}>
                    <p>This is the roadmap of the development of Green Tickets. The idea of presenting our roadmap is that you
                        can provide us with feedback: which options would you like to see first? Do you have comments
                        about what we are working on right now? Or on what we will work on soon? Or do you have
                        completely new ideas that we haven't thought of before? We'd really like to hear this from you!
                        You can submit your idea using the button below or show your support for an idea by liking
                        it.</p>
                </Grid.Column>
            </Grid.Row>
            <Grid.Row centered divided>
                <Grid.Column width={5}>
                    <Header as='h2'>In progress</Header>
                    <p className="description">We're working hard on the stuff below. Like to show your support!</p>
                </Grid.Column>
                <Grid.Column width={5}>
                    <Header as='h2'>Coming up</Header>
                    <p className="description">These are the things we have in mind to tackle next, which ones would you
                        like to see?</p>
                </Grid.Column>
                <Grid.Column width={5}>
                    <Header as='h2'>
                        Ideas
                        <RoadMapForm/>
                    </Header>
                    <p className="description">This is our wishlist of some ideas we're still thinking about. Do you
                        have an idea? Let us know!</p>
                </Grid.Column>
            </Grid.Row>
            {fetching ?
                <Grid.Row centered>
                    <i className="spinner fas fa-circle-notch fa-spin"/>
                </Grid.Row>
                :
                <Grid.Row centered>
                    <Grid.Column width={5}>
                        {progressItems}
                        {progressItems.length > 3 && !showAllProgress && (
                            <Button fluid onClick={() => this.setState({showAllProgress: true})}>show all</Button>
                        )}
                    </Grid.Column>
                    <Grid.Column width={5}>
                        {soonItems}
                        {soonItems.length > 3 && !showAllSoon && (
                            <Button fluid onClick={() => this.setState({showAllSoon: true})}>show all</Button>
                        )}
                    </Grid.Column>
                    <Grid.Column width={5}>
                        {ideaItems}
                        {ideaItems.length > 3 && !showAllIdea && (
                            <Button fluid onClick={() => this.setState({showAllIdea: true})}>show all</Button>
                        )}
                    </Grid.Column>
                </Grid.Row>
            }

        </Grid>
    }
}

export default connect(state => ({
    fetching: state.roadmap.fetching,
    items: state.roadmap.items,
}), dispatch => ({
    fetchRoadmapItems: () => dispatch(RoadmapActions.fetchRoadmapItems())
}))(RoadMap)