import React, {Component} from 'react';
import Autocomplete from 'react-autocomplete';
import poweredByGoogle from '../resources/images/powered_by_google_on_white.png';
import {Icon} from "semantic-ui-react";

export default class AutocompletePlaceInput extends Component {

    render() {
        this.menuStyle = {
            borderRadius: '3px',
            boxShadow: '0 2px 12px rgba(0, 0, 0, 0.1)',
            background: 'rgba(255, 255, 255, 0.9)',
            padding: '2px 0',
            fontSize: '90%',
            position: 'fixed',
            overflow: 'auto',
            maxHeight: '50%', // TODO: don't cheat, let it flow to the bottom
        };
        return (
            <Autocomplete
                {...this.props}
                ref={this.props.inputRef}
                getItemValue={(item) => item.place_id}
                renderItem={(item, isHighlighted) =>
                    <div className={"item" + (isHighlighted ? ' highlighted' : '')}
                         key={item.place_id}>
                        {item.historical ?
                            <div className="padding">
                                {item.place_id === "GPS" && <Icon name="point" />}
                                {item.name}
                            </div>
                            :
                            <div className="padding">
                                <span>{item.structured_formatting.main_text}</span>{item.structured_formatting.secondary_text ? (", " + item.structured_formatting.secondary_text) : ""}
                            </div>
                        }
                    </div>
                }
                renderMenu={this.props.items.length === 0 ? () =>
                        <div/>
                    :
                    (items, value, style) => <div className="menu" style={{...style, ...this.menuStyle}}>
                        {items}
                        {!this.props.hideGoogle && (<div className="powered-by"><img src={poweredByGoogle} alt={"Powered by Google"}/></div>)}
                    </div>}
            />
        );
    }
}
