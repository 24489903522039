import update from 'immutability-helper';

const initialState = {
    items: [],
    fetched: false,
    fetching: false,
};

export default (state = initialState, action)  => {

    switch (action.type){
        case "FETCH_OFFER_ITEMS":
            return update(state, {
                fetched: {$set: false},
                fetching: {$set: true}
            });
        case "FETCHED_OFFER_ITEMS":
            return update(state, {
                fetched: {$set: true},
                fetching: {$set: false},
                items: {$set: action.payload},
            });
        default:
            return state;
    }
}