import firebase from 'firebase/app';
import 'firebase/auth';
import * as InspirationActions from "./InspirationActions";

export const signIn = () => {
    return {type: "SIGN_IN"};
};
export const editProfile = () => {
    return {type: "EDIT_PROFILE"};
};
export const clearError = () => {
    return {type: "CLEAR_SIGN_IN_ERROR"};
};
export const closeEditProfileModal = () => {
    return {type: "CLOSE_EDIT_PROFILE_MODAL"};
};
export const closeSignInModal = () => {
    return {type: "CLOSE_SIGN_IN_MODAL"};
};

export const doSignOut = () => {
    return (dispatch) => {
        dispatch({type: "SIGNING_IN"});

        firebase.auth().signOut().then(function() {
            dispatch({type: "SIGNED_OUT"});
        }).catch(function(error) {
            console.log(error);
        });
    };
};

export const doSignIn = (email, password) => {
    return (dispatch) => {
        dispatch({type: "SIGNING_IN"});

        firebase.auth().signInWithEmailAndPassword(email, password).then(result => {
            dispatch({type: "SIGN_IN_SUCCESS"});
        }).catch(function (error) {
            // Handle Errors here.
            dispatch({type: "SIGN_IN_ERROR", payload: error.message});
        });

    };
};

export const doSignUp = (email, password, password2, displayName) => {
    return (dispatch) => {
        dispatch({type: "SIGNING_IN"});

        if(password !== password2) {
            return dispatch({type: "SIGN_IN_ERROR", payload: "Enter the same password twice."});
        }

        firebase.auth().createUserWithEmailAndPassword(email, password).then(result => {

            dispatch({type: "SIGN_IN_SUCCESS"});
            dispatch({type: "FETCHING_USER_INFO", payload: result.user.uid});

            let userInfo = {
                displayName,
            };

            // Fetch info
            firebase.firestore().collection("users/").doc(result.user.uid).set(userInfo).then(doc => {
                dispatch({type: "FETCHED_USER_INFO", payload: userInfo});
            }).catch((e) => {
                console.warn("Error saving User Info:", e);
                dispatch({type: "FETCHED_USER_INFO", payload: null});
            })

        }).catch(function (error) {
            // Handle Errors here.
            console.log(error, error.code);
            dispatch({type: "SIGN_IN_ERROR", payload: error.message});
        });

    };
};

export const updateProfile = userInfo => {
    return (dispatch, getState) => {
        const state = getState();

        dispatch({type: "UPDATE_PROFILE"});
        if (userInfo.image === undefined)userInfo.image = null;

        firebase.firestore().collection("users/").doc(state.user.user.uid).update(userInfo).then(doc => {
            dispatch({type: "SAVED_USER_INFO", payload: userInfo});
        }).catch((e) => {
            if(e.code === "not-found") {
                firebase.firestore().collection("users/").doc(state.user.user.uid).set(userInfo).then(doc => {
                    dispatch({type: "SAVED_USER_INFO", payload: userInfo});
                }).catch((e) => {
                    console.warn("Error saving NEW User Info:", e);
                    dispatch({type: "FETCHED_USER_INFO", payload: null});
                })
            } else {
                console.warn("Error saving User Info:", e);
                dispatch({type: "FETCHED_USER_INFO", payload: null});
            }
        });
    }
};

export const doSignInFB = () => {
    return (dispatch) => {
        dispatch({type: "SIGNING_IN"});

        const provider = new firebase.auth.FacebookAuthProvider();
        firebase.auth().signInWithPopup(provider).then(function(result) {
            // This gives you a Facebook Access Token. You can use it to access the Facebook API.
            // console.log(result, result.additionalUserInfo);

            let profile = result.additionalUserInfo.profile;

            // Fetch info
            firebase.firestore().collection("users/").doc(result.user.uid).get().then(doc => {
                if (doc) {
                    let data = doc.data();
                    let updates = {};
                    if(!data.displayName) {
                        updates["displayName"] = profile.name;
                    }
                    if(!data.image) {
                        updates["image"] = {
                            url: "http://graph.facebook.com/" + profile.id + "/picture?type=large",
                        }
                    }
                    if (Object.values(updates).length > 0){
                        firebase.firestore().collection("users/").doc(result.user.uid).set(updates).then(doc => {
                            dispatch({type: "FETCHED_USER_INFO", payload: {...data, ...updates}});
                            dispatch(InspirationActions.fetchSavedStories(null, result.user.uid));
                        })
                    } else {
                        dispatch({type: "FETCHED_USER_INFO", payload: doc.data()});
                        dispatch(InspirationActions.fetchSavedStories(null, result.user.uid));
                    }
                } else throw "Couldn't fetch user info";
            }).catch((e) => {
                console.warn(e);
                dispatch({type: "FETCHED_USER_INFO", payload: null});
            });
        }).catch(function(error) {
            // Handle Errors here.
            console.log(error);
        });

    };
};


export function verifyAuth() {
    return function (dispatch, getState) {
        firebase.auth().onAuthStateChanged(user => {
            if (user) {
                dispatch({type: "SIGNED_IN", payload: user});

                const state = getState();
                if(user.uid && !state.user.fetchingUserInfo) {
                    dispatch({type: "FETCHING_USER_INFO", payload: user.uid});

                    // Fetch info
                    firebase.firestore().collection("users/").doc(user.uid).get().then(doc => {
                        if (doc) {
                            dispatch({type: "FETCHED_USER_INFO", payload: doc.data()});
                            dispatch(InspirationActions.fetchSavedStories(null, user.uid));
                        } else throw "Couldn't fetch user info";
                    }).catch((e) => {
                        console.warn(e);
                        dispatch({type: "FETCHED_USER_INFO", payload: null});
                    })
                }

            } else {
                dispatch({type: "SIGNED_OUT"});
            }
        });
    }
}

export function saveRoute(routeData) {
    return function (dispatch, getState) {
        dispatch({type: "SAVE_ROUTE"});

        const state = getState();
        // console.log(state.user, state.user.user.uid, routeData);

        firebase.firestore().collection("users/").doc(state.user.user.uid).collection("routes").add(routeData).then(() => {
            dispatch({type: "SAVED_ROUTE", payload: routeData.routeId});
        }).catch(error => {
            console.error(error);
        })

    }
}

export function fetchSavedRoutes() {
    return (dispatch, getState) => {

        const state = getState();
        if(!state.user.user || !state.user.user.uid)return;
        if(state.user.fetchedSavedRoutes)return;

        dispatch({type: "FETCH_SAVED_ROUTES"});

        let ref = firebase.firestore().collection("users").doc(state.user.user.uid).collection("routes");
        ref .orderBy("createdAt", "desc")
            .onSnapshot({
                includeMetadataChanges: false
            }, snapshot => {
                let items = [];
                snapshot.forEach(doc => {
                    const item = doc.data();
                    item.id = doc.id;
                    items.push(item);
                });

                dispatch({type: "FETCHED_SAVED_ROUTES", payload: items})
            })
    };
}

export function fetchSavedMaps() {
    return (dispatch, getState) => {

        const state = getState();
        if(!state.user.user || !state.user.user.uid)return;
        if(state.user.fetchedSavedMaps)return;

        dispatch({type: "FETCH_SAVED_MAPS"});

        let ref = firebase.firestore().collection("users").doc(state.user.user.uid).collection("maps");
        ref .orderBy("createdAt", "desc")
            .onSnapshot({
                includeMetadataChanges: false
            }, snapshot => {
                let items = [];
                snapshot.forEach(doc => {
                    const item = doc.data();
                    item.id = doc.id;
                    items.push(item);
                });

                dispatch({type: "FETCHED_SAVED_MAPS", payload: items})
            })
    };
}