import update from 'immutability-helper';

const initialState = {
    loading: false,
    showSignInModal: false,
    showEditProfileModal: false,
    error: null,
    user: null,
    authenticated: false,

    fetchingUserInfo: false,
    userInfo: {},

    savingRoute: false,
    savedRoute: false,

    fetchingSavedRoutes: false,
    fetchedSavedRoutes: false,
    savedRoutes: [],

    fetchingSavedMaps: false,
    fetchedSavedMaps: false,
    savedMaps: [],
};

export default (state = initialState, action)  => {

    switch (action.type){
        case "SIGN_IN":
            return update(state, {
                showSignInModal: {$set: true}
            });
        case "EDIT_PROFILE":
            return update(state, {
                showEditProfileModal: {$set: true}
            });
        case "CLEAR_SIGN_IN_ERROR":
            return update(state, {
                error: {$set: null},
            });
        case "SIGNED_IN":
            return update(state, {
                authenticated: {$set: true},
                loading: {$set: false},
                error: {$set: null},
                user: {$set: action.payload},
                showSignInModal: {$set: false},
            });
        case "SIGNED_OUT":
            return update(state, {
                authenticated: {$set: false},
                fetchedSavedRoutes: {$set: false},
                user: {$set: null},
                userInfo: {$set: {}},
                loading: {$set: false},
            });
        case "CLOSE_SIGN_IN_MODAL":
            return update(state, {
                showSignInModal: {$set: false}
            });
        case "CLOSE_EDIT_PROFILE_MODAL":
            return update(state, {
                showEditProfileModal: {$set: false}
            });
        case "SIGNING_IN":
            return update(state, {
                error: {$set: null},
                loading: {$set: true}
            });
        case "UPDATE_PROFILE":
            return update(state, {
                loading: {$set: true}
            });
        case "SIGN_IN_SUCCESS":
            return update(state, {
                showSignInModal: {$set: false},
                loading: {$set: false},
            });
        case "SIGN_IN_ERROR":
            console.warn("SIGN_IN_ERROR: ", action);
            return update(state, {
                error: {$set: action.payload},
                loading: {$set: false},
            });

        case "FETCHING_USER_INFO":
            return update(state, {
                fetchingUserInfo: {$set: true},
            });
        case "FETCHED_USER_INFO":
            return update(state, {
                fetchingUserInfo: {$set: false},
                userInfo: {$set: action.payload},
            });
        case "SAVED_USER_INFO":
            return update(state, {
                fetchingUserInfo: {$set: false},
                loading: {$set: false},
                showEditProfileModal: {$set: false},
                userInfo: {$set: action.payload},
            });

        case "SAVE_ROUTE":
            return update(state, {
                savingRoute: {$set: false},
                savedRoute: {$set: true},
            });
        case "SAVED_ROUTE":
            return update(state, {
                savingRoute: {$set: false},
                savedRoute: {$set: action.payload},
            });

        case "FETCH_SAVED_ROUTES":
            return update(state, {
                fetchingSavedRoutes: {$set: true},
            });
        case "FETCHED_SAVED_ROUTES":
            return update(state, {
                fetchingSavedRoutes: {$set: false},
                fetchedSavedRoutes: {$set: true},
                savedRoutes: {$set: action.payload},
            });

        case "FETCH_SAVED_MAPS":
            return update(state, {
                fetchingSavedMaps: {$set: true},
            });
        case "FETCHED_SAVED_MAPS":
            return update(state, {
                fetchingSavedMaps: {$set: false},
                fetchedSavedMaps: {$set: true},
                savedMaps: {$set: action.payload},
            });
        default:
            return state;
    }
}