import React from 'react';
import * as formatters from "../../formatters";

const RoutePartInfo = (props) => {
    return (
        <div className="route-part-info">
            <h4>
                {(props.carrier && props.carrier.name !== "UNKNOWN") ? (
                    props.carrier.name
                ) : (
                    props.mode.toLowerCase()
                )}
                {" "}
                {props.longName} {props.headsign && "towards"} {props.headsign}
                {" "}({formatters.minutesToTimeDurationString(props.duration/60)})
            </h4>
            {(!props.notBoundToTime && (props.mode === "DRIVING" || props.mode === "WAITING")) ? (
                <div>
                    From {props.departureDate.format("H:mm")} to {props.arrivalDate.format("H:mm")}
                </div>
            ) : (
                <table>
                    <tbody>
                    <tr>
                        <th>{!props.notBoundToTime && props.departureDate.format("H:mm")}</th>
                        <td>{props.from && props.from.name}</td>
                    </tr>
                    <tr>
                        <th>{!props.notBoundToTime &&props.arrivalDate.format("H:mm")}</th>
                        <td>{props.to && props.to.name}</td>
                    </tr>
                    </tbody>
                </table>
            )}
            {(props.mode === "FLYING" && props.agencies && props.agencies.length > 0 && (
                <a href={props.agencies[0].url}><img src={props.agencies[0].raw.ImageUrl}
                                                     alt={props.agencies[0].name}/> Book</a>
            ))}
        </div>
    )
};

export default RoutePartInfo;