import React, {Component} from 'react';
import {Modal} from "semantic-ui-react";

export default class InteractiveMapModal extends Component {

    render() {

        const {urls, i} = this.props;

        return <Modal closeIcon basic style={{textAlign: "center"}}
               trigger={<img src={urls[i]} />}
               content={<InteractiveMapModalContent urls={urls} i={i} />}
        />;
    }
}

class InteractiveMapModalContent extends Component {

    state = {
        j: null,
    };

    constructor(props){
        super(props);
        this.keyPressed = this.keyPressed.bind(this);
    }
    keyPressed(event){
        if(event.keyCode === 39) {
            this.next();
        } else if (event.keyCode === 37) {
            this.prev();
        }
    }
    componentDidMount(){
        document.addEventListener("keydown", this.keyPressed, false);
    }
    componentWillUnmount(){
        document.removeEventListener("keydown", this.keyPressed, false);
    }

    next() {
        let curr = this.state.j;
        if (curr === null) curr = this.props.i;
        let next = curr + 1;
        if (next > this.props.urls.length-1)next = 0;
        this.setState({j: next})
    }
    prev() {
        let curr = this.state.j;
        if (curr === null) curr = this.props.i;
        let next = curr - 1;
        if (next < 0)next = this.props.urls.length-1;
        this.setState({j: next})
    }

    render() {

        let {urls, i} = this.props;
        const {j} = this.state;
        if (j !== null) i = j;

        return <img src={urls[i]} style={{margin: '0 auto'}} onClick={(e) => this.next()} onKeyPress={(event) => this.keyPressed(event)} />;

    }
}