import React, {Component} from 'react';
import {Button, Card, Container, Grid, Icon} from "semantic-ui-react";
import ContactForm from "../components/ContactForm";
import logo from "../resources/images/logo-header.png"
import AdsGridRow from "../components/ads/AdsGridRow";
import {NavLink} from "react-router-dom";

export default class ContactPage extends Component {

    render() {
        return <div className="page-container">

            <div className="contact-header">
                <div className="line-background">
                    <div className="line"/>
                    <Container>
                        <Grid>
                            <Grid.Row>
                                <Grid.Column width={7}>
                                    <img src={logo}
                                         alt="Green Tickets | Building the European travel planner of the future"/>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Container>
                </div>
            </div>

            <Container>

                <Grid>
                    <Grid.Row>
                        <a id="planner" name="planner"> </a>
                        <h1 className="large-main-title"><span>contact us</span></h1>
                    </Grid.Row>
                </Grid>

                <Card fluid>
                    <Card.Content>
                        <Grid stackable>
                            <Grid.Row divided centered>
                                <Grid.Column>
                                    <p>
                                        We would love to hear from you! Whether you have a question, an idea or a
                                        suggestion, don't hesitate to let us know.
                                    </p>
                                    <Icon disabled name='mail'/> <a
                                    href="mailto:info@greentickets.app">info@greentickets.app</a><br/>
                                    <Icon disabled name='phone'/> <a href="tel:+31633149435">+31 6 3314 9435</a><br/>

                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row divided centered>
                                <Grid.Column width={6}>


                                    <Button color='facebook' href="https://www.facebook.com/greenticketsapp/"
                                            target="_blank"
                                            rel="noopener noreferrer">
                                        <Icon name='facebook'/> Follow us on Facebook
                                    </Button><br/><br/>
                                    <Button as="a" href="https://www.facebook.com/groups/easygreentravels"
                                            target="_blank" rel="noopener noreferrer" basic color="blue">
                                        <Icon name='facebook'/> Join our Facebook
                                        group</Button><br/>


                                    <h3>Green Tickets</h3>
                                    <strong>Amsterdam</strong><br/>
                                    <br /><br />

                                    <h3>Support Us</h3>
                                    <p>
                                        We need your help! Let's travel to a sustainable future together!
                                    </p>
                                    <Button as={NavLink} to="/climate-impact/compensation/donation" color="green">Donate</Button>

                                </Grid.Column>
                                <Grid.Column width={10}>
                                    <h3>Contact form</h3>
                                    <ContactForm/>
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row divided centered>
                                <Grid.Column>
                                    <h3>Green Tickets</h3>
                                    Green Tickets is a registered tradename of <a href="http://www.webzites.nl"
                                                                                  target="_blank"
                                                                                  rel="noopener noreferrer">Webzites.nl</a>,
                                    registered in the Business Register of the Chamber of Commerce in the Netherlands
                                    under number 72725036.
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Card.Content>
                </Card>

            </Container>

            <Grid stackable style={{marginTop: '100px'}}>

                <AdsGridRow/>

                <Grid.Row>
                    <div style={{height: "100px"}}/>
                </Grid.Row>
            </Grid>

        </div>;
    }
}