import React, {Component} from 'react';
import {injectStripe} from "react-stripe-elements";
import {Link, Redirect} from 'react-router-dom';
import firebase from 'firebase/app';
import 'firebase/firestore';
import {Button, Card, Form, Grid, Icon, Input, Message, Popup} from "semantic-ui-react";

const priceFactor = 20/1000;

class CheckoutForm extends Component {

    constructor(props) {
        super(props);

        this.state = {
            redirect: null,
            co2: props.co2 || 0,
            co2price: (props.co2 || 0) * priceFactor,
            donation: props.donation || 0,
            amount: 0,
            vat: 0,
            name: "",
            email: "",
            loading: false,
            error: false,
        };

        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount() {
        this.recalculate(this.state);
    }

    handleChange(event) {
        let key = event.target.name;
        let value = event.target.value;
        if (key === "co2") {
            value = parseInt(value);
            if(isNaN(value))value = 0;
        } else if(key === "donation") {
            value = value.replace(",", ".");
        }

        this.setState({[key]: value});
        this.setState({error: false});

        if (key === "co2" || key === "donation") {
            let info = this.state;
            info[key] = value;
            this.recalculate(info);
        }

    }

    recalculate(info) {
        let co2price = parseFloat(info.co2 || 0) * priceFactor;
        if (isNaN(co2price))co2price = 0;
        let amount = co2price + parseFloat(info.donation || 0);
        if (isNaN(amount))amount = 0;
        let vat = (co2price / 1.21 * 0.21);
        this.setState({co2price, amount, vat})
    }

    handleSubmit(ev) {
        // We don't want to let default form submission happen here, which would refresh the page.
        ev.preventDefault();

        if(!this.state.name || !this.state.email){
            this.setState({error: true});
            return;
        }

        this.setState({loading: true});
        let _this = this;

        let descriptions = [];
        let longDescriptions = [];
        if (this.state.co2 && this.state.co2 > 0){
            descriptions.push(this.state.co2 + "kg CO2");
            longDescriptions.push(this.state.co2 + "kg CO2 at €" + (parseFloat(this.state.co2price).toFixed(2)) + " including 21% VAT (€" + parseFloat(this.state.vat).toFixed(2) + ")");
        }
        if (this.state.donation && this.state.donation > 0){
            descriptions.push("E" + parseFloat(this.state.donation).toFixed(2) + "");
            longDescriptions.push("donation of €" + parseFloat(this.state.donation).toFixed(2) + "");
        }
        let description = descriptions.join(" and ");
        let longDescription = longDescriptions.join(" plus a ");

        let info = {
            amount: Math.round(parseFloat(this.state.amount) * 100),
            vat: parseFloat(this.state.vat).toFixed(2),
            name: this.state.name,
            email: this.state.email,
            donation: parseFloat(this.state.donation).toFixed(2),
            co2: parseFloat(this.state.co2).toFixed(2),
            co2price: parseFloat(this.state.co2price).toFixed(2),
            createdAt: Date(),
            description, //'Green Tickets Carbon Offset'
            longDescription,
        };

        let checkoutId = "";

        firebase.firestore().collection("checkouts").add(info).then((ref) => {
            checkoutId = ref.id;

            return ref.collection("payment").doc("status").set({completed: false});

        }).then(() => {

            this.props.stripe.createSource({
                type: 'ideal',
                amount: info.amount,
                currency: 'eur',
                owner: {
                    name: info.name,
                    email: info.email,
                },
                metadata: {
                    id: checkoutId,
                    description: longDescription,
                },
                redirect: {
                    return_url: 'https://greentickets.app/checkout/result/' + checkoutId + "/",
                },
                statement_descriptor: info.description,
            }).then(function (result) {
                // handle result.error or result.source
                console.log(result);

                if (result.source) {
                    let redirect = result.source.redirect.url;
                    // console.log(redirect);
                    window.location.href = redirect;
                } else {
                    //Error!

                }

            });

        }).catch(error => {
            console.error(error);
        });

    }

    render() {

        let {redirect, co2, donation, amount, name, email, loading, co2price, vat, error} = this.state;

        return <Card className="checkout-form" fluid style={{marginTop: '50px'}}>
            <Card.Header className="checkout-title">
                <h2>Compensate your CO<sub>2</sub> and help us build Green Tickets!</h2>
            </Card.Header>
            <Form onSubmit={this.handleSubmit.bind(this)} loading={loading}>
                <Card.Content className="checkout-content">
                    <Grid padded>
                        <Grid.Row>
                            <Grid.Column>
                            <p>
                                Thank you for choosing to compensate through Green Tickets! We only need a few more details to process your payment.
                            </p>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column width={10}>

                                <Form.Field required>
                                    <label>Name</label>
                                    <Input type="text" name="name" placeholder="Full name" value={name}
                                           onChange={this.handleChange}/>
                                </Form.Field>
                                <Form.Field required>
                                    <label>E-mail</label>
                                    <Input type="email" name="email" placeholder="E-mail address" value={email}
                                           onChange={this.handleChange}/>
                                </Form.Field>
                                {error && <Message negative>
                                    <Message.Header>Please enter all fields.</Message.Header>
                                    We need your name and e-mail address to process your payment.
                                </Message>}
                            </Grid.Column>
                            <Grid.Column width={6}>

                                <Form.Field>
                                    <label>CO<sub>2</sub> compensation</label>
                                        <Input className="small" type="text" name="co2" value={co2}
                                               onChange={this.handleChange}
                                               label={{basic: true, content: 'kg'}} labelPosition='right'/><br /><br />
                                    <span className="amount">€ {co2price.toFixed(2)}</span>
                                </Form.Field>

                                <Form.Field>
                                    <label>
                                        Green Tickets donation:

                                        <div style={{float: "right"}}>
                                        <Popup
                                            trigger={<Icon name="info" circular inverted size="tiny" color="grey" />}
                                            content={"Do you want to help realize Green Tickets even more? We can use all the support we can get to offer you the easiest way to travel through Europe. You can specify an additional amount that you would like to donate in the field below. Completely optional of course!"}
                                            position="left center"
                                            size="mini"/>
                                        </div>
                                    </label>
                                    <Input className="small" type="text" name="donation" value={donation}
                                           onChange={this.handleChange}
                                           label={{basic: true, content: '€'}} labelPosition='left'/><br/>
                                </Form.Field>

                                <hr/>

                                <Form.Field className="vat">
                                    <label>Dutch VAT (21% BTW)</label>
                                    <span className="amount">€ {vat.toFixed(2)}</span>
                                </Form.Field>

                                <Form.Field>
                                    <label>Total amount (incl. VAT)</label>
                                    <span className="amount total">€ {amount.toFixed(2)}</span>
                                </Form.Field>

                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Card.Content>

                <Card.Content extra className="padding">
                    <Link to="/info/faq" target="_blank">FAQ</Link>{" | "}
                    <Link to="/info/conditions" target="_blank">Terms and conditions</Link>{" | "}
                    <Link to="/info/privacy" target="_blank">Privacy Statement</Link>

                    <Input className="submit" type="submit" value="Checkout" style={{float: "right"}}/><br /><br />
                </Card.Content>
            </Form>
            {this.state.redirect && <Redirect to={redirect}/>}
        </Card>
            ;
    }

}

export default injectStripe(CheckoutForm);