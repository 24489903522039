import update from 'immutability-helper';

const initialState = {
    items: [],
    tags: [],
    fetched: false,
    fetching: false,
    saving: false,
    saved: false,
};

Array.prototype.unique = function() {
    return this.filter(function (value, index, self) {
        return self.indexOf(value) === index;
    });
};

export default (state = initialState, action)  => {

    switch (action.type){
        case "FETCH_TRAVEL_TIP_ITEMS":
            return update(state, {
                fetched: {$set: false},
                fetching: {$set: true}
            });
        case "SAVE_TRAVEL_TIP":
            return update(state, {
                saving: {$set: true},
            });
        case "SAVED_TRAVEL_TIP":
            return update(state, {
                saving: {$set: false},
                saved: {$set: true},
            });
        case "FETCHED_TRAVEL_TIP_ITEMS":
            let tags = [];

            let allTags = action.payload.filter(item => item.tags).reduce((acc, item) => [...acc, ...item.tags], []).unique();

            tags = allTags;

            return update(state, {
                fetched: {$set: true},
                fetching: {$set: false},
                items: {$set: action.payload},
                tags: {$set: tags},
            });
        default:
            return state;
    }
}