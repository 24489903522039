import React from "react";
import {
    Button,
    Card,
    Container,
    Form,
    Grid,
    Icon,
    Input,
    Label,
    Loader,
    Message,
    Table,
    TextArea
} from "semantic-ui-react";
import 'firebase/firestore';
import {mapMakerMapStyle as mapStyle} from "./mapMakerMapStyle";
import moment from "moment";
import firebase from 'firebase/app';
import 'firebase/firestore';
import {connect} from "react-redux";
import * as UserActions from "../actions/UserActions";
import {NavLink} from "react-router-dom";
import logo from "../resources/images/logo-header.png";

const uuid = function (a, b) {
    for (b = a = ''; a++ < 36; b += a * 51 & 52 ? (a ^ 15 ? 8 ^ Math.random() * (a ^ 20 ? 16 : 4) : 4).toString(16) : '-') ;
    return b
};


class MapMaker extends React.Component {

    state = {
        working: false,
        from: "",
        to: "",
        departingDate: moment().format("d-M-YYYY"),
        departing: "10:00",
        status: "",
        bounds: null,
        refs: {},
        map: null,
        mapsRendered: {},
        mainMapRendered: false,
        result: {},
        lines: [],
        parts: [],
        name: "",

        saving: false,
        saved: false,
        id: uuid(),
    };

    constructor(props) {
        super(props);

        if (props.match.params.id) {
            //Load route
            let id = props.match.params.id;
            let userId = props.match.params.userId;
            let _this = this;
            firebase.firestore().collection("users").doc(userId).collection("maps").doc(id).get().then(ref => {
                let doc = ref.data();
                if (!doc) {
                    console.error("Route not found");
                    return;
                }
                _this.setState({
                    lines: doc.lines,
                    parts: doc.parts,
                    name: doc.name,
                });
                if (_this.state.mainMapRendered) {
                    let bounds = _this.state.bounds;
                    for (let line of doc.lines) {
                        bounds = _this.addPolylineToMap(line, bounds, _this.state.map)
                    }
                    _this.setState({bounds});
                }
            });
        }
    }

    editField(label, e) {
        let value = e.target.value;
        this.setState({
            [label]: value
        })
    }

    save() {

        this.setState({
            saving: true,
        });

        let info = {
            createdAt: new Date(),
            lines: this.state.lines,
            name: this.state.name,
            parts: this.state.parts,
        };

        let userId = this.props.user.uid;
        let _this = this;

        firebase.firestore().collection("users").doc(userId).collection("maps").add(info).then((ref) => {
            console.log(ref.path);
            // _this.props.route.refPath = ref.path;
            // callback(ref);

            this.setState({
                saving: false,
                saved: true,
            });
        }).catch(error => {
            console.error(error);
        });


    }

    go() {
        const {from, to, departing, result, status} = this.state;

        this.setState({
            working: true,
            mapsRendered: {},
            result: {},
            refs: {},
        });

        const _this = this;
        // const googleMapsClient = require('@google/maps').createClient({
        //     key: 'AIzaSyB4KH8yafK53uAH2OsSKu8IdDpKKiLWWdU',
        //     Promise: Promise // 'Promise' is the native constructor.
        // });
        const directionsService = new window.google.maps.DirectionsService();
        directionsService.route({
            // origin: {lat: 51.21985, lng: 6.794658},
            // destination: {lat: 48.186667, lng: 16.38},
            origin: from,
            destination: to,
            travelMode: window.google.maps.TravelMode["TRANSIT"],
            // mode: "transit",
            transitOptions: {
                departureTime: moment(departing, "H:m").toDate()
            },
            provideRouteAlternatives: true,
            // transit_mode: "train",
            // transit_routing_preference: "fewer_transfers",
            // alternatives: true,
        }, (response, status) => {
            _this.setState({
                working: false,
                mapsRendered: {},
                status,
                result: response
            });
            if (response.routes) {

                // setTimeout(() => {
                //     console.log("wokring...");
                //
                // }, 500)
            }
        })

    }


    showMainMap(el) {
        if (this.state.mainMapRendered) return;
        this.setState({
            mainMapRendered: true,
        });

        let _this = this;

        setTimeout(() => {
            // let center = this.props.list ? {lat: 52, lng: 8} : {lat: 52.0, lng: 5.2};
            let map = new window.google.maps.Map(el, {
                zoom: 4,
                styles: mapStyle,
                center: {lat: 52, lng: 8},
            });

            let bounds = _this.state.bounds;
            for (let polyline of _this.state.lines) {
                bounds = _this.addPolylineToMap(polyline, bounds, map)
            }
            _this.setState({
                bounds,
                map
            });

        }, 500);

    }

    addPolylineToMap(polyline, bounds, map) {
        if (!map) map = this.state.map;
        if (!bounds) bounds = new window.google.maps.LatLngBounds();

        let points = window.google.maps.geometry.encoding.decodePath(polyline);

        for (let point of points) {
            bounds.extend(point);
        }
        map.fitBounds(bounds, 20);

        let line = new window.google.maps.Polyline({
            path: points,
            geodesic: true,
            strokeColor: "#66cc33",
            strokeOpacity: 0.9,
            strokeWeight: 4,
            zIndex: 2,
        });
        line.setMap(map);

        return bounds;
    }


    addToTrip(line, from, to) {
        let lines = this.state.lines;
        let parts = this.state.parts;

        lines.push(line);
        parts.push({from, to});
        this.setState({
            lines,
            parts,
        });

        let bounds = this.state.bounds;
        this.addPolylineToMap(line, bounds, this.state.map);
        this.setState({bounds});

        window.scrollTo(0, 0);
    }

    showMap(i, el) {
        if (this.state.mapsRendered[i]) return;
        let mapsRendered = this.state.mapsRendered;
        mapsRendered[i] = true;
        this.setState({
            mapsRendered,
        });

        let _this = this;

        setTimeout(() => {
            let map = new window.google.maps.Map(el, {
                zoom: 5,
                disableDefaultUI: true
            });

            let polyline = _this.state.result.routes[i].overview_polyline;
            let points = window.google.maps.geometry.encoding.decodePath(polyline);

            let bounds = new window.google.maps.LatLngBounds();
            for (let point of points) {
                bounds.extend(point);
            }
            map.fitBounds(bounds, 20);

            let line = new window.google.maps.Polyline({
                path: points,
                geodesic: true,
                strokeColor: "#f22",
                strokeOpacity: 1.0,
                strokeWeight: 4,
                zIndex: 2,
            });
            line.setMap(map);

        }, 500);


    }

    render() {

        const {working, from, to, departing, departingDate, status, result, lines, saving, saved, name, parts} = this.state;

        let id = this.props.match.params.id;
        let url = this.state.id && this.props.user && "https://www.greentickets.app/maps/" + this.props.user.uid + "/" + this.state.id;

        return (
            <div className="page-container interactive-map-full-page">

                <div className="route-header">
                    <div className="line-background">
                        <div className="line"/>
                        <Container>
                            <Grid>
                                <Grid.Row>
                                    <Grid.Column width={7}>
                                        <img src={logo}
                                             alt="Green Tickets | Building the European travel planner of the future"/>
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>
                        </Container>
                    </div>
                </div>

                <br/>

                <Container className="mapMakerContainer">
                    <Grid>
                        <Grid.Row>
                            <Card fluid>
                                <Card.Content>
                                    <h2>{name ? name : "Map your journey"}</h2>
                                </Card.Content>
                                <Card.Content>
                                    <div ref={el => this.showMainMap(el)} className="main-map"/>
                                    {!id &&
                                    <div>
                                        {parts.map(part => <Label>
                                            {part.from} <Icon name="arrow right" /> {part.to}
                                        </Label>)}

                                        <br/>

                                        <Input value={name} onChange={e => this.setState({name: e.target.value})}
                                               name="name" placeholder="Map Title" />&nbsp;
                                        <Button onClick={() => this.save()} color="blue" loading={saving}>Save</Button>

                                        {saved && <Message success>
                                            Map succesfully saved: <a href={url} target="_blank">{url}</a>.<br/><br/>
                                            <Button as={NavLink} to="/profile" size="tiny">My Green Tickets</Button>
                                        </Message>}
                                    </div>

                                    }
                                </Card.Content>
                            </Card>
                            {/*<TextArea style={{minHeight: "100px"}} value={lines.join("\n")}/>*/}
                        </Grid.Row>

                        {!id &&
                        <Grid.Row>
                            <Card fluid>
                                <Card.Content>
                                    <h4>Add routes</h4>
                                </Card.Content>
                                <Card.Content>
                                    <Form>
                                        <Input placeholder="From" value={from}
                                               onChange={(e) => this.editField("from", e)}/> &#8594; {" "}
                                        <Input placeholder="To" value={to}
                                               onChange={(e) => this.editField("to", e)}/>

                                        <Input placeholder="d-m-Y" value={departingDate}
                                               onChange={(e) => this.editField("departingDate", e)}/>
                                        <Input placeholder="HH:mm" value={departing}
                                               onChange={(e) => this.editField("departing", e)}/> <br/>
                                        {working ? <Loader active={true}/> :
                                            <Button onClick={(e) => this.go()}>Search</Button>}
                                    </Form>
                                </Card.Content>
                                <Card.Content>
                                    {result.routes && <div>

                                        <strong>{result.routes.length} routes found:</strong>

                                        <Grid>
                                            <Grid.Row>
                                                {result.routes.map((route, i) =>
                                                    <Grid.Column width={4}>
                                                        <Card fluid>

                                                            <Table>
                                                                <Table.Body>
                                                                    {route.legs.map(leg => <Table.Row>
                                                                        <td>{leg.departure_time.text}<br/>{leg.start_address}
                                                                        </td>
                                                                        <td>{leg.arrival_time.text}<br/>{leg.end_address}
                                                                        </td>
                                                                    </Table.Row>)}
                                                                </Table.Body>
                                                            </Table>

                                                            <div ref={el => this.showMap(i, el)}
                                                                 style={{width: '100%', height: '300px'}}/>

                                                            <Button icon="add"
                                                                    onClick={() => this.addToTrip(route.overview_polyline, route.legs[0].start_address, route.legs[route.legs.length-1].end_address)}/>

                                                        </Card>
                                                    </Grid.Column>)}
                                            </Grid.Row>
                                        </Grid>

                                    </div>}

                                </Card.Content>
                            </Card>

                        </Grid.Row>
                        }
                    </Grid>

                </Container>
            </div>
        )
    }

}

export default connect((state) => ({
    user: state.user.user,
}), (dispatch) => ({
    doSignOut: () => dispatch(UserActions.doSignOut()),
}))(MapMaker);
