// @flow

import type {SearchUpdateAction} from "./Action";
import {Moment} from "moment";
import moment from "moment";

export const SearchUpdateTypes = {
    SEARCH_INTERPRETATION: "SEARCH_INTERPRETATION",
    DRIVING: "DRIVING",
    TRANSIT: "TRANSIT",
    TRANSITTICKETS: "TRANSITTICKETS",
    AIRPORTS: "AIRPORTS",
    FLIGHTS: "FLIGHTS",
    FLIGHTTRANSIT: "FLIGHTTRANSIT",
}
export type SearchUpdateType = $Keys<typeof SearchUpdateTypes>;

export default class SearchUpdate {
    type: string;
    isError: boolean;

    constructor(type: string, isError?: boolean){
        this.type = type;
        this.isError = isError || false;
    }
}

export class SearchUpdateInterpretation extends SearchUpdate{
    constructor(isError?: boolean){super(SearchUpdateTypes.SEARCH_INTERPRETATION, isError)}

    from: string;
    to: string;
    dateFrom: Moment;
    dateTo: Moment;
    dateConstraint: string;
    fromCountry: ?string;
    toCountry: ?string;
}
export class SearchUpdateDriving extends SearchUpdate{
    constructor(isError?: boolean){super(SearchUpdateTypes.DRIVING, isError)}

    duration: number;
    distance: number;
}

export class SearchUpdateTransit extends SearchUpdate{
    constructor(isError?: boolean){super(SearchUpdateTypes.TRANSIT, isError)}

    foundRoutes: boolean;
}

export class SearchUpdateTransitTickets extends SearchUpdate{
    constructor(isError?: boolean){super(SearchUpdateTypes.TRANSITTICKETS, isError)}
}

export class SearchUpdateAirports extends SearchUpdate{
    constructor(isError?: boolean){super(SearchUpdateTypes.AIRPORTS, isError)}

    airports: Array<Array<{name: string, iata: string}>>;
}
export class SearchUpdateFlights extends SearchUpdate{
    constructor(isError?: boolean){super(SearchUpdateTypes.FLIGHTS, isError)}

    numFlights: number;
    airports: Array<{name: string, iata: string}>;
}
export class SearchUpdateFlightTransit extends SearchUpdate{
    constructor(isError?: boolean){super(SearchUpdateTypes.FLIGHTTRANSIT, isError)}
}

export const SearchUpdateFactory = (action: SearchUpdateAction) : ?SearchUpdate => {
    switch(action.meta.type){
        case SearchUpdateTypes.SEARCH_INTERPRETATION:
            const searchUpdate = new SearchUpdateInterpretation(action.meta.isError);
            searchUpdate.from = action.payload.from.name;
            searchUpdate.to = action.payload.to.name;
            searchUpdate.fromCountry = action.payload.from.country;
            searchUpdate.toCountry = action.payload.to.country;
            searchUpdate.dateFrom = moment(action.payload.dateFrom);
            searchUpdate.dateTo = moment(action.payload.dateTo);
            searchUpdate.dateConstraint = action.payload.dateConstraint;

            return searchUpdate;
        case SearchUpdateTypes.DRIVING:
            const searchUpdateDriving = new SearchUpdateDriving(action.meta.isError);
            searchUpdateDriving.duration = action.payload.duration;
            searchUpdateDriving.distance = action.payload.distance;

            return searchUpdateDriving;
        case SearchUpdateTypes.TRANSIT:
            const searchUpdateTransit = new SearchUpdateTransit(action.meta.isError);
            searchUpdateTransit.foundRoutes = action.payload.foundRoutes;
            searchUpdateTransit.fetching = !action.meta.finished;

            return searchUpdateTransit;
        case SearchUpdateTypes.TRANSITTICKETS:
            const searchUpdateTransitTickets = new SearchUpdateTransitTickets(action.meta.isError);

            return searchUpdateTransitTickets;
        case SearchUpdateTypes.AIRPORTS:
            const searchUpdateAirports = new SearchUpdateAirports(action.meta.isError);
            searchUpdateAirports.airports = action.payload.airports;

            return searchUpdateAirports;
        case SearchUpdateTypes.FLIGHTS:
            const searchUpdateFlights = new SearchUpdateFlights(action.meta.isError);
            searchUpdateFlights.numFlights = action.payload.numFlights;
            searchUpdateFlights.airports = action.payload.airports;

            return searchUpdateFlights;
        case SearchUpdateTypes.FLIGHTTRANSIT:
            const searchUpdateFlightTransit = new SearchUpdateFlightTransit(action.meta.isError);

            return searchUpdateFlightTransit;
        default:
            return null;
    }
}