// @flow

import firebase from 'firebase/app';
import 'firebase/firestore';

export default class RoadMapItem {
    id: string;
    name: string;
    description: string;
    category: number;

    image: ?string;
    likes: number = 0;
    eta: ?string;


    get isLiked() : boolean {
        return !!window.localStorage.getItem("RoadMapItemLiked-" + this.id);
    }

    like() : Promise<*>{
        const likeId = window.localStorage.getItem("RoadMapItemLiked-" + this.id);


        if(!!likeId) {
            this.likes--;
            window.localStorage.removeItem("RoadMapItemLiked-" + this.id);
            return firebase.firestore().doc("roadmap/" + this.id + "/likes/" + likeId)
                .delete()
        }else{
            this.likes++;
            return firebase.firestore().collection("roadmap/" + this.id + "/likes")
                .add({date: new Date()})
                .then(ref => window.localStorage.setItem("RoadMapItemLiked-" + this.id, ref.id));
        }
    }

    isValid() : boolean {
        const mandatoryFields = [this.id, this.name, this.description, this.category];

        for(let field of mandatoryFields){
            if(!field) return false;
        }

        return true;
    }
}