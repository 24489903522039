import React, {Component} from 'react';
import RouteInfoPageContent from "../components/route-details/RouteInfoPageContent";
import logo from "../resources/images/logo-header.png"
import interrailMap from "../resources/images/interrail-map.jpg"
import {Button, Card, Container, Grid, Icon, Label, Tab, Table} from "semantic-ui-react";
import pricing from "../resources/interrail-pricing.json";
import chroma from "chroma-js";

export default class Page extends Component {

    state = {
        showClass: 0,
        showAge: 0,
    };

    InterrailPricingTable = (props) => {
        const {showClass, showAge} = this.state;
        const {data, pricing} = props;
        return <Table className="interrail-pricing-table">
            <Table.Header>
                <Table.Row>
                    <Table.HeaderCell/>
                    {(showAge === 0 || showAge === 1) &&
                    <Table.HeaderCell colSpan={showClass === 0 ? 2 : 1}>
                        Youth
                        <span className="ages">12-27 yr</span>
                    </Table.HeaderCell>
                    }
                    {(showAge === 0 || showAge === 2) &&
                    <Table.HeaderCell colSpan={showClass === 0 ? 2 : 1}>
                        Adult
                        <span className="ages">28-59 yr</span>
                    </Table.HeaderCell>
                    }
                    {(showAge === 0 || showAge === 3) &&
                    <Table.HeaderCell colSpan={showClass === 0 ? 2 : 1}>
                        Senior
                        <span className="ages">60+ yr</span>
                    </Table.HeaderCell>
                    }
                </Table.Row>
                <Table.Row>
                    <Table.HeaderCell/>
                    {showClass !== 1 && (showAge === 0 || showAge === 1) &&
                    <Table.HeaderCell>2nd class</Table.HeaderCell>}
                    {showClass !== 2 && (showAge === 0 || showAge === 1) &&
                    <Table.HeaderCell>1st class</Table.HeaderCell>}
                    {showClass !== 1 && (showAge === 0 || showAge === 2) &&
                    <Table.HeaderCell>2nd class</Table.HeaderCell>}
                    {showClass !== 2 && (showAge === 0 || showAge === 2) &&
                    <Table.HeaderCell>1st class</Table.HeaderCell>}
                    {showClass !== 1 && (showAge === 0 || showAge === 3) &&
                    <Table.HeaderCell>2nd class</Table.HeaderCell>}
                    {showClass !== 2 && (showAge === 0 || showAge === 3) &&
                    <Table.HeaderCell>1st class</Table.HeaderCell>}
                </Table.Row>
            </Table.Header>
            <Table.Body>
                {data.map((row, i) => <Table.Row key={"global" + i}>
                    <Table.Cell><strong>{row.title}</strong></Table.Cell>
                    {showClass !== 1 && (showAge === 0 || showAge === 1) &&
                    <Table.Cell>€ {pricing[row.validity].youth["2"]}</Table.Cell>}
                    {showClass !== 2 && (showAge === 0 || showAge === 1) &&
                    <Table.Cell>€ {pricing[row.validity].youth["1"]}</Table.Cell>}
                    {showClass !== 1 && (showAge === 0 || showAge === 2) &&
                    <Table.Cell>€ {pricing[row.validity].adult["2"]}</Table.Cell>}
                    {showClass !== 2 && (showAge === 0 || showAge === 2) &&
                    <Table.Cell>€ {pricing[row.validity].adult["1"]}</Table.Cell>}
                    {showClass !== 1 && (showAge === 0 || showAge === 3) &&
                    <Table.Cell>€ {pricing[row.validity].senior["2"]}</Table.Cell>}
                    {showClass !== 2 && (showAge === 0 || showAge === 3) &&
                    <Table.Cell>€ {pricing[row.validity].senior["1"]}</Table.Cell>}
                </Table.Row>)}
            </Table.Body>
        </Table>;
    };

    render() {
        const {showClass, showAge} = this.state;

        const {routeId} = this.props.match.params;
        const globalTableRows = [
            {
                validity: "5 in 15",
                title: "5 days in 1 month",
            },
            {
                validity: "7 in M",
                title: "7 days in 1 month",
            },
            {
                validity: "15 in M",
                title: "15 days in 1 month",
            },
            {
                validity: "15",
                title: "15 days",
            },
            {
                validity: "22",
                title: "22 days",
            },
            {
                validity: "M",
                title: "1 month",
            },
        ];
        const countryTableRows = [
            {
                validity: "3",
                title: "3 days in 1 month",
            },
            {
                validity: "4",
                title: "4 days in 1 month",
            },
            {
                validity: "6",
                title: "6 days in 1 month",
            },
            {
                validity: "8",
                title: "8 days in 1 month",
            },
        ];


        const countries = {
            "Germany": {
                code: "de",
                cat: 6,
            },
            "Great Britain": {
                code: "uk",
                cat: 6,
            },
            "France": {
                code: "fr",
                cat: 5,
            },
            "Italy Plus": {
                code: "it+",
                cat: 5,
            },
            "Norway": {
                code: "no",
                cat: 5,
            },
            "Spain": {
                code: "sp",
                cat: 5,
            },
            "Sweden": {
                code: "se",
                cat: 5,
            },
            "Austria": {
                code: "at",
                cat: 4,
            },
            "Switzerland": {
                code: "ch",
                cat: 4,
            },
            "Italy": {
                code: "it",
                cat: 2,
            },
            "Benelux": {
                code: "bnl",
                cat: 3,
            },
            "Denmark": {
                code: "dk",
                cat: 3,
            },
            "Finland": {
                code: "fi",
                cat: 3,
            },
            "Greece Plus": {
                code: "gr+",
                cat: 3,
            },
            "Ireland": {
                code: "ir",
                cat: 3,
            },
            "Hungary": {
                code: "hu",
                cat: 1,
            },
            "Greece": {
                code: "gr",
                cat: 1,
            },
            "Portugal": {
                code: "po",
                cat: 1,
            },
            "Romania": {
                code: "ro",
                cat: 1,
            },
            "Bulgaria": {
                code: "bg",
                cat: 0,
            },
            "Croatia": {
                code: "hr",
                cat: 0,
            },
            "Czech Republic": {
                code: "cz",
                cat: 0,
            },
            "FYR Macedonia": {
                code: "mk",
                cat: 0,
            },
            "Poland": {
                code: "pl",
                cat: 0,
            },
            "Serbia": {
                code: "sr",
                cat: 0,
            },
            "Slovakia": {
                code: "sk",
                cat: 0,
            },
            "Slovenia": {
                code: "sl",
                cat: 0,
            },
            "Turkey": {
                code: "tr",
                cat: 0,
            },
        };

        const orderedCountries = Object.keys(countries).sort((a, b) => a.localeCompare(b));

        let validCountries = [...Object.keys(countries), "Belgium", "The Netherlands", "Luxembourg"].filter(c => c.indexOf("Plus") === -1 && c !== "Benelux");
        const orderedValidCountries = validCountries.sort((a, b) => a.localeCompare(b));
        const catColors = [
            // 'purple',
            '#ccc',
            'blue',
            'teal',
            'green',
            'olive',
            'yellow',
            'orange',
            'red',
        ];
        const colorScale = chroma.scale(['white', 'navy']);

        const panes = orderedCountries.map(countryName => {
            let country = countries[countryName];
            let cat = country["cat"];
            let color = colorScale((cat + 1) / 7).hex();
            return {
                menuItem: {
                    key: country["code"], content: <div>
                        {countryName}
                        <Label circular style={{float: "right", backgroundColor: color}} empty/>
                    </div>
                },
                render: () => <div>
                    <this.InterrailPricingTable data={countryTableRows} pricing={pricing.countries[cat]}/>
                </div>
            }
        });

        // console.log(pricing);

//https://www.interrail.eu/nl/interrail-passen/global-pass
        //


        return (
            <div className="page-container route-info-page">

                <div className="route-header">
                    <div className="line-background">
                        <div className="line"/>
                        <Container>
                            <Grid>
                                <Grid.Row>
                                    <Grid.Column width={7}>
                                        <img src={logo}
                                             alt="Green Tickets | Building the European travel planner of the future"/>
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>
                        </Container>
                    </div>
                </div>

                <br/>

                <Container>
                    <Card fluid>
                        <Card.Content>
                            <h1>Interrail</h1>

                            <p>The most flexible way to travel through Europe by train. With an Interrail pass you can
                                take as many trains as you like within the period you choose.</p>

                            <p>
                                <a href="https://www.interrail.eu/sites/interrail.eu/files/documents/interrail_2018-lr.pdf" target="_blank" rel="noopener noreferrer">
                                    <img src={interrailMap} alt="Interrail Map" style={{width: "200px"}} /><br />
                                    Download map (8MB)</a>
                            </p>

                            <Grid>
                                <Grid.Row columns={2} centered={true}>
                                    <Grid.Column textAlign="center">
                                        <strong>What age are you?</strong><br/>
                                        <Button.Group basic style={{marginTop: "4px"}}>
                                            <Button basic active={showAge === 1}
                                                    onClick={() => this.setState({showAge: showAge === 1 ? 0 : 1})}>12 -
                                                27</Button>
                                            <Button basic active={showAge === 2}
                                                    onClick={() => this.setState({showAge: showAge === 2 ? 0 : 2})}>28 -
                                                59</Button>
                                            <Button basic active={showAge === 3}
                                                    onClick={() => this.setState({showAge: showAge === 3 ? 0 : 3})}>60+</Button>
                                        </Button.Group>
                                    </Grid.Column>
                                    <Grid.Column>
                                        <strong>What class do you want to travel in?</strong><br/>
                                        <Button.Group basic style={{marginTop: "4px"}}>
                                            <Button basic active={showClass === 2}
                                                    onClick={() => this.setState({showClass: showClass === 2 ? 0 : 2})}>2nd
                                                class</Button>
                                            <Button basic active={showClass === 1}
                                                    onClick={() => this.setState({showClass: showClass === 1 ? 0 : 1})}>1st
                                                class</Button>
                                        </Button.Group>
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>

                            <h2>Global Pass</h2>

                            <Button as="a" style={{float: "right"}} primary icon labelPosition="right" target="_blank" rel="noopener noreferrer"
                                    href="https://www.awin1.com/cread.php?awinmid=10901&awinaffid=510525&clickref=&p=https%3A%2F%2Fwww.interrail.eu%2Fnl%2Finterrail-passen%2Fglobal-pass">Buy
                                on interrail.eu <Icon name="right arrow"/></Button>
                            <p>
                                The Interrail Global pass is valid in the following
                                countries: {orderedValidCountries.join(", ")}.
                            </p><br/>
                            <this.InterrailPricingTable data={globalTableRows} pricing={pricing.global}/>

                            <h2>One Country Pass</h2>

                            <Button as="a" style={{float: "right"}} primary icon labelPosition="right" target="_blank" rel="noopener noreferrer"
                                    href="https://www.awin1.com/cread.php?awinmid=10901&awinaffid=510525&clickref=&p=https%3A%2F%2Fwww.interrail.eu%2Fnl%2Finterrail-passen%2Fone-country-pass">Buy
                                on interrail.eu <Icon name="right arrow"/></Button>

                            <p>
                                Interrail country passes have seven different price levels, depending on which country
                                you choose. There are four different periods: 3, 4, 6 or 8 days within one month.
                            </p>
                            <Tab menu={{fluid: true, vertical: true, tabular: true, secondary: true, pointing: true}}
                                 panes={panes}/>

                        </Card.Content>
                    </Card>
                </Container>
            </div>
        );
    }
}