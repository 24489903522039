import React, {Component} from 'react';
import {connect} from "react-redux";
import PlannerInput from '../components/plannerInput';
import moment from "moment";
import {Button, Grid, Icon, Transition, Container, Loader, Card} from "semantic-ui-react";
import HomePageContent from "../components/HomePageContent";
import {MODES, PLANNER_VIEWS, SORTING} from "../constants";
import {Ref} from "semantic-ui-react";
import * as RouteActions from "../actions/RouteActions";
import RouteInfoModal from "../components/RouteInfoModal";
import LoadingAnimation from "../components/LoadingAnimation";
import RouteRow from "../components/planner/RouteRowNew";
import RouteInfo from "../components/planner/RouteInfoNew";
import AdSense from 'react-adsense';
import ModalityInfo from "../components/planner/ModalityInfo";
import AdsGridRow from "../components/ads/AdsGridRow";

class PlannerPageNew extends Component {

    DEFAULT_FACTOR = 0.05;

    static activeView() {
        return PLANNER_VIEWS.LIST;
    }

    constructor(props) {
        super(props);

        this.state = {
            from: (props.params && props.params.from),
            to: (props.params && props.params.to),
            on: (props.params && props.params.on),
            by: (props.params && props.params.by),
            factor: 0.05,
            backgroundWidth: "100%",
            baseTime: moment(),
            routes: [],
        };


    }

    calculateFactor(routes, baseTime) {

        if (this.routesContainer && this.routesContainer.offsetWidth) {

            let containerWidth = this.routesContainer && this.routesContainer.offsetWidth;
            let durations = routes.map(result => result && result.duration);
            let longestDuration = Math.max(...durations);
            let lastArrival = moment(Math.max(...routes.map(result => result && result.arrivalDate)));
            // let averageDuration = durations.reduce((p, c) => p + c, 0) / durations.length;
            // let padding = 200;
            //@TODO : Factor aanpassen
            let factor = (containerWidth - 100) / (longestDuration); //Math.max((containerWidth - padding) / (longestDuration * 1.1), 0.02);
            let backgroundWidth = (lastArrival.diff(baseTime, "s") + 3600) * factor;
            this.setState({
                factor,
                backgroundWidth,
            });

        } else {
            this.setState({factor: this.DEFAULT_FACTOR});
        }
    }

    setRoutesContainer(el) {
        this.routesContainer = el;

        const {routes, baseTime} = this.state;
        if (routes && baseTime) {
            this.calculateFactor(routes, baseTime);
        }
    }


    componentWillReceiveProps(newProps) {


        let {routes} = newProps;
        let results = routes && routes.length > 0;
        let baseTime = null;
        if (results) {
            let firstDeparture = Math.min(...routes.map(result => result && result.departureDate));
            baseTime = moment(firstDeparture).minutes(0).seconds(0).subtract(1, "h");
            this.calculateFactor(routes, baseTime); //0.03;
            this.setState({
                baseTime,
                routes,
                from: (newProps.params && newProps.params.from),
                to: (newProps.params && newProps.params.to),
                on: (newProps.params && newProps.params.on),
                by: (newProps.params && newProps.params.by),
            });
        }

        routes.forEach(route => {
            if (!route.pendingAddTransit) {
                if (route.legs.reduce((acc, cur) => acc || cur.type === "NOT_FETCHED_ESTIMATION", false)) {
                    this.props.addTransit(route);
                }
            }
        })
    }

    componentDidUpdate() {
        if (this.state.factor === this.DEFAULT_FACTOR) {
            // this.componentWillReceiveProps(this.props);
        }
    }

    highlightMode(mode) {
        let currentMode = this.props.currentHighlightMode;
        this.props.highlightMode(mode);

        // let elements = document.getElementsByClassName("route-row-container  " + mode);

        if (currentMode !== mode) {
            document.getElementById("results-container").scrollIntoView();
            // window.setTimeout(() => {
            //     if (elements && elements.length > 0) {
            //         elements[0].scrollIntoView();
            //     }
            // }, 200);
        }
    }

    render() {

        let {from, to, on, by, activeRow} = this.state;

        let {routes, itemCount, filteredItemCount, fetching, filtering, sort, sorting, fetchingTakesLong, showPlannerForm, query, currentHighlightMode, expandAll} = this.props;
        let results = routes && routes.length > 0;
        // let showBetaBanner = (!from || !to);
        let showSplash = (!from || !to);

        return (
            <div className={"main-container container" + (showSplash ? " splash" : "")}>

                <div className={"header-container"}>

                    <div
                        className={"header" + ((false && (!from || !to)) ? " large" : "") + (showSplash ? " splash" : "")}>
                        {(showPlannerForm || !from || !to) ? /*(from && to && showPlannerForm) !== false*/
                            <PlannerInput from={from} to={to} on={on} by={by} history={this.props.history}
                                          splash={showSplash}/>
                            :
                            <LoadingAnimation active={true} by={by || "car,plane,transit"}/>
                        }
                    </div>

                </div>

                <div className="content-container new">
                    <Container>

                        <br/>

                        <div className="comment" style={{textAlign: "center"}}>
                            Note: not all modes of transport in all countries are included yet.
                        </div>

                        <Card fluid className="modality-info-container">
                            {routes.map(route => route.mainMode).unique().map(mode =>
                                <ModalityInfo key={"modality" + mode}
                                              routes={routes.filter(route => route.mainMode === mode)} mode={mode}
                                              onClick={() => this.highlightMode(mode)}/>)}
                        </Card>

                        <Grid style={{height: "100%"}} stackable id="results-container">
                            {(from && to && results) &&
                            <Grid.Row className="trip-results-info">
                                {/*Showing {filteredItemCount} of {itemCount} routes.<br/>*/}
                                {/*{JSON.stringify(plannerInfo)}*/}
                                <Grid.Column width={8} textAlign="center">
                                    {/*Sort on:<br />*/}
                                    <Button basic className="inactive no-border" size="mini">Sort:</Button>
                                    <Button.Group className="sort-buttons" size="mini">
                                        <Button active={sorting === SORTING.PRICE} onClick={(e) => {
                                            sort(SORTING.PRICE);
                                            e.target.blur()
                                        }}>&euro;</Button>
                                        <Button active={sorting === SORTING.DURATION} onClick={(e) => {
                                            sort(SORTING.DURATION);
                                            e.target.blur()
                                        }}><i className="fa fa-clock"/></Button>
                                        <Button active={sorting === SORTING.TRANSFERS} onClick={(e) => {
                                            sort(SORTING.TRANSFERS);
                                            e.target.blur()
                                        }}><i className="fa fa-random"/></Button>
                                        <Button active={sorting === SORTING.CLIMATE_IMPACT} onClick={(e) => {
                                            sort(SORTING.CLIMATE_IMPACT);
                                            e.target.blur()
                                        }}>CO<sub>2</sub></Button>
                                    </Button.Group>
                                </Grid.Column>
                                <Grid.Column width={8} textAlign="center">
                                    <Button size="mini" onClick={() => expandAll()}>Expand all routes</Button>
                                </Grid.Column>
                                {/*<Grid.Column width={8} textAlign="center">*/}
                                {/*/!*{itemCount === 1 ?*!/*/}
                                {/*/!*<span>Showing one route.<br/></span> :*!/*/}
                                {/*/!*<span>Showing the best {routes.length} of {itemCount} routes.<br/></span>*!/*/}
                                {/*/!*}*!/*/}
                                {/*{fetching ?*/}
                                {/*<span>*/}
                                {/*<Loader active={true} inline/>*/}
                                {/*{fetchingTakesLong && <strong> Hold on, we're still searching...</strong>}*/}
                                {/*</span>*/}
                                {/*:*/}
                                {/*<span>We have found {routes.length} routes.<br/></span>*/}
                                {/*// itemCount !== filteredItemCount && (filtering ?*/}
                                {/*//     <a onClick={(e) => this.props.toggleFiltering(false)} className="pointer">Show*/}
                                {/*//         unfiltered results.</a>*/}
                                {/*//     :*/}
                                {/*//     <a onClick={(e) => this.props.toggleFiltering(true)} className="pointer">Show*/}
                                {/*//         less.</a>*/}
                                {/*// )*/}
                                {/*}*/}
                                {/*</Grid.Column>*/}
                            </Grid.Row>
                            }

                            {from && to ? (
                                results ?
                                    <Grid.Row>
                                        <Grid.Column width={8}
                                                     className={"new routes-info-container" + (fetching ? " loading" : "")}>
                                            {routes.map((option, i) => (
                                                <RouteInfo key={option.id + "info"} {...option}
                                                           large={false}
                                                           className={i === activeRow ? "active" : ""}
                                                           onMouseEnter={(e) => this.setState({activeRow: i})}
                                                           onMouseLeave={(e) => this.setState({activeRow: null})}
                                                           onClick={(e) => this.props.setActiveRoute(option.id)}
                                                />
                                            ))}
                                        </Grid.Column>
                                        <Ref innerRef={el => this.setRoutesContainer(el)}>
                                            <Grid.Column width={8} className="routes-container new"
                                                         only="tablet computer">
                                                <svg style={{width: this.state.backgroundWidth}}>
                                                    <defs>
                                                        <pattern id="basicPattern" x="0" y="0"
                                                                 width={3600 * this.state.factor}
                                                                 height="40"
                                                                 patternUnits="userSpaceOnUse">
                                                            <line x1="0" y1="0" x2="0" y2="40" stroke="#eee"
                                                                  strokeWidth="2"/>
                                                        </pattern>
                                                    </defs>
                                                    <rect fill="url(#basicPattern)"/>
                                                </svg>
                                                {query && query.date &&
                                                <div className="target-time"
                                                     style={{marginLeft: this.state.factor * query.date.diff(this.state.baseTime, "s")}}/>}
                                                {routes.map((option, i) => (
                                                    <RouteRow key={option.id + "row"} {...option}
                                                              factor={this.state.factor} large={false}
                                                              baseTime={this.state.baseTime}
                                                              className={i === activeRow ? "active" : ""}
                                                              onMouseEnter={(e) => this.setState({activeRow: i})}
                                                              onMouseLeave={(e) => this.setState({activeRow: null})}
                                                              onClick={(e) => this.props.setActiveRoute(option.id)}
                                                    />
                                                ))}
                                            </Grid.Column>
                                        </Ref>
                                    </Grid.Row>
                                    :
                                    <Grid.Row style={{color: "#888", textAlign: "center", paddingTop: "100px"}}>
                                        {fetching ?
                                            <div>
                                                <Loader active/><br/>Loading
                                                routes...
                                            </div> :
                                            <div>
                                                No routes found...
                                            </div>
                                        }
                                        <br/><br/>
                                        <br/><br/>
                                    </Grid.Row>
                            ) : (
                                <HomePageContent/>
                            )}

                            <Grid.Row columns={1}>
                                <Grid.Column>
                                    <AdSense.Google
                                        client='ca-pub-5852781193487729'
                                        slot='4858849984'
                                        style={{display: 'block'}}
                                        format='auto'
                                        responsive="true"
                                    />
                                </Grid.Column>
                            </Grid.Row>

                        </Grid>
                    </Container>

                    <Grid stackable style={{marginTop: '100px'}}>

                        <AdsGridRow/>

                        <Grid.Row>
                            <div style={{height: "100px"}}/>
                        </Grid.Row>
                    </Grid>

                </div>

                <RouteInfoModal/>
            </div>
        );
    }
}

export default connect(state => ({
    filtering: state.routes.filtering,
    fetching: state.routes.fetching,
    fetchingTakesLong: state.routes.fetchingTakesLong,
    sorting: state.routes.sorting,
    routes: state.routes.items.filter(r => !state.routes.filtering || !r.hidden),
    itemCount: state.routes.itemCount,
    query: state.routes.query,
    // plannerInfo: state.routes.plannerInfo,
    filteredItemCount: state.routes.filteredItemCount,
    showPlannerForm: state.routes.showPlannerForm,
    currentHighlightMode: state.routes.highlightMode,
}), dispatch => ({
    setActiveRoute: (i) => dispatch(RouteActions.setActive(i)),
    toggleFiltering: (i) => dispatch(RouteActions.toggleFiltering(i)),
    highlightMode: (mode) => dispatch(RouteActions.highlightMode(mode)),
    expandAll: () => dispatch(RouteActions.expandAll()),
    addTransit: (route, query) => dispatch(RouteActions.addTransit(route, query)),
    sort: (on) => dispatch(RouteActions.sort(on)),
}))(PlannerPageNew);