import update from 'immutability-helper';
import moment from "moment";

const initialState = {
    savingStory: false,
    savedStory: false,

    fetchingSavedStories: false,
    fetchedSavedStories: false,
    fetchedSavedStory: false,
    savedStories: {},
    savedUserStories: {},

    editingStory: {},

};

export default (state = initialState, action) => {

    switch (action.type) {

        case "SAVE_STORY":
            return update(state, {
                savingStory: {$set: true},
                savedStory: {$set: false},
            });

        case "SAVED_STORY":
            return update(state, {
                savingStory: {$set: false},
                savedStory: {$set: action.payload},
                editingStory: {
                    saved: {$set: true},
                    savedMoment: {$set: moment()}
                }
            });

        case "FETCH_SAVED_STORIES":
            return update(state, {
                fetchingSavedStories: {$set: true},
            });

        case "FETCHED_SAVED_STORIES":
            return update(state, {
                fetchingSavedStories: {$set: false},
                fetchedSavedStories: {$set: true},
                savedStories: {$set: action.payload},
            });

        case "FETCHED_SAVED_STORY":
            return update(state, {
                fetchingSavedStories: {$set: false},
                fetchedSavedStories: {$set: false},
                fetchedSavedStory: {$set: true},
                editingStory: {
                    $set: action.payload
                },
                savedStories: {
                    [action.payload.id]:
                        {$set: action.payload},
                },
            });
        case "FETCHED_SAVED_STORY_USER_INFO":
            if (state.editingStory.id !== action.payload.id)return state; //Old data
            return update(state, {
                editingStory: {
                    userInfo: {
                        $set: action.payload.userInfo,
                    }
                },
            });
        case "EDIT_STORY_FIELD":
            return update(state, {
                editingStory: {
                    [action.payload.label]: {
                        $set: action.payload.value
                    },
                    saved: {$set: false},
                },
            });
        case "EDIT_STORY_SUBFIELD":

            const {field, nr, label, value} = action.payload;

            let subField = state.editingStory[field] ? [...state.editingStory[field]] : [];
            if(!subField[action.payload.nr])subField[action.payload.nr] = {};
            subField = update(subField, {
                [action.payload.nr]: {
                    [action.payload.label]: {
                        $set: action.payload.value
                    },
                },
            });

            return update(state, {
                editingStory: {
                    [action.payload.field]: {
                        $set: subField,
                    },
                    saved: {$set: false},
                },
            });


        case "SIGNED_OUT":
            return update(state, {
                fetchedSavedStories: {$set: false},
                savedStories: {$set: {}},
                editingStory: {$set: {}},
            });

        default:
            return state;
    }
}