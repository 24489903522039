import React from 'react';
import {MODES, PLANNER_VIEWS} from "../../constants";
import moment from "moment/moment";
import RoutePart from "./RoutePart";
import PlannerPage from '../../pages/planner';

const RouteRow = (props) => {
    let departureDate = props.departureDate;
    let arrivalDate = props.arrivalDate;
    let parts = [];
    if (props.parts && props.parts.length > 0) {
        for (let part of props.parts) {
            //Don't show walking parts of less then 5 mins
            if (part.mode !== MODES.WALKING || part.duration > 60 * 5) {
                part.departureDate = moment(part.departureDate);
                part.arrivalDate = moment(part.arrivalDate);
                parts.push(part);
            } else {
                part.onlySpacer = true;
                parts.push(part);
            }
        }
    }

    let marginLeft = PlannerPage.activeView() === PLANNER_VIEWS.TIMELINE ? props.factor * departureDate.diff(props.baseTime, "s") : 100;

    return (
        <div className={props.className + " route-row-container" + (props.large ? "" : " small")}
             onMouseEnter={props.onMouseEnter}
             onMouseLeave={props.onMouseLeave}
             onClick={props.onClick}
            // style={(props.hidden ? {opacity: 0.2} : {})}
        >
            <div className="route-row" style={{marginLeft}}>
                <div className="start-time">{departureDate.format("H:mm")}</div>
                <div className="route-bar" style={{width: props.factor * props.duration}}/>
                {parts.map((part, i) => {
                    if (part.onlySpacer) {
                        let spacerWidth = (i === 0) ? 0 : props.factor * part.duration;
                        return <div key={props.id + i + "spacer"} className="spacer" style={{width: spacerWidth}}/>
                    } else {
                        let spacerWidth = (i === 0) ? 0 : props.factor * part.departureDate.diff(parts[i - 1].arrivalDate, "s");
                        if (i > 0 && parts[i - 1].mode === MODES.WAITING) part.flightAfterWait = true;
                        if (i < parts.length - 1 && parts[i + 1].mode === MODES.WAITING) part.flightBeforeWait = true;
                        if (part.mode === MODES.WAITING && i > 0 && parts[i - 1].mode === MODES.FLYING) part.waitAfterFlight = true;
                        if (part.mode === MODES.WAITING && i < parts.length - 1 && parts[i + 1].mode === MODES.FLYING) part.waitBeforeFlight = true;
                        if (i === 0) part.first = true;
                        if (i === parts.length - 1) part.last = true;
                        part.showTo = true;
                        if (i < parts.length - 1 &&
                            parts[i + 1].from.name === part.to.name &&
                            parts[i + 1].mode !== MODES.WAITING
                        ) part.showTo = false;
                        if (part.mode === MODES.ESTIMATION && props.pendingAddTransit === true)part.pendingAddTransit = true;
                        // if (i > 0 && parts[i - 1].to.name !== part.from.name) part.showFrom = true;
                        return [
                            <div key={props.id + i + "spacer"} className="spacer" style={{width: spacerWidth}}/>,
                            <RoutePart key={props.id + i} {...part} width={props.factor * part.duration}/>
                        ]
                    }
                })}
                <div className="end-time">{arrivalDate.format("H:mm")}</div>
            </div>
        </div>
    );
};

export default RouteRow;