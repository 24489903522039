import React, {Component} from 'react';
import {
    extendedEmissionFactor,
    extendedEmissionFactors, extendedEmissionFactorSources,
    googleMapsModes,
    googleMapsProviders,
    modeDescriptions,
    MODES
} from "../constants";
import {Button, Card, Form, Grid, Icon, Input, Label, Loader, Popup, Table} from "semantic-ui-react";
import ModeIcon from "./ModeIcon";
import moment from "moment/moment";


export default class CO2Comparison extends Component {

    // Mode --> country --> provider --> type
    state = {
        calculated: false,
        total: 0,
        parts: [],


        working: false,
        from: "Amsterdam",
        to: "Madrid",
        departingDate: "5-8-2018",
        departing: "10:00",
        status: "",
        bounds: null,
        refs: {},
        map: null,
        mapsRendered: {},
        mainMapRendered: false,
        result: null,
        lines: [],
    };

    editField(label, e) {
        let value = e.target.value;
        this.setState({
            [label]: value
        })
    }

    parseDistances(distances) {

        let parts = [];
        let total = 0;

        for (let distancePart of distances) {

            let factor = extendedEmissionFactor(distancePart.mode, distancePart.country, distancePart.provider, distancePart.type);

            parts.push({
                ...distancePart,
                factor,
                // searchString,
                amount: distancePart.distance * factor.amount / 1000,
            })
        }

        this.setState({
            parts,
            total,
            calculated: true,
        });
    }


    go() {
        const {from, to, departing, result, status} = this.state;

        this.setState({
            working: true,
            mapsRendered: {},
            result: null,
            refs: {},
        });

        const _this = this;
        // const googleMapsClient = require('@google/maps').createClient({
        //     key: 'AIzaSyB4KH8yafK53uAH2OsSKu8IdDpKKiLWWdU',
        //     Promise: Promise // 'Promise' is the native constructor.
        // });
        const directionsService = new window.google.maps.DirectionsService();
        directionsService.route({
            // origin: {lat: 51.21985, lng: 6.794658},
            // destination: {lat: 48.186667, lng: 16.38},
            origin: from,
            destination: to,
            travelMode: window.google.maps.TravelMode["TRANSIT"],
            // mode: "transit",
            transitOptions: {
                departureTime: moment(departing, "H:m").toDate()
            },
            provideRouteAlternatives: true,
            // transit_mode: "train",
            // transit_routing_preference: "fewer_transfers",
            // alternatives: true,
        }, (response, status) => {
            // console.log(response);

            let results = [];
            for (let route of response.routes) {
                let steps = [];
                let totalCO2 = 0;
                let totalDistance = 0;

                let leg = route.legs[0];
                for (let step of leg.steps) {

                    if (step["travel_mode"] !== "TRANSIT")continue;

                    let mapsMode = step.transit && step.transit.line && step.transit.line.vehicle.type;
                    let mode = MODES.UNKNOWN;
                    if (mapsMode && googleMapsModes[mapsMode]) mode = googleMapsModes[mapsMode];

                    let providerName = step.transit && step.transit.line && step.transit.line.agencies[0].name;
                    let provider = googleMapsProviders[providerName] || providerName;

                    let country = null;
                    let arrivalStopLocation = step.transit && step.transit.arrival_stop.location;
                    console.log(arrivalStopLocation);

                    let type = step.transit && step.transit.line && step.transit.line.short_name;
                    let factor = extendedEmissionFactor(mode, country, provider, type);
                    let distance = step.distance.value / 1000;
                    let co2 = distance * factor.amount / 1000;

                    totalCO2 += co2;
                    totalDistance += distance;

                    let source = extendedEmissionFactorSources[factor.source] || null;

                    steps.push({
                        description: step.transit && step.transit.line && step.transit.line.vehicle.name,
                        distance,
                        distanceText: step.distance.text,
                        providerName,
                        provider,
                        country,
                        type,
                        mode,
                        factor,
                        co2,
                        departure: step.transit && step.transit.departure_stop.name,
                        arrival: step.transit && step.transit.arrival_stop.name,
                        source,
                    });
                }

                let result = {
                    departure_time: leg.departure_time.text,
                    departure_address: leg.start_address,
                    arrival_time: leg.arrival_time.text,
                    arrival_address: leg.end_address,
                    steps,
                    totalCO2,
                    totalDistance,
                };
                results.push(result);
            }

            console.log(results);

            _this.setState({
                working: false,
                mapsRendered: {},
                status,
                result: results
            });
            if (response.routes) {

                // setTimeout(() => {
                //     console.log("wokring...");
                //
                // }, 500)
            }
        })

    }


    render() {

        const distances = [
            {
                distance: 200,
                mode: MODES.TRAIN,
                country: "nl",
                provider: "NS",
            },
            {
                distance: 50,
                mode: MODES.TRAIN,
                provider: "db",
                country: "de",
            },
            {
                distance: 800,
                mode: MODES.TRAIN,
                // type: "Thalys",
                country: "fr",
                provider: "SNCF",
            },
            {
                distance: 20,
                mode: MODES.BUS,
                country: "de",
            },
        ];

        const {total, parts, calculated} = this.state;
        const {working, from, to, status, result, lines, departing, departingDate} = this.state;

        // if (!calculated) this.parseDistances(distances);

        return <div className="co2-ccalculator-container">

            <Form>
                <Input placeholder="From" value={from}
                       onChange={(e) => this.editField("from", e)}/> &#8594; {" "}
                <Input placeholder="To" value={to}
                       onChange={(e) => this.editField("to", e)}/>{" "}

                {/*<Input placeholder="d-m-Y" value={departingDate}*/}
                {/*onChange={(e) => this.editField("departingDate", e)}/>*/}
                <Input placeholder="HH:mm" value={departing}
                onChange={(e) => this.editField("departing", e)}/>{" "}

                <Button onClick={(e) => this.go()} loading={working}>Search</Button>

                <br/>
            </Form>

            {result && <div>

                <br />
                {/*<strong>{result.length} routes found:</strong>*/}

                <Grid>
                    <Grid.Row>
                        {result.map((route, i) => {
                            return <Grid.Column width={8} key={"route" + i}>
                                <Card fluid style={{marginBottom: "20px"}}>

                                    <Table>
                                        <Table.Body>
                                            <Table.Row>
                                                <Table.Cell>{route.departure_time}<br/>{route.departure_address}
                                                </Table.Cell>
                                                <Table.Cell>{route.arrival_time}<br/>{route.arrival_address}
                                                </Table.Cell>
                                            </Table.Row>
                                        </Table.Body>
                                    </Table>

                                    <Table compact striped>
                                        <Table.Body>
                                            {route.steps.map((step, j) => <Table.Row key={"route" + i + "leg" + j}>
                                                <Table.Cell style={{width: '40px'}}>
                                                    <ModeIcon mode={step.mode}/> {modeDescriptions[step.mode]}
                                                </Table.Cell>
                                                <Table.Cell>
                                                    <strong>{step.departure}</strong> to <strong>{step.arrival}</strong>
                                                </Table.Cell>
                                                <Table.Cell style={{width: '100px'}}>
                                                    {step.providerName}
                                                </Table.Cell>
                                                <Table.Cell style={{width: '80px'}}>
                                                    {step.distanceText}
                                                </Table.Cell>
                                                <Table.Cell style={{width: '80px'}} textAlign="right">
                                                    {step.co2 ? <strong>{Math.round(step.co2 * 10) / 10} kg</strong> : <span style={{color: "#ccc"}}>0 kg</span>}
                                                </Table.Cell>
                                                <Table.Cell>
                                                    {step.source && <Popup trigger={<Label circular size="small">{step.factor.precision}</Label>} position="bottom right" content={
                                                        <div>
                                                            {step.factor.amount} g CO<sub>2</sub> per passenger km, based on {step.factor.precisionDescription}.
                                                            Source: {step.source.name}
                                                        </div>} />}
                                                </Table.Cell>
                                            </Table.Row>)}
                                        </Table.Body>
                                        <Table.Footer>
                                            <Table.Row>
                                                <Table.HeaderCell colSpan={3}/>
                                                <Table.HeaderCell>{Math.round(route.totalDistance)} km</Table.HeaderCell>
                                                <Table.HeaderCell colSpan={2} textAlign="center">
                                                    <Button size="small" onClick={() => this.props.showCO2(Math.round(route.totalCO2 * 10) / 10)}>{Math.round(route.totalCO2 * 10) / 10} kg</Button>
                                                </Table.HeaderCell>
                                            </Table.Row>
                                        </Table.Footer>
                                    </Table>

                                </Card>
                            </Grid.Column>
                        })}
                    </Grid.Row>
                </Grid>

            </div>}

            {/*{calculated && <Table>*/}
            {/*<Table.Header>*/}
            {/*<Table.Row>*/}
            {/*<Table.HeaderCell>Mode</Table.HeaderCell>*/}
            {/*<Table.HeaderCell>Country</Table.HeaderCell>*/}
            {/*<Table.HeaderCell>Provider</Table.HeaderCell>*/}
            {/*<Table.HeaderCell>Type</Table.HeaderCell>*/}
            {/*<Table.HeaderCell>CO<sub>2</sub> amount</Table.HeaderCell>*/}
            {/*</Table.Row>*/}
            {/*</Table.Header>*/}
            {/*<Table.Body>*/}
            {/*{parts.map((part, i) => <Table.Row key={"part" + i}>*/}
            {/*<Table.Cell>*/}
            {/*<ModeIcon mode={part.mode}/> {modeDescriptions[part.mode]}*/}
            {/*</Table.Cell>*/}
            {/*<Table.Cell>*/}
            {/*{part.country}*/}
            {/*</Table.Cell>*/}
            {/*<Table.Cell>*/}
            {/*{part.provider}*/}
            {/*</Table.Cell>*/}
            {/*<Table.Cell>*/}
            {/*{part.type}*/}
            {/*</Table.Cell>*/}
            {/*<Table.Cell>*/}
            {/*{part.distance} km*/}
            {/*</Table.Cell>*/}
            {/*<Table.Cell>*/}
            {/*{part.amount} kg*/}
            {/*</Table.Cell>*/}
            {/*<Table.Cell>*/}
            {/*{part.searchString}<br/>*/}
            {/*{part.factor && part.factor.amount}<br/>*/}
            {/*{part.factor && part.factor.source}*/}
            {/*</Table.Cell>*/}
            {/*</Table.Row>)}*/}
            {/*</Table.Body>*/}
            {/*</Table>}*/}


            <br style={{clear: "both"}}/>

        </div>;
    }
}