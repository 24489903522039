import React, {Component} from 'react';
import {Button, Card, Form, Grid, Icon, Input, Label, Loader, Popup, Table} from "semantic-ui-react";
import * as RouteActions from "../actions/RouteActions";
import Autocomplete from '../components/AutocompletePlaceInput.js';
import {connect} from "react-redux";
import {
    extendedEmissionFactor,
    MODES
} from "../constants";
import {filterAirport, sortAirports} from "../homepage/CO2Compensation";

class CO2FlightCalculator extends Component {

    // Mode --> country --> provider --> type
    state = {
        calculated: false,
        total: 0,
        parts: [],

        working: false,
        from: "AMS",
        to: "LAX",
        departingDate: "5-8-2018",
        departing: "10:00",
        status: "",
        bounds: null,
        refs: {},
        map: null,
        mapsRendered: {},
        mainMapRendered: false,
        result: null,
        lines: [],

        flights: [],


        airportsfrom: [],
        airportsto: [],

        fieldfrom: "",
        fieldto: "",

        airportfrom: null,
        airportto: null,
    };

    fieldTo = null;
    fieldVia = null;
    fieldFrom = null;

    handleChangeForNameField(field, value) {

        this.setState({
            ["airports" + field]: this.airportsArray.filter(airport => filterAirport(airport, value)).sort((a, b) => sortAirports(a, b, value)).slice(0, 5),
            ["field" + field]: value,
        })
    }

    loadAirports() {

        if (!this.airportsArray || this.airportsArray.length === 0) {

            import('../iata.json').then(airports => {

                this.airports = airports;
                this.airportsArray = Object.keys(airports).map(id => ({
                    place_id: id,
                    name: airports[id].name,
                    city: airports[id].city,
                    size: airports[id].size,
                    country: airports[id].country,
                    structured_formatting: {
                        main_text: id + " " + airports[id].name
                    }
                })).filter(airport => airport && airport.name);

                this.setState({
                    airportsfrom: this.airportsArray.filter(a =>
                        a.place_id === "AMS" ||
                        a.place_id === "EIN" ||
                        a.place_id === "RTM" ||
                        a.place_id === "MST" ||
                        a.place_id === "GRQ"
                    )
                })

            });
        }

    }


    selectPlaceID(field, value) {
        let airport = this.airports[value];
        airport.place_id = value;
        this.setState({
            ["field" + field]: this.airports[value].name,
            ["airport" + field]: airport,
        }, () => {
        });

        if (field === "from") {
            this.fieldTo.focus()
        }
    }

    editField(label, e) {
        let value = e.target.value;
        this.setState({
            [label]: value
        })
    }

    go() {
        console.log(this.state);
        this.props.fetchFlights({
            origin: this.state.airportfrom.place_id,
            destination: this.state.airportto.place_id,
        })
    }

    componentDidMount() {
        this.loadAirports();
    }

    componentWillReceiveProps(newProps) {
        const oldFlights = newProps.flights;

        if (oldFlights) {

            let flights = oldFlights.map(flight => {
                console.log(flight);

                let co2 = 0;
                let co2Details = [];

                for (let i = 0; i < flight.distances.length; i++) {
                    let distance = flight.distances[i];
                    let aircraft = flight.aircraft[i];

                    let country = null;
                    let provider = flight.airlines[0];

                    let factor = extendedEmissionFactor(MODES.FLYING, country, provider, aircraft, distance);

                    co2 += distance * factor.amount / 1000;
                    co2Details.push({
                        factor,
                        distance,
                        co2: distance * factor.amount / 1000,
                    })
                }

                flight.co2 = co2;
                flight.co2Details = co2Details;
                return flight;
            });

            this.setState({flights});
        }
    }


    render() {

        const {from, to, flights} = this.state;
        const {fetchingFlights} = this.props;

        return <div className="co2-flight-calculator-container">

            <Form>
                <div className="content-flight">
                    <div className="ui input field-holders-container">
                        <div className="field-holder autocomplete-holder">
                            <Autocomplete
                                items={this.state.airportsfrom}
                                name="donationFrom"
                                value={this.state.fieldfrom}
                                inputProps={{
                                    placeholder: "Enter the name of an airport",
                                    className: "donation-airport"
                                }}
                                onChange={(e) => this.handleChangeForNameField("from", e.target.value)}
                                onSelect={(value) => this.selectPlaceID("from", value)}
                                selectOnBlur={true}
                                hideGoogle
                            />
                        </div>
                        {/*<Input placeholder="From" value={from}*/}
                        {/*onChange={(e) => this.editField("from", e)}/> */}
                        &#8594; {" "}

                        {/*<Icon name="long arrow right" className="flights-arrow"/>*/}
                        <div className="field-holder autocomplete-holder">
                            <Autocomplete
                                items={this.state.airportsto}
                                inputRef={el => this.fieldTo = el}
                                name="donationTo"
                                value={this.state.fieldto}
                                inputProps={{
                                    placeholder: "Enter the name of an airport",
                                    className: "donation-airport donation-airport-to"
                                }}
                                onChange={(e) => this.handleChangeForNameField("to", e.target.value)}
                                onSelect={(value) => this.selectPlaceID("to", value)}
                                selectOnBlur={true}
                                hideGoogle
                            />
                        </div>
                        {' '}
                        <Button onClick={(e) => this.go()} loading={fetchingFlights}>Search</Button>
                    </div>
                </div>
                {/*<Input placeholder="To" value={to}*/}
                {/*onChange={(e) => this.editField("to", e)}/>{" "}*/}

                {/*<Input placeholder="d-m-Y" value={departingDate}*/}
                {/*onChange={(e) => this.editField("departingDate", e)}/>*/}
                {/*<Input placeholder="HH:mm" value={departing}*/}
                {/*onChange={(e) => this.editField("departing", e)}/> <br/>*/}
            </Form>

            {flights && flights.length > 0 && <Table compact striped>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>Departure</Table.HeaderCell>
                        {/*<Table.HeaderCell>Arrival</Table.HeaderCell>*/}
                        <Table.HeaderCell>Transfers</Table.HeaderCell>
                        <Table.HeaderCell>Flight Numbers</Table.HeaderCell>
                        <Table.HeaderCell>Aircraft</Table.HeaderCell>
                        <Table.HeaderCell>Distances</Table.HeaderCell>
                        <Table.HeaderCell>CO<sub>2</sub></Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {flights.map((flight, i) => <Table.Row key={"flight" + i}>
                        <Table.Cell>{flight.departure_time.date} {flight.departure_time.time}</Table.Cell>
                        {/*<Table.Cell>{flight.arrival_time.time}</Table.Cell>*/}
                        <Table.Cell>{flight.via ? "Via " + flight.via : "Direct"}</Table.Cell>
                        {/*<Table.Cell>{flight.airlines.join(", ")}</Table.Cell>*/}
                        <Table.Cell>{flight.idents.join(", ")}</Table.Cell>
                        <Table.Cell>{flight.aircraft.join(", ")}</Table.Cell>
                        <Table.Cell>{flight.distances.join("km , ")} km</Table.Cell>
                        <Table.Cell>
                            <span
                                style={{color: "#747474", float: "right"}}>({(flight.co2Details.map(d => d.distance + "*" + (d.factor.amount / 1000)).join(" + "))})</span>
                            <Button size="small" onClick={() => this.props.showCO2(Math.round(flight.co2 * 10) / 10)}>
                                {Math.round(flight.co2 * 10) / 10} kg
                            </Button>
                        </Table.Cell>
                    </Table.Row>)}
                </Table.Body>

            </Table>}

            <br style={{clear: "both"}}/>

        </div>;
    }
}

export default connect(state => ({
    flights: state.routes.flights,
    fetchingFlights: state.routes.fetchingFlights,
    // fetching: state.interactiveMap.fetchingRoutes,
}), dispatch => ({
    fetchFlights: (data) => dispatch(RouteActions.fetchFlights(data)),
}))(CO2FlightCalculator)