import React, {Component} from 'react';
import {Container, Grid} from "semantic-ui-react";
import {Elements, StripeProvider} from "react-stripe-elements";
import CheckoutForm from "../components/CheckoutForm";
import CheckoutResult from "../components/CheckoutResult";


export default class CheckoutPage extends Component {

    constructor(props) {
        super(props);

        let resultId = null;
        let co2 = null;
        let donation = null;
        if (props && props.match && props.match.params) {
            resultId = props.match.params.result;
            co2 = props.match.params.co2;
            donation = props.match.params.donation;
        }

        this.state = {
            stripe: null,
            resultId, co2, donation,
        };

    }

    componentDidMount() {
        if (window.Stripe) {
            this.setState({stripe: window.Stripe('pk_live_kWczCayw1KnYLwn21g5Hk9sb')});
        } else {
            document.querySelector('#stripe-js').addEventListener('load', () => {
                // Create Stripe instance once Stripe.js loads
                this.setState({stripe: window.Stripe('pk_live_kWczCayw1KnYLwn21g5Hk9sb')});
            });
        }
    }

    render() {

        // this.state.stripe will either be null or a Stripe instance
        // depending on whether Stripe.js has loaded.
        return (
            <div className="page-container checkout-holder">
                <Container>
                    <Grid stackable>
                        <Grid.Row centered>
                            <Grid.Column width={12}>
                                <StripeProvider stripe={this.state.stripe}>
                                    <Elements>
                                        {this.state.resultId ?
                                            <CheckoutResult resultId={this.state.resultId} />
                                            :
                                            <CheckoutForm co2={this.state.co2} donation={this.state.donation} />
                                        }
                                    </Elements>
                                </StripeProvider>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Container>
            </div>
        );
    }

}

