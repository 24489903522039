import React, {Component} from 'react';
import {
    extendedEmissionFactorSources,
    extendedEmissionFactorModes,
    extendedEmissionFactors,
    modeDescriptions, MODES,
} from "../constants";
import {Table} from "semantic-ui-react";
import ModeIcon from "./ModeIcon";

export default class CO2EmissionFactors extends Component {

    render() {

        return <div>

            <Table compact striped>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>Mode</Table.HeaderCell>
                        <Table.HeaderCell>Country</Table.HeaderCell>
                        <Table.HeaderCell>Provider / Type</Table.HeaderCell>
                        <Table.HeaderCell>Description</Table.HeaderCell>
                        <Table.HeaderCell>Source</Table.HeaderCell>
                        <Table.HeaderCell textAlign="right">CO<sub>2</sub>-eq per passenger km</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {Object.values(extendedEmissionFactors).map((factor, i) => {
                        if (factor.source.indexOf("recsZaJ1SBhrpWBmz") !== -1)return null; //Skip Atmosfair for now
                        let mode = factor.mode && extendedEmissionFactorModes[factor.mode] || MODES.UNKNOWN;
                        return <Table.Row key={"row" + i}>
                            <Table.Cell>
                                <ModeIcon mode={mode}/> {modeDescriptions[mode]}
                            </Table.Cell>
                            <Table.Cell>{factor.country.join(", ")}</Table.Cell>
                            <Table.Cell>{factor.type.join(", ")} {factor.provider.join(", ")}</Table.Cell>
                            <Table.Cell>{factor.description}</Table.Cell>
                            <Table.Cell>{factor.source.map((source) => extendedEmissionFactorSources[source] || null).map(
                                (source, i) => <a key={source.name} href={source.url} target="_blank"
                                                  rel="noopener noreferrer">
                                    {source.name}
                                </a>)}</Table.Cell>
                            <Table.Cell textAlign="right">{factor.amount} g</Table.Cell>
                        </Table.Row>
                    })}
                </Table.Body>
            </Table>

        </div>;
    }
}