import {createStore, applyMiddleware} from 'redux';

// import promiseMiddleware from 'redux-promise-middleware';
import thunkMiddleware from 'redux-thunk'
import { createLogger } from 'redux-logger'
import createSocketIoMiddleware from 'redux-socket.io';
// import googleAnalytics from './utilities/google-analytics-middleware'

import reducer from './reducers/index';
import io from 'socket.io-client';
import * as UserActions from "./actions/UserActions";
import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';

// Initialize Firebase
const config = {
    apiKey: "AIzaSyCeb48pz-vT-UF_BTd0qnd4b69EfIP_Q80",
    authDomain: "green-tickets-1513950874098.firebaseapp.com",
    databaseURL: "https://green-tickets-1513950874098.firebaseio.com",
    projectId: "green-tickets-1513950874098",
    storageBucket: "green-tickets-1513950874098.appspot.com",
    messagingSenderId: "415231493143"
};
firebase.initializeApp(config);

let socket = io('https://api.getgreentickets.com');

if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
    socket = io('http://localhost:8888');
}

const middlewares = [
    thunkMiddleware,
    createSocketIoMiddleware(socket, "api/"),
];
if (process.env.NODE_ENV === `development`) {
    middlewares.push(createLogger({
        collapsed: (getState, action, logEntry) => !logEntry.error
    }));
}

const middleware = applyMiddleware(
    ...middlewares,
    // promiseMiddleware(),
    // googleAnalytics()
);

const store = createStore(reducer, middleware);

store.dispatch(UserActions.verifyAuth());

export default store;