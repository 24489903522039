import update from 'immutability-helper';

const initialState = {
    items: [],
    tags: [],
    fetched: false,
    fetching: false,
    saving: false,
    saved: false,
};

Array.prototype.unique = function() {
    return this.filter(function (value, index, self) {
        return self.indexOf(value) === index;
    });
};

export default (state = initialState, action)  => {

    switch (action.type){
        case "FETCH_BLOG_ITEMS":
            return update(state, {
                fetched: {$set: false},
                fetching: {$set: true}
            });
        case "SAVE_BLOG":
            return update(state, {
                saving: {$set: true},
            });
        case "SAVED_BLOG":
            return update(state, {
                saving: {$set: false},
                saved: {$set: true},
            });
        case "FETCHED_BLOG_ITEMS":

            return update(state, {
                fetched: {$set: true},
                fetching: {$set: false},
                items: {$set: action.payload},
            });
        default:
            return state;
    }
}