import React, {Component} from 'react';
import firebase from 'firebase/app';
import 'firebase/firestore';
import * as msgpack from "msgpack-lite";
import PlannerInput from '../components/plannerInput';
import moment from "moment";
import * as RouteActions from "../actions/RouteActions";
import update from 'immutability-helper';
import {connect} from "react-redux";

class RouteForward extends Component {


    constructor(props) {
        super(props);

        let routeId = props.match.params.routeId;

        firebase.firestore().collection("routes").doc(routeId).get().then(ref => {
            let doc = ref.data();
            if(!doc){
                console.error("Route not found");
                return;
            }

            let route = msgpack.decode(doc.route.toUint8Array());

            //Fix moment objects
            route = update(route, {
                "departureDate": value => (value._isAMomentObject) ? moment(value._i) : value,
                "arrivalDate": value => (value._isAMomentObject) ? moment(value._i) : value,
                "parts": value => value.map(part => update(part, {
                    "departureDate": value => (value._isAMomentObject) ? moment(value._i) : value,
                    "arrivalDate": value => (value._isAMomentObject) ? moment(value._i) : value,
                }))
            });
            this.props.setActiveRoute(route);

            let query = msgpack.decode(doc.query.toUint8Array());
            query.date = moment(query.date);
            let url = PlannerInput.formToURLString(query);
            if (url) {
                this.props.history.replace(url);
            }

        })

    }

    render() {
        return (
            <div>Loading...</div>
        );
    }
}

export default connect(state => {
}, dispatch => ({
    setActiveRoute: (i) => dispatch(RouteActions.setActive(i)),
}))(RouteForward);