import React, {Component} from 'react';
import {Button, Divider, Form, Grid, Header, Icon, Message, Modal} from "semantic-ui-react";
import {connect} from "react-redux";
import firebase from "./ContactForm";
import * as UserActions from "../actions/UserActions";
import {withRouter} from "react-router-dom";

class SignInModal extends Component {

    state = {
        email: '',
        password: '',
        password2: '',
        name: '',
        signUpOpen: false,
    };

    handleChange = (e, {name, value}) => this.setState({[name]: value});

    handleSubmit = () => {

        const {email, password} = this.state;
        this.props.doSignIn(email, password);
        this.setState({
            password: '',
        });

    };
    handleSignUpSubmit = () => {

        const {doSignUp} = this.props;
        const {email, password2, password, name} = this.state;
        doSignUp(email, password, password2, name);
        this.setState({
            password: '',
            password2: '',
        });

    };

    signUp = () => {

        const {clearError} = this.props;
        clearError();

        this.setState({
            signUpOpen: true,
            password: '',
            password2: '',
        });

    };

    render() {


        let {showSignInModal, loading, closeModal, error, doSignInFB, location, authenticated} = this.props;
        const {name, email, password2, password, signUpOpen} = this.state;

        if (location.pathname === '/sign-in' && !authenticated)showSignInModal = true;

        return [<Modal open={showSignInModal && !signUpOpen} onClose={closeModal} closeIcon key="signInModal">
            <Modal.Header>Sign In</Modal.Header>
            <Modal.Content>

                <Grid divided>
                    <Grid.Row columns={2}>
                        <Grid.Column>

                            {error && <Message
                                negative
                                header='Error'
                                content={error}
                            />}

                            <Form onSubmit={this.handleSubmit} loading={loading}>

                                <Form.Input fluid label='E-mail' name="email" value={email} onChange={this.handleChange}/>
                                <Form.Input fluid label='Password' type="password" name="password" value={password}
                                            onChange={this.handleChange}/>
                                <Form.Button type='submit' primary content="Sign In"
                                             style={{float: "right"}}
                                        disabled={!email || !password}
                                        onClick={this.handleSubmit}
                                        loading={loading}
                                />

                            </Form>

                        </Grid.Column>
                        <Grid.Column>
                            <Button color='facebook' onClick={doSignInFB}>
                                <Icon name='facebook'/> Sign in with Facebook
                            </Button>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>

                <Divider />
                <Header as="h3">Don't have an account yet?</Header>

                <Button content="Create an account"
                        onClick={this.signUp} />

            </Modal.Content>
            <Modal.Actions>
                <Button onClick={closeModal}>
                    Cancel
                </Button>
            </Modal.Actions>
        </Modal>,
            <Modal open={showSignInModal && signUpOpen} onClose={() => {this.setState({signUpOpen: false,});closeModal();}} closeIcon key="signUpModal">
            <Modal.Header>Sign Up</Modal.Header>
            <Modal.Content>

                <Grid divided>
                    <Grid.Row columns={2}>
                        <Grid.Column>

                            {error && <Message
                                negative
                                header='Error'
                                content={error}
                            />}

                            <Form onSubmit={this.handleSignUpSubmit} loading={loading}>

                                <Form.Input fluid label='E-mail' name="email" value={email} onChange={this.handleChange}/>
                                <Form.Input fluid label='Password' type="password" name="password" value={password}
                                            onChange={this.handleChange}/>
                                <Form.Input fluid label='Repeat password' type="password" name="password2" value={password2}
                                            onChange={this.handleChange}/>
                                <Form.Input fluid label='Display Name' type="name" name="name" value={name}
                                            onChange={this.handleChange}/>
                                <Form.Button type='submit' primary content="Sign In"
                                        disabled={!email || !password || !password2}
                                        onClick={this.handleSignUpSubmit}
                                        loading={loading}
                                />
                            </Form>

                        </Grid.Column>
                        <Grid.Column>
                            <Button color='facebook' onClick={doSignInFB}>
                                <Icon name='facebook'/> Sign in with Facebook
                            </Button>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>

                {/*<Divider />*/}
                {/*<Header as="h3">Don't have an account yet?</Header>*/}

            </Modal.Content>
            <Modal.Actions>
                <Button color='black' onClick={() => this.setState({signUpOpen: false,})}>
                    Cancel
                </Button>
            </Modal.Actions>
        </Modal>];
    }
}

export default withRouter(connect((state) => ({
    authenticated: state.user.authenticated,
    showSignInModal: state.user.showSignInModal,
    loading: state.user.loading,
    error: state.user.error,
}), (dispatch) => ({
    doSignIn: (email, password) => dispatch(UserActions.doSignIn(email, password)),
    doSignUp: (email, password, password2, name) => dispatch(UserActions.doSignUp(email, password, password2, name)),
    doSignInFB: () => dispatch(UserActions.doSignInFB()),
    closeModal: () => dispatch(UserActions.closeSignInModal()),
    clearError: () => dispatch(UserActions.clearError()),
}))(SignInModal));
