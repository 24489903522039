import React, {Component} from 'react';
import {injectStripe} from "react-stripe-elements";
import {NavLink} from 'react-router-dom';
import firebase from 'firebase/app';
import 'firebase/firestore';
import {Button, Card, Loader} from "semantic-ui-react";

class CheckoutResult extends Component {

    constructor(props) {
        super(props);
        this.state = {
            resultId: props.resultId,
            completed: null,
            success: null,
            canceled: false,
        };
    }

    componentDidMount() {
        let _this = this;
        firebase.firestore().collection("checkouts").doc(this.state.resultId).collection("payment").doc("status").onSnapshot(doc => {
            let info = doc.data();
            if(info) {
                _this.setState({
                    completed: info.completed,
                    success: info.success,
                    canceled: info.canceled,
                });
            }
        });
    }

    render() {
        let {completed, success, canceled} = this.state;
        return <Card className="checkout-form" fluid>
            <div style={{textAlign: "center", minHeight: "300px", padding: "20px"}}>
            <Loader active={!completed} inline size="small" /><br /><br />

            {completed !== null && (
                completed === true ? (
                    canceled ? <div>
                            <h2>Payment canceled</h2><br />
                            The payment has been canceled.<br /><br /><br />
                            <Button as={NavLink} to="/">Go Back</Button>
                        </div> :
                        (success ? <div>
                            <h2>Payment successfully completed!</h2><br />
                        Thank you for your payment. You will receive a confirmation e-mail shortly.<br /><br /><br />
                            <Button as={NavLink} to="/">Go Back</Button>
                    </div>
                        : <div>
                            <h2>Something went wrong...</h2><br />
                            Unfortunately something went wrong, please contact us at <a href="mailto:info@greentickets.app">info@greentickets.app</a>.
                    </div>
                )) : "Processing payment..."
            )}
        </div>
        </Card>;
    }

}

export default injectStripe(CheckoutResult);