import firebase from 'firebase/app';
import 'firebase/firestore';

export const fetchInteractiveMapItems = () => {
    return (dispatch) => {
        dispatch({type: "FETCH_INTERACTIVE_MAP_ITEMS"});

        if (!firebase || !firebase.firestore) return;
        firebase.firestore().collection("interactive-map")
        // .orderBy("order")
            .onSnapshot({
                includeMetadataChanges: false
            }, snapshot => {
                let items = [];
                snapshot.forEach(doc => {
                    const item = doc.data();
                    item.id = doc.id;
                    items.push(item);

                });

                dispatch({type: "FETCHED_INTERACTIVE_MAP_ITEMS", payload: items})
            })
    };
};
export const fetchInteractiveMapRoutes = () => {
    return (dispatch) => {
        dispatch({type: "FETCH_INTERACTIVE_MAP_ROUTES"});

        // fetch("http://localhost:4000/lines").then(async response => {
        //
        //     let json = await response.json();
        //     console.log(json);
        //
        //     dispatch({
        //         type: "FETCHED_INTERACTIVE_MAP_ROUTES", payload: {
        //             lines: json
        //         }
        //     });
        //
        // })

        if (!firebase || !firebase.firestore) return;
        firebase.firestore().collection("interactive-map").doc("routes").collection("trains")
        .orderBy("from")
            .onSnapshot({
                includeMetadataChanges: false
            }, snapshot => {
                let items = [];
                snapshot.forEach(doc => {
                    const item = doc.data();
                    item.id = doc.id;
                    items.push(item);
                });
                dispatch({
                    type: "FETCHED_INTERACTIVE_MAP_ROUTES", payload: {
                        trains: items
                    }
                })
            })
    };
};
export const fetchInteractiveMapRoute = (routeId) => {
    return (dispatch) => {
        dispatch({type: "FETCH_INTERACTIVE_MAP_ROUTES"});

        if (!firebase || !firebase.firestore) return;
        firebase.firestore().collection("interactive-map").doc("routes").collection("trains")
        .doc(routeId)
            .onSnapshot( snapshot => {
                let items = [];
                const item = snapshot.data();
                item.id = snapshot.id;
                items.push(item);
                dispatch({
                    type: "FETCHED_INTERACTIVE_MAP_ROUTES", payload: {
                        trains: items,
                        activeRoute: items[0],
                    }
                })
            })
    };
};
export const fetchInteractiveMapDestinations = (originId) => {
    return (dispatch) => {
        dispatch({type: "FETCH_INTERACTIVE_MAP_DESTINATIONS"});

        if (!firebase || !firebase.firestore) return;
        firebase.firestore().collection("interactive-map").doc(originId).collection("destinations")
        // .orderBy("order")
            .onSnapshot({
                includeMetadataChanges: false
            }, snapshot => {
                let items = [];
                snapshot.forEach(doc => {
                    const item = doc.data();
                    item.id = doc.id;
                    items.push(item);

                });

                dispatch({type: "FETCHED_INTERACTIVE_MAP_DESTINATIONS", payload: items})
            })
    };
};