import React, {Component} from 'react'
import {Button, Container, Grid, Input, Modal, Popup} from 'semantic-ui-react'
import {MODES} from "../constants";
import {connect} from "react-redux";
import * as RouteActions from "../actions/RouteActions";
import * as UserActions from "../actions/UserActions";
import * as utils from "../utils";
import firebase from 'firebase/app';
import 'firebase/firestore';
import msgpack from 'msgpack-lite';
import update from 'immutability-helper';
import * as ReactGA from "react-ga";
import * as Bufferish from "msgpack-lite/lib/bufferish-uint8array";
import RouteDetailsCloseControl from "./route-details/RouteDetailsCloseControl";
import RouteDetailsRouteInfoLeg from "./route-details/RouteDetailsRouteInfoLeg";
import * as formatters from "../formatters";
import {NavLink} from "react-router-dom";


class RouteInfoModal extends Component {

    constructor(props) {
        super(props);
        this.state = {
            activePart: null,
            feedback: null,
            feedbackSaved: false,
            feedbackRef: null,
            feedbackText: "",
            loadingShareUrl: false,
            url: null,
            urlCopied: false,
            urlPopupOpen: false,
            saving: false,
        };
        this.focus = this.focus.bind(this);
        this.zoomOut = this.zoomOut.bind(this);
        this.feedback = this.feedback.bind(this);
        this.feedbackText = this.feedbackText.bind(this);
        this.handleKeyDown = this.handleKeyDown.bind(this);
        this.share = this.share.bind(this);
        this.save = this.save.bind(this);
        this.bookingURLClicked = this.bookingURLClicked.bind(this);
        this.copyToClipboard = this.copyToClipboard.bind(this);

    }

    firestoreRoute(reason, callback, value) {

        let reasonObject = {};
        if (reason === "share") {
            reasonObject["shared"] = true;
        } else if (reason === "feedback") {
            reasonObject["feedback"] = value;
        } else if (reason === "booking") {
            reasonObject["booked"] = true;
            reasonObject["price"] = value;
        }

        let _this = this;
        if (_this.props.route.refPath) {
            //Only update
            let ref = firebase.firestore().doc(_this.props.route.refPath);
            callback(ref);
            ref.update(reasonObject).then(() => {
            }).catch(error => {
                console.error(error);
            })
        } else {
            //Create
            //Remove Google Maps objects in 'lines'
            let route = update(this.props.route, {
                "parts": {
                    $apply: parts =>
                        parts.map(part => update(part, {$unset: ["lines"]}))
                }
            });

            let routeData = Object.assign({
                createdAt: new Date(),
                routeId: route.id,
                requestId: this.props.requestId,
                query: firebase.firestore.Blob.fromUint8Array(Bufferish.from(msgpack.encode(this.props.query))),
                route: firebase.firestore.Blob.fromUint8Array(Bufferish.from(msgpack.encode(route))),
                from: this.props.query.from.name || "",
                to: this.props.query.to.name || "",
                date: (this.props.query.date && this.props.query.date.toDate()) || "",
                action: reason,
            }, reasonObject);

            firebase.firestore().collection("routes").add(routeData).then((ref) => {
                _this.props.route.refPath = ref.path;
                callback(ref);
            }).catch(error => {
                console.error(error);
            })

        }
    }

    share(e) {
        e.stopPropagation();
        if (this.state.url) {
            this.setState({
                urlPopupOpen: true,
                loadingShareUrl: false,
                urlCopied: false,
            });
            return;
        }
        ReactGA.event({
            category: 'route',
            action: 'share'
        });
        this.setState({
            loadingShareUrl: true
        });
        let _this = this;
        this.firestoreRoute("share", (ref) => {
            let url = window.location.protocol + "//" + window.location.hostname + (window.location.port ? ":" + window.location.port : "") + "/route/" + ref.id;
            _this.setState({
                url,
                loadingShareUrl: false,
                urlPopupOpen: true,
            });
            _this.copyToClipboard(url);
        });
    }

    save(e) {
        e.stopPropagation();
        if (this.state.url) {
            this.setState({
                saving: false,
            });
            return;
        }
        ReactGA.event({
            category: 'route',
            action: 'save'
        });
        this.setState({
            saving: true
        });
        let _this = this;
        this.firestoreRoute("save", (ref) => {
            let url = "/route/" + ref.id;

            _this.setState({
                saving: false,
            });
            _this.props.saveRoute({
                url,
                ref: "routes/" + ref.id,
                createdAt: new Date(),
                routeId: this.props.route.id,
                duration: this.props.route.duration,
                mainMode: this.props.route.mainMode,
                requestId: this.props.requestId,
                from: this.props.query.from.name || "",
                to: this.props.query.to.name || "",
                date: (this.props.query.date && this.props.query.date.toDate()) || "",
            });
        });
    }

    copyToClipboard(text) {
        let textField = document.createElement('textarea');
        textField.innerText = text;
        document.body.appendChild(textField);
        textField.select();
        let successful = document.execCommand('copy');
        textField.remove();
        if (successful) {
            this.setState({
                copiedUrl: true
            });
        }
    }

    bookingURLClicked(leg) {
        ReactGA.event({
            category: 'booking',
            action: 'click',
            value: leg.price,
        });
        this.firestoreRoute("booking", () => {
        }, leg.price);
    }

    handleKeyDown(event) {
        if (event.keyCode === 39 || event.keyCode === 40) {
            //Next
            this.props.setNextRouteActive();
            ReactGA.event({
                category: 'route',
                action: 'iterate',
                label: "next"
            });
        } else if (event.keyCode === 37 || event.keyCode === 38) {
            //Previous
            this.props.setPreviousRouteActive();
            ReactGA.event({
                category: 'route',
                action: 'iterate',
                label: "previous"
            });
        }

    }

    componentWillMount() {
        document.addEventListener("keydown", this.handleKeyDown);
        ReactGA.modalview('route-info');
    }

    componentWillUnmount() {
        document.removeEventListener("keydown", this.handleKeyDown);
    }

    componentWillReceiveProps(newProps) {
        this.mapElement = null;
        this.setState({
            activePart: null,
            feedback: null,
            feedbackRef: null,
            feedbackSaved: false,
            feedbackText: "",
            loadingShareUrl: false,
            url: null,
            urlCopied: false,
            urlPopupOpen: false,
        });
    }

    feedback(positive) {
        let feedbackLabel = positive ? "positive" : "negative";
        ReactGA.event({
            category: 'route',
            action: 'feedback',
            label: feedbackLabel
        });
        let info = {
            type: "route",
            routeId: this.props.route.id,
            positive,
        };
        if (this.state.feedbackRef) {
            this.state.feedbackRef.update(info);
        } else {
            this.firestoreRoute("feedback", (ref) => {
                info.route = ref;
                firebase.firestore().collection("feedback").add(info).then((ref) => {
                    this.setState({feedbackRef: ref});
                });
            }, feedbackLabel);
        }
        this.setState({feedback: positive});
    }

    feedbackText(e) {
        this.state.feedbackRef.update({
            text: this.state.feedbackText
        });
        this.setState({feedbackSaved: true});
    }

    setMapElement(el) {
        if (this.mapElement) return;
        this.mapElement = el;
        if (this.props.route && this.mapElement && window.google) {
            // if(!this.map) {
            this.map = new window.google.maps.Map(this.mapElement, {
                zoom: 7,
                // center: {lat: 52.3, lng: 4.76}
                streetViewControl: false,
                fullscreenControl: false,
                mapTypeControlOptions: {
                    position: window.google.maps.ControlPosition.LEFT_BOTTOM
                },
            });

            let closeControlDiv = document.createElement('div');
            new RouteDetailsCloseControl(closeControlDiv, this.props.setActiveRoute);

            closeControlDiv.index = 1;
            this.map.controls[window.google.maps.ControlPosition.TOP_RIGHT].push(closeControlDiv);

            let bounds = new window.google.maps.LatLngBounds();
            for (let part of this.props.route.parts) {
                if ((!part.paths || part.paths.length === 0) && (!part.path || part.path.length === 0) && part.mode !== "FLYING") continue;

                let color = (part.carrier && part.carrier.color) || "#ddd";
                let points = [];

                if (part.mode === "FLYING") {
                    points = [
                        {lat: part.from.lat, lng: part.from.lng},
                        {lat: part.to.lat, lng: part.to.lng}
                    ];
                } else if (part.path) {
                    points = window.google.maps.geometry.encoding.decodePath(part.path);
                } else {
                    points = part.paths.map(p => window.google.maps.geometry.encoding.decodePath(p))
                        .reduce((acc, cur) => {
                            return [...acc, ...cur]
                        }, []);
                }
                for (let point of points) {
                    bounds.extend(point);
                }
                if (part.mode === MODES.ESTIMATION) {
                    part.lines = RouteInfoModal.drawDashedPolylineOnMap(points, this.map, color);
                } else {
                    part.lines = RouteInfoModal.drawPolylineOnMap(points, this.map, color);
                }

            }

            this.map.fitBounds(bounds, 0);
            this.bounds = bounds;
            // this.setState({
            //     bounds
            // });

        }
    }

    static drawPolylineOnMap(points, map, color) {
        let polyline = new window.google.maps.Polyline({
            path: points,
            geodesic: true,
            strokeColor: color,
            strokeOpacity: 1.0,
            strokeWeight: 4,
            zIndex: 2,
        });
        polyline.setMap(map);
        let polyline2 = new window.google.maps.Polyline({
            path: points,
            geodesic: true,
            strokeColor: "#404040",
            strokeOpacity: 1.0,
            strokeWeight: 6,
            zIndex: 1,
        });
        polyline2.setMap(map);
        return {fg: polyline, bg: polyline2}
    }

    static drawDashedPolylineOnMap(points, map, color) {
        let lineSymbol = {
            path: 'M 0,-1 0,1',
            strokeOpacity: 1,
            scale: 4,
            strokeWeight: 4,
        };
        let lineSymbolBg = {
            path: 'M 0,-1 0,1',
            strokeOpacity: 1,
            scale: 4,
            strokeWeight: 6,
        };

        let polyline = new window.google.maps.Polyline({
            path: points,
            geodesic: true,
            strokeColor: color,
            strokeOpacity: 0, //1.0,
            // strokeWeight: 4,
            zIndex: 2,
            icons: [{
                icon: lineSymbol,
                offset: '0',
                repeat: '20px'
            }],
        });
        polyline.setMap(map);
        let polyline2 = new window.google.maps.Polyline({
            path: points,
            geodesic: true,
            strokeColor: "#404040",
            strokeOpacity: 0,//1.0,
            // strokeWeight: 6,
            zIndex: 1,
            icons: [{
                icon: lineSymbolBg,
                offset: '0',
                repeat: '20px'
            }],
        });
        polyline2.setMap(map);
        return {fg: polyline, bg: polyline2}
    }

    static highlight(part) {
        ReactGA.event({
            category: 'route',
            action: 'highlight-part',
        });
        part && part.lines && part.lines.bg && part.lines.bg.setOptions({strokeWeight: 8});
    }

    static deHighlight(part) {
        ReactGA.event({
            category: 'route',
            action: 'dehighlight-part',
        });
        part && part.lines && part.lines.bg && part.lines.bg.setOptions({strokeWeight: 6});
    }

    zoomOut() {
        this.map.fitBounds(this.bounds, 0);
        this.setState({
            activePart: null,
            urlPopupOpen: false,
        });
    }

    focus(e, part) {
        e.preventDefault();
        e.stopPropagation();
        if (!part.from || !part.to || part.mode === "WAITING") return;
        let bounds = new window.google.maps.LatLngBounds();
        let points = [
            {lat: part.from.lat, lng: part.from.lng},
            {lat: part.to.lat, lng: part.to.lng}
        ];
        for (let point of points) {
            bounds.extend(point);
        }
        this.map.fitBounds(bounds, 0);
        this.setState({activePart: part.id})
    }

    render() {
        let {route, partsById, savedRoute} = this.props;
        let {url} = this.state;

        let savingRoute = this.props.savingRoute || this.state.saving;

        let shareButton = !this.state.loadingShareUrl ?
            <a onClick={this.share} className="share">
                <i className="fal fa-share-alt"/>
                <div>SHARE</div>
            </a> :
            <i className="spinner fas fa-circle-notch fa-spin"
               style={{float: "right"}}/>;

        const SaveButton = () => !savingRoute ?
            <a onClick={this.save} className="share" style={{marginRight: '8px'}}>
                <i className="fal fa-save"/>
                <div>SAVE</div>
            </a> :
            <i className="spinner fas fa-circle-notch fa-spin"
               style={{float: "right", marginRight: '8px'}}/>;

        return (
            route ? (
                <Modal open={true} size="large" className="route-info-modal-container" closeIcon={false}
                       onClose={() => this.props.setActiveRoute(null)}>
                    <Container>
                        <Grid stackable>
                            <Grid.Row className="">
                                <Grid.Column className="route-info-modal" width={6} onClick={(e) => this.zoomOut()}>
                                    <h1>
                                        Route Details

                                        <Popup
                                            trigger={shareButton}
                                            open={this.state.urlPopupOpen}
                                            position='bottom right'
                                            flowing
                                            inverted
                                        >
                                            <a onClick={(e) => this.copyToClipboard(url)} className={"pointer"}
                                               target="_blank">
                                                <i className={"fas fa-copy"}/>{" "}
                                                {url}
                                            </a><br/>
                                            {this.state.copiedUrl &&
                                            <em>The above link has been copied to your clipboard!</em>}
                                        </Popup>

                                        <SaveButton/>

                                    </h1>
                                    <Modal.Content scrolling>
                                        {/*<Modal.Content className="padding info" style={{paddingTop: 0}}>*/}
                                        <div className="info main-route-info-container">
                                            <div className="padding">
                                                <div className="main-route-info">
                                                    <strong>{route.departureDate && route.departureDate.format("dddd D MMM YYYY")}</strong>
                                                    {" "}
                                                    {route.notBoundToTime ? <span style={{float: "right"}}>
                                                        {formatters.minutesToTimeDurationString(route.duration / 60)}
                                                    </span> :
                                                        <span style={{float: "right"}}>
                                                        {route.departureDate && route.departureDate.format("H:mm")}
                                                            &mdash;
                                                            {route.arrivalDate && route.arrivalDate.format("H:mm")}
                                                    </span>}
                                                </div>
                                                <br style={{clear: "both"}}/>

                                                <Popup
                                                    trigger={<div
                                                        className={"block price" + (!route.priceIsComplete ? " incomplete" : "")}>
                                                        {
                                                            route.priceIsAcceptable ?
                                                                utils.formatPriceAsString(route.price)
                                                                :
                                                                <i className={"fal fa-question"}/>
                                                        }
                                                    </div>}
                                                    content={
                                                        !route.priceIsAcceptable ? "Unfortunately, we were unable to find the total price for this route" :
                                                            !route.priceIsComplete ? "Some short parts of this route are not included in the total price" : ""
                                                    }
                                                    position="bottom center"
                                                    inverted
                                                    on={(!route.priceIsComplete || !route.priceIsAcceptable) ? "hover" : null}
                                                />

                                                {route.co2 && (
                                                    <div className="block"
                                                         style={{backgroundColor: utils.climateImpactScale(route.co2Factor)}}>
                                                        {utils.formatCo2(route.co2)}
                                                        {" "} kg CO<sub>2</sub>
                                                    </div>
                                                )}
                                                <br style={{clear: "both"}}/>
                                                <p>{route.description}</p>
                                            </div>
                                        </div>
                                        {/*</Modal.Content>*/}
                                        {/*<Modal.Content className="padding">*/}
                                        {/*{route.parts.map((part, i) => <RouteInfoPart key={i} {...part}*/}
                                        {/*/>)}*/}
                                        {route.legs && route.legs.map((leg, i) => <RouteDetailsRouteInfoLeg key={i}
                                                                                                            leg={leg}
                                                                                                            partsById={partsById}
                                                                                                            parts={route.parts}
                                                                                                            focus={this.focus}
                                                                                                            book={(part) => this.bookingURLClicked(leg)}
                                                                                                            activePart={this.state.activePart}
                                                                                                            pendingAddTransit={route.pendingAddTransit}
                                            // onMouseEnter={(e) => RouteInfoModal.highlight(part)}
                                            // onMouseLeave={(e) => RouteInfoModal.deHighlight}
                                            // onClick={(e) => this.focus(part)}

                                        />)}
                                        {/*</Modal.Content>*/}
                                        <Modal.Content className="padding">
                                            <div className="route-feedback">
                                                {savedRoute === route.id ?
                                                    <Button as={NavLink} to="/profile" size="tiny">Route
                                                        saved</Button> :
                                                    <Button size="tiny" onClick={this.save} loading={savingRoute}>Add to
                                                        saved routes</Button>}

                                                <h3>Is this a good travel option?</h3>
                                                <i className={"fa-thumbs-up " + (this.state.feedback ? "fas" : "far")}
                                                   onClick={(e) => this.feedback(true)}/>
                                                <i className={"fa-thumbs-down " + (this.state.feedback === false ? "fas" : "far")}
                                                   onClick={(e) => this.feedback(false)}/>
                                                {(this.state.feedback !== null &&
                                                    (!this.state.feedbackSaved ?
                                                            <div>
                                                                <Input placeholder='Why?'
                                                                       onChange={(e) => this.setState({feedbackText: e.target.value})}/>
                                                                <Button
                                                                    onClick={this.feedbackText}>Send</Button>
                                                            </div>
                                                            :
                                                            <div><strong>Thank you for your feedback!</strong></div>
                                                    )
                                                )}
                                            </div>
                                        </Modal.Content>
                                    </Modal.Content>
                                </Grid.Column>
                                <Grid.Column className="" width={10}>
                                    <div id="map" ref={el => this.setMapElement(el)}/>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Container>
                </Modal>
            ) : null
        )
    }
}

export {RouteInfoModal};

export default connect(state => ({
    requestId: state.routes.requestId,
    query: state.routes.query,
    route: state.routes.active,
    partsById: state.routes.active && state.routes.active.parts && state.routes.active.parts.reduce((acc, cur) => {
        acc[cur.id] = cur;
        return acc
    }, {}),
    savingRoute: state.user.savingRoute,
    savedRoute: state.user.savedRoute,
}), dispatch => ({
    setActiveRoute: (i) => dispatch(RouteActions.setActive(i)),
    setNextRouteActive: (i) => dispatch(RouteActions.setNextRouteActive(i)),
    setPreviousRouteActive: (i) => dispatch(RouteActions.setPreviousRouteActive(i)),
    saveRoute: (info) => dispatch(UserActions.saveRoute(info)),
}))(RouteInfoModal);
