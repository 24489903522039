import * as ReactGA from "react-ga";

export const FETCH_ROUTES = "api/route";
export const FETCH_ADD_TRANSIT = "api/add-transit";
export const CANCEL_ROUTES = "api/cancel";
export const FETCH_FLIGHTS = "api/flights";
export const RECEIVED_FLIGHTS = "RECEIVED_FLIGHTS";
export const FLIGHTS_ERROR = "FLIGHTS_ERROR";
export const RECEIVED_ROUTES = "RECEIVED_ROUTES";
export const RECEIVED_REMOVED_ROUTES = "RECEIVED_REMOVED_ROUTES";
export const ADDED_TRANSIT_PART = "ADDED_TRANSIT_PART";
export const SET_ACTIVE_ROUTE = "SET_ACTIVE_ROUTE";
export const SORT = "SORT";
export const FINISHED_RECEIVED_ROUTES = "FINISHED_RECEIVED_ROUTES";
export const SET_FILTERING = "SET_FILTERING";
export const FILTER_MODE = "FILTER_MODE";
export const CHECK_FETCHING_STATUS = "CHECK_FETCHING_STATUS";
export const SHOW_PLANNER_FORM = "SHOW_PLANNER_FORM";
export const SET_DRIVING_TYPE = "SET_DRIVING_TYPE";
export const ADJUST_DRIVING_PAX = "ADJUST_DRIVING_PAX";
export const HIGHLIGHT_MODE = "HIGHLIGHT_MODE";
export const EXPAND_ALL = "EXPAND_ALL";

const uuid = function (a, b) {
    for (b = a = ''; a++ < 36; b += a * 51 & 52 ? (a ^ 15 ? 8 ^ Math.random() * (a ^ 20 ? 16 : 4) : 4).toString(16) : '-') ;
    return b
};

export function fetchRoutes(form) {

    //https://gist.github.com/LeverOne/1308368
    let userId = window.localStorage.getItem("userId");
    if (userId === null) {
        let userId = uuid();
        window.localStorage.setItem("userId", userId);
    }

    return (dispatch, getState) => {

        const state = getState();
        form.drivingType = state.routes.drivingType;

        setTimeout(() => {
            dispatch({
                type: CHECK_FETCHING_STATUS,
                data: form,
            });
        }, 5000);

        dispatch({
            type: FETCH_ROUTES,
            data: form,
            meta: {
                userId
            }
        });

        ReactGA.event({
            category: 'Planner',
            action: 'Search',
        });
        ReactGA.ga('set', 'dimension2', form.from.name);
        ReactGA.ga('set', 'dimension3', form.to.name);
    }
}

export function fetchFlights(data) {
    return {
        type: FETCH_FLIGHTS,
        data
    };
}

export function addTransit(route) {
    return {
        type: FETCH_ADD_TRANSIT,
        payload: route.id
    };
}

export function cancelRoutes() {
    return {
        type: CANCEL_ROUTES,
    }
}

export function adjustDrivingPax(type) {
    return {
        type: ADJUST_DRIVING_PAX,
        payload: type,
    }
}

export function setDrivingType(type) {

    let userId = window.localStorage.getItem("userId");
    if (userId === null) {
        let userId = uuid();
        window.localStorage.setItem("userId", userId);
    }

    return (dispatch, getState) => {
        dispatch({
            type: SET_DRIVING_TYPE,
            payload: type
        });
        const state = getState();
        let form = state.routes.query;
        form.drivingType = type;

        dispatch({
            type: FETCH_ROUTES,
            data: form,
            meta: {
                userId
            }
        });
    }
}

export function showPlannerForm() {
    return (dispatch) => {
        dispatch({
            type: SHOW_PLANNER_FORM,
        });
        dispatch({
            type: CANCEL_ROUTES,
        });
    }
}

export function setActive(routeId) {
    return {
        type: SET_ACTIVE_ROUTE,
        payload: routeId,
    };
}

export function toggleFiltering(shouldFilter) {
    return {
        type: SET_FILTERING,
        payload: shouldFilter,
    };
}

export function updateModeFilters(modes) {
    return {
        type: FILTER_MODE,
        payload: {
            modes,
        },
    };
}
export function highlightMode(mode) {
    return {
        type: HIGHLIGHT_MODE,
        payload: {
            mode,
        },
    };
}
export function expandAll(mode) {
    return {
        type: EXPAND_ALL,
        payload: {
            mode,
        },
    };
}

export function setNextRouteActive() {

    ReactGA.event({
        category: 'Planner',
        action: 'Traverse',
        label: 'Next',
    });

    return (dispatch, getState) => {
        const state = getState();
        if (state.routes.active) {
            let oldRouteId = state.routes.active.id;
            let items = state.routes.items.filter(r => !state.routes.filtering || !r.hidden);
            let key = parseInt(Object.keys(items).filter(function (key) {
                return items[key].id === oldRouteId
            })[0], 10);
            key += 1;
            if (items[key]) {
                let newRouteId = items[key].id;
                dispatch({
                    type: SET_ACTIVE_ROUTE,
                    payload: newRouteId,
                })
            }
        }
    }
}

export function setPreviousRouteActive(routeId) {

    ReactGA.event({
        category: 'Planner',
        action: 'Traverse',
        label: 'Previous',
    });

    return (dispatch, getState) => {
        const state = getState();
        if (state.routes.active) {
            let oldRouteId = state.routes.active.id;
            let items = state.routes.items.filter(r => !state.routes.filtering || !r.hidden);
            let key = parseInt(Object.keys(items).filter(function (key) {
                return items[key].id === oldRouteId
            })[0], 10);
            key -= 1;
            if (items[key]) {
                let newRouteId = items[key].id;
                dispatch({
                    type: SET_ACTIVE_ROUTE,
                    payload: newRouteId,
                })
            }
        }
    }
}

export function sort(on) {

    ReactGA.event({
        category: 'Planner',
        action: 'Sort',
        label: on,
    });

    return {
        type: SORT,
        payload: {on},
    }
}