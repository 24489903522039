import update from 'immutability-helper';

const initialState = {
    items: [],
    fetched: false,
    fetching: false,

    destinations: [],
    fetchedDestinations: false,
    fetchingDestinations: false,

    routes: [],
    activeRoute: null,
    fetchedRoutes: false,
    fetchingRoutes: false,
};

export default (state = initialState, action)  => {

    switch (action.type){
        case "FETCH_INTERACTIVE_MAP_ITEMS":
            return update(state, {
                fetched: {$set: false},
                fetching: {$set: true}
            });
        case "FETCHED_INTERACTIVE_MAP_ITEMS":
            return update(state, {
                fetched: {$set: true},
                fetching: {$set: false},
                items: {$set: action.payload}
            });

        case "FETCH_INTERACTIVE_MAP_DESTINATIONS":
            return update(state, {
                fetchedDestinations: {$set: false},
                fetchingDestinations: {$set: true},
                destinations: {$set: []}
            });
        case "FETCHED_INTERACTIVE_MAP_DESTINATIONS":
            return update(state, {
                fetchedDestinations: {$set: true},
                fetchingDestinations: {$set: false},
                destinations: {$set: action.payload}
            });

        case "FETCH_INTERACTIVE_MAP_ROUTES":
            return update(state, {
                fetchingRoutes: {$set: true},
                fetchedRoutes: {$set: false}
            });
        case "FETCHED_INTERACTIVE_MAP_ROUTES":
            return update(state, {
                fetchingRoutes: {$set: false},
                fetchedRoutes: {$set: true},
                routes: {$set: action.payload},
                activeRoute: {$set: action.payload.activeRoute},
            });
        default:
            return state;
    }
}