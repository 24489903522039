import React, {Component} from 'react';
import {Button, Icon, Label} from "semantic-ui-react";

export default class TipTag extends Component {
    static getIcon(tag) {
        switch (tag) {
            case "train":
                return <Icon name="train"/>;
            // return <ModeIcon mode={MODES.TRAIN}/>;
            case "bus":
                return <Icon name="bus"/>;
            // return <ModeIcon mode={MODES.BUS}/>;
            case "car":
                return <Icon name="car"/>;
            case "aviation":
                return <Icon name="plane"/>;
            // return <ModeIcon mode={MODES.FLYING}/>;
            case "tickets":
                return <Icon name="ticket"/>;
            case "website":
                return <Icon name="computer"/>;
            case "map":
                return <Icon name="map"/>;
            case "sustainability":
                return <Icon name="leaf"/>;
            case "report":
                return <Icon name="file"/>;
            case "inspiration":
                return <Icon name="idea"/>;
            case "product":
                return <Icon name="gift"/>;
            case "tourism":
                return <Icon name="travel"/>;
            case "article":
                return <Icon name="file text"/>;
            case "dutch":
                return <Icon name="flag"/>;
            case "travel agency":
                return <Icon name="phone"/>;
            default:
                return null;
        }
    }

    static getColor(tag, active) {
        //redorangeyellowolivegreentealbluevioletpurplepinkbrowngreyblack
        if (active) return "grey";
        switch (tag) {
            // case "train":
            // case "bus":
            // case "aviation":
            //     return "black";
            // case "sustainability":
            //     return "green";
            // case "website":
            //     return "blue";
            // case "inspiration":
            //     return "orange";
            default:
                return "black";
        }
    }

    render() {
        const {tag, button, filterTags, onClick} = this.props;
        if(!tag)return null;
        const inactive = (filterTags && filterTags.length > 0 && filterTags.indexOf(tag) === -1);
        let icon = TipTag.getIcon(tag);
        return button ? <Button basic={inactive || (!filterTags || filterTags.length === 0)} size="mini" className="TipTag"
                                color={TipTag.getColor(tag, inactive)} onClick={() => {
                const currentFilterPos = filterTags.indexOf(tag);
                let newFilterTags = filterTags;
                if (currentFilterPos === -1) {
                    newFilterTags = [...filterTags, tag];
                } else {
                    newFilterTags.splice(currentFilterPos, 1);
                }
                onClick(newFilterTags)
            }}>
                {icon}
                {tag.toUpperCase()}
            </Button>
            :
            <Label size="mini" className="TipTag" basic color={TipTag.getColor(tag)}>
                {icon}
                {tag.toUpperCase()}
            </Label>;
    }
}
