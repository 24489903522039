import React from 'react';
import {climateImpactScale, durationScale, transfersScale} from "../../utils";
import * as utils from "../../utils";
import {Grid, Icon, Label, Popup} from "semantic-ui-react";
import {NavLink} from "react-router-dom";
import unique from 'array-unique';
import ModeIcon from "../ModeIcon";
import {MODES} from "../../constants";

const ModalityInfo = ({mode, routes, onClick}) => {

    let durations = routes.map(route => route.duration).sort((a, b) => a - b);
    let durationFactors = routes.map(route => route.durationFactor).sort((a, b) => a - b);
    let transfers = routes.map(route => route.transfers).unique().sort((a, b) => a - b);
    let transfersFactors = routes.map(route => route.transfersFactor).unique().sort((a, b) => a - b);
    let impacts = routes.map(route => Math.round(route.co2)).unique().sort((a, b) => a - b);
    let impactFactors = routes.map(route => route.co2Factor).unique().sort((a, b) => a - b);
    let prices = routes.map(route => route.price || 0).filter(price => price > 0).unique().sort((a, b) => a - b);

    return <Grid.Row className="modality-info new" onClick={onClick}>
            <Grid centered>
                <Grid.Row>
                    <Grid.Column tablet={3} computer={3} mobile={2}>
                        <ModeIcon mode={mode} size="large"/><br/>
                        {routes.length} route{routes.length === 1 ? "" : "s"} found
                    </Grid.Column>
                    <Grid.Column tablet={3} computer={3} mobile={4}>
                        <div className="bar" style={{background: "linear-gradient(to right, " + durationScale(durationFactors[0]).hex() + " ,  " + durationScale(durationFactors[[durationFactors.length - 1]]).hex() + ")"}} />
                        <Icon name="clock outline" className="icon" size="huge"/>
                        <div className="subtitle">Duration:</div>
                        <span className="value">
                            {utils.formatHoursAsString(durations[0] / 60)}
                            {durations.length > 1 && <span>
                            <span className="dash"> &mdash;</span><br/>
                                {utils.formatHoursAsString(durations[durations.length - 1] / 60)}
                            </span>}
                        </span>
                    </Grid.Column>
                    <Grid.Column width={3}>
                        <div className="bar" style={{background: "linear-gradient(to right, " + transfersScale(transfersFactors[0]).hex() + " ,  " + transfersScale(transfersFactors[[transfersFactors.length - 1]]).hex() + ")"}} />
                        <Icon name="shuffle" className="icon" size="huge"/>
                        <div className="subtitle">Nr of transfers:</div>
                        <span className="value">
                            {transfers[0]}
                            {transfers.length > 1 && <span>
                            <span className="dash"> &mdash;</span>
                                {transfers[transfers.length - 1]}
                            </span>}
                        </span>
                    </Grid.Column>
                    <Grid.Column width={3}>
                        <div className="bar" style={{background: "linear-gradient(to right, " + climateImpactScale(impactFactors[0]).hex() + " ,  " + climateImpactScale(impactFactors[[impactFactors.length - 1]]).hex() + ")"}} />
                        <div className="icon">CO<sub>2</sub></div>
                        <div className="subtitle">Climate impact:</div>
                        <span className="value">
                            {impacts[0]}
                            {impacts.length > 1 ? <span>
                            <span className="dash"> &mdash;</span>
                                {impacts[impacts.length - 1]} kg
                            </span> : <span>kg</span>}
                        </span>
                    </Grid.Column>
                    <Grid.Column width={3}>
                        <div className="bar"/>
                        <Icon name="euro" className="icon" size="huge"/>
                        <div className="subtitle">Price:</div>
                        {prices.length > 0 ?
                        <span className="value">
                            {utils.formatPriceAsString(prices[0])}
                            {prices.length > 1 && <span>
                            <span className="dash"> &mdash;</span>
                                {utils.formatPriceAsString(prices[prices.length - 1])}
                            </span>}
                        </span> :
                            <i className={"fal fa-question"}/>
                        }
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </Grid.Row>;
};
export default ModalityInfo;