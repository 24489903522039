
function minutesToTimeString(time) {
    if (isNaN(time)) return "";
    time = parseInt(time, 10);
    let pre = "";
    if (time < 0) {
        pre = "-";
        time = Math.abs(time);
    }
    let hours = Math.floor(time / 60);
    let minutes = ("0" + (time - hours * 60)).substr(-2);
    return pre + hours + ":" + minutes;
}
function minutesToTimeDurationString(time) {
    if (isNaN(time)) return "";
    time = parseInt(time, 10);
    let pre = "";
    if (time < 0) {
        pre = "-";
        time = Math.abs(time);
    }
    let hours = Math.floor(time / 60);
    let minutes = ("0" + (time - hours * 60)).substr(-2);
    if (hours === 0) {
        return pre + minutes + "m"
    }
    return pre + hours + "h " + minutes + "m";
}

function formatPrice(price) {
    const nf = new Intl.NumberFormat(`nl-NL`, {
           style: 'currency',
           currency: 'EUR',
//         maximumFractionDigits: 2
    });
    return nf.format(price);
}

export {minutesToTimeString, minutesToTimeDurationString, formatPrice};