import React, {Component} from 'react';
import update from 'immutability-helper';
import {connect} from "react-redux";
// import {Button, Clearfix, Form, FormControl} from "react-bootstrap";
import * as RouteActions from "../actions/RouteActions";
import * as AutocompleteActions from "../actions/AutocompleteActions";
import {Container, Grid, Label, Popup} from "semantic-ui-react";
import {dateConstraint, MODES} from "../constants";
import moment from "moment";
import Autocomplete from '../components/AutocompletePlaceInput.js';
import ModeIcon from "./ModeIcon";
import InfiniteCalendar from 'react-infinite-calendar';
import 'react-infinite-calendar/styles.css';
import TimePicker from "./TimePicker";
import * as ReactGA from "react-ga"; // Make sure to import the default stylesheet
import Donation from "./Donation";
import {NavLink} from "react-router-dom";
// import Loading from "../components/Loading";

class PlannerInput extends Component {

    constructor(props) {
        super(props);

        let from = PlannerInput.locationFromURLString(props.from) || {name: ""};
        let to = PlannerInput.locationFromURLString(props.to) || {name: ""};
        let modes = PlannerInput.modesFromURLString(props.by) || {
            driving: true,
            transit: true,
            flights: true,
        };
        // let fromGPS = ucfirst(props.from && decodeURIComponent(props.from.replace("+", "%20"))) || "";
        // let toGPS = ucfirst(props.to && decodeURIComponent(props.to.replace("+", "%20"))) || "";
        let dateInfo = PlannerInput.dateFromURLString(props.on);
        let date = (dateInfo && dateInfo.date) || moment("10:00", "H:m").add(1, "d").add(1, "h").minutes(0).seconds(0);
        let dateConstraintSet = (dateInfo && dateInfo.dateConstraint) || dateConstraint.DEPARTURE;

        this.state = {
            changingName: false,
            form: {
                from: from,
                // fromGPS: fromGPS,
                to: to,
                // toGPS: toGPS,
                time: date.format("H:mm"),
                date: date.format("DD/MM/Y"),
                dateMoment: date,
                dateConstraint: dateConstraintSet,
                modes,
                shouldSearchAgain: [],
            }
        };

        this.handleChangeForField = this.handleChangeForField.bind(this);
        this.submitForm = this.submitForm.bind(this);
        this.parsePosition = this.parsePosition.bind(this);
        // this.handleAutocompleteChange = this.handleAutocompleteChange.bind(this);
        this.selectPlaceID = this.selectPlaceID.bind(this);
        this.setInput = this.setInput.bind(this);
        this.toggleMode = this.toggleMode.bind(this);
    }

    setInput(label, el) {
        this[label] = el;
    }

    toggleMode(mode) {
        ReactGA.event({
            category: 'Planner',
            action: 'Mode toggled',
            label: mode,
            value: !this.state.form.modes[mode],
        });
        const newState = update(this.state, {
            form: {
                modes: {
                    [mode]: {$set: !this.state.form.modes[mode]}
                }
            }
        });

        newState.shouldSearchAgain = [];
        for (mode of Object.keys(this.state.form.modes)) {
            if (newState.form.modes[mode] && !this.props.routes.modes[mode].fetched) {
                newState.shouldSearchAgain.push(mode)
            }
        }

        this.setState(newState);
        this.props.updateModeFilters(newState.form.modes);
    }

    // handleChange(event) {
    //     this.setState({
    //         form:
    //             update(this.state.form, {
    //                 [event.target.name]: {$set: event.target.value}
    //             })
    //     });
    // }

    handleChangeForNameField(label, value) {
        this.setState({
            form:
                update(this.state.form, {
                    [label]: {
                        $set: {name: value}
                    }
                })
        });
        this.props.fetchSuggestions(label, value);
    }

    handleChangeForField(label, value) {
        this.setState({
            form:
                update(this.state.form, {
                    [label]: {$set: value}
                })
        });

    }

    handleChangeForDateField(value, format) {
        ReactGA.event({
            category: 'Planner',
            action: 'Date changed',
        });
        let newDateMoment = moment(value, format);
        let dateMoment = moment(this.state.form.dateMoment);
        dateMoment.date(newDateMoment.date());
        dateMoment.month(newDateMoment.month());
        dateMoment.year(newDateMoment.year());
        this.setState({
            form:
                update(this.state.form, {
                    date: {$set: dateMoment.format("DD/MM/Y")},
                    dateMoment: {$set: dateMoment}
                })
        });
    }

    handleChangeForTimeField(value, format) {
        ReactGA.event({
            category: 'Planner',
            action: 'Time changed',
        });
        let dateMoment = format ? moment(value, format) : value;
        this.setState({
            form:
                update(this.state.form, {
                    time: {$set: dateMoment.format("H:mm")},
                    dateMoment: {$set: dateMoment}
                })
        });
    }

    selectPlaceID(label, value) {
        if (value === "GPS") {

            let _this = this;
            navigator.geolocation.getCurrentPosition((position, error) => {
                _this.parsePosition(position, label)
            });
            let form = this.state.form;
            form[label].name = "Loading location...";
            this.setState({form});

        } else if (parseInt(value, 10).toString() === value && this.props.autocomplete[label][parseInt(value, 10)]) {
            ReactGA.event({
                category: 'Planner',
                action: 'Autocomplete',
                label: "Local history used",
            });
            let form = this.state.form;
            form[label] = this.props.autocomplete[label][parseInt(value, 10)];
            this.setState({form});
        } else {
            ReactGA.event({
                category: 'Planner',
                action: 'Autocomplete',
                label: "Place selected",
            });
            let placesService = new window.google.maps.places.PlacesService(document.createElement('div'));
            let autocompleteInfo = this.props.autocomplete[label].filter(place => place.place_id === value)[0];
            placesService.getDetails({placeId: value, fields: ["address_component", "geometry", "type"]}, (place) => {
                let form = this.state.form;
                let country = null;
                if (place.address_components) {
                    for (let c of place.address_components) {
                        if (c.types.indexOf("country") > -1) {
                            country = c.short_name;
                        }
                    }
                }
                form[label] = {
                    name: autocompleteInfo.description,
                    // address: place.formatted_address,
                    ...place.geometry.location.toJSON(),
                    type: place.types[0],
                    country
                };
                this.setState({form});
                // if(label === "from" && this.fromInput.hasFocus()){
                // this.toInput.focus();
                // } else if(label === "from" && this.toInput.hasFocus()) {
                // this.toInput.blur();
                // }
            })
        }
        // if(label === "from") {
        //     this.toInput.focus();
        // }

        if (label === "from") {
            if (this.state.form.to && this.state.form.to.name) {
                this.fromInput.blur();
            } else {
                this.toInput.focus();
            }
        } else if (label === "to") {
            this.toInput.blur();
        }
    }

    componentDidMount() {
        if (this.state.form.from.lat && this.state.form.to.lat) {
            this.submitForm();
            // return;
        } else if (this.state.form.from.name === "") {
            // if (navigator.geolocation) {
            // navigator.geolocation.getCurrentPosition(this.parsePosition);
            // } else {
            //Focus on from field
            // this.fromInput.focus();
            // }
        } else if (this.state.form.to.name === "") {
            //Focus on to field
            this.toInput.focus();
        } else {
            this.submitForm();
        }


        // if(window.google) {
        //
        //     // let defaultBounds = new window.google.maps.LatLngBounds(
        //     //     new window.google.maps.LatLng(36.192586, -12.439226),
        //     //     new window.google.maps.LatLng(71.484764, 32.152987)
        //     // );
        //
        //     // let options = {
        //     //     bounds: defaultBounds,
        //     // };
        //
        //     // this.fromAutocomplete = new window.google.maps.places.Autocomplete(this.fromInput, options);
        //     // this.toAutocomplete = new window.google.maps.places.Autocomplete(this.toInput, options);
        //     // this.fromAutocomplete.addListener('place_changed', this.handleAutocompleteChange);
        //     // this.toAutocomplete.addListener('place_changed', this.handleAutocompleteChange);
        // }

    }

    // handleAutocompleteChange(){
    //     let form = this.state.form;
    //     let fromPlace = this.fromAutocomplete.getPlace();
    //     console.log(fromPlace);
    //     if(fromPlace){
    //         form.from = {name: this.fromInput.value, ...fromPlace.geometry.location.toJSON(), type: fromPlace.type};
    //         // form.fromGPS = fromPlace.geometry.location.toUrlValue();
    //         // form.from = fromPlace.formatted_address;
    //     }
    //     let toPlace = this.toAutocomplete.getPlace();
    //     if(toPlace){
    //         form.to = {name: this.toInput.value, ...toPlace.geometry.location.toJSON(), type: toPlace.type};
    //         // form.toGPS = toPlace.geometry.location.toUrlValue();
    //         // form.to = toPlace.formatted_address;
    //     }
    //     this.setState({form});
    // }

    parsePosition(position, label) {

        // console.log(position);
        ReactGA.event({
            category: 'Planner',
            action: 'Autocomplete',
            label: "Browser GPS Parsed",
        });

        let geocoder = new window.google.maps.Geocoder();
        geocoder.geocode({
            'location': {
                lat: position.coords.latitude,
                lng: position.coords.longitude
            }
        }, function (results, status) {
            // console.log(status, results);
            if (results && results.length > 0) {
                let form = this.state.form;
                // console.log(form.from);
                form[label].lat = position.coords.latitude;
                form[label].lng = position.coords.longitude;
                form[label].name = results[0].formatted_address;
                let country = null;
                if (results[0].address_components) {
                    for (let c of results[0].address_components) {
                        if (c.types.indexOf("country") > -1) {
                            country = c.short_name;
                        }
                    }
                }
                form[label].country = country;
                this.setState({form: form});
                //Focus on to
                this.toInput && this.toInput.focus();
            } else {
                //Focus on from
                this.fromInput.focus();
            }
        }.bind(this));
        // console.log(position);
    }

    submitForm(e) {

        if (e) e.preventDefault();
        let form = {...this.state.form};
        // let form = {
        //     from: {this.state.form.from,
        //     to: this.state.form.to,
        //     time: this.state.form.time,
        //     date: this.state.form.date,
        //     dateConstraint: this.state.form.dateConstraint,
        // };
        const {fetchRoutes} = this.props;
        form.date = moment(form.date + " " + form.time, "DD/MM/Y H:m");

        let url = PlannerInput.formToURLString(form);


        //Check if new request
        if (!e && this.props.routes.query &&
            PlannerInput.formToURLString(this.props.routes.query) === url) return;

        fetchRoutes(form);
        console.log(form, url);


        if (url) {
            this.props.history.replace(url);
        }

        PlannerInput.saveOriginAndDestination(form.from, form.to);

        return false;
    }

    static saveOriginAndDestination(from, to) {
        let fromItems = [];
        if (window.localStorage.getItem("fromHistory")) fromItems = JSON.parse(window.localStorage.getItem("fromHistory"));
        fromItems.unshift(from);
        //https://stackoverflow.com/a/14438954/886283
        window.localStorage.setItem("fromHistory", JSON.stringify(fromItems.filter((obj, pos, arr) => {
            return arr.map(mapObj => mapObj["name"]).indexOf(obj["name"]) === pos;
        })));

        let toItems = [];
        if (window.localStorage.getItem("toHistory")) toItems = JSON.parse(window.localStorage.getItem("toHistory"));
        toItems.unshift(to);
        //https://stackoverflow.com/a/14438954/886283
        window.localStorage.setItem("toHistory", JSON.stringify(toItems.filter((obj, pos, arr) => {
            return arr.map(mapObj => mapObj["name"]).indexOf(obj["name"]) === pos;
        })));
    }

    cancel() {
        this.props.cancelRoutes();
    }

    static formToURLString(form) {
        let saveFrom = PlannerInput.locationToURLString(form.from);
        let saveTo = PlannerInput.locationToURLString(form.to);
        let modesString = PlannerInput.modesToURLString(form.modes);

        if (saveFrom && saveTo) {
            return "/from/" + saveFrom +
                "/to/" + saveTo +
                "/on/" + form.dateConstraint + "_" + form.date.unix() +
                (modesString ? ("/by/" + modesString) : "");
        }
    }

    static modesToURLString(modes) {
        //car,transit,plane
        if (!Object.values(modes).includes(false)) return null;

        let modeParts = [];
        if (modes["driving"]) modeParts.push("car");
        if (modes["transit"]) modeParts.push("transit");
        if (modes["flights"]) modeParts.push("plane");
        return modeParts.join(",");
    }

    static modesFromURLString(modesString) {
        if (!modesString) return null;
        let modeParts = modesString.split(",");
        return {
            driving: modeParts.includes("car"),
            transit: modeParts.includes("transit"),
            flights: modeParts.includes("plane"),
        }
    }

    static locationToURLString(location) {
        if (!location || !location.lat || !location.name) return null;
        return (location.lat + "").substr(0, 10) + "_" +
            (location.lng + "").substr(0, 10) + "_" +
            encodeURIComponent(location.name).replace("%20", "+") + "_" +
            encodeURIComponent(location.country)
    }

    static locationFromURLString(string) {
        if (typeof string !== "string") return null;
        let parts = string.split("_");
        if (parts.length < 3) return null;
        return {
            lat: parseFloat(parts[0]),
            lng: parseFloat(parts[1]),
            name: decodeURIComponent(parts[2].replace("+", "%20")),
            country: decodeURIComponent(parts[3]),
        }
    }

    static dateFromURLString(string) {
        if (typeof string !== "string") return null;
        let parts = string.split("_");
        if (parts.length !== 2) return null;
        return {
            dateConstraint: parts[0],
            date: moment.unix(parts[1])
        }
    }

    render() {
        return (
            <Grid>
                <Grid.Row style={{padding: 0}}>
                    {this.props.splash &&
                    <Grid.Column width={6}>
                        <Donation/>
                    </Grid.Column>
                    }
                    <Grid.Column width={this.props.splash ? 10 : 16} style={{padding: 0}}>

                        <form onSubmit={(e) => this.submitForm(e)}>
                            {/*<img className="graphic" src={graphic} />*/}
                            <div className="main">

                                <Label color='red' size="huge" className="beta-corner-huge" corner="left">
                                    <div>BETA</div>
                                </Label>
                                {/*{this.props.splash &&*/}
                                {/*<Label color='red' size="medium" corner="left">*/}
                                    {/*<div>BETA</div>*/}
                                {/*</Label>*/}
                                {/*}*/}
                                <h2 className="try">Try the first version of our travel planner:</h2><br />
                                {/*<div className="try-sub"><em>Note: We mainly support The Netherlands and Germany for now.</em></div>*/}
                                <Container>
                                    <Grid className="route-form" centered stackable columns={3}>
                                        <Grid.Row className="large-fields autocomplete">
                                            <Grid.Column className="first" size={5}>
                                                {/*<input*/}
                                                {/*placeholder="From address"*/}
                                                {/*autoComplete="off"*/}
                                                {/*value={this.state.form.from.name}*/}
                                                {/*ref={el => this.fromInput = el}*/}
                                                {/*onChange={(e) => this.handleChangeForNameField("from", e.target.value)}*/}
                                                {/*type="text" />*/}
                                                <Autocomplete
                                                    inputRef={el => this.fromInput = el}
                                                    items={this.props.autocomplete["from"]}
                                                    name="from"
                                                    value={this.state.form.from.name}
                                                    inputProps={{
                                                        placeholder: "From | Enter an address or place",
                                                        className: (this.props.autocomplete["from"] && this.props.autocomplete["from"].length > 0) ? "autocomplete" : ""
                                                    }}
                                                    onChange={(e) => this.handleChangeForNameField("from", e.target.value)}
                                                    onSelect={(value) => this.selectPlaceID("from", value)}
                                                    selectOnBlur={true}
                                                />
                                            </Grid.Column>
                                            {/*<Grid.Column size={1}>*/}
                                            {/*<Icon name="arrow right" />*/}
                                            {/*</Grid.Column>*/}
                                            <Grid.Column size={5}>
                                                {/*<input*/}
                                                {/*placeholder="To address"*/}
                                                {/*autoComplete="off"*/}
                                                {/*value={this.state.form.to.name}*/}
                                                {/*ref={el => this.toInput = el}*/}
                                                {/*onChange={(e) => this.handleChangeForNameField("to", e.target.value)}*/}
                                                {/*type="text" />*/}
                                                <Autocomplete
                                                    inputRef={el => this.toInput = el}
                                                    items={this.props.autocomplete["to"]}
                                                    name="to"
                                                    value={this.state.form.to.name}
                                                    inputProps={{
                                                        placeholder: "To | e.g. Berlin Hbf",
                                                        className: (this.props.autocomplete["to"] && this.props.autocomplete["to"].length > 0) ? "autocomplete" : ""
                                                    }}
                                                    onChange={(e) => this.handleChangeForNameField("to", e.target.value)}
                                                    onSelect={(value) => this.selectPlaceID("to", value)}
                                                    selectOnBlur={true}
                                                />
                                            </Grid.Column>
                                            <Grid.Column width={2/*3*/}>
                                                <input className="search" type="submit" value="Search!"
                                                       disabled={!this.state.form.from.lat || !this.state.form.to.lat || this.props.fetching}/>
                                                {/*<input className="cancel" type="button" value="Cancel!" onClick={this.cancel.bind(this)} />*/}
                                                {this.props.fetching && (
                                                    <i className="spinner fas fa-circle-notch fa-spin"/>
                                                )}
                                            </Grid.Column>
                                        </Grid.Row>
                                    </Grid>
                                </Container>
                            </div>
                            <div className="sub">
                                <Container>
                                    <Grid centered className="route-form">
                                        <Grid.Row className="large-fields">
                                            {/*<Grid.Column width={7} className="time">*/}
                                                {/*<div className="type">*/}
                                                {/*<span*/}
                                                    {/*className={this.state.form.dateConstraint === dateConstraint.DEPARTURE ? "selected" : ""}*/}
                                                    {/*onClick={(e) => this.handleChangeForField("dateConstraint", dateConstraint.DEPARTURE)}>Departing</span><br/>*/}
                                                    {/*<span*/}
                                                        {/*className={this.state.form.dateConstraint === dateConstraint.ARRIVAL ? "selected" : ""}*/}
                                                        {/*onClick={(e) => this.handleChangeForField("dateConstraint", dateConstraint.ARRIVAL)}>Arriving</span><br/>*/}
                                                {/*</div>*/}
                                                {/*<div className="time-input">*/}

                                                    {/*<Popup trigger={*/}
                                                        {/*<input type="text" value={this.state.form.time}*/}
                                                               {/*onChange={(e) => this.handleChangeForTimeField(e.target.value, "H:mm")}/>*/}
                                                    {/*} position="bottom center" on="focus" className="time-picker-popup">*/}
                                                        {/*<TimePicker dateMoment={this.state.form.dateMoment}*/}
                                                                    {/*onChange={date => this.handleChangeForTimeField(date)}/>*/}
                                                    {/*</Popup>*/}
                                                {/*</div>*/}
                                            {/*</Grid.Column>*/}
                                            <Grid.Column width={4} className="date">
                                                {/*{!this.props.onHomepage &&*/}
                                                <div className="date-input">
                                                    <Popup trigger={
                                                        <input type="text" value={this.state.form.date}
                                                               onChange={(e) => this.handleChangeForDateField(e.target.value, "DD/MM/Y")}/>
                                                    } position="bottom center" on="focus" className="date-picker-popup">
                                                        <InfiniteCalendar
                                                            minDate={moment().add(1, "day").toDate()}
                                                            min={moment().add(1, "day").toDate()}
                                                            width={400}
                                                            height={400}
                                                            selected={this.state.form.dateMoment && this.state.form.dateMoment.toDate()}
                                                            onSelect={date => this.handleChangeForDateField(date)}
                                                            rowHeight={50}
                                                            locale={{
                                                                weekStartsOn: 1
                                                            }}
                                                            displayOptions={{
                                                                showHeader: false,
                                                            }}
                                                            // disabledDays={[0,6]}
                                                            // minDate={lastWeek}
                                                        />
                                                    </Popup>
                                                </div>
                                                {/* } */}
                                            </Grid.Column>
                                            <Grid.Column width={5}>

                                                {/*<div className="mode-icons">*/}

                                                    {/*<div className="mode-icon">*/}
                                                        {/*<ModeIcon mode={MODES.TRAIN}*/}
                                                                  {/*active={this.state.form.modes.transit}*/}
                                                                  {/*onClick={(e) => this.toggleMode("transit")}/>*/}
                                                        {/*/!*<i className={"mode fas fa-" + modeIcons[MODES.TRAIN] + (this.state.form.modes.transit ? " active" : "")}*!/*/}
                                                        {/*/!* onClick={(e) => this.toggleMode("transit")}/>*!/*/}
                                                        {/*/!*<i className={"fas fa-circle small " + (this.props.modes.transit.fetching ? "visible" : "")}/>*!/*/}
                                                    {/*</div>*/}
                                                    {/*<div className="mode-icon">*/}
                                                        {/*<ModeIcon mode={MODES.FLYING}*/}
                                                                  {/*active={this.state.form.modes.flights}*/}
                                                                  {/*onClick={(e) => this.toggleMode("flights")}/>*/}
                                                        {/*/!*<i className={"mode fas fa-" + modeIcons[MODES.FLYING] + (this.state.form.modes.flights ? " active" : "")}*!/*/}
                                                        {/*/!*onClick={(e) => this.toggleMode("flights")}/>*!/*/}
                                                        {/*/!*<i className={"fas fa-circle small " + (this.props.modes.flights.fetching ? "visible" : "")}/>*!/*/}
                                                    {/*</div>*/}
                                                    {/*<div className="mode-icon">*/}
                                                        {/*<ModeIcon mode={MODES.DRIVING}*/}
                                                                  {/*active={this.state.form.modes.driving}*/}
                                                                  {/*onClick={(e) => this.toggleMode("driving")}/>*/}
                                                        {/*/!*<i className={"mode fas fa-" + modeIcons[MODES.DRIVING] + (this.state.form.modes.driving ? " active" : "")}*!/*/}
                                                        {/*/!*onClick={(e) => this.toggleMode("driving")}/>*!/*/}
                                                        {/*/!*<i className={"fas fa-circle small " + (this.props.modes.driving.fetching ? "visible" : "")}/>*!/*/}
                                                    {/*</div>*/}
                                                    {/*<div className="search-again">*/}
                                                        {/*/!*{this.state.shouldSearchAgain && this.state.shouldSearchAgain.length > 0 && "Search again to include " + this.state.shouldSearchAgain.join(" and ")}*!/*/}
                                                    {/*</div>*/}

                                                {/*</div>*/}


                                            </Grid.Column>
                                        </Grid.Row>
                                    </Grid>
                                </Container>
                            </div>
                        </form>

                    </Grid.Column>
                </Grid.Row>
            </Grid>
        );
    }
}

export default connect(state => ({
    routes: state.routes,
    fetching: state.routes.fetching,
    autocomplete: state.autocomplete.labels,
    modes: state.routes.modes,
}), dispatch => ({
    fetchRoutes: (form) => dispatch(RouteActions.fetchRoutes(form)),
    cancelRoutes: () => dispatch(RouteActions.cancelRoutes()),
    fetchSuggestions: (label, value) => dispatch(AutocompleteActions.fetchSuggestions(label, value)),
    updateModeFilters: (mode, on) => dispatch(RouteActions.updateModeFilters(mode, on)),
}))(PlannerInput);