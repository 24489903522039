import React from 'react';
import {MODES} from "../../constants";
import ModeIcon from "../ModeIcon";
import RoutePartInfo from "./RoutePartInfo";
import {Loader} from "semantic-ui-react";

const RoutePart = (props) => {
    let style = {};
    let mode = props.mode;
    if (mode === MODES.ESTIMATION && props.pendingAddTransit) mode = MODES.FETCHING;
    if (props.carrier && props.carrier.color && !props.new) {
        style.backgroundColor = props.carrier.color;
    }
    let full = props.width > 100;
    let showStartTime = !props.waitAfterFlight &&
        mode !== MODES.WALKING && mode !== MODES.FETCHING && mode !== MODES.ESTIMATION; //(!props.last && )
    let showEndTime = full && !props.waitBeforeFlight &&
        mode !== MODES.WALKING && mode !== MODES.WAITING && mode !== MODES.FETCHING && mode !== MODES.ESTIMATION;
    if(props.notBoundToTime) {
        showStartTime = false;
        showEndTime = false;
    }
    return (
        <div className="route-part new" style={{width: props.width}}>
            {(mode === MODES.FLYING || mode === MODES.BOAT) && props.flightAfterWait && (
                <div className="flight-background-fix left"/>
            )}
            {(mode === MODES.FLYING || mode === MODES.BOAT) && props.flightBeforeWait && (
                <div className="flight-background-fix right"/>
            )}
            {showStartTime && (
                <div className={"part-start-time" + (!full ? " full" : "")}>{props.departureDate.format("H:mm")}</div>
            )}
            {showEndTime && (
                <div className="part-end-time">{props.arrivalDate.format("H:mm")}</div>
            )}
            <div
                className={"bar " + mode + (props.waitAfterFlight ? " wait-after-flight" : "") + (props.waitBeforeFlight ? " wait-before-flight" : "") + (mode === MODES.ESTIMATION ? " estimation" : "")}
                style={style}>
                <div className="icon">
                    {mode === MODES.FETCHING ? <Loader active={true} inline size="mini" inverted/> :
                        <ModeIcon mode={mode} light={mode !== MODES.WAITING}/>}
                </div>
                <div className="description">
                    {props.longName}
                </div>
            </div>
            {props.from && props.from.name && mode !== MODES.WAITING && (
                <div className={"origin" + (!full ? " full" : "")}>{props.from.name}</div>
            )}
            {full && props.showTo && props.to && props.to.name && mode !== MODES.WAITING && (
                <div className="destination">{props.to.name}</div>
            )}
            <RoutePartInfo {...props} />
        </div>
    );
};

export default RoutePart;