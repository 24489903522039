// @flow
import * as React from 'react';
import type Moment from "moment";

type Props = {
    dateMoment: Moment,
    onChange: (date: Moment) => {},
}

class TimePicker extends React.Component<Props> {

    elementHeight = 32;
    minutesStep = 5;
    hoursElement: ?HTMLDivElement;
    minuteElement: ?HTMLDivElement;

    componentDidMount() {

        let hoursElement = this.hoursElement;
        if(hoursElement) {
            hoursElement.scrollTop = this.elementHeight * this.props.dateMoment.hours();
        }
        let minuteElement = this.minuteElement;
        if(minuteElement) {
            minuteElement.scrollTop = this.elementHeight * (this.props.dateMoment.minute() / this.minutesStep);
        }

    }

    render() {
        let hours = [];
        for (let h = 0; h < 24; h++) {
            hours.push(h)
        }
        let minutes = [];
        for (let m = 0; m < 59; m += this.minutesStep) {
            minutes.push(m)
        }
        let currentHour = this.props.dateMoment.hour();
        let currentMinute = this.props.dateMoment.minute();
        return <div className="time-picker">
            <div className="hours header">Hour :</div>
            <div className="minutes header">&nbsp;Minutes</div>
            <br style={{clear: "both"}}/>
            <div className="hours" ref={el => (this.hoursElement = el)}>
                <ul>
                    {hours.map(hour =>
                        <li key={hour} className={hour === currentHour ? "selected" : ""}
                            onClick={() => this.props.onChange(this.props.dateMoment.hour(hour))}>
                            {hour}
                        </li>
                    )}
                </ul>
            </div>
            <div className="minutes" ref={el => (this.minuteElement = el)}>
                <ul>
                    {minutes.map(minute =>
                        <li key={minute} className={minute === currentMinute ? "selected" : ""}
                            onClick={() => this.props.onChange(this.props.dateMoment.minute(minute))}>
                            {("0" + minute).slice(-2)}
                        </li>
                    )}
                </ul>
            </div>
        </div>;
    }
};


export default TimePicker;