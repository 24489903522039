import 'whatwg-fetch';

export const FETCH_SUGGESTIONS = "FETCH_SUGGESTIONS";

export function fetchSuggestions(label, value) {

    const service = new window.google.maps.places.AutocompleteService();
    const bounds = new window.google.maps.LatLngBounds(
        new window.google.maps.LatLng(36.192586, -12.439226),
        new window.google.maps.LatLng(71.484764, 32.152987)
    );

    return (dispatch, state) => {

        if (value === ""){
            dispatch({
                type: FETCH_SUGGESTIONS,
                label,
                value,
                results: [],
            });
        } else {
            service.getPlacePredictions({input: value, bounds}, (results) => {
                // console.log(results);
                dispatch({
                    type: FETCH_SUGGESTIONS,
                    label,
                    value,
                    results,
                });
            });
        }
    }
}