import update from 'immutability-helper';
import * as AutocompleteActions from "../actions/AutocompleteActions";

// const EUCountryCodes = ["BE", "BG", "CZ", "DK", "DE", "EE", "IE", "EL", "ES", "FR", "HR", "IT", "CY", "LV", "LT", "LU", "HU", "MT", "NL", "AT", "PL", "PT", "RO", "SI", "SK", "FI", "SE", "UK", "IS", "NO", "LI", "CH", "ME", "MK", "AL", "RS", "TR", "XK", "BA", "AM", "AZ", "BY", "MD", "GE", "UA"];

const itemsFromLocalstorage = (what) => {

    let list = [];


    if (navigator.geolocation) {
        list.push({
            historical: true,
            place_id: "GPS",
            name: "Use your current location",
        })
    }

    let searchHistory = window.localStorage.getItem(what + "History");

    if (searchHistory) {
        list.concat(JSON.parse(searchHistory).map(
            (item, index) => {
                return {
                    historical: true,
                    place_id: index.toString(),
                    name: item.name,
                    types: [item.type],
                    lat: item.lat,
                    lng: item.lng,
                }
            }
        ));
    }
    return list;
};

export default (state = {
    labels: {
        "from": itemsFromLocalstorage("from") || [],
        "to": itemsFromLocalstorage("to") || [],

        "from-so": [], //Suggestions Only
        "to-so": [],
    },
    fetching: false,
    fetched: false,
}, action) => {
    switch (action.type) {
        case AutocompleteActions.FETCH_SUGGESTIONS:
            // let items = action.results.places
            //     .filter(place => EUCountryCodes.indexOf(place.countryCode) !== -1)
            //     .map(place => {
            //         place.icon = getIconFromKinds(place.kind);
            //         return place;
            //     });
            let results = action.results || [];
            if (results.length === 0 && action.label.indexOf("-so") === -1) {
                results = itemsFromLocalstorage(action.label) || [];
            }
            return update(state, {
                labels: {
                    [action.label]: {$set: results}
                }
            });
        default:
            return state;
    }
};