import React, {Component} from 'react';
import {Button, Card, Container, Grid, Header, Menu, Tab, Table} from "semantic-ui-react";
import logo from "../resources/images/logo-header.png"
import {emissionFactors, holidayDescriptions, holidayOptions, modeDescriptions, MODES} from "../constants";
import CO2Comparison from "../components/CO2Comparison";
import CO2Calculator from "../components/CO2Calculator";
import CO2FlightCalculator from "../components/CO2FlightCalculator";
import CO2EmissionFactors from "../components/CO2EmissionFactors";
import ModeIcon from "../components/ModeIcon";
import {NavLink} from "react-router-dom";
import CO2Compensation from "../homepage/CO2Compensation";
import EmissionsInfographic from "../components/infographics/EmissionsInfographic";
import AdSense from 'react-adsense';

export default class DevPage extends Component {

    showEmissionsOfModes = [
        MODES.TRAIN,
        MODES.BUS,
        MODES.TRAM,
        MODES.METRO,
        MODES.DRIVING,
        MODES.FLYING
    ];

    constructor(props) {
        super(props);


        this.state = {
            co2: null,
            activeIndex: null,
            defaultActiveIndex: 0,
        };
        if (props && props.match && props.match.params) {
            let {part} = props.match.params;

            this.state.defaultActiveIndex = Math.max(0, [
                "calculator",
                "comparison",
                "compensation",
                "factors",
                "infographic"
            ].indexOf(part) || 0);
            this.state.activeIndex = this.state.defaultActiveIndex;
        }

    }

    handleTabChange = (e, {activeIndex}) => this.setState({activeIndex});

    setCO2(co2) {
        this.setState({co2, activeIndex: 1});

        // document.getElementById("CO2Comparison").scrollIntoView();
    }

    render() {

        const types = [
            "Train / Bus",
            "Car",
            "Car Sharing",
            "Plane",
        ];

        let compensationPage = this.props.match && this.props.match.params && this.props.match.params.subpart;

        const {co2, activeIndex, defaultActiveIndex} = this.state;
        const holidayOptionKeys = Object.keys(holidayOptions);
        const holidayOptionValues = Object.values(holidayOptions);

        return (
            <div className="page-container">

                <div className="route-header">
                    <div className="line-background">
                        <div className="line"/>
                        <Container>
                            <Grid>
                                <Grid.Row>
                                    <Grid.Column width={7}>
                                        <img src={logo}
                                             alt="Green Tickets | Building the European travel planner of the future"/>
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>
                        </Container>
                    </div>
                </div>

                <br/>


                <Container>

                    <Grid>
                        <Grid.Row>
                            <a id="planner" name="planner"> </a>
                            <h1 className="large-main-title"><span>climate impact</span></h1>
                        </Grid.Row>
                    </Grid>

                    <Tab activeIndex={activeIndex} onTabChange={this.handleTabChange} menu={{pointing: true}} panes={[
                        {
                            menuItem: {
                                key: 'Calculator',
                                icon: 'calculator',
                                content: "Calculate your emissions",
                                as: NavLink,
                                to: "/climate-impact/calculator"
                            },
                            render: () =>
                                <Card fluid>
                                    <Card.Content>

                                        <h2>Transport Calculator</h2>

                                        <Tab menu={{secondary: true, pointing: true}} panes={[{
                                            menuItem: {key: 'Bus & Train', content: "Bus & Train"},
                                            render: () => <CO2Calculator showCO2={(co2) => this.setCO2(co2)}/>
                                        },
                                        //     {
                                        //     menuItem: {key: 'Flights', content: "Flights"},
                                        //     render: () => <CO2FlightCalculator showCO2={(co2) => this.setCO2(co2)}/>
                                        // }
                                        ]
                                        }/>

                                        <h2>Holidays</h2>
                                        <p>
                                            The following table gives an idea of the climate impact, in to
                                            CO<sub>2</sub>-equivalent,
                                            of different types of holidays.
                                            This includes transport and accommodation.
                                        </p>

                                        <Table className="co2-examples-table">
                                            <Table.Header>
                                                <Table.Row>
                                                    <Table.HeaderCell/>
                                                    {holidayOptionKeys.map(period => <Table.HeaderCell key={period}>
                                                        {holidayDescriptions[period]}
                                                    </Table.HeaderCell>)}
                                                </Table.Row>
                                            </Table.Header>
                                            <Table.Body>
                                                {types.map((type, i) => <Table.Row key={"type" + i}>
                                                    <Table.Cell>{type}</Table.Cell>
                                                    {holidayOptionValues.map((option, j) =>
                                                        <Table.Cell key={"type" + i + "option" + j}>
                                                            {option[type] &&
                                                            <Button size="small"
                                                                    onClick={() => this.setCO2(option[type])}>
                                                                {(option[type] + " kg")}
                                                            </Button>}
                                                        </Table.Cell>
                                                    )}
                                                </Table.Row>)}
                                            </Table.Body>
                                        </Table>
                                    </Card.Content>
                                </Card>
                        }, {
                            menuItem: {
                                key: 'Comparison',
                                icon: 'tree',
                                content: "Comparison",
                                as: NavLink,
                                to: "/climate-impact/comparison"
                            },
                            render: () =>
                                <Card fluid id="CO2Comparison">
                                    <Card.Content>
                                        <h2>Putting things into perspective</h2>
                                        <p>
                                            Although it's tricky to compare apples to pears, it does help to put things
                                            into
                                            perspective by showing the climate impact of different parts of life.
                                        </p>
                                        <CO2Comparison co2={co2}/>

                                    </Card.Content>
                                </Card>
                        }, {
                            menuItem: {
                                key: 'Compensation',
                                icon: 'leaf',
                                content: "Compensation",
                                as: NavLink,
                                to: "/climate-impact/compensation"
                            },
                            render: () =>
                                <CO2Compensation page={compensationPage}/>
                        }, {
                            menuItem: {
                                key: 'Factors',
                                icon: 'list',
                                content: "CO2 Emission Factors",
                                as: NavLink,
                                to: "/climate-impact/factors"
                            },
                            render: () =>
                                <Card fluid>
                                    <Card.Content>
                                        <h2>CO<sub>2</sub> emission factors</h2>
                                        <p>
                                            We try to use as detailed CO<sub>2</sub> emission data as possible, the
                                            table below
                                            shows all different factors used.
                                        </p>
                                        <CO2EmissionFactors/>

                                    </Card.Content>
                                </Card>
                        }, {
                            menuItem: {
                                key: 'Infographic',
                                icon: 'image',
                                content: "Infographic Impact of Travel",
                                as: NavLink,
                                to: "/climate-impact/infographic"
                            },
                            render: () =>
                                <EmissionsInfographic/>
                        },
                    ]}/>

                    {/*<Card fluid>*/}
                    {/*<Card.Content>*/}
                    {/*<Grid>*/}
                    {/*<Grid.Row centered>*/}
                    {/*<Grid.Column width={16}>*/}
                    {/*<a name="climate-impact">*/}
                    {/*<Header as='h1'>*/}
                    {/*Decision Metrics*/}
                    {/*</Header>*/}
                    {/*</a>*/}
                    {/*<p>To help you compare all possible solutions we show a number of metrics for*/}
                    {/*each*/}
                    {/*route:</p>*/}
                    {/*<ul>*/}
                    {/*<li>*/}
                    {/*<h3 style={{marginBottom: 0}}>Climate Impact (CO<sub>2</sub>)</h3>*/}
                    {/*<p>The CO<sub>2</sub> emissions of a route are estimated based on the*/}
                    {/*distance and the type of transportation vehicle:</p>*/}
                    {/*<p/>*/}
                    {/*<Table>*/}
                    {/*<Table.Header>*/}
                    {/*<Table.Row>*/}
                    {/*{this.showEmissionsOfModes.map(mode =>*/}
                    {/*<Table.HeaderCell key={mode}>*/}
                    {/*<ModeIcon mode={mode}/><br/>*/}
                    {/*{modeDescriptions[mode]}*/}
                    {/*</Table.HeaderCell>*/}
                    {/*)}*/}
                    {/*</Table.Row>*/}
                    {/*</Table.Header>*/}
                    {/*<Table.Body>*/}
                    {/*<Table.Row>*/}
                    {/*{this.showEmissionsOfModes.map(mode =>*/}
                    {/*<Table.Cell key={"c" + mode}>*/}
                    {/*{emissionFactors[mode]} kg/km*/}
                    {/*</Table.Cell>*/}
                    {/*)}*/}
                    {/*</Table.Row>*/}
                    {/*<Table.Row>*/}
                    {/*<Table.Cell colSpan={this.showEmissionsOfModes.length}>*/}
                    {/*<small>Based on <a*/}
                    {/*href="https://co2emissiefactoren.nl/lijst-emissiefactoren/"*/}
                    {/*target="_blank"*/}
                    {/*rel="noopener noreferrer">co2emissiefactoren.nl</a>*/}
                    {/*</small>*/}
                    {/*</Table.Cell>*/}
                    {/*</Table.Row>*/}
                    {/*</Table.Body>*/}
                    {/*</Table>*/}
                    {/*<p>*/}
                    {/*<small>*/}
                    {/*Note: One of the improvements we want to make is to calculate*/}
                    {/*climate*/}
                    {/*impact more precisely: by using different factors for different*/}
                    {/*transport providers, countries, airplane types, etc. The*/}
                    {/*emissions of*/}
                    {/*driving should furthermore be customizable for different types*/}
                    {/*of cars.*/}
                    {/*</small>*/}
                    {/*</p>*/}
                    {/*<p/>*/}
                    {/*</li>*/}
                    {/*<li>*/}
                    {/*<h3 style={{marginBottom: 0}}>Price</h3>*/}
                    {/*<p>When possible we request price information directly from the*/}
                    {/*transport*/}
                    {/*provider, otherwise we try to estimate the costs based on the*/}
                    {/*distance and*/}
                    {/*type of transport.</p>*/}
                    {/*<p>For driving routes, the price is calculated on a price of €0,51 per*/}
                    {/*kilometre. (based on <a*/}
                    {/*href="https://www.nibud.nl/consumenten/wat-kost-een-auto/"*/}
                    {/*target="_blank" rel="noopener noreferrer">Nibud</a>)</p>*/}
                    {/*</li>*/}
                    {/*</ul>*/}

                    {/*</Grid.Column>*/}
                    {/*</Grid.Row>*/}

                    {/*/!*<Grid.Row>*!/*/}
                    {/*/!*<Image src={photo} />*!/*/}
                    {/*/!*</Grid.Row>*!/*/}
                    {/*</Grid>*/}
                    {/*</Card.Content>*/}
                    {/*</Card>*/}

                    <div style={{paddingTop: '50px'}}>
                        <AdSense.Google
                            client='ca-pub-5852781193487729'
                            slot='4858849984'
                            style={{ display: 'block' }}
                            format='auto'
                            responsive="true"
                        />
                    </div>

                </Container>

                <br/><br/>

            </div>
        );
    }
}