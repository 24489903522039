// @flow

import React from "react";
import RoadMapItem from "../models/RoadMapItem";
import {Card, Icon, Image} from "semantic-ui-react";

type Props = {
    item: RoadMapItem,
    visible: boolean,
}

type State = {
    animate: boolean,
}

export default class RoadMapItemComponent extends React.Component<Props, State>{

    state = {
        animate: false
    }

    like(){
        const {item} = this.props;

        if(!item.isLiked){
            this.setState({animate: true})
        }else{
            this.setState({animate: false})
        }

        item.like()
            .then(() => this.forceUpdate());
        this.forceUpdate()
    }

    render(){

        const {item, visible} = this.props;

        return visible && <Card fluid>
            {item.image && <Image src={item.image} />}
            <Card.Content>
                <Card.Header>
                    {item.name}
                </Card.Header>
                {item.eta && (<Card.Meta>
                    <span style={{fontWeight: "bold", fontSize: "8pt", color: "#ccc"}}>ETA</span> {item.eta}
                </Card.Meta>)}
                <Card.Description>
                    {item.description}
                </Card.Description>
            </Card.Content>
            <Card.Content extra>
                <Icon name='like' onClick={this.like.bind(this)} color={item.isLiked ? "red" : undefined} />
                {item.likes} like{item.likes !== 1 ? "s" : ""}
                <div className={"roadmap-thanks" + (this.state.animate ? " active":"")}>
                    <div className="balloon one" />
                    <div className="balloon two" />
                    <div className="balloon three" />
                    yay!
                </div>
            </Card.Content>
        </Card>
    }
}